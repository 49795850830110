import React, { useState } from 'react';
import openEye from '../../../assets/Images/openEye.svg';
import closeEye from '../../../assets/Images/closeEye.svg';
import { useAppSelector } from '../../..';

export const Input = ({
    paddingForEye,
    focus,
    // role,
    margin,
    label,
    labeldesign,
    size,
    onChange,
    error,
    value,
    type,
    isTouched,
    design,
    passwordreset,
    impo,
    disabled,
    placeholder,
    ...rest
}: any) => {
    const [passwordType, setPasswordType] = useState(false);
    const { isLoading, role } = useAppSelector(
        ({ signin }: any) => signin.signinValue
    );
    const errorState = isTouched && error;
    const isTrainer = ['TI', 'FL'].includes(role);

    return (
        <div
            className={`${design} flex flex-col justify-between ${
                errorState && margin && 'mb-4'
            }`}
        >
            <label className={`${labeldesign ? labeldesign : 'text-lg'}`}>
                {label}
                {!impo && <span className="ml-1">*</span>}
            </label>
            <div
                className={`w-full relative ${
                    errorState && margin && 'mb-4' && role === 'FL'
                }`}
            >
                {!isTrainer && label === 'Institute URL' ? (
                    <a href={value} target="_blank">
                        <p
                            value={value}
                            // onChange={onChange}
                            className="w-full border border-[#F4F4F4] outline-[#0074FC] p-2 rounded-lg text-md"
                            {...rest}
                        >
                            {value}
                        </p>
                    </a>
                ) : (
                    isTrainer &&
                    label === 'Institute URL' && (
                        <input
                            autoFocus={focus && true}
                            disabled={disabled || isLoading}
                            className={`w-full border-[1px] ${paddingForEye} ${
                                disabled && 'bg-[#F6F7F8] opacity-60'
                            } ${passwordreset} ${
                                labeldesign ? labeldesign : 'text-md'
                            } ${
                                label === 'Skills' && 'capitalize'
                            } border-[1px] border-[#F4F4F4] outline-[#0074FC] p-2 rounded-lg`}
                            type={passwordType ? 'text' : type}
                            placeholder={placeholder ? placeholder : ''}
                            value={value}
                            onChange={onChange}
                            {...rest}
                        />
                    )
                )}
                {label !== 'Address' && label !== 'Institute URL' && (
                    <input
                        autoFocus={focus && true}
                        disabled={disabled || isLoading}
                        className={`w-full border-[1px] ${paddingForEye} ${
                            disabled && 'bg-[#F6F7F8] opacity-60'
                        } ${passwordreset} ${
                            labeldesign ? labeldesign : 'text-md'
                        } ${
                            label === 'Skills' && 'capitalize'
                        } border-[0.7px] border-black outline-[#0074FC] p-2 rounded-lg`}
                        type={passwordType ? 'text' : type}
                        value={value}
                        onChange={onChange}
                        {...rest}
                    />
                )}

                {label === 'Address' && (
                    <textarea
                        autoCorrect="false"
                        resize-none
                        disabled={disabled || isLoading}
                        className={`w-full border-[1px] ${
                            size ? 'h-[60px] p-2' : 'h-[170px] p-4'
                        } ${
                            disabled && 'bg-[#F6F7F8] opacity-60'
                        } ${passwordreset} border-[0.7px] border-black outline-[#0074FC] p-2 rounded-lg`}
                        value={value}
                        onChange={onChange}
                        {...rest}
                    >
                        {value}
                    </textarea>
                )}

                {errorState && (
                    <p
                        className={`text-red-500 text-[12px] w-full text-end mt-1 absolute`}
                    >
                        {error}
                    </p>
                )}

                {type === 'password' ? (
                    passwordType ? (
                        <>
                            <img
                                alt="closeEyeIcon"
                                src={closeEye}
                                className={`w-5 object-contain absolute right-2 top-3 cursor-pointer`}
                                onClick={() => setPasswordType(false)}
                            />
                        </>
                    ) : (
                        <>
                            <img
                                alt="openEyeIcon"
                                src={openEye}
                                className={`w-5 object-contain absolute right-2 top-3 cursor-pointer`}
                                onClick={() => setPasswordType(true)}
                            />
                        </>
                    )
                ) : null}
            </div>
        </div>
    );
};

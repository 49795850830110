import React from 'react';
import { useFormik } from "formik";
import { inputForCO } from './FormikProfile';
import { profileForCO } from '../../Services/Formik/Formik';
import { UserInfo } from './Userinfo';
import { ContactInfo } from './Contactinfo';

export const COprofile = ({
    formik,
    role,
    isViewable,
    email,
    CityInfo,
    search,
    places,
    dropdownVal,
    selectedInputValue,
}: any) => {
    // const formik = useFormik({
    //   initialValues: inputForCO,
    //   validationSchema: profileForCO,
    //   onSubmit: () => {
    //     // updateProfile()
    //   }
    // });
    // const {values, handleChange, handleBlur, touched, handleSubmit, setFieldValue, errors, resetForm}=formik;

    return (
        <div className="space-y-3">
            <UserInfo
                role={role}
                formik={formik}
                cardTitle={'Organization'}
                isViewable={isViewable}
            />
            <ContactInfo
                isViewable={isViewable}
                role={role}
                formik={formik}
                email={email}
                CityInfo={CityInfo}
                search={search}
                places={places}
                dropdownVal={dropdownVal}
                selectedInputValue={selectedInputValue}
            />
        </div>
    );
};

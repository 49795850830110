import React, { useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { Card, RequestDetailsCard } from './Card';
import rs from '../../assets/Images/rs.svg';
import profileDropDown from '../../assets/Images/profileDropDown.svg';
import {
    deleteRecord,
    postRecord,
    readRecord,
    updateRecord,
} from '../../Services/backend/apiCalls';
import {
    createSearchParams,
    Navigate,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { MoonLoader, PulseLoader } from 'react-spinners';
import { useAppSelector } from '../..';
import { dateFormat, RoleCard } from './Role';
import { isTrainer, actionText } from '../../Services/commonFunctions';
import { toast } from 'react-hot-toast';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import { ModalBox } from '../UI_Modal/UI_Modal';

import Button from '@mui/material/Button';
import { Dialog } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

//
export const BidDetails = () => {
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);

    const [bidData, setBidData] = useState<any>(false);
    const [loading, setLoading] = useState(false);
    const routeParams = useParams();
    const navigate = useNavigate();
    const [{ token, role, username, uid }, { appConfig }] = useAppSelector(
        ({ signin, appConfig }: any) => [
            signin['signinValue'],
            appConfig['configData'],
        ]
    );
    // console.log({appConfig});

    // const [searchParams] = useSearchParams();
    // const bidStatus: string = searchParams.get("status") || "";
    const [bidStatus, setBidStatus] = useState('');
    const userType = ['TI', 'FL'].includes(role) ? 'Trainer' : 'Trainee';
    // const isTrainer
    let message = 'Bid Not Found';

    useEffect(() => {
        (async () => {
            setLoading(true);
            const params = {
                id: routeParams.id,
            };
            const bidDetails = await readRecord(params, 'biddings');
            // console.log(bidDetails, 'bidDetails');

            if (bidDetails.status) {
                setBidStatus(bidDetails.data.status);
                setBidData({
                    // update
                    Trainee: bidDetails.data.Trainee.name,
                    role: bidDetails.data.Trainee.role,
                    trainee_id: bidDetails.data.Trainee.user_id,

                    courseTitle: bidDetails.data.title,
                    description: bidDetails.data.description,
                    createdAt: dateFormat(bidDetails.data.createdAt),
                    id: bidDetails.data.id,
                    ...(bidDetails.data[userType] &&
                        bidDetails.data[userType] !== null && {
                            role: bidDetails.data[userType].role,
                            user_id: bidDetails.data[userType].user_id,
                            userName: bidDetails.data[userType].name,
                        }),
                    amount: bidDetails.data.accepted_amount,
                    start_date: dateFormat(bidDetails.data.start_date),
                    trainees_count: bidDetails.data.trainees_count,
                    address: bidDetails.data.address,
                    duration: bidDetails.data.days,
                    mode: bidDetails.data.mode,
                    session: bidDetails.data.preferred_days,
                    participants: bidDetails.data.participants,
                    biddingChats: bidDetails.data.biddingchats,
                    isPaymentDone: bidDetails.data.isPaymentDone,
                    acceptedBidderId: bidDetails.data.accepted_bidder_id,
                    creator_id: bidDetails.data.creator_id,
                    tags: bidDetails.data.tags,
                });
            } else toast.error(bidDetails.message);
            setLoading(false);
        })();
    }, []);
    const deleteBid = async (bidId: string | number) => {
        // console.log({bidId});
        const deleteResponse = await deleteRecord({ id: bidId }, 'biddings');
        if (deleteResponse.status) {
            navigate('/dashboard');
        } else {
            toast.error(deleteResponse.message);
        }
    };

    const acceptBidAction = () => {
        setBidStatus('accepted');
        setBidData({ ...bidData, isPaymentDone: true });
    };
    if (loading) {
        return (
            <div className="flex w-full min-h-[450px] items-center justify-center">
                <PulseLoader color="#0074FC" />
            </div>
        );
    }

    if (!bidData) {
        return (
            <div className="h-[500px] flex justify-center items-center">
                {message}
            </div>
        );
    }

    let isOpen = true;

    // console.log(bidData.participants.length, appConfig?.bidding_action, bidData.creator_id,uid);
    return (
        <div className="bg-[#F6F7F8]">
            <div className="w-11/12 mx-auto">
                <div className="w-full mt-10 sticky top-0 z-[1] bg-[#F6F7F8] py-4">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center justify-between w-full">
                            <h1 className="text-[25px] font-[500]">
                                Bid Details
                            </h1>
                            <button
                                className="text-[#065FEF] font-[500] text-[14px]"
                                // onClick={() => navigate('/dashboard#bidding')}
                                onClick={() =>
                                    navigate('/dashboard#suggestions')
                                }
                                // update by me
                            >
                                View All
                            </button>
                            {/* <p className='text-[#5B5B5B] text-[13px] font-[400] mt-2'>My Request {">"} Web Development {">"} Course Details</p> */}
                        </div>
                        {!bidData.participants.length &&
                            appConfig?.bidding_action === 'edit' &&
                            bidData.creator_id === uid && (
                                <button
                                    className="bg-[#065FEF] w-[57px] ml-4 h-[30px] text-white font-[400] text-[12px] rounded-[4px]"
                                    onClick={() =>
                                        navigate(
                                            '/bid/edit/' +
                                                bidData.courseTitle +
                                                '/' +
                                                routeParams.id
                                        )
                                    }
                                >
                                    Edit
                                </button>
                            )}
                        {!bidData.participants.length &&
                            appConfig?.bidding_action === 'edit' &&
                            bidData.creator_id === uid && (
                                <>
                                    <button
                                        className="bg-red-500 w-[60px] ml-2 h-[30px] text-white font-[400] text-[12px] rounded-[4px]"
                                        onClick={() => {
                                            setShowDeletePrompt(true);
                                            // alert('delete')
                                            //  deleteBid(bidData.id)
                                        }}
                                    >
                                        Delete
                                    </button>
                                    {/* <Popup /> */}
                                    <div>
                                        <Transition
                                            show={showDeletePrompt}
                                            as={React.Fragment}
                                        >
                                            <Dialog
                                                onClose={() => {}}
                                                className="fixed inset-0 z-10 overflow-y-auto"
                                            >
                                                <div className="flex items-center justify-center min-h-screen">
                                                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                                                    <div className="bg-white p-4 w-80 max-w-md mx-auto rounded shadow-lg z-50">
                                                        <Dialog.Title className="text-lg font-semibold">
                                                            Confirm Deletion
                                                        </Dialog.Title>
                                                        <Dialog.Description className="text-sm mt-2">
                                                            Are you sure you
                                                            want to delete{' '}
                                                            {
                                                                bidData.courseTitle
                                                            }
                                                            ?
                                                        </Dialog.Description>
                                                        <div className="flex justify-end mt-4">
                                                            <Button
                                                                onClick={() => {
                                                                    setShowDeletePrompt(
                                                                        false
                                                                    );
                                                                }}
                                                                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                onClick={() => {
                                                                    deleteBid(
                                                                        bidData.id
                                                                    );
                                                                }}
                                                                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Dialog>
                                        </Transition>
                                    </div>
                                </>
                            )}
                    </div>
                </div>

                <div>
                    <RequestDetailsCard
                        commonKeyData={bidData}
                        role={bidData.role}
                        trainee_id={bidData.trainee_id}
                        isDr={false}
                        actionText={() =>
                            actionText(bidStatus, isTrainer(role), false)
                        }
                        isTrainer={isTrainer(role)}
                        navigate={navigate}
                        status={bidStatus}
                        paymentStatus={
                            (bidData.acceptedBidderId === uid ||
                                !isTrainer(role)) &&
                            bidStatus === 'accepted'
                        }
                    />
                    <BiddingResponse
                        bidChats={bidData.biddingChats}
                        isTrainer={isTrainer(role)}
                        id={routeParams.id}
                        role={role}
                        name={username}
                        user_id={uid}
                        status={bidStatus}
                        acceptBidAction={acceptBidAction}
                    />
                </div>
            </div>
        </div>
    );
};
const BiddingResponse = ({
    bidChats,
    isTrainer,
    id,
    user_id,
    name,
    role,
    status,
    acceptBidAction,
}: any) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [chatID, setChatID] = useState(null);
    const [amount, setAmount] = useState('');
    const [biddingChats, setBiddingChats] = useState(bidChats);
    const bidRef = useRef<any>(null);
    const placeBidButtonRef = useRef<any>(null);
    const [acceptedId, setAcceptedId] = useState(null);
    const bidFilters = ['New Bids', 'Lowest Bids'];
    const [selectedFilter, setSelectedFilter] = useState('New Bids');
    // const
    // const acceptButtonRef = useRef<any>(null);

    useEffect(() => {
        (async () => {
            if (JSON.stringify(bidChats) !== JSON.stringify(biddingChats)) {
                setBiddingChats(bidChats);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(bidChats)]);

    const placeBid = async () => {
        placeBidButtonRef.current.disabled = true;
        const isBidderPuttingRepeatingAmount = biddingChats.filter(
            (bid: any) => {
                return bid.Trainer.user_id && Number(amount) === bid.amount;
            }
        );
        if (isBidderPuttingRepeatingAmount.length) {
            placeBidButtonRef.current.disabled = false;
            return toast.error(
                camelCaseConvertForErrorMessage('Amount is Already Entered')
            );
        }
        const bidChatsData = await postRecord(
            { bidding_id: Number(id), amount: Number(amount) },
            'bidchats'
        );
        if (bidChatsData.status) {
            setBiddingChats([
                {
                    ...bidChatsData.data,
                    Trainer: {
                        user_id,
                        name,
                        role,
                    },
                },
                ...biddingChats,
            ]);
            setAmount('');
            setSelectedFilter('New Bids');
            toast.success('Placed Bid');
        } else {
            if (
                bidChatsData.message ===
                camelCaseConvertForErrorMessage(
                    'Trainee already accepted other bid amount'
                )
            ) {
                window.location.reload();
            }
            toast.error(bidChatsData.message);
        }
        placeBidButtonRef.current.disabled = false;
    };

    const acceptBid = async () => {
        // const confirmation = window.confirm("Are you sure to accept the bid?");
        // if (!confirmation) return;
        // console.log(biddingChats, 'biddingChats');
        const mapBiddingChatsId = biddingChats?.map((ft: any) =>
            ft?.id.toString()
        );
        // console.log(mapBiddingChatsId, 'mapBiddingChatsId');
        const filterWithBiddingChatId = mapBiddingChatsId?.filter(
            (fil: any) => Number(fil) !== Number(chatID)
        );
        // console.log(filterWithBiddingChatId, 'filterWithBiddingChatId');
        const body = {
            id: Number(chatID),
            bidding_id: Number(id),
            status: 'accepted',
            rejected_bidder_ids: filterWithBiddingChatId,
        };
        // console.log(body, 'body');
        const response = await updateRecord(body, 'bidchats');
        // console.log(response);
        if (response.status) {
            toast.success('Bid Accepted');
            setAcceptedId(chatID);
            navigate({
                pathname: '/checkout',
                search: createSearchParams({
                    id,
                    checkoutOrigin: 'bd',
                }).toString(),
            });
            // window.location.reload();
            // acceptBidAction();
        } else {
            toast.error(response.message);
            if (
                response.message ===
                camelCaseConvertForErrorMessage(
                    `can't able to make status accepted when bidding is already accepted`
                )
            ) {
                window.location.reload();
            }
        }
    };

    const deleteModal = (flag: any) => {
        setShow(false);
        if (flag === 'yes') {
            acceptBid();
        } else {
            setShow(false);
        }
    };

    const openModal = (chatId: any) => {
        setShow(true);
        setChatID(chatId);
    };

    const PlaceBid = (
        <div className="w-11/12 mx-auto mt-10">
            <p className="font-[500] text-[20px] mb-2">Amount</p>
            <div className="flex items-center space-x-4">
                <div
                    className="border flex py-2 w-5/12 cursor-text"
                    onClick={() => bidRef.current.focus()}
                >
                    <p className="px-2 border-r font-[500] text-[16px]">INR</p>
                    <input
                        title="amount"
                        ref={bidRef}
                        type="number"
                        autoFocus
                        className="outline-none px-2 numberClass"
                        placeholder="Enter the Bid Amount"
                        value={amount}
                        onChange={(e: any) => setAmount(e.target.value)}
                        onKeyDown={(keyBoardEvent: React.KeyboardEvent) => {
                            if (keyBoardEvent.code === 'Enter') {
                                placeBid();
                            }
                        }}
                    />
                </div>
                <button
                    ref={placeBidButtonRef}
                    className="bg-[#33BD87] text-white outline-none py-2 px-4"
                    onClick={placeBid}
                >
                    Place Bid
                </button>
            </div>
        </div>
    );

    const sortFilter = (e: any) => {
        const filter = e.target.value === 'Lowest Bids';
        let sortedBids = biddingChats.sort((a: any, b: any) => {
            return filter
                ? a['amount'] - b['amount']
                : new Date(b['createdAt']).valueOf() -
                      new Date(a['createdAt']).valueOf();
        });
        setBiddingChats(sortedBids);
        setSelectedFilter(e.target.value);
    };
    return (
        // <div className="w-full bg-white my-10 pb-8">
        //     <ModalBox
        //         show={show}
        //         fun={deleteModal}
        //         message={'Are you sure, You want to accept the bid?'}
        //     />
        //     <div className="bg-[#F3F7FB] flex flex-col items-center justify-center">
        //         <h1 className="text-[23px] font-[500] text-[#065FEF] py-4">
        //             Bidding
        //         </h1>
        //         <div className="border-b-4 border-[#2D5EF4] w-full"></div>
        //     </div>
        //     {isTrainer && status === 'pending' && PlaceBid}
        //     {biddingChats && biddingChats.length ? (
        //         <table className="w-11/12 mx-auto flex items-center flex-col mt-10 space-y-10">
        //             <thead className="w-full">
        //                 <tr className="w-8/12 mx-auto flex">
        //                     <td className="w-6/12"></td>
        //                     <td className="w-4/12">
        //                         <select
        //                             id="filter"
        //                             name="bid filter"
        //                             title="filter"
        //                             className="mt-1 block rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base outline-none"
        //                             // defaultValue={selectedFilter}
        //                             onChange={sortFilter}
        //                             value={selectedFilter}
        //                         >
        //                             {bidFilters.map(
        //                                 (filter: any, index: number) => {
        //                                     return (
        //                                         <option key={index}>
        //                                             {filter}
        //                                         </option>
        //                                     );
        //                                 }
        //                             )}
        //                         </select>
        //                     </td>
        //                     <td></td>
        //                 </tr>
        //             </thead>

        //             <tbody className="relative w-full h-[300px] space-y-9 scroll-smooth overflow-auto">
        //                 {biddingChats.map((bid: any, i: number) => {
        //                     console.log(bid, 'bid');
        //                     const isYou = bid.bidder_id === user_id;
        //                     return (
        //                         <tr
        //                             key={i}
        //                             className="w-8/12 mx-auto flex items-center"
        //                         >
        //                             <td className="flex items-center space-x-4 w-6/12">
        //                                 <div className="w-[45px] h-[45px] bg-black rounded-full text-white flex items-center justify-center">
        //                                     {bid[
        //                                         'Trainer'
        //                                     ].name[0].toUpperCase()}
        //                                 </div>
        //                                 <h1 className="text-[18px] font-[600]">
        //                                     {isYou
        //                                         ? 'You'
        //                                         : bid['Trainer'].name}
        //                                     <span className="w-[56px] h-[20px] rounded-[12px] bg-[#E9F3FF] text-[7px] font-[400] text-[#065FEF] ml-2">
        //                                         {bid.role}
        //                                     </span>
        //                                 </h1>
        //                                 <RoleCard role={bid['Trainer'].role} />
        //                             </td>
        //                             <td className="flex w-4/12">
        //                                 <img
        //                                     src={rs}
        //                                     alt="RsSymbol"
        //                                     className="w-[10px]"
        //                                 />
        //                                 <p className="ml-2 text-[#2975FD] text-[22px] font-[600]">
        //                                     {bid.amount.toLocaleString('en-IN')}
        //                                 </p>
        //                             </td>
        //                             {!isTrainer &&
        //                                 acceptedId === null &&
        //                                 status === 'pending' &&
        //                                 bid.status === 'pending' && (
        //                                     <td>
        //                                         <button
        //                                             disabled={
        //                                                 bid.status ===
        //                                                 'accepted'
        //                                             }
        //                                             className="bg-[#33BD87] w-[74px] h-[35px] rounded-[4px] text-white text-[12px] font-[400]"
        //                                             onClick={() =>
        //                                                 openModal(bid.id)
        //                                             }
        //                                         >
        //                                             Accept
        //                                         </button>
        //                                     </td>
        //                                 )}
        //                             {((status === 'accepted' &&
        //                                 bid.status === 'accepted') ||
        //                                 acceptedId === bid.id) && (
        //                                 <td>
        //                                     <button
        //                                         className="bg-[#33BD87] w-[74px] h-[35px] rounded-[4px] text-white text-[12px] font-[400]"
        //                                         disabled
        //                                     >
        //                                         Accepted
        //                                     </button>{' '}
        //                                 </td>
        //                             )}
        //                         </tr>
        //                     );
        //                 })}
        //             </tbody>
        //         </table>
        //     ) : (
        //         <div className="flex justify-center items-center h-[300px]">
        //             No biddings
        //         </div>
        //     )}
        // </div>
        <div className="w-full bg-white my-10 pb-8">
            <ModalBox
                show={show}
                fun={deleteModal}
                message={'Are you sure, You want to accept the bid?'}
            />
            <div className="bg-[#F3F7FB] flex flex-col items-center justify-center">
                <h1 className="text-[20px] md:text-[23px] font-[500] text-[#065FEF] py-4">
                    Bidding
                </h1>
                <div className="border-b-4 border-[#2D5EF4] w-full"></div>
            </div>
            {isTrainer && status === 'pending' && PlaceBid}
            {biddingChats && biddingChats.length ? (
                <div className="w-11/12 mx-auto flex flex-col space-y-4 mt-10">
                    <div className="w-full flex justify-end">
                        <select
                            id="filter"
                            name="bid filter"
                            title="filter"
                            className="block rounded-md border border-gray-300 py-2 pl-3 pr-10 text-sm md:text-base outline-none"
                            onChange={sortFilter}
                            value={selectedFilter}
                        >
                            {bidFilters.map((filter: any, index: number) => (
                                <option key={index}>{filter}</option>
                            ))}
                        </select>
                    </div>

                    <div className="relative w-full space-y-4 overflow-auto">
                        {biddingChats.map((bid: any, i: number) => {
                            const isYou = bid.bidder_id === user_id;
                            return (
                                <div
                                    key={i}
                                    className="bg-gray-100 p-4 rounded-lg shadow-md flex flex-col md:flex-row items-center justify-between"
                                >
                                    <div className="flex items-center space-x-4 w-full md:w-6/12">
                                        <div className="w-[40px] md:w-[45px] h-[40px] md:h-[45px] bg-black rounded-full text-white flex items-center justify-center">
                                            {bid[
                                                'Trainer'
                                            ].name[0].toUpperCase()}
                                        </div>
                                        <div className="flex flex-col">
                                            <h1 className="text-[16px] md:text-[18px] font-[600] truncate">
                                                {isYou
                                                    ? 'You'
                                                    : bid['Trainer'].name}
                                                <span className="ml-2 bg-[#E9F3FF] text-[#065FEF] text-[10px] md:text-[12px] px-2 py-1 rounded-md">
                                                    {bid.role}
                                                </span>
                                            </h1>
                                            <div className="flex items-center space-x-1">
                                                <img
                                                    src={rs}
                                                    alt="RsSymbol"
                                                    className="w-[12px] md:w-[15px] align-middle"
                                                />
                                                <p className="text-[#2975FD] text-[20px] md:text-[22px] font-[600] truncate">
                                                    {bid.amount.toLocaleString(
                                                        'en-IN'
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {!isTrainer &&
                                        acceptedId === null &&
                                        status === 'pending' &&
                                        bid.status === 'pending' && (
                                            <div className="w-full md:w-auto mt-2 md:mt-0 flex justify-end">
                                                <button
                                                    disabled={
                                                        bid.status ===
                                                        'accepted'
                                                    }
                                                    className="bg-[#33BD87] w-[64px] md:w-[74px] h-[30px] md:h-[35px] rounded-md text-white text-[12px] font-[400]"
                                                    onClick={() =>
                                                        openModal(bid.id)
                                                    }
                                                >
                                                    Accept
                                                </button>
                                            </div>
                                        )}
                                    {((status === 'accepted' &&
                                        bid.status === 'accepted') ||
                                        acceptedId === bid.id) && (
                                        <div className="w-full md:w-auto mt-2 md:mt-0 flex justify-end">
                                            <button
                                                className="bg-[#33BD87] w-[64px] md:w-[74px] h-[30px] md:h-[35px] rounded-md text-white text-[12px] font-[400]"
                                                disabled
                                            >
                                                Accepted
                                            </button>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <div className="flex justify-center items-center h-[300px]">
                    No biddings
                </div>
            )}
        </div>
    );
};

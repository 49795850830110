import React, { useState } from "react";
import { useDropzone } from 'react-dropzone';
import Frame from "../../assets/Images/Frame.svg";
import { FileUploader } from "react-drag-drop-files";

export const DragDrop = ({
  default:Component,
  onUpload
}: any) => {
  const fileTypes = ["JPG", "PNG"]

  // const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
  //   accept:{
  //   'image/jpeg': [],
  //     'image/png': []
  // },
  //   multiple: false,
  //   maxFiles:5,
  //   onDrop: (acceptedFiles: any) => {onUpload(acceptedFiles);}
  // });
  

  return (
    // <div className={`${isDragActive && "border-green-600"}`} {...getRootProps()}>
    //   <Component inputProps={getInputProps} />
    // </div>
    <FileUploader
      children={<Component />}
      multiple={false}
      handleChange={onUpload}
      types={fileTypes}
    />
      
  );
};

import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "..";
import { readRecord } from "../Services/backend/apiCalls";
import { setAppConfig } from "../Redux/Reducers/appConfigReducers";

export const usePreloadHook = () => {
    const dispatch = useAppDispatch();
    const [
        { token, role, username, uid },
        { showMenuModal, showNotifyAvailable, showNotifyModal },
        { skills },
        { notifyResponse },
        { appConfig },
    ] = useAppSelector(
        ({ signin, modal, skill, notification, appConfig }: any) => [
            signin['signinValue'],
            modal,
            skill['skillBasedSearchData'],
            notification['notificationData'],
            appConfig['configData'],
        ]
    );
    useEffect(() => {
        (async () => {
            if (!appConfig.length) {
                const { status, appConfig } = await readRecord({}, "appconfigs");
                if (status) {
                    dispatch(setAppConfig(appConfig))
                }
            }
        })();
    }, [dispatch,appConfig]);
    return true;
}
// Create new file: Redux/Reducers/internshipSearchReducer.ts

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    Value: {
        input: [],
        filters: {},
        selectedInternships: [], // For comparison
    },
};

const internshipSearchSlice = createSlice({
    name: 'internshipSearch',
    initialState,
    reducers: {
        setInternshipResults: (state, action) => {
            state.Value.input = action.payload.input;
        },
        setInternshipFilters: (state, action) => {
            state.Value.filters = action.payload;
        },
        setSelectedInternships: (state, action) => {
            state.Value.selectedInternships = action.payload;
        },
    },
});

export const {
    setInternshipResults,
    setInternshipFilters,
    setSelectedInternships,
} = internshipSearchSlice.actions;

export default internshipSearchSlice.reducer;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../..";
import toast from "react-hot-toast";
import { deleteRecord } from "../../Services/backend/apiCalls";
import { useRef } from "react";
import { ButtonCompWithActionController } from "../Elements/Button/Button";
import { camelCaseConvertForErrorMessage } from "../../Services/commonFunctions";
import { ModalBox } from "../UI_Modal/UI_Modal";

export const Button=({tab,id,individualCourse,isTrainer,sendStateForDeleteFunction}:any)=>{
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const deleteButtonRef = useRef<any>();
    const editButtonActionRef = useRef<any>();

    const deleteCourse = async () => {
            let deleteResponse = await deleteRecord({id},"courses");
            if (deleteResponse.status) {
                toast.success(individualCourse&&individualCourse.title+" Course Deleted Successfully");
                return navigate("/request/mycourse", (sendStateForDeleteFunction && {state: sendStateForDeleteFunction}));
            }
        else{
            toast.error(deleteResponse.message ? deleteResponse.message : "Course Deletion Failed");
        }
    }

            const deleteModal = (flag: any) => {
                setShow(false);
                if (flag === 'yes') {
                    deleteCourse();
                } else {
                    setShow(false);
                }
            };

            const openModal = () => {
                setShow(true);
            };
    
    return (
        <div>
            <ModalBox show={show} fun={deleteModal} />
            {
                tab ? (
                    <div className="text-white flex items-center text-[13px] font-[400] space-x-2">
                        <button
                            ref={editButtonActionRef}
                            className="bg-[#065FEF] w-[70px] py-2 rounded-[4px]"
                            onClick={() => navigate(`/course/edit/${individualCourse.title}/${id}`)}
                        >
                            Edit
                        </button>
                        {isTrainer && (
                            <ButtonCompWithActionController
                                // ref={deleteButtonRef}
                                className="bg-[#FF0000] w-[70px] py-2 rounded-[4px]"
                                action={openModal}
                                buttonText={'Delete'}
                                Ref={deleteButtonRef}
                            />
                        )}
                    </div>
                ) : null
                // <button className='bg-[#065FEF] w-[70px] py-2 rounded-[4px] text-white text-[12px] font-[400]' onClick={uploadResumeFile}>Save</button>
            }
        </div>
    );
}
import { DotLoader,PulseLoader } from 'react-spinners';

export const DashBoardLoader=({children,loading,data}:any)=>{
    // console.log({component})
    // console.log({Component});
    return(
        <div className={`overflow-hidden flex w-full`}>
            {loading?<div className='flex w-full h-full items-center justify-center'>
                <PulseLoader color="#0074FC" />
            </div>:
            <IsDataPresentOrNoData children={children} data={data}/>
                // {children}
            // </IsDataPresentOrNoData>
            }
        </div>
    )
}

export const IsDataPresentOrNoData=({data,children}:any)=>{
    return(
        <div className='h-full w-full'>
        {
            !data.length?<NoDataFound />:children
        }
        </div>
    )
}

export const NoDataFound=({dataType}:any)=>{
    return(
      <div className="h-full flex items-center justify-center text-[#797d7b] text-center">
          No data found
      </div>
    )
  }
import { useState, useEffect } from 'react';
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../..';
import { deleteRecord, readRecord } from '../../Services/backend/apiCalls';
import { setViewCourse } from '../../Redux/Reducers/viewCourseReducers';
import toast from 'react-hot-toast';
import { PulseLoader } from 'react-spinners';
import { BooknowPopup } from '../CMS/BooknowPopup';
import { Document } from './DocumentSection';
import { Review } from '../CourseReview/Review';
import { SelectedDetails } from './SelectedDetails';
import { CourseCard } from './CourseCard';
import { Button } from './Button';
import { isTrainer } from '../../Services/commonFunctions';
import { ModalBox } from '../UI_Modal/UI_Modal';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import { bookedBatchTimeHandler } from '../../Redux/Reducers/comparecoursesReducers';

export const parseFileNameFromStorageUrl = (url: string) => {
    let parsedText = url.split('%2F')[1].split('?')[0];
    return parsedText;
};

export const ViewCourse = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const dr_id = searchParams.get('dr_id');
    const isCoursePage = !location.pathname.includes('document');
    const isDirectRequestPage = location.pathname.includes('booknow');

    const { id, title }: any = params;

    const [reviewsData, setReviewsData] = useState([]);
    const [trainer_id, setTrainer_ID] = useState('');
    const [individualCourse, setIndividualCourse] = useState({} as any);
    const [directRequestStatus, setDirectRequestStatus] = useState({});
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [selectUserId, setSelectUserId] = useState(null);
    const [selectedBatchDate, setSelectedBatchDate] = useState('');
    const [{ role, attendedCourses, uid }, { showBookModal }] = useAppSelector(
        ({ signin, DR }: any) => [signin['signinValue'], DR]
    );

    const { reviews } = useAppSelector(({ view }: any) => view['ReviewData']);
    const [materials, setMaterials] = useState<any>([]);
    const isAdmin = uid === trainer_id;

    const documentShow = attendedCourses.includes(id) || isAdmin;
    const reviewShow = individualCourse?.canGiveReview || isAdmin;
    const courseNavigate = '/course/view/' + title + '/' + id;
    const courseDocumentNavigate = `/course/${title}/documents/${id}`;

    const converDate = function () {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        let month: any = currentDate.getMonth() + 1;
        let day: any = currentDate.getDate();

        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    };

    const viewCourses = async () => {
        setLoading(true);
        const CourseDetail = await readRecord(
            { id, ...(isDirectRequestPage && { isDrNeeded: true, dr_id }) },
            'coursequery'
        );
        dispatch(bookedBatchTimeHandler([CourseDetail.bookedBatchTimings]));
        if (CourseDetail.status) {
            const currentDate: any = converDate();
            const batchDate = CourseDetail.data[0].batches[0].batchDate;
            if (currentDate <= batchDate) {
                setSelectedBatchDate(CourseDetail.data[0].batches[0].batchDate);
            }
            setIndividualCourse({
                ...CourseDetail.data[0],
                canGiveReview: CourseDetail?.canGiveReview,
            });
            setDirectRequestStatus(CourseDetail.directRequest);
            setTrainer_ID(CourseDetail.data[0].trainer_id);
            dispatch(setViewCourse({ reviews: CourseDetail.reviews }));
            setReviewsData(CourseDetail.reviews);
        } else toast.error(`${CourseDetail.message}`);
        setLoading(false);
    };

    useEffect(() => {
        let listen: boolean = true;
        if (listen) {
            viewCourses();
            (async () => {
                if (!isDirectRequestPage) {
                    if (documentShow) {
                        let courseMaterials = await readRecord(
                            { courseId: id },
                            'coursematerials'
                        );
                        if (courseMaterials.status) {
                            setMaterials(courseMaterials.data);
                        } else {
                            return toast.error(
                                courseMaterials.message ||
                                    'Unable to Get Course Materials'
                            );
                        }
                    } else {
                        navigate(courseNavigate, { replace: true });
                    }
                }
            })();
        }
        return () => {
            listen = false;
        };
    }, []);

    const removeReviewId = (ID: any) => {
        const removedIds = reviews.filter((item: any) => item['id'] !== ID);
        dispatch(setViewCourse({ reviews: [...removedIds] }));
    };

    const deleteReviewDetails = async () => {
        const ReviewDelete = await deleteRecord(
            { id: selectUserId },
            'reviews'
        );
        if (ReviewDelete.status) {
            removeReviewId(selectUserId);
            toast.success(ReviewDelete.message);
        } else {
            toast.error(ReviewDelete.message);
        }
    };

    const deleteModal = (flag: any) => {
        setShow(false);
        setSelectUserId(null);
        if (flag === 'yes') {
            deleteReviewDetails();
        } else {
            setShow(false);
        }
    };

    const callDeleteFunc = ({ id }: any) => {
        setShow(true);
        setSelectUserId(id);
    };

    return (
        <div className="bg-[#F6F7F8]">
            {showBookModal && (
                <BooknowPopup
                    courseId={id}
                    batchData={individualCourse.batches}
                />
            )}
            <ModalBox show={show} fun={deleteModal} />
            <div className="w-full max-w-screen-lg mx-auto px-4">
                {!isDirectRequestPage ? (
                    <div className="w-full mt-10 sticky top-0 z-[1] bg-[#F6F7F8] py-4">
                        <div>
                            <h1 className="text-[20px] md:text-[25px] font-[500]">
                                {isCoursePage ? 'Course Details' : ''}
                            </h1>
                            <p className="text-[12px] md:text-[13px] font-[500] mt-2">
                                <span
                                    className={`${
                                        documentShow &&
                                        'hover:text-[#085BEB] cursor-pointer'
                                    }`}
                                    onClick={() =>
                                        documentShow
                                            ? navigate(`/request/mycourse`, {
                                                  state: 'typeForHeaderClick',
                                              })
                                            : navigate(-1)
                                    }
                                >
                                    {documentShow ? 'My Course' : 'Search'}
                                </span>
                                <span className="ml-2 mr-2">{'>'}</span>
                                {individualCourse
                                    ? individualCourse.title
                                    : 'Full Stack Web Development'}
                            </p>
                            <div className="flex flex-col mt-6">
                                <div className="flex justify-between flex-wrap">
                                    <div className="flex items-end ml-2">
                                        <h1
                                            className={`font-[500] cursor-pointer ${
                                                isCoursePage
                                                    ? 'text-[#085BEB] text-[16px] underline underline-offset-[14px]'
                                                    : 'text-[#323232] text-[15px]'
                                            }`}
                                            onClick={() =>
                                                navigate(courseNavigate)
                                            }
                                        >
                                            Course
                                        </h1>
                                        {true && (
                                            <h1
                                                className={`ml-8 font-[400] cursor-pointer ${
                                                    !isCoursePage
                                                        ? 'text-[#085BEB] text-[16px] underline underline-offset-[14px]'
                                                        : 'text-[#323232] text-[15px]'
                                                }`}
                                                onClick={() =>
                                                    navigate(
                                                        courseDocumentNavigate
                                                    )
                                                }
                                            >
                                                Course Documents
                                            </h1>
                                        )}
                                    </div>
                                    {isAdmin && (
                                        <Button
                                            tab={isCoursePage}
                                            id={id}
                                            isTrainer={isTrainer(role)}
                                            individualCourse={individualCourse}
                                            sendStateForDeleteFunction={
                                                'typeForHeaderClick'
                                            }
                                        />
                                    )}
                                </div>
                                <div className="border-b-2 mt-2"></div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mt-4">
                        <p className="font-[500] text-[20px] md:text-[25px]">
                            Book Now
                        </p>
                        <div className="border-b-2 w-full mt-2"></div>
                    </div>
                )}
                <div className="py-6">
                    {!isCoursePage ? (
                        <Document
                            documentShow={documentShow}
                            id={id}
                            title={individualCourse.title}
                            materials={materials}
                            setMaterials={setMaterials}
                            isAdmin={isAdmin}
                        />
                    ) : loading ? (
                        <div className="flex justify-center items-center min-h-[761px]">
                            <PulseLoader color="#0074FC" />
                        </div>
                    ) : (
                        Object.keys(individualCourse).length > 0 && (
                            <div className="flex flex-col md:flex-row justify-between w-full gap-4 ">
                                <div className="flex flex-col w-full md:w-8/12">
                                    <CourseCard
                                        individualCourse={individualCourse}
                                        loading={loading}
                                        directRequestDetails={
                                            directRequestStatus
                                        }
                                        isDirectRequestPage={
                                            isDirectRequestPage
                                        }
                                        isTrainer={isTrainer(role)}
                                        role={role}
                                    />
                                    <Review
                                        trainer_id={trainer_id}
                                        id={id}
                                        role={role}
                                        reviewsData={reviewsData}
                                        setReviewsData={setReviewsData}
                                        callDeleteFunc={callDeleteFunc}
                                        setSelectUserId={setSelectUserId}
                                        selectUserId={selectUserId}
                                        removeReviewId={removeReviewId}
                                        reviewShow={reviewShow}
                                    />
                                </div>
                                <SelectedDetails
                                    directRequestStatus={directRequestStatus}
                                    individualCourse={individualCourse}
                                    id={id}
                                    role={role}
                                    isDirectRequestPage={isDirectRequestPage}
                                    selectedBatchDate={selectedBatchDate}
                                />
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

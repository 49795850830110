export const URLConfig: any = {
    localhost: `http://localhost:3000/`,
    'app.trisekt.com': 'https://app.trisekt.com/',
    'trisekt-c1b63.web.app': 'https://trisekt-c1b63.web.app/',
    'trisekt-uat-deeto.web.app': 'https://trisekt-uat-deeto.web.app/',
    'trisekt-uat-deeto.web.app/': 'https://trisekt-uat-deeto.firebaseapp.com/',
};

export const passwordDecryptSecretKey = 'trisektPasswordKey';
export const ALLOWED_USERS: any[] = ['TI', 'FL', 'ST', 'CO', false, undefined];

import React, { useState, useEffect, useRef } from 'react';
import { useAppSelector, useAppDispatch } from '../..';
import { filterCourse } from './FilterChecker';
import { setFilters } from '../../Redux/Reducers/directRequestReducers';
import checkedFilter from '../../assets/Images/checkedFilter.svg';
import checkFilter from '../../assets/Images/checkFilter.svg';
import { convertedTimeToNumber } from '../AddCourse/ConverterTimeToNumber';
import { toast } from 'react-hot-toast';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import rs from '../../assets/Images/rs.svg';
import { useDebounce } from '../../hooks/DebounceHook';

export const FilterData = ({ resetFunc, filter }: any) => {
    const dispatch = useAppDispatch();
    const { filters } = useAppSelector(
        ({ directRequest }) => directRequest.Value
    );
    //   const {input} = useAppSelector(({directRequest}:any)=>directRequest["Value"]);
    const [selectValue, setSelectValue] = useState('');

    const checkFunction = (value: any) => {
        // console.log({value});
        setSelectValue(value === selectValue ? '' : value);
        dispatch(
            setFilters({
                name: filter.filterName,
                value: value === selectValue ? '' : value,
            })
        );
    };

    useEffect(() => {
        setSelectValue('');
    }, [resetFunc]);

    return (
        <div>
            {filter.filters.map((val: any, i: number) => {
                return (
                    <RadioBox
                        title={val.name}
                        value={val.value}
                        isChecked={filters[filter.filterName] === val.value}
                        key={i}
                        onClick={checkFunction}
                    />
                );
            })}
        </div>
    );
};

const RadioBox = ({ title, onClick, isChecked, value }: any) => {
    // console.log(selectValue,"selectValue");
    return (
        <div className="flex items-center gap-2 cursor-pointer mt-2">
            {/* <input id={title} name={title} type='radio' checked={isChecked} className='hidden' onChange={()=>onClick(value)}/> */}
            <label onClick={() => onClick(value)}>
                <img
                    src={isChecked ? checkedFilter : checkFilter}
                    alt="checked"
                    className="cursor-pointer"
                />
            </label>
            <label className="text-[15px] font-[400] text-[#5F5F5F] capitalize">
                {title}
            </label>
        </div>
    );
};

export const DayFilter = ({ resetFunc, filter }: any) => {
    const dispatch = useAppDispatch();
    const [selectedDays, setSelectedDays] = useState<any>([]);
    const setDays = (value: any) => {
        if (selectedDays.includes(value)) {
            let days = selectedDays.filter((day: any) => day !== value);
            setSelectedDays(days);
            if (!days.length) {
                return dispatch(setFilters(false));
            }
            dispatch(setFilters({ name: filter.filterName, value: days }));
        } else {
            setSelectedDays([...selectedDays, value]);
            dispatch(
                setFilters({
                    name: filter.filterName,
                    value: [...selectedDays, value],
                })
            );
        }
    };

    useEffect(() => {
        setSelectedDays([]);
    }, [resetFunc]);

    return (
        <div>
            {filter.filters.map((day: any, index: number) => {
                return (
                    <div
                        className="flex items-center space-x-2 mb-2"
                        key={index}
                    >
                        <img
                            src={
                                selectedDays.includes(day.value)
                                    ? checkedFilter
                                    : checkFilter
                            }
                            alt="checkBox"
                            onClick={() => setDays(day.value)}
                        />
                        <label className="text-[15px] font-[400] text-[#5F5F5F] capitalize">
                            {day.name}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export const FeeFilter = ({ resetFunc, minAmount, maxAmount, filter }: any) => {
    // const minAmount = 0;

    // const amountRef = useRef(0)
    const [filterAmount, setFilterAmount] = useState(minAmount);
    // console.log(filterAmount, resetFunc, minAmount,maxAmount, filter, "QWE")

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (filterAmount !== minAmount) {
            setFilterAmount(minAmount);
        }
    }, [minAmount]);

    const handleChange = (e: any) => {
        const { value } = e.target;
        setFilterAmount(value);
        if (value == 0) {
            return dispatch(setFilters({ name: filter.filterName, value: '' }));
        }
        dispatch(
            setFilters({
                name: filter.filterName,
                value: { lowerLimit: 0, higherLimit: Number(value) },
            })
        );
    };

    useEffect(() => {
        setFilterAmount(0);
    }, [resetFunc]);

    return (
        <div className="flex flex-col items-start space-y-6">
            <div className="w-full space-y-1">
                <div className="flex items-center gap-2">
                    <p className="text-[#FB5935]">Amount:</p>
                    <div className="flex items-center gap-0.5">
                        <img src={rs} alt="rs" />
                        <p className="text-black">{filterAmount}</p>
                    </div>
                </div>
                <div className="space-y-1">
                    <input
                        title="fee"
                        type="range"
                        min={0}
                        max={maxAmount}
                        value={filterAmount}
                        onInput={handleChange}
                        className="w-full h-0.5 opacity-90 cursor-pointer outline-none"
                    />

                    <div className="flex justify-between">
                        <div>0</div>
                        <div>{maxAmount}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const BatchTimingFilter = ({ resetFunc, filter }: any) => {
    const dispatch = useAppDispatch();
    const [time, setTime] = useState({
        startTime: '',
        endTime: '',
    });

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setTime({ ...time, [name]: value });
    };

    useEffect(() => {
        if (time['startTime'].length && time['endTime'].length) {
            applyBatchFilter();
        }
        if (!time['startTime'].length || !time['endTime'].length) {
            dispatch(setFilters({ name: filter.filterName, value: '' }));
        }
    }, [time['startTime'], time['endTime']]);

    const applyBatchFilter = () => {
        let startTime = convertedTimeToNumber(time.startTime);
        let endTime = convertedTimeToNumber(time.endTime);
        if (startTime > endTime) {
            return toast.error(
                camelCaseConvertForErrorMessage(
                    'Start Time Should be Lesser than End Time'
                )
            );
        }
        dispatch(
            setFilters({
                name: filter.filterName,
                value: `${time['startTime']}-${time['endTime']}`,
            })
        );
    };

    const SetClear = () => {
        setTime({
            startTime: '',
            endTime: '',
        });
    };

    useEffect(() => {
        setTime({
            startTime: '',
            endTime: '',
        });
    }, [resetFunc]);

    return (
        <div className="flex xl:flex-col lg:space-y-2 items-start">
            <div className="flex lg:flex-col xl:flex-row items-center gap-1">
                <input
                    title="start-time"
                    type="time"
                    name="startTime"
                    value={time['startTime']}
                    className="p-1 border-[1px] rounded-[7px] border-[#E0E4E7]"
                    onChange={handleChange}
                />
                <p>-</p>
                <input
                    title="end-time"
                    type="time"
                    name="endTime"
                    value={time['endTime']}
                    className="border-[1px] p-1 rounded-[7px] border-[#E0E4E7]"
                    onChange={handleChange}
                />
            </div>
            {time['startTime'].length || time['endTime'].length ? (
                <p
                    className="bg-[#e92e04] ml-1 text-white rounded-sm px-2 py-1 text-[13px] font-[500] cursor-pointer"
                    onClick={SetClear}
                >
                    Clear
                </p>
            ) : (
                ''
            )}
            {/* <div className='flex gap-6'>
                <p className='text-[#5F5F5F] text-[15px] font-[400] border-b-[1px] cursor-pointer' onClick={applyBatchFilter}>Go</p>
                <p className='text-[#5F5F5F] text-[15px] font-[400] border-b-[1px] cursor-pointer' onClick={SetClear}>Clear</p>
            </div> */}
        </div>
    );
};

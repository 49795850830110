// import { useState, useEffect } from 'react';
// import { useAppDispatch, useAppSelector } from '../..';
// import { PulseLoader } from 'react-spinners';
// import markAsRead from '../../assets/Images/markAsRead.svg';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { OrderWiseSort } from '../Elements/OrderwiseSort';
// import moment from 'moment';
// import { MarkAllNotificationRead } from './ShowNotificationModal';
// import { readRecord } from '../../Services/backend/apiCalls';
// import { setNotify } from '../../Redux/Reducers/notificationReducer';
// import toast from 'react-hot-toast';
// import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
// import { lowerCase } from 'lodash';

// export const dateFormat = (dateText: string) => {
//     let text;
//     if (moment(dateText).format('MMMM DD,YYYY') !== 'Invalid date') {
//         text = moment(dateText).format('MMMM DD,YYYY H:MM:SS A');
//     } else {
//         text = moment(new Date()).format('MMMM DD,YYYY H:MM:SS A');
//     }
//     return text;
// };

// export const setDatePropertyValues = (
//     date: Date | null,
//     year: number | null,
//     month: number | null,
//     day: number | null,
//     hour: number | null,
//     minute: number | null,
//     second: number | null
// ) => {
//     const currentDate = date === null ? new Date() : date;

//     const yearNotValid = year === null || year > 100 || year < 0;
//     if (!yearNotValid) {
//         currentDate.setFullYear(year);
//     }

//     const monthNotValid = month === null || month < 0 || month > 12;
//     if (!monthNotValid) {
//         currentDate.setMonth(month);
//     }

//     const datNotValid = day === null || day < 0 || day > 31;
//     if (!datNotValid) {
//         currentDate.setDate(day);
//     }

//     const hourNotValid = hour === null || hour < 0 || hour > 24;
//     if (!hourNotValid) {
//         currentDate.setHours(hour);
//     }

//     const minuteNotValid = minute === null || minute < 0 || minute > 60;
//     if (!minuteNotValid) {
//         currentDate.setMinutes(minute);
//     }

//     const secondNotValid = second === null || second < 0 || second > 60;
//     if (!secondNotValid) {
//         currentDate.setSeconds(second);
//     }

//     return currentDate;
// };

// const getDateMonthYearString = (dateString: any) => {
//     const todayDate = new Date(dateString);
//     return todayDate.getDate() + todayDate.getMonth() + todayDate.getFullYear();
// };
// export const dateParseFormat = (
//     forward: boolean,
//     year: number | null,
//     month: number | null,
//     day: number | null,
//     hour: number | null,
//     minute: number | null,
//     second: number | null
// ) => {
//     const currentDate = new Date();

//     if (year === null || year > 100 || year < 0) {
//         year = 0;
//     }
//     const setYearValue = forward
//         ? currentDate.getFullYear() + year
//         : currentDate.getFullYear() - year;
//     currentDate.setFullYear(setYearValue);

//     if (month === null || month < 0 || month > 12) {
//         month = 0;
//     }
//     const setMonthValue = forward
//         ? currentDate.getMonth() + month
//         : currentDate.getMonth() - month;
//     currentDate.setMonth(setMonthValue);

//     if (day === null || day < 0 || day > 31) {
//         day = 0;
//     }
//     const setDateValue = forward
//         ? currentDate.getDate() + day
//         : currentDate.getDate() - day;
//     currentDate.setDate(setDateValue);

//     if (hour === null || hour < 0 || hour > 24) {
//         hour = 0;
//     }
//     const setHoursValue = forward
//         ? currentDate.getHours() + hour
//         : currentDate.getHours() - hour;
//     currentDate.setHours(setHoursValue);

//     if (minute === null || minute < 0 || minute > 60) {
//         minute = 0;
//     }
//     const setMinuteValue = forward
//         ? currentDate.getMinutes() + minute
//         : currentDate.getMinutes() - minute;
//     currentDate.setMinutes(setMinuteValue);

//     if (second === null || second < 0 || second > 60) {
//         second = 0;
//     }
//     const setSecondValue = forward
//         ? currentDate.getSeconds() + second
//         : currentDate.getSeconds() - second;
//     currentDate.setSeconds(setSecondValue);

//     return currentDate;
// };

// export const NotificationPage = () => {
//     const navigate = useNavigate();
//     const dispatch = useAppDispatch();
//     const [sorted, setSorted] = useState('Newest');
//     const [loading, setLoading] = useState(false);
//     const [{ token }, { notifyResponse }] = useAppSelector(
//         ({ signin, notification }: any) => [
//             signin['signinValue'],
//             notification['notificationData'],
//         ]
//     );
//     const [notificationDetails, setNotificationDetails] =
//         useState(notifyResponse);
//     console.log(notificationDetails, '148');

//     const optionsForSort = [
//         'Newest',
//         'Oldest',
//         'Today',
//         'Yesterday',
//         'Last 7 days',
//         'This month',
//     ];

//     useEffect(() => {
//         if (
//             JSON.stringify(notifyResponse) !==
//             JSON.stringify(notificationDetails)
//         ) {
//             setNotificationDetails(notifyResponse);
//         }
//     }, [JSON.stringify(notifyResponse)]);

//     const filterForIsRead = notifyResponse.filter(
//         (data: any) => data['is_read'] === false
//     );

//     const thisMonthFilter = (notifyData: any) => {
//         const thisMonthValue = new Date().getMonth();
//         const filterData = notifyData.filter(
//             (data: any) =>
//                 new Date(data['createdAt']).getMonth() === thisMonthValue
//         );
//         return filterData;
//     };

//     const filterData = (event: any) => {
//         setSorted(event.target.value);
//         let filteredData: any = [];
//         let validFrom = setDatePropertyValues(null, null, null, null, 0, 0, 0);
//         let validTo = new Date();
//         let referenceData = [...notifyResponse];

//         if (event.target.value === 'Newest') {
//             referenceData.sort((a: any, b: any) => {
//                 let da: any = new Date(a['createdAt']),
//                     db: any = new Date(b['createdAt']);
//                 return db - da;
//             });
//             setNotificationDetails(referenceData);
//             return;
//         } else if (event.target.value === 'Oldest') {
//             referenceData.sort((a: any, b: any) => {
//                 let da: any = new Date(a['createdAt']),
//                     db: any = new Date(b['createdAt']);
//                 return da - db;
//             });
//             setNotificationDetails(referenceData);
//             return;
//         }

//         if (event.target.value === 'Yesterday') {
//             validFrom = dateParseFormat(false, null, null, 1, null, null, null);
//             validFrom = setDatePropertyValues(
//                 validFrom,
//                 null,
//                 null,
//                 null,
//                 0,
//                 0,
//                 0
//             );
//             validTo = setDatePropertyValues(null, null, null, null, 0, 0, 0);
//             notifyResponse.forEach((element: any) => {
//                 if (
//                     validFrom <= new Date(element['createdAt']) &&
//                     new Date(element['createdAt']) <= validTo
//                 ) {
//                     filteredData.push(element);
//                 }
//             });
//             setNotificationDetails(filteredData);
//         } else if (event.target.value === 'Today') {
//             let date = new Date();
//             const todayDate = getDateMonthYearString(date);
//             const filteredRecords = notifyResponse.filter(
//                 (data: any) =>
//                     todayDate === getDateMonthYearString(data.createdAt)
//             );
//             setNotificationDetails(filteredRecords);
//         } else if (event.target.value === 'Last 7 days') {
//             // const
//             validFrom = dateParseFormat(false, null, null, 7, null, null, null);
//             validFrom = setDatePropertyValues(
//                 validFrom,
//                 null,
//                 null,
//                 null,
//                 0,
//                 0,
//                 0
//             );
//             validTo = new Date();
//             notifyResponse.forEach((element: any) => {
//                 if (
//                     validFrom <= new Date(element['createdAt']) &&
//                     new Date(element['createdAt']) <= validTo
//                 ) {
//                     filteredData.push(element);
//                 }
//             });
//             setNotificationDetails(filteredData);
//         } else if (event.target.value === 'This month') {
//             setNotificationDetails(thisMonthFilter(referenceData));
//             // validFrom = dateParseFormat(false, null, 1, null, null, null, null);
//             // validFrom = setDatePropertyValues(
//             //     validFrom,
//             //     null,
//             //     null,
//             //     null,
//             //     0,
//             //     0,
//             //     0
//             // );
//             // validTo = new Date();
//         }
//     };

//     const Notification = async () => {
//         setLoading(true);
//         const Response = await readRecord({}, 'notifications');
//         if (Response.status) {
//             setLoading(false);
//             dispatch(setNotify({ notifyResponse: Response.data }));
//         } else toast.error(Response.message);
//     };

//     useEffect(() => {
//         notifyResponse.length === 0 && Notification();
//     }, []);

//     const handleNotificationClick = (data: any) => {
//         const normalizedMessage = data.message.trim().toLowerCase();

//         if (normalizedMessage.includes('new bid')) {
//             navigate('/request/bidding');
//             // navigate('/request/suggestion');
//         } else if (normalizedMessage.includes('customized' || 'Booking')) {
//             navigate('/request/booknow');
//         } else if (normalizedMessage.includes('requested')) {
//             navigate('/request/booknow');
//         } else if (normalizedMessage.includes('scheduled')) {
//             navigate('/request/training');
//         } else if (normalizedMessage.includes('booking')) {
//             navigate('/request/booknow');
//         } else if (normalizedMessage.includes('new booking')) {
//             navigate('/request/training');
//         } else {
//             console.log(data);
//             toast.error('No route specified for this notification');
//         }
//     };

//     //  directRequestCustomize: "CN",
//     // directRequestAlignDirect: "BNC",
//     // directRequestCustomizeAccept:"CA",
//     // directRequestReject: "CR",
//     // paymentDone: "PD",
//     // trainingDone: "FR",
//     // reminder: "RE",
//     // bidQuote: "BDQ",
//     // bidAccept: "BDA",
//     // showInterest: "SI",
//     // cancelTraining:"CL"

//     return (
//         <div className="bg-[#F6F7F8] h-auto border">
//             <div className="mt-10 w-11/12 mx-auto mb-10">
//                 {notifyResponse.length > 0 && (
//                     <OrderWiseSort
//                         optionsForSort={optionsForSort}
//                         filterData={filterData}
//                         sorted={sorted}
//                         startDiv={'flex justify-end'}
//                     />
//                 )}
//             </div>
//             <div className="mt-8 w-11/12 mx-auto mb-10 bg-white rounded-[10px] p-5 space-y-8">
//                 <div className="flex items-center justify-between px-4 mt-4 border-b-[1px] border-[#6b5757] pb-2">
//                     <h1 className="text-[23px] font-[500] tracking-wide">
//                         Notifications
//                     </h1>
//                     {notifyResponse.length > 0 &&
//                         filterForIsRead.length > 0 && (
//                             <div className="flex items-center gap-2 cursor-pointer">
//                                 <img src={markAsRead} alt="MarkAsRead" />
//                                 <p
//                                     className="text-[#2975FD] text-[18px] font-[500]"
//                                     onClick={() =>
//                                         MarkAllNotificationRead(
//                                             notifyResponse,
//                                             token,
//                                             navigate,
//                                             dispatch
//                                         )
//                                     }
//                                 >
//                                     Mark as read
//                                 </p>
//                             </div>
//                         )}
//                 </div>
//                 <div className="space-y-4 overflow-y-auto scroll scroll-smooth h-[400px]">
//                     {loading ? (
//                         <div className="flex justify-center items-center h-full">
//                             <PulseLoader color="#0074FC" />
//                         </div>
//                     ) : notificationDetails?.length > 0 ? (
//                         notificationDetails.map((note: any, index: number) => {
//                             return (
//                                 <div
//                                     key={index}
//                                     className={`flex gap-1 cursor-pointer ${
//                                         note['is_read']
//                                             ? 'bg-white'
//                                             : 'bg-[#F6F6F6]'
//                                     }`}
//                                 >
//                                     <div className="px-4 py-4 mt-1">
//                                         <p className="w-[10px] h-[10px] rounded-full bg-blue-600 mt-1.5"></p>
//                                     </div>
//                                     <div
//                                         className="w-full py-4 border-b-[0.5px] border-[#b3d6e4]"
//                                         onClick={() =>
//                                             handleNotificationClick(note)
//                                         }
//                                     >
//                                         <p className=" cursor-pointer">
//                                             {note.message}
//                                         </p>
//                                         <p className="mt-1 text-[#808283]">
//                                             {dateFormat(note.createdAt)}
//                                         </p>
//                                     </div>
//                                 </div>
//                             );
//                         })
//                     ) : (
//                         <div className="flex justify-center items-center h-[300px]">
//                             No Notifications
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../..';
import { PulseLoader } from 'react-spinners';
import markAsRead from '../../assets/Images/markAsRead.svg';
import { useNavigate } from 'react-router-dom';
import { OrderWiseSort } from '../Elements/OrderwiseSort';
import moment from 'moment';
import { MarkAllNotificationRead } from './ShowNotificationModal';
import { readRecord } from '../../Services/backend/apiCalls';
import { setNotify } from '../../Redux/Reducers/notificationReducer';
import toast from 'react-hot-toast';

export const dateFormat = (dateText: string) => {
    let text;
    if (moment(dateText).format('MMMM DD,YYYY') !== 'Invalid date') {
        text = moment(dateText).format('MMMM DD,YYYY H:MM:SS A');
    } else {
        text = moment(new Date()).format('MMMM DD,YYYY H:MM:SS A');
    }
    return text;
};

export const NotificationPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [sorted, setSorted] = useState('Newest');
    const [loading, setLoading] = useState(false);
    const [{ token }, { notifyResponse }] = useAppSelector(
        ({ signin, notification }: any) => [
            signin['signinValue'],
            notification['notificationData'],
        ]
    );
    const [notificationDetails, setNotificationDetails] =
        useState(notifyResponse);

    const optionsForSort = [
        'Newest',
        'Oldest',
        'Today',
        'Yesterday',
        'Last 7 days',
        'This month',
    ];

    useEffect(() => {
        if (
            JSON.stringify(notifyResponse) !==
            JSON.stringify(notificationDetails)
        ) {
            setNotificationDetails(notifyResponse);
        }
    }, [JSON.stringify(notifyResponse)]);

    const filterForIsRead = notifyResponse.filter(
        (data: any) => data['is_read'] === false
    );

    const thisMonthFilter = (notifyData: any) => {
        const thisMonthValue = new Date().getMonth();
        const filterData = notifyData.filter(
            (data: any) =>
                new Date(data['createdAt']).getMonth() === thisMonthValue
        );
        return filterData;
    };

    const filterData = (event: any) => {
        setSorted(event.target.value);
        let filteredData: any = [];
        let validFrom = new Date();
        let validTo = new Date();
        let referenceData = [...notifyResponse];

        if (event.target.value === 'Newest') {
            referenceData.sort((a: any, b: any) => {
                let da: any = new Date(a['createdAt']),
                    db: any = new Date(b['createdAt']);
                return db - da;
            });
            setNotificationDetails(referenceData);
            return;
        } else if (event.target.value === 'Oldest') {
            referenceData.sort((a: any, b: any) => {
                let da: any = new Date(a['createdAt']),
                    db: any = new Date(b['createdAt']);
                return da - db;
            });
            setNotificationDetails(referenceData);
            return;
        }

        if (event.target.value === 'Yesterday') {
            validFrom.setDate(validFrom.getDate() - 1);
            notifyResponse.forEach((element: any) => {
                if (
                    validFrom <= new Date(element['createdAt']) &&
                    new Date(element['createdAt']) <= validTo
                ) {
                    filteredData.push(element);
                }
            });
            setNotificationDetails(filteredData);
        } else if (event.target.value === 'Today') {
            let date = new Date();
            const todayDate =
                date.getDate() + date.getMonth() + date.getFullYear();
            const filteredRecords = notifyResponse.filter(
                (data: any) =>
                    todayDate ===
                    new Date(data.createdAt).getDate() +
                        new Date(data.createdAt).getMonth() +
                        new Date(data.createdAt).getFullYear()
            );
            setNotificationDetails(filteredRecords);
        } else if (event.target.value === 'Last 7 days') {
            validFrom.setDate(validFrom.getDate() - 7);
            notifyResponse.forEach((element: any) => {
                if (
                    validFrom <= new Date(element['createdAt']) &&
                    new Date(element['createdAt']) <= validTo
                ) {
                    filteredData.push(element);
                }
            });
            setNotificationDetails(filteredData);
        } else if (event.target.value === 'This month') {
            setNotificationDetails(thisMonthFilter(referenceData));
        }
    };

    const Notification = async () => {
        setLoading(true);
        const Response = await readRecord({}, 'notifications');
        if (Response.status) {
            setLoading(false);
            dispatch(setNotify({ notifyResponse: Response.data }));
        } else toast.error(Response.message);
    };

    useEffect(() => {
        notifyResponse.length === 0 && Notification();
    }, []);

    const handleNotificationClick = (data: any) => {
        const normalizedMessage = data.message.trim().toLowerCase();

        if (normalizedMessage.includes('new bid')) {
            navigate('/request/bidding');
        } else if (
            normalizedMessage.includes('customized') ||
            normalizedMessage.includes('booking')
        ) {
            navigate('/request/booknow');
        } else if (normalizedMessage.includes('requested')) {
            navigate('/request/booknow');
        } else if (normalizedMessage.includes('scheduled')) {
            navigate('/request/training');
        } else if (normalizedMessage.includes('booking')) {
            navigate('/request/booknow');
        } else if (normalizedMessage.includes('new booking')) {
            navigate('/request/training');
        } else {
            console.log(data);
            toast.error('No route specified for this notification');
        }
    };

    return (
        <div className="bg-[#F6F7F8] h-auto border">
            <div className="mt-6 sm:mt-10 w-11/12 mx-auto mb-6 sm:mb-10">
                {notifyResponse.length > 0 && (
                    <OrderWiseSort
                        optionsForSort={optionsForSort}
                        filterData={filterData}
                        sorted={sorted}
                        startDiv={'flex justify-end'}
                    />
                )}
            </div>
            <div className="mt-4 sm:mt-8 w-11/12 mx-auto mb-6 sm:mb-10 bg-white rounded-[10px] p-4 sm:p-5 space-y-6 sm:space-y-8">
                <div className="flex items-center justify-between px-4 mt-4 border-b-[1px] border-[#6b5757] pb-2">
                    <h1 className="text-[18px] sm:text-[23px] font-[500] tracking-wide">
                        Notifications
                    </h1>
                    {notifyResponse.length > 0 &&
                        filterForIsRead.length > 0 && (
                            <div className="flex items-center gap-2 cursor-pointer">
                                <img
                                    src={markAsRead}
                                    alt="MarkAsRead"
                                    className="w-4 h-4 sm:w-5 sm:h-5"
                                />
                                <p
                                    className="text-[#2975FD] text-[16px] sm:text-[18px] font-[500]"
                                    onClick={() =>
                                        MarkAllNotificationRead(
                                            notifyResponse,
                                            token,
                                            navigate,
                                            dispatch
                                        )
                                    }
                                >
                                    Mark as read
                                </p>
                            </div>
                        )}
                </div>
                <div className="space-y-4 overflow-y-auto scroll scroll-smooth h-[300px] sm:h-[400px]">
                    {loading ? (
                        <div className="flex justify-center items-center h-full">
                            <PulseLoader color="#0074FC" />
                        </div>
                    ) : notificationDetails?.length > 0 ? (
                        notificationDetails.map((note: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className={`flex gap-1 cursor-pointer ${
                                        note['is_read']
                                            ? 'bg-white'
                                            : 'bg-[#F6F6F6]'
                                    }`}
                                >
                                    <div className="px-4 py-4 mt-1">
                                        <p className="w-[8px] sm:w-[10px] h-[8px] sm:h-[10px] rounded-full bg-blue-600 mt-1.5"></p>
                                    </div>
                                    <div
                                        className="w-full py-4 border-b-[0.5px] border-[#b3d6e4]"
                                        onClick={() =>
                                            handleNotificationClick(note)
                                        }
                                    >
                                        <p className="text-[14px] sm:text-[16px] cursor-pointer">
                                            {note.message}
                                        </p>
                                        <p className="mt-1 text-[12px] sm:text-[14px] text-[#808283]">
                                            {dateFormat(note.createdAt)}
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className="flex justify-center items-center h-[300px]">
                            No Notifications
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

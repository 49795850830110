import {useState, useEffect} from "react";
import { MoonLoader } from "react-spinners";
import { DragDrop } from "../Elements/DragAndDrop";
import Frame from "../../assets/Images/Frame.svg";
import deleteImage from "../../assets/Images/deleteImage.svg";

export const DragAndDrop=({imageKeyURL,deleteImg,setImageURL,label,uploadResumeFile,imageURL,error,isTouched,...rest}:any)=>{
    const [imageUrl, setImageUrl] = useState(imageKeyURL);
    const [uploading,setUploading]=useState(false);

    useEffect(() => {
        if (imageKeyURL !== imageURL) {
            setImageUrl(imageKeyURL);
            setUploading(false);
        }
    }, [imageKeyURL]);

    const resetImage = () => {
        setImageUrl(false);
    }
    return (
        <div className="flex flex-col relative">
            <label className="font-[500] text-[15px] mb-2">
                {label}
                <span className="ml-0.5 text-red-600">*</span>
            </label>
            {imageUrl || uploading ? (
                <ImageUploadedContainer
                    uploading={uploading}
                    imageURL={imageUrl}
                    uploadImageFunc={resetImage}
                    deleteImageFunc={() => {
                        setUploading(true);
                        deleteImg(imageKeyURL);
                    }}
                />
            ) : (
                <DragDrop
                    default={CourseDragAndDropContainer}
                    onUpload={(files: any) => {
                        setUploading(true);
                        uploadResumeFile(files);
                    }}
                />
            )}
            {error && isTouched && (
                <p className="text-red-500 text-[12px] absolute right-2 bottom-6">
                    {error}
                </p>
            )}
        </div>
    );
}

const ImageUploadedContainer = ({ imageURL, uploadImageFunc, deleteImageFunc,uploading }: any) => {
    const [buttonActive, setButtonActive] = useState(false);
    return (
        <div className='h-[240px] rounded-[8px] relative'>
            {!uploading && <img src={imageURL} className="h-full object-cover w-full rounded-[8px]" alt='courseImage' />}
            {
                uploading?<div className='absolute top-0 w-full h-full flex justify-center items-center'>
                    <MoonLoader size={60} color={"#065FEF"} />
                </div>:<div className='absolute top-0 w-full h-full flex justify-center items-center space-x-4'>
                {/* <button type='button' className='flex justify-center items-center gap-2 text-[#065FEF] p-3 px-4 rounded-[4px] bg-white text-[12px] font-[400]' title='uploadButton' onClick={()=>{uploadImageFunc();setButtonActive(true)}} disabled={buttonActive}><img alt='uploadIcon' src={uploadImage} />Upload</button> */}
                <button type='button' className='flex justify-center items-center gap-2 text-[#065FEF] p-3 px-4 rounded-[4px] bg-white text-[12px] font-[400]' title='deleteButton' onClick={()=>{deleteImageFunc();setButtonActive(true)}} disabled={buttonActive}><img alt='deleteIcon' src={deleteImage} />Delete</button>
            </div>
            }
        </div>
    )
}

const CourseDragAndDropContainer = ({inputProps}:any) => {
    return (
        <div className="flex flex-col justify-center space-y-4 items-center h-[240px] bg-white rounded-[8px] border-[0.7px] border-black">
            <img src={Frame} alt="dragDropImage" />
            <label className="text-[14px] select-none">
                Drag and drop an image, or{' '}
                <span className="text-[#1F74E5] cursor-pointer font-[500]">
                    Click Here
                </span>
            </label>
            <span className="text-[12px] text-red-500 select-none">
                Image resolution 400*300 is recommended
            </span>
        </div>
    );
}
import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { useAppSelector } from '../..';
import SampleImageUpload from "../../assets/Images/sampleImageUpload.svg";
import arrow from "../../assets/Images/RightArrow.svg";
import { readRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import {PulseLoader} from "react-spinners";
import moment from 'moment';
import { dateFormat } from '../ViewCourse/DateFormat';
import "../Dashboard/card.css";
import { FilterDataForCMS } from '../Elements/FilterDataForCMS';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const CourseMenu = () => {
    const navigate=useNavigate();
    const [loading, setLoading] = useState(false);
    const [activeCourses, setActiveCourses] = useState([]);
    const [inActiveCourses, setInActiveCourses] = useState([]);
    const [activeStatus, setActiveStatus] = useState(true);
    const coursesList = activeStatus ? activeCourses : inActiveCourses;
    // const NoCourseFoundText=activeStatus?"No active"
    const [tokenExpired, setTokenExpired] = useState("")
    const {token} = useAppSelector(({signin}:any)=>signin["signinValue"]);

    const addcourseuserRole = {
        TI:"Institute",
        FL:"Freelancer",
        ST:"Student",
        CO:"Corporate"
    }

    const getAllCourses=async()=>{
        setLoading(true);
        const Courses = await readRecord({},"courses");
        if (Courses.status) {
            const activeCoursesList = Courses.data.filter((course: any) => course.isactive === true);
            const inActiveCoursesList = Courses.data.filter((course: any) => course.isactive !== true);
            setActiveCourses(activeCoursesList)
            setInActiveCourses(inActiveCoursesList)
        } else toast.error(Courses.message);
        setLoading(false);
    }

    useEffect(()=>{
        let listen:boolean=true;
        if(listen){
            getAllCourses();
        }
        return ()=>{    
          listen=false;
        }
    }, [])
    
  return (
      <div className="h-auto bg-[#F6F7F8]">
          <div className="w-full mx-auto">
              <div>
                  <StatusOfMyCourse
                      activeStatus={activeStatus}
                      setActiveStatus={setActiveStatus}
                  />
              </div>
              {loading ? (
                  <div className="flex w-full justify-center items-center mx-auto min-h-screen">
                      <PulseLoader color="#0074FC" />
                  </div>
              ) : (
                  <div className="min-h-[761px]">
                      {!coursesList.length && (
                          <p className="text-center">No courses found</p>
                      )}
                      <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6 mb-10">
                          {coursesList.map((data, index) => {
                              if (data['isactive'] === activeStatus) {
                                  return (
                                      <CourseCard
                                          key={index}
                                          image={data['image']}
                                          postedDate={dateFormat(
                                              data['createdAt']
                                          )}
                                          id={data['id']}
                                          tutor_name={data['profile']['name']}
                                          tutor_role={
                                              addcourseuserRole[
                                                  data['profile']['role']
                                              ]
                                          }
                                          title={data['title']}
                                      />
                                  );
                              }
                          })}
                      </div>
                  </div>
              )}
          </div>
      </div>
  );
}

const StatusOfMyCourse = ({ activeStatus,setActiveStatus }:any) => {
    return (
        <div className="w-11/12 mx-auto flex justify-center items-center gap-10 mt-10 mb-10 relative">
            <button
                className={`${
                    activeStatus
                        ? 'bg-[#E7EFFF] text-[#2975FD]'
                        : 'bg-white text-[#A8ABBB]'
                } cursor-pointer text-[14px] rounded-[8px] py-3 px-9 outline-none`}
                onClick={() => setActiveStatus(true)}
            >
                Active
            </button>
            <button
                className={`${
                    !activeStatus
                        ? 'bg-[#E7EFFF] text-[#2975FD]'
                        : 'bg-white text-[#A8ABBB]'
                } cursor-pointer text-[14px] rounded-[8px] py-3 px-8 outline-none`}
                onClick={() => setActiveStatus(false)}
            >
                Inactive
            </button>
            {/* <FilterDataForCMS /> */}
        </div>
    );
};

const CourseCard = ({ image, title, tutor_name, tutor_role, postedDate, id }: any) => {
    const navigate = useNavigate();
    return (        
        <div className='bg-[#FBFBFB] flex flex-col justify-between items-center pt-1'>
                            <img src={image ? image : SampleImageUpload} alt="SampleImageUpload" className='px-1 h-[200px] object-cover' />
                            <div className='flex flex-col items-end w-full px-2 py-6'>
                                <div className='flex flex-col items-start w-11/12 mx-auto'>
                                    <h1 className='text-[18px] font-[600]'>{title}</h1>
                                    <p className='text-[13px] font-[500] mt-2 text-left'>{tutor_name}<span className='text-[9px] p-1 px-2 font-[400] ml-2 text-[#267DF8] bg-[#E7EFFF] rounded-[12px]'>{tutor_role}</span></p>
                                    <p className='text-[12px] font-[300] mt-2'>Posted on <span className='font-[600]'>{postedDate}</span></p>
                                    <div className='border-b-2 mt-4 w-11/12'></div>
                                <div className='w-11/12 flex justify-end'>
                                    <div className='w-[90%]'></div>
                                        <img src={arrow} alt="arrow" className='mt-2 arrow cursor-pointer' onClick={()=>navigate(`/course/view/${title}/${id}`)} />
                                    </div>
                                </div>
                            </div>
                    </div>
    )
}

// import React, { useState, useEffect, useRef } from 'react';
// import {
//     createSearchParams,
//     useLocation,
//     useNavigate,
//     useParams,
// } from 'react-router-dom';
// import Users from '../../assets/Images/Users.svg';
// import Cal from '../../assets/Images/Cal.svg';
// import Mode from '../../assets/Images/mode.svg';
// import Session from '../../assets/Images/session.svg';
// import dateImg from '../../assets/Images/dateImg.svg';
// import locationImg from '../../assets/Images/locationImg.svg';
// import arrow from '../../assets/Images/RightArrow.svg';
// import editAll from '../../assets/Images/editAll.svg';
// import TargetIcon from '../../assets/Images/TargetIcon.svg';
// import { dateFormat } from './Role';
// import { useAppSelector } from '../..';
// import toast from 'react-hot-toast';
// import { readRecord, updateRecord } from '../../Services/backend/apiCalls';
// import startDate from '../../assets/Images/startDate.svg';
// import rs from '../../assets/Images/rs.svg';
// import {
//     daysConversionAndSort,
//     actionText,
// } from '../../Services/commonFunctions';
// import { PulseLoader } from 'react-spinners';
// import { RoleCard } from './Role';
// import { ProgressBar } from '../Elements/Progressbar';
// import { TopBids } from '../Dashboard/Card';
// import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
// import { OrderWiseSort } from '../Elements/OrderwiseSort';
// import { RequestStatus } from './RequestPage';
// import { DisplayReadOnlyTags } from '../Elements/Tags';

// import DOMPurify from 'dompurify';
// export const Card = ({
//     appConfig,
//     status,
//     request,
//     loading,
//     setLoading,
//     checkTrainer,
//     pageName,
//     setStatus,
//     paramsState,
//     state,
// }: any) => {
//     const navigate = useNavigate();
//     const [requestState, setRequestState] = useState([]);
//     const [sorted, setSorted] = useState('Newest');
//     const { role } = useAppSelector(({ signin }: any) => signin['signinValue']);
//     const userType = ['TI', 'FL'].includes(role) ? 'Trainee' : 'Trainer';
//     const isTrainer = ['TI', 'FL'].includes(role);
//     const { token } = useAppSelector(
//         ({ signin }: any) => signin['signinValue']
//     );
//     const optionsForSort = ['Newest', 'Oldest'];

//     const selectCourseInSection: any = {
//         booknow: 'directrequests',
//         bidding: 'biddings',
//         training: 'trainings',
//     };

//     const getRequestDetails = async () => {
//         if (!status.length || !request.length) {
//             return;
//         }
//         const sectionEndpoint = selectCourseInSection[request];
//         const params = {
//             status,
//         };
//         const response = await readRecord(params, sectionEndpoint);
//         // console.log({ response });
//         if (response.status) {
//             setRequestState(response.data);
//             console.log('response', response.data);
//         } else toast.error(response.message);
//         setLoading(false);
//     };

//     const getCommonUIKeys = (data: any) => {
//         console.log(data, 'data');
//         let isTrainingAndDr =
//             request === 'training' &&
//             ['DR', 'dr'].includes(data.training_origin);
//         let isTrainingAndBd =
//             request === 'training' &&
//             ['BD', 'bd'].includes(data.training_origin);
//         // request === 'directrequest' ? data.course_id : isTrainingAndDr? data.id

//         return {
//             courseTitle: request === 'booknow' ? data.course.title : data.title,
//             description:
//                 request === 'booknow'
//                     ? data.course.description
//                     : data.description,
//             createdAt: dateFormat(data.createdAt),
//             id: data.id,
//             order_id: request === 'training' && data.order_id,
//             isPaymentDone: data.isPaymentDone,
//             navigate_id:
//                 isTrainingAndDr || request === 'booknow'
//                     ? data.course_id
//                     : isTrainingAndBd
//                     ? data.bid_id
//                     : data.id,
//             currency_code: data.currency_code,
//             ...(data[userType] &&
//                 data[userType] !== null && {
//                     role: data[userType].role,
//                     user_id: data[userType].user_id,
//                     userName: data[userType].name,
//                 }),
//             amount:
//                 request === 'training'
//                     ? data.amount
//                     : request === 'booknow'
//                     ? data.fee
//                     : data.accepted_amount,
//             // update
//             // start_date: dateFormat(data.start_date),
//             start_date: dateFormat(data.date_of_training),
//             end_date: dateFormat(data.end_date),
//             trainees_count: data.trainees_count,
//             address:
//                 request !== 'bidding'
//                     ? data[userType].address_line
//                     : data.address,
//             duration: request !== 'bidding' ? data.duration : data.days,
//             // mode:
//             //     request === 'dr'
//             //         ? data.mode_of_teaching.mode
//             //         : data.mode || 'online',
//             mode:
//                 (data.mode_of_teaching && data.mode_of_teaching.mode) ||
//                 data.mode ||
//                 '',
//             session:
//                 request !== 'bidding'
//                     ? data.mode_of_teaching.session
//                     : data.preferred_days,
//             topBid: request === 'bidding' && data.topBidderDetails,
//             bidAmount: request === 'bidding' && data.accepted_amount,
//             bidStatus: request === 'bidding' && data.status,
//             bidTrainer: request === 'bidding' && data.Trainer,
//             checkIsDirectRequest: request === 'training' && data.directrequest,
//         };
//     };

//     const navigateTo = (id: number, title: string, additonalId: string) => {
//         const routeLink = request !== 'bidding' ? '/booknow/' : '/bid/view/';
//         // console.log({routeLink});
//         navigate({
//             pathname: routeLink + title + '/' + id,
//             ...(request === 'booknow' && {
//                 search: createSearchParams({ dr_id: additonalId }).toString(),
//             }),
//         });
//     };

//     const navigateToForTraining = (
//         id: number,
//         title: string,
//         checkIsDirectRequest: any
//     ) => {
//         const routeLink =
//             checkIsDirectRequest !== null ? '/course/view/' : '/bid/view/';
//         navigate(routeLink + title + '/' + id);
//     };

//     useEffect(() => {
//         let listen: boolean = true;
//         // setLoading(true);
//         if (listen) {
//             (async () => {
//                 getRequestDetails();
//             })();
//         }
//         // setLoading(false);
//         return () => {
//             listen = false;
//         };
//     }, [status, request]);

//     const updateDirectRequest = async (id: number, status: any) => {
//         let body = { id, status };
//         let drResponse = await updateRecord(body, 'directrequests');
//         if (drResponse.status) {
//             const filteredArray = requestState.filter(
//                 (data: any) => data.id !== id
//             );
//             setRequestState(filteredArray);
//             toast.success(
//                 `Book Now ${camelCaseConvertForErrorMessage(
//                     status
//                 )} Successfully`
//             );
//         } else {
//             toast.error(drResponse.message);
//         }
//     };

//     const updateTraining = async (id: number, status: string) => {
//         const updatedResponse = await updateRecord(
//             {
//                 id,
//                 status: 'completed',
//             },
//             'trainings'
//         );
//         if (updatedResponse.status) {
//             const filteredArray = requestState.filter(
//                 (data: any) => data.id !== id
//             );
//             setRequestState(filteredArray);
//             toast.success(
//                 `Training ${camelCaseConvertForErrorMessage(
//                     status
//                 )} Successfully`
//             );
//         } else {
//             toast.error(updatedResponse.message);
//         }
//     };

//     const cancelUpcomingCourse = (order_id: any, id: number) => {
//         // console.log('cancelling upcoming course');
//         navigate('/cancellation', { state: { order_id: order_id, id: id } });
//     };

//     const filterData = (event: any) => {
//         const { value } = event.target;
//         setSorted(value);
//         if (value === 'Newest') {
//             requestState.sort((a: any, b: any) => {
//                 let da: any = new Date(a['createdAt']),
//                     db: any = new Date(b['createdAt']);
//                 return db - da;
//             });
//             setRequestState(requestState);
//             return;
//         } else if (value === 'Oldest') {
//             requestState.sort((a: any, b: any) => {
//                 let da: any = new Date(a['createdAt']),
//                     db: any = new Date(b['createdAt']);
//                 return da - db;
//             });
//             setRequestState(requestState);
//             return;
//         }
//     };

//     // if (loading) {
//     //     return (
//     //         <div className="flex w-full min-h-[450px] items-center justify-center">
//     //             <PulseLoader color="#0074FC" />
//     //         </div>
//     //     );
//     // }

//     return (
//         <>
//             <div className="flex justify-center relative py-0">
//                 <RequestStatus
//                     checkTrainer={checkTrainer}
//                     paramsState={state !== 'typeForHeaderClick'}
//                     pageName={pageName}
//                     status={status}
//                     request={request}
//                     setStatus={setStatus}
//                     setLoading={setLoading}
//                 />
//                 <OrderWiseSort
//                     optionsForSort={optionsForSort}
//                     filterData={filterData}
//                     sorted={sorted}
//                     startDiv={'absolute right-0'}
//                 />
//             </div>
//             <div className="min-h-[500px] mt-2">
//                 {loading ? (
//                     <div className="flex w-full min-h-[450px] items-center justify-center">
//                         <PulseLoader color="#0074FC" />
//                     </div>
//                 ) : requestState?.length === 0 ? (
//                     <div className="min-h-[200px] flex justify-center items-center text-[#085BEB] text-[18px] font-[500]">
//                         No Data Available
//                     </div>
//                 ) : (
//                     requestState?.map((response: any, index: any) => {
//                         console.log('ffd', requestState);
//                         console.log('fff', response);

//                         const commonKeyData = getCommonUIKeys(response);
//                         // console.log({ commonKeyData });
//                         console.log('this is commomn', commonKeyData);

//                         return (
//                             <RequestDetailsCard
//                                 tags={response.tags}
//                                 request={request}
//                                 key={index}
//                                 commonKeyData={commonKeyData}
//                                 role={commonKeyData.role}
//                                 isDr={request === 'booknow'}
//                                 isTr={request === 'training'}
//                                 actionText={() =>
//                                     actionText(
//                                         status,
//                                         isTrainer,
//                                         request === 'booknow'
//                                     )
//                                 }
//                                 isTrainer={isTrainer}
//                                 navigateTo={
//                                     request === 'training'
//                                         ? navigateToForTraining
//                                         : navigateTo
//                                 }
//                                 navigate={navigate}
//                                 status={status}
//                                 updateDirectRequest={updateDirectRequest}
//                                 updateTraining={updateTraining}
//                                 cancelUpcomingCourse={cancelUpcomingCourse}
//                                 appConfig={appConfig}
//                             />
//                         );
//                     })
//                 )}
//             </div>
//         </>
//     );
// };

// export const RequestDetailsCard = ({
//     trainee_id,
//     appConfig,
//     isTr,
//     request,
//     commonKeyData,
//     isTrainer,
//     status,
//     actionText,
//     isDr,
//     navigateTo,
//     role,
//     navigate,
//     paymentStatus,
//     updateDirectRequest,
//     updateTraining,
//     cancelUpcomingCourse,
//     tags,
// }: any) => {
//     console.log({ commonKeyData, role, appConfig, isTrainer });

//     let sanatiseDescription = DOMPurify.sanitize(commonKeyData.description);

//     const statusColor =
//         status === 'accepted' || status === 'upcoming'
//             ? 'text-[#00B89B] font-[300]'
//             : 'text-[#FB5935] font-[300]';
//     const statusColorForName =
//         isTrainer || request === 'training'
//             ? 'text-[#000000]'
//             : status === 'accepted' || status === 'upcoming'
//             ? 'text-[#00B89B]'
//             : status === 'pending'
//             ? 'text-[#ECB833]'
//             : 'text-[#FB5935]';
//     const location = useLocation();
//     const isBidView = location.pathname.includes('/bid/view');
//     const TrainerAndCTPAndProgress =
//         request === 'training' && isTrainer && status === 'pending';

//     return (
//         <div className="mt-4 w-full bg-white rounded-[8px] py-5 px-6 flex flex-col space-y-8">
//             <div>
//                 <div className="flex justify-between items-center">
//                     <div className={'flex justify-between w-full items-center'}>
//                         <div className="flex gap-4 items-center">
//                             <h1
//                                 className="text-[#085BEB] text-[35px] font-[500] cursor-pointer "
//                                 // update by me remove cousor-pointer

//                                 onClick={() => {
//                                     navigateTo &&
//                                         navigateTo(
//                                             commonKeyData.navigate_id,
//                                             commonKeyData.courseTitle,
//                                             request !== 'training'
//                                                 ? commonKeyData.id
//                                                 : commonKeyData.checkIsDirectRequest
//                                         );
//                                 }}
//                             >
//                                 {commonKeyData.courseTitle}
//                             </h1>

//                             <span className="text-[14px] font-[400] text-[#5F5F5F] pt-1">
//                                 {commonKeyData.createdAt}
//                             </span>
//                             {/* {request === 'bd' &&
//                                 status === 'rejected' &&
//                                 isTrainer && (
//                                     <div className="flex items-center gap-4 text-[23px] font-[500] text-[#2975FD]">
//                                         <h1 className="text-[#5F5F5F] text-[17px] font-[300]">
//                                             My Bid
//                                         </h1>
//                                         <div className="flex items-center gap-0.5">
//                                             <img
//                                                 src={rs}
//                                                 alt="rupees"
//                                                 className="w-[18px] h-[18px]"
//                                             />
//                                             <span>
//                                                 {commonKeyData.bidAmount &&
//                                                     commonKeyData.bidAmount.toLocaleString(
//                                                         'en-IN'
//                                                     )}
//                                             </span>
//                                         </div>
//                                     </div>
//                                 )} */}
//                         </div>
//                         {request !== 'training' &&
//                             status === 'accepted' &&
//                             ((request === 'booknow' &&
//                                 appConfig['direct_request_action'] === 'edit' &&
//                                 appConfig['cms_action'] === 'edit') ||
//                                 (request === 'bidding' &&
//                                     appConfig['bidding_action'] === 'edit' &&
//                                     appConfig['cms_action'] === 'edit')) && (
//                                 <MakePayment
//                                     isTr={isTr}
//                                     isDr={isDr}
//                                     isTrainer={isTrainer}
//                                     id={commonKeyData.id}
//                                     status={status}
//                                     role={role}
//                                     isPaymentDone={commonKeyData.isPaymentDone}
//                                 />
//                             )}
//                         {request === 'booknow' &&
//                             status === 'pending' &&
//                             appConfig['direct_request_action'] === 'edit' &&
//                             appConfig['cms_action'] === 'edit' && (
//                                 <AcceptOrReject
//                                     isTrainer={isTrainer}
//                                     id={commonKeyData.id}
//                                     updateDirectRequest={updateDirectRequest}
//                                 />
//                             )}
//                         {/* {request === 'bd' &&
//                             status === 'accepted' &&
//                             isTrainer && (
//                                 <div className="flex items-center gap-4 text-[23px] font-[500] text-[#2975FD]">
//                                     <h1 className="text-[#5F5F5F] text-[17px] font-[300]">
//                                         My Bid
//                                     </h1>
//                                     <div className="flex items-center gap-0.5">
//                                         <img
//                                             src={rs}
//                                             alt="rupees"
//                                             className="w-[18px] h-[18px]"
//                                         />
//                                         <span>
//                                             {commonKeyData.bidAmount &&
//                                                 commonKeyData.bidAmount.toLocaleString(
//                                                     'en-IN'
//                                                 )}
//                                         </span>
//                                     </div>
//                                 </div>
//                             )} */}
//                         {request === 'bidding' &&
//                             ((['pending', 'rejected'].includes(status) &&
//                                 isTrainer) ||
//                                 (['pending'].includes(status) &&
//                                     !isTrainer)) && (
//                                 <TopBids
//                                     commmonkeyData={commonKeyData}
//                                     id={commonKeyData.id}
//                                     topBidderDetails={
//                                         status === 'pending'
//                                             ? commonKeyData.topBid
//                                             : {
//                                                   ...commonKeyData.bidTrainer,
//                                                   amount: commonKeyData.bidAmount,
//                                               }
//                                     }
//                                     status={
//                                         isTrainer
//                                             ? status
//                                             : commonKeyData.bidStatus
//                                     }
//                                     requote={status === 'pending'}
//                                     isTrainee={!isTrainer}
//                                     config={
//                                         appConfig['bidding_action'] ===
//                                             'edit' &&
//                                         appConfig['cms_action'] === 'edit'
//                                     }
//                                 />
//                             )}
//                         {request === 'training' &&
//                             status === 'upcoming' &&
//                             !isTrainer && (
//                                 <CancelButton
//                                     onClick={cancelUpcomingCourse}
//                                     order_id={commonKeyData.order_id}
//                                     id={commonKeyData.id}
//                                 />
//                             )}
//                         {TrainerAndCTPAndProgress && (
//                             <CompleteButton
//                                 id={commonKeyData.id}
//                                 updateTraining={updateTraining}
//                             />
//                         )}
//                         {/* {(status==="rejected")&&(request)&&<MakePayment isTrainer={isTrainer} status={status} role={role}/>} */}
//                     </div>
//                     {/* {status === 'accepted' && !isDr && (
//                         <h1 className="text-[17px] font-[300] text-[#5F5F5F]">
//                             Accepted bid
//                             <span className="ml-2 text-[25px] font-[700] text-[#33BD87]">
//                                 {commonKeyData.amount && commonKeyData.amount.toLocaleString(
//                                     'en-IN'
//                                 )}
//                             </span>
//                         </h1>
//                     )} */}
//                     {status === 'Inprogress' && !isDr && (
//                         <h1 className="text-[17px] font-[500] text-[#ECB833]">
//                             In Progress
//                         </h1>
//                     )}
//                 </div>
//                 {commonKeyData.role && (
//                     <div className="flex gap-2 items-center mt-2">
//                         <p className={`${statusColor}`}>
//                             {actionText}
//                             <span
//                                 className={`ml-1 cursor-pointer text-[17px] font-[500] ${statusColorForName}`}
//                                 onClick={(e) => {
//                                     localStorage.setItem(
//                                         'selectedCourseUserId',
//                                         JSON.stringify(commonKeyData?.user_id)
//                                     );
//                                     e.stopPropagation();
//                                     navigate(
//                                         `/profile/view/${commonKeyData.userName}`
//                                     );
//                                 }}
//                             >
//                                 {commonKeyData.userName}
//                                 {/* {console.log(commonKeyData.userName)} */}
//                             </span>
//                         </p>
//                         {isTrainer ? (
//                             <div className="flex items-center ">
//                                 <p
//                                     className="font-[600] text-[12px] cursor-pointer"
//                                     onClick={() => {
//                                         localStorage.setItem(
//                                             'selectedCourseUserId',
//                                             JSON.stringify(
//                                                 commonKeyData?.trainee_id
//                                             )
//                                         );
//                                         navigate(
//                                             `/profile/view/${commonKeyData.Trainee}`
//                                         );
//                                     }}
//                                 >
//                                     {commonKeyData.Trainee}
//                                     {/* {console.log(commonKeyData.Trainee)} */}
//                                 </p>

//                                 <RoleCard role={commonKeyData.role} />
//                             </div>
//                         ) : null}
//                     </div>
//                 )}
//                 {/* {(status==="accepted")&&(request)&&<p className='text-[22px] font-[500] text-[#303030] mt-4'>{response[`${userType}`].name}<span className='w-[76px] h-[20px] rounded-[12px] bg-[#D4FFF8] text-[7px] font-[400] text-[#00B89B]'>{userTypeCondition[response[`${userType}`].role].name}</span></p>} */}
//                 {commonKeyData.tags && (
//                     <DisplayReadOnlyTags tags={commonKeyData.tags} />
//                 )}
//                 <h1
//                     className="text-[16px] font-[300] text-[#5F5F5F] mt-3 min-h-[75px]"
//                     dangerouslySetInnerHTML={{
//                         __html: sanatiseDescription,
//                     }}
//                 >
//                     {/* {commonKeyData.description}
//                     {console.log(commonKeyData)}{' '} */}
//                 </h1>
//                 {tags?.length > 0 &&
//                     tags.map((tag: any, i: any) => {
//                         return (
//                             <div className="flex flex-wrap my-2 ">
//                                 <p
//                                     className="bg-[#F8F8F8] mr-2 text-[#3061F4] font-[400] text-[12px] px-4 py-1 rounded-[18px]"
//                                     key={i}
//                                 >
//                                     #{tag}
//                                 </p>
//                             </div>
//                         );
//                     })}
//                 {console.log(commonKeyData)}
//                 <UserRequestDetails
//                     isDr={isDr}
//                     roleType={commonKeyData.role}
//                     fee={commonKeyData.amount}
//                     status={status}
//                     mode={commonKeyData.mode}
//                     session={commonKeyData.session}
//                     trainees_count={commonKeyData.trainees_count}
//                     address_line={commonKeyData.address}
//                     duration={commonKeyData.duration}
//                     // update date start_date
//                     // start_date={commonKeyData.start_date}
//                     start_date={commonKeyData.start_date}
//                     trainee={commonKeyData.trainee}
//                     role={commonKeyData.trainee?.role}
//                 />
//             </div>
//             {request === 'training' && status === 'pending' && (
//                 <ProgressBar
//                     endDate={commonKeyData.end_date}
//                     startDate={commonKeyData.start_date}
//                 />
//             )}
//             {!isBidView && request === 'bidding' && (
//                 <div className="flex flex-col justify-between">
//                     <div className="border-b-[0.5px]"></div>
//                     <ArrowKey
//                         id={commonKeyData.id}
//                         status={status}
//                         isBd={!isDr}
//                     />
//                 </div>
//             )}
//         </div>
//     );
// };

// const CompleteButton = ({ id, updateTraining }: any) => {
//     const completeButtonRef: any = useRef();

//     const completeCourseAction = async () => {
//         completeButtonRef.current.disabled = true;
//         await updateTraining(id, 'completed');
//         completeButtonRef.current.disabled = false;
//     };
//     return (
//         // <div className="flex justify-end">
//         <button
//             ref={completeButtonRef}
//             className="bg-[#07BEA1] text-white px-4 py-2 rounded-[5px] text-[12px]"
//             onClick={completeCourseAction}
//         >
//             Complete
//         </button>
//         // </div>
//     );
// };

// const CancelButton = ({ order_id, id, onClick }: any) => {
//     return (
//         <div className="flex justify-end">
//             <button
//                 className="border border-[#EA4335] text-[#EA4335] text-[12px] px-4 py-2 rounded-[5px]"
//                 onClick={() => onClick(order_id, id)}
//             >
//                 Cancel
//             </button>
//         </div>
//     );
// };

// const UserRequestDetails = ({
//     roleType,
//     status,
//     trainees_count,
//     address_line,
//     mode,
//     session,
//     mode_of_teaching,
//     fee,
//     duration,
//     start_date,
//     trainee,
//     role,
// }: any) => {
//     const className = 'text-[#303030] text-[13px] xl:text-[15px] font-[400]';
//     // const {role} = useAppSelector(({signin}:any)=>signin["signinValue"]);
//     // console.log({role});
//     console.log(roleType);
//     console.log(mode);
//     console.log(mode_of_teaching);

//     const countConversion = () => {
//         console.log(roleType);

//         let traineeType: string = // ["TI", "ST"].includes(role) ||
//             ['TI', 'ST'].includes(roleType) ? ' Student' : ' Employee';
//         if (trainees_count > 1) {
//             traineeType = traineeType + 's';
//         }
//         return trainees_count + traineeType;
//     };
//     return (
//         <div className="space-y-4">
//             <div className="flex items-center   ">
//                 <Keyvalue
//                     icon={Users}
//                     alt="users"
//                     // value={countConversion()}
//                     // update by me
//                     // value={`${trainees_count} ${
//                     //     role === 'ST' ? 'Employee' : 'Student'
//                     // }${trainees_count > 1 ? 's' : ''}`}
//                     value={`${trainees_count} ${`Learner`}${
//                         trainees_count > 1 ? 's' : ''
//                     }`}
//                     classType={className}
//                     addClass={'w-1/5'}
//                     index={'index'}
//                 />
//                 {mode === 'both' ? (
//                     <Keyvalue
//                         icon={Mode}
//                         alt="mode"
//                         value={'Online Offline'}
//                         addClass={'w-1/5'}
//                         classType={className}
//                     />
//                 ) : (
//                     <Keyvalue
//                         icon={Mode}
//                         alt="mode"
//                         value={mode}
//                         addClass={'w-1/5'}
//                         classType={className}
//                     />
//                 )}

//                 {/* <Keyvalue
//                     icon={Mode}
//                     alt="mode"
//                     value={mode}
//                     addClass={'w-1/5'}
//                     classType={className}
//                 /> */}

//                 <Keyvalue
//                     icon={Session}
//                     alt="session"
//                     value={daysConversionAndSort(session)}
//                     addClass={'w-1/5'}
//                     classType={className}
//                 />
//                 <Keyvalue
//                     icon={startDate}
//                     alt="start_date"
//                     value={start_date}
//                     addClass={'w-1/5'}
//                     classType={className}
//                 />
//             </div>
//             <div className="flex items-start">
//                 <Keyvalue
//                     icon={dateImg}
//                     alt="date"
//                     value={duration}
//                     addClass={'w-1/5'}
//                     classType={className}
//                     index={'index'}
//                 />
//                 <Keyvalue
//                     icon={TargetIcon}
//                     alt="targetIcon"
//                     value={Number(fee).toLocaleString('en-IN')}
//                     addClass={'w-1/5'}
//                     classType={className}
//                     fee={'fee'}
//                     index={'index'}
//                 />
//                 {/* to hide address ticket 271 */}
//                 {/* <Keyvalue
//                     icon={locationImg}
//                     alt="venue"
//                     value={address_line}
//                     classType={className}
//                     addClass={'w-1/2'}
//                 /> */}
//             </div>
//         </div>
//     );
// };

// const Keyvalue = ({ isDr, icon, value, addClass, index, alt }: any) => {
//     const params = useParams();
//     const { pathname } = useLocation();
//     // console.log(pathname, "location");
//     // console.log(params, "req")
//     const path = pathname.includes('request/bidding');
//     const path1 = pathname.includes('bid/view');
//     //  console.log(path, path1, "path")
//     return (
//         <div
//             className={`flex gap-2 ${addClass} ${
//                 !index && 'border-l-[1px] px-3'
//             }`}
//         >
//             <img
//                 src={icon}
//                 alt={alt}
//                 className={`${icon === Users ? 'w-[17px]' : 'w-[14px]'}`}
//             />
//             <div className="flex gap-2">
//                 {icon === TargetIcon && (
//                     <img src={rs} alt="rs" className="w-[8px]" />
//                 )}
//                 <p className="text-[#303030] text-[14px] font-[400] capitalize max-h-[25px] shrinkToTwoLines">
//                     {value}
//                     {(pathname.includes('request/bidding') ||
//                         pathname.includes('bid/view')) && (
//                         // (params.requestorigin && params.requestorigin === 'bidding')
//                         <span className="ml-1">
//                             {`${
//                                 alt === 'date' && value === 1
//                                     ? 'Day'
//                                     : alt === 'date' && value !== 1
//                                     ? 'Days'
//                                     : ''
//                             }`}
//                         </span>
//                     )}
//                 </p>
//             </div>
//         </div>
//     );
// };

// const ArrowKey = ({ id, isBd, status }: any) => {
//     const navigate = useNavigate();
//     const location = useLocation();
//     const isNavigationOff = location.pathname.includes('view');
//     const navigateTo = () => {
//         if (isBd && !isNavigationOff) navigate('/bid/view/' + id);
//     };
//     return (
//         <div className="flex w-full justify-end mt-3">
//             {/* <img src={editAll} alt="editIcon" className='w-[15px]' /> */}
//             <img
//                 src={arrow}
//                 alt={'ArrowIcon'}
//                 className="w-[26px] cursor-pointer"
//                 onClick={navigateTo}
//             />
//         </div>
//     );
// };

// const MakePayment = ({
//     isTrainer,
//     status,
//     id,
//     role,
//     isPaymentDone,
//     isDr,
//     isTr,
// }: any) => {
//     const navigate = useNavigate();
//     const navigateTo = () => {
//         if (!isTrainer) {
//             navigate({
//                 pathname: '/checkout',
//                 search: createSearchParams({
//                     id,
//                     checkoutOrigin: isDr ? 'dr' : 'bd',
//                 }).toString(),
//             });
//         } else {
//             toast.error(
//                 camelCaseConvertForErrorMessage(
//                     'Students or Corporate are Allowed to do this Action'
//                 )
//             );
//         }
//     };
//     return (
//         <button
//             className={`p-2 px-5 rounded-[4px] ${
//                 isPaymentDone
//                     ? 'bg-[#33BD87]'
//                     : isTrainer
//                     ? 'bg-yellow-500'
//                     : 'bg-[#33BD87]'
//             } text-white text-[13px] font-[400] disabled:opacity-50`}
//             disabled={status !== 'accepted' || isPaymentDone || isTrainer}
//             onClick={navigateTo}
//         >
//             {isPaymentDone
//                 ? 'Paid'
//                 : isTrainer
//                 ? 'payment pending'
//                 : 'Make Payment'}
//         </button>
//     );
// };

// const AcceptOrReject = ({
//     acceptFunction,
//     rejectFunction,
//     updateDirectRequest,
//     id,
//     isTrainer,
// }: any) => {
//     return (
//         <>
//             {isTrainer && (
//                 <div className="flex space-x-2 justify-end text-[12px]">
//                     <button
//                         className="border border-[#33BD87] text-[#33BD87] py-2 px-5 rounded-[4px]"
//                         onClick={() => updateDirectRequest(id, 'accepted')}
//                     >
//                         Accept
//                     </button>
//                     <button
//                         className="border border-[#EA4335] text-[#EA4335] py-2 px-5 rounded-[4px]"
//                         onClick={() => updateDirectRequest(id, 'rejected')}
//                     >
//                         Reject
//                     </button>
//                 </div>
//             )}
//         </>
//     );
// };

import React, { useState, useEffect, useRef } from 'react';
import {
    createSearchParams,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import Users from '../../assets/Images/Users.svg';
import Cal from '../../assets/Images/Cal.svg';
import Mode from '../../assets/Images/mode.svg';
import Session from '../../assets/Images/session.svg';
import dateImg from '../../assets/Images/dateImg.svg';
import locationImg from '../../assets/Images/locationImg.svg';
import arrow from '../../assets/Images/RightArrow.svg';
import editAll from '../../assets/Images/editAll.svg';
import TargetIcon from '../../assets/Images/TargetIcon.svg';
import { dateFormat } from './Role';
import { useAppSelector } from '../..';
import toast from 'react-hot-toast';
import { readRecord, updateRecord } from '../../Services/backend/apiCalls';
import startDate from '../../assets/Images/startDate.svg';
import rs from '../../assets/Images/rs.svg';
import {
    daysConversionAndSort,
    actionText,
} from '../../Services/commonFunctions';
import { PulseLoader } from 'react-spinners';
import { RoleCard } from './Role';
import { ProgressBar } from '../Elements/Progressbar';
import { TopBids } from '../Dashboard/Card';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import { OrderWiseSort } from '../Elements/OrderwiseSort';
import { RequestStatus } from './RequestPage';
import { DisplayReadOnlyTags } from '../Elements/Tags';
import DOMPurify from 'dompurify';

export const Card = ({
    appConfig,
    status,
    request,
    loading,
    setLoading,
    checkTrainer,
    pageName,
    setStatus,
    paramsState,
    state,
}: any) => {
    const navigate = useNavigate();
    const [requestState, setRequestState] = useState([]);
    const [sorted, setSorted] = useState('Newest');
    const { role } = useAppSelector(({ signin }: any) => signin['signinValue']);
    const userType = ['TI', 'FL'].includes(role) ? 'Trainee' : 'Trainer';
    const isTrainer = ['TI', 'FL'].includes(role);
    const { token } = useAppSelector(
        ({ signin }: any) => signin['signinValue']
    );
    const optionsForSort = ['Newest', 'Oldest'];

    const selectCourseInSection: any = {
        booknow: 'directrequests',
        bidding: 'biddings',
        training: 'trainings',
    };

    const getRequestDetails = async () => {
        if (!status.length || !request.length) {
            return;
        }
        const sectionEndpoint = selectCourseInSection[request];
        const params = {
            status,
        };
        const response = await readRecord(params, sectionEndpoint);
        console.log({ response });
        if (response.status) {
            setRequestState(response.data);
            console.log('response', response.data);
        } else toast.error(response.message);
        setLoading(false);
    };

    const getCommonUIKeys = (data: any) => {
        console.log(data, 'data');
        let isTrainingAndDr =
            request === 'training' &&
            ['DR', 'dr'].includes(data.training_origin);
        let isTrainingAndBd =
            request === 'training' &&
            ['BD', 'bd'].includes(data.training_origin);
        // request === 'directrequest' ? data.course_id : isTrainingAndDr? data.id

        return {
            courseTitle: request === 'booknow' ? data.course.title : data.title,
            description:
                request === 'booknow'
                    ? data.course.description
                    : data.description,
            createdAt: dateFormat(data.createdAt),
            id: data.id,
            order_id: request === 'training' && data.order_id,
            isPaymentDone: data.isPaymentDone,
            navigate_id:
                isTrainingAndDr || request === 'booknow'
                    ? data.course_id
                    : isTrainingAndBd
                    ? data.bid_id
                    : data.id,
            currency_code: data.currency_code,
            ...(data[userType] &&
                data[userType] !== null && {
                    role: data[userType].role,
                    user_id: data[userType].user_id,
                    userName: data[userType].name,
                }),
            amount:
                request === 'training'
                    ? data.amount
                    : request === 'booknow'
                    ? data.fee
                    : data.accepted_amount,
            // update
            // start_date: dateFormat(data.start_date),
            start_date: dateFormat(data.date_of_training),
            end_date: dateFormat(data.end_date),
            trainees_count: data.trainees_count,
            address:
                request !== 'bidding'
                    ? data[userType].address_line
                    : data.address,
            duration: request !== 'bidding' ? data.duration : data.days,
            // update by mee
            // mode:
            //     request === 'dr'
            //         ? data.mode_of_teaching.mode
            //         : // : data.mode || 'online',
            //           data.mode ,
            mode:
                (data.mode_of_teaching && data.mode_of_teaching.mode) ||
                data.mode ||
                '',
            session:
                request !== 'bidding'
                    ? data.mode_of_teaching.session
                    : data.preferred_days,
            topBid: request === 'bidding' && data.topBidderDetails,
            bidAmount: request === 'bidding' && data.accepted_amount,
            bidStatus: request === 'bidding' && data.status,
            bidTrainer: request === 'bidding' && data.Trainer,
            checkIsDirectRequest: request === 'training' && data.directrequest,
        };
    };

    const navigateTo = (id: number, title: string, additonalId: string) => {
        const routeLink = request !== 'bidding' ? '/booknow/' : '/bid/view/';
        // console.log({routeLink});
        navigate({
            pathname: routeLink + title + '/' + id,
            ...(request === 'booknow' && {
                search: createSearchParams({ dr_id: additonalId }).toString(),
            }),
        });
    };

    const navigateToForTraining = (
        id: number,
        title: string,
        checkIsDirectRequest: any
    ) => {
        const routeLink =
            checkIsDirectRequest !== null ? '/course/view/' : '/bid/view/';
        navigate(routeLink + title + '/' + id);
    };

    useEffect(() => {
        let listen: boolean = true;
        // setLoading(true);
        if (listen) {
            (async () => {
                getRequestDetails();
            })();
        }
        // setLoading(false);
        return () => {
            listen = false;
        };
    }, [status, request]);

    const updateDirectRequest = async (id: number, status: any) => {
        let body = { id, status };
        let drResponse = await updateRecord(body, 'directrequests');
        if (drResponse.status) {
            const filteredArray = requestState.filter(
                (data: any) => data.id !== id
            );
            setRequestState(filteredArray);
            toast.success(
                `Book Now ${camelCaseConvertForErrorMessage(
                    status
                )} Successfully`
            );
        } else {
            toast.error(drResponse.message);
        }
    };

    const updateTraining = async (id: number, status: string) => {
        const updatedResponse = await updateRecord(
            {
                id,
                status: 'completed',
            },
            'trainings'
        );
        if (updatedResponse.status) {
            const filteredArray = requestState.filter(
                (data: any) => data.id !== id
            );
            setRequestState(filteredArray);
            toast.success(
                `Training ${camelCaseConvertForErrorMessage(
                    status
                )} Successfully`
            );
        } else {
            toast.error(updatedResponse.message);
        }
    };

    const cancelUpcomingCourse = (order_id: any, id: number) => {
        // console.log('cancelling upcoming course');
        navigate('/cancellation', { state: { order_id: order_id, id: id } });
    };

    const filterData = (event: any) => {
        const { value } = event.target;
        setSorted(value);
        if (value === 'Newest') {
            requestState.sort((a: any, b: any) => {
                let da: any = new Date(a['createdAt']),
                    db: any = new Date(b['createdAt']);
                return db - da;
            });
            setRequestState(requestState);
            return;
        } else if (value === 'Oldest') {
            requestState.sort((a: any, b: any) => {
                let da: any = new Date(a['createdAt']),
                    db: any = new Date(b['createdAt']);
                return da - db;
            });
            setRequestState(requestState);
            return;
        }
    };

    // if (loading) {
    //     return (
    //         <div className="flex w-full min-h-[450px] items-center justify-center">
    //             <PulseLoader color="#0074FC" />
    //         </div>
    //     );
    // }

    return (
        <>
            <div className="flex justify-center relative py-0">
                <RequestStatus
                    checkTrainer={checkTrainer}
                    paramsState={state !== 'typeForHeaderClick'}
                    pageName={pageName}
                    status={status}
                    request={request}
                    setStatus={setStatus}
                    setLoading={setLoading}
                />
                <OrderWiseSort
                    optionsForSort={optionsForSort}
                    filterData={filterData}
                    sorted={sorted}
                    startDiv={'absolute right-0'}
                />
            </div>
            <div className="min-h-[500px] mt-2">
                {loading ? (
                    <div className="flex w-full min-h-[450px] items-center justify-center">
                        <PulseLoader color="#0074FC" />
                    </div>
                ) : requestState?.length === 0 ? (
                    <div className="min-h-[200px] flex justify-center items-center text-[#085BEB] text-[18px] font-[500]">
                        No Data Available
                    </div>
                ) : (
                    requestState?.map((response: any, index: any) => {
                        console.log('ffd', requestState);
                        console.log('fff', response);

                        const commonKeyData = getCommonUIKeys(response);
                        // console.log({ commonKeyData });
                        console.log('this is commomn', commonKeyData);

                        return (
                            <RequestDetailsCard
                                tags={response.tags}
                                request={request}
                                key={index}
                                commonKeyData={commonKeyData}
                                role={role}
                                isDr={request === 'booknow'}
                                isTr={request === 'training'}
                                actionText={() =>
                                    actionText(
                                        status,
                                        isTrainer,
                                        request === 'booknow'
                                    )
                                }
                                isTrainer={isTrainer}
                                navigateTo={
                                    request === 'training'
                                        ? navigateToForTraining
                                        : navigateTo
                                }
                                navigate={navigate}
                                status={status}
                                updateDirectRequest={updateDirectRequest}
                                updateTraining={updateTraining}
                                cancelUpcomingCourse={cancelUpcomingCourse}
                                appConfig={appConfig}
                            />
                        );
                    })
                )}
            </div>
        </>
    );
};

export const RequestDetailsCard = ({
    appConfig,
    isTr,
    request,
    commonKeyData,
    isTrainer,
    status,
    actionText,
    isDr,
    navigateTo,
    role,
    navigate,
    paymentStatus,
    updateDirectRequest,
    updateTraining,
    cancelUpcomingCourse,
    tags,
}: any) => {
    console.log({ commonKeyData });
    let sanatiseDescription = DOMPurify.sanitize(commonKeyData.description);

    const statusColor =
        status === 'accepted' || status === 'upcoming'
            ? 'text-[#00B89B] font-[300]'
            : 'text-[#FB5935] font-[300]';
    const statusColorForName =
        isTrainer || request === 'training'
            ? 'text-[#000000]'
            : status === 'accepted' || status === 'upcoming'
            ? 'text-[#00B89B]'
            : status === 'pending'
            ? 'text-[#ECB833]'
            : 'text-[#FB5935]';
    const location = useLocation();
    const isBidView = location.pathname.includes('/bid/view');

    // Check if the current path is '/request/bidding'

    const isBiddingPage =
        location.pathname === '/request/bidding' || '/request/booknow';

    const TrainerAndCTPAndProgress =
        request === 'training' && isTrainer && status === 'pending';

    return (
        <div
            className="mt-4 w-full bg-white rounded-[8px] py-5 px-6 flex flex-col space-y-8"
            key={commonKeyData.id}
        >
            <div>
                <div className="flex justify-between items-center">
                    <div className={'flex justify-between w-full items-center'}>
                        <div className="sm:flex gap-4 items-center">
                            <h1
                                // className="text-[#085BEB] sm:text-[35px] text-[20px] font-[500] shrinkToTwoLines cursor-pointer "
                                className={`text-[#085BEB] sm:text-[35px] text-[20px] font-[500] shrinkToTwoLines  ${
                                    isBiddingPage ? 'cursor-pointer' : ''
                                }`}
                                // update by me remove cousor-pointer

                                onClick={() => {
                                    navigateTo &&
                                        navigateTo(
                                            commonKeyData.navigate_id,
                                            commonKeyData.courseTitle,
                                            request !== 'training'
                                                ? commonKeyData.id
                                                : commonKeyData.checkIsDirectRequest
                                        );
                                }}
                            >
                                {commonKeyData.courseTitle}
                            </h1>

                            <span className="text-[14px] font-[400] text-[#5F5F5F] pt-1">
                                {commonKeyData.createdAt}
                            </span>
                            {/* {request === 'bd' &&
                                status === 'rejected' &&
                                isTrainer && (
                                    <div className="flex items-center gap-4 text-[23px] font-[500] text-[#2975FD]">
                                        <h1 className="text-[#5F5F5F] text-[17px] font-[300]">
                                            My Bid
                                        </h1>
                                        <div className="flex items-center gap-0.5">
                                            <img
                                                src={rs}
                                                alt="rupees"
                                                className="w-[18px] h-[18px]"
                                            />
                                            <span>
                                                {commonKeyData.bidAmount &&
                                                    commonKeyData.bidAmount.toLocaleString(
                                                        'en-IN'
                                                    )}
                                            </span>
                                        </div>
                                    </div>
                                )} */}
                        </div>

                        {request !== 'training' &&
                            status === 'accepted' &&
                            ((request === 'booknow' &&
                                appConfig['direct_request_action'] === 'edit' &&
                                appConfig['cms_action'] === 'edit') ||
                                (request === 'bidding' &&
                                    appConfig['bidding_action'] === 'edit' &&
                                    appConfig['cms_action'] === 'edit')) && (
                                <MakePayment
                                    isTr={isTr}
                                    isDr={isDr}
                                    isTrainer={isTrainer}
                                    id={commonKeyData.id}
                                    status={status}
                                    role={role}
                                    isPaymentDone={commonKeyData.isPaymentDone}
                                />
                            )}
                        {request === 'booknow' &&
                            status === 'pending' &&
                            appConfig['direct_request_action'] === 'edit' &&
                            appConfig['cms_action'] === 'edit' && (
                                <AcceptOrReject
                                    isTrainer={isTrainer}
                                    id={commonKeyData.id}
                                    updateDirectRequest={updateDirectRequest}
                                />
                            )}
                        {/* {request === 'bd' &&
                            status === 'accepted' &&
                            isTrainer && (
                                <div className="flex items-center gap-4 text-[23px] font-[500] text-[#2975FD]">
                                    <h1 className="text-[#5F5F5F] text-[17px] font-[300]">
                                        My Bid
                                    </h1>
                                    <div className="flex items-center gap-0.5">
                                        <img
                                            src={rs}
                                            alt="rupees"
                                            className="w-[18px] h-[18px]"
                                        />
                                        <span>
                                            {commonKeyData.bidAmount &&
                                                commonKeyData.bidAmount.toLocaleString(
                                                    'en-IN'
                                                )}
                                        </span>
                                    </div>
                                </div>
                            )} */}
                        {request === 'bidding' &&
                            ((['pending', 'rejected'].includes(status) &&
                                isTrainer) ||
                                (['pending'].includes(status) &&
                                    !isTrainer)) && (
                                <TopBids
                                    id={commonKeyData.id}
                                    topBidderDetails={
                                        status === 'pending'
                                            ? commonKeyData.topBid
                                            : {
                                                  ...commonKeyData.bidTrainer,
                                                  amount: commonKeyData.bidAmount,
                                              }
                                    }
                                    status={
                                        isTrainer
                                            ? status
                                            : commonKeyData.bidStatus
                                    }
                                    requote={status === 'pending'}
                                    isTrainee={!isTrainer}
                                    config={
                                        appConfig['bidding_action'] ===
                                            'edit' &&
                                        appConfig['cms_action'] === 'edit'
                                    }
                                />
                            )}
                        {request === 'training' &&
                            status === 'upcoming' &&
                            !isTrainer && (
                                <CancelButton
                                    onClick={cancelUpcomingCourse}
                                    order_id={commonKeyData.order_id}
                                    id={commonKeyData.id}
                                />
                            )}
                        {TrainerAndCTPAndProgress && (
                            <CompleteButton
                                id={commonKeyData.id}
                                updateTraining={updateTraining}
                            />
                        )}
                        {/* {(status==="rejected")&&(request)&&<MakePayment isTrainer={isTrainer} status={status} role={role}/>} */}
                    </div>
                    {/* {status === 'accepted' && !isDr && (
                        <h1 className="text-[17px] font-[300] text-[#5F5F5F]">
                            Accepted bid
                            <span className="ml-2 text-[25px] font-[700] text-[#33BD87]">
                                {commonKeyData.amount && commonKeyData.amount.toLocaleString(
                                    'en-IN'
                                )}
                            </span>
                        </h1>
                    )} */}
                    {status === 'Inprogress' && !isDr && (
                        <h1 className="text-[17px] font-[500] text-[#ECB833]">
                            In Progress
                        </h1>
                    )}
                </div>
                {commonKeyData.role && (
                    <div className="flex gap-0 items-center mt-2  font-medium">
                        {/* update by me */}
                        <h1>
                            {commonKeyData.Trainee}
                            {/* {commonKeyData.role} */}
                        </h1>
                        {/* {isBiddingPage && (
                            <p className={`${statusColor}`}>
                                {actionText}
                                <span
                                    className={`ml-1 cursor-pointer text-[17px] font-[500] ${statusColorForName}`}
                                    onClick={() => {
                                        localStorage.setItem(
                                            'selectedCourseUserId',
                                            JSON.stringify(
                                                commonKeyData?.user_id
                                            )
                                        );
                                        navigate(
                                            `/profile/view/${commonKeyData.userName}`
                                        );
                                    }}
                                >
                                    {commonKeyData.userName}
                                </span>
                            </p>
                        )} */}

                        <RoleCard role={commonKeyData.role} />
                    </div>
                )}
                {/* {(status==="accepted")&&(request)&&<p className='text-[22px] font-[500] text-[#303030] mt-4'>{response[`${userType}`].name}<span className='w-[76px] h-[20px] rounded-[12px] bg-[#D4FFF8] text-[7px] font-[400] text-[#00B89B]'>{userTypeCondition[response[`${userType}`].role].name}</span></p>} */}
                {commonKeyData.tags && (
                    <DisplayReadOnlyTags tags={commonKeyData.tags} />
                )}
                <h1
                    className="text-[16px] font-[300] text-[#5F5F5F] mt-3 min-h-[75px]"
                    dangerouslySetInnerHTML={{
                        __html: sanatiseDescription,
                    }}
                ></h1>
                {tags?.length > 0 &&
                    tags.map((tag: any, i: any) => {
                        return (
                            <div className="flex flex-wrap my-2 inline-flex">
                                <p
                                    className="bg-[#F8F8F8] mr-2 text-[#3061F4] font-[400] text-[12px] px-4 py-1 rounded-[18px]"
                                    key={i}
                                >
                                    #{tag}
                                </p>
                            </div>
                        );
                    })}
                <UserRequestDetails
                    isDr={isDr}
                    roleType={commonKeyData.role}
                    fee={commonKeyData.amount}
                    status={status}
                    mode={commonKeyData.mode}
                    session={commonKeyData.session}
                    trainees_count={commonKeyData.trainees_count}
                    address_line={commonKeyData.address}
                    duration={commonKeyData.duration}
                    // update date start_date
                    // start_date={commonKeyData.start_date}
                    start_date={commonKeyData.start_date}
                    trainee={commonKeyData.trainee}
                    role={commonKeyData.trainee?.role}
                />
            </div>
            {request === 'training' && status === 'pending' && (
                <ProgressBar
                    endDate={commonKeyData.end_date}
                    startDate={commonKeyData.start_date}
                />
            )}
            {!isBidView && request === 'bidding' && (
                <div className="flex flex-col justify-between">
                    <div className="border-b-[0.5px]"></div>
                    <ArrowKey
                        id={commonKeyData.id}
                        status={status}
                        isBd={!isDr}
                    />
                </div>
            )}
        </div>
    );
};

const CompleteButton = ({ id, updateTraining }: any) => {
    const completeButtonRef: any = useRef();

    const completeCourseAction = async () => {
        completeButtonRef.current.disabled = true;
        await updateTraining(id, 'completed');
        completeButtonRef.current.disabled = false;
    };
    return (
        // <div className="flex justify-end">
        <button
            ref={completeButtonRef}
            className="bg-[#07BEA1] text-white px-4 py-2 rounded-[5px] text-[12px]"
            onClick={completeCourseAction}
        >
            Complete
        </button>
        // </div>
    );
};

const CancelButton = ({ order_id, id, onClick }: any) => {
    return (
        <div className="flex justify-end">
            <button
                className="border border-[#EA4335] text-[#EA4335] text-[12px] px-4 py-2 rounded-[5px]"
                onClick={() => onClick(order_id, id)}
            >
                Cancel
            </button>
        </div>
    );
};

const UserRequestDetails = ({
    roleType,
    status,
    trainees_count,
    address_line,
    mode,
    session,
    fee,
    duration,
    start_date,
}: any) => {
    const className = 'text-[#303030] text-[13px] xl:text-[15px] font-[400]';
    const countConversion = () => {
        // let traineeType = ['TI', 'FL'].includes(roleType)
        //     ? 'Student'
        //     : 'Employee';
        let traineeType = 'Learner';
        if (trainees_count > 1) traineeType += 's';
        return trainees_count + ' ' + traineeType;
    };

    return (
        <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:flex-wrap gap-4">
            {/* First Row of Keyvalue Items */}
            <div className="flex flex-col sm:flex-row flex-wrap gap-4 ">
                {/* {console.log(session)}, */}
                <Keyvalue
                    icon={Users}
                    alt="users"
                    value={countConversion()}
                    classType={className}
                    addClass="w-full "
                />
                <Keyvalue
                    icon={Mode}
                    alt="mode"
                    value={mode === 'both' ? 'Online Offline' : mode}
                    classType={className}
                    addClass="w-full "
                />
                <Keyvalue
                    icon={Session}
                    alt="session"
                    value={session.join(', ')} // Join days with a comma
                    classType={className}
                    addClass="w-full "
                />
                <Keyvalue
                    icon={startDate}
                    alt="start_date"
                    value={start_date}
                    classType={className}
                    addClass="w-full "
                />
            </div>
            {/* Second Row of Keyvalue Items */}
            <div className="flex flex-col sm:flex-row flex-wrap gap-4 ">
                <Keyvalue
                    icon={dateImg}
                    alt="date"
                    value={duration}
                    classType={className}
                    addClass="w-full "
                />
                <Keyvalue
                    icon={TargetIcon}
                    alt="targetIcon"
                    value={Number(fee).toLocaleString('en-IN')}
                    classType={className}
                    addClass="w-full "
                />
            </div>
        </div>
    );
};

const Keyvalue = ({ isDr, icon, value, addClass, index, alt }: any) => {
    const location = useLocation();
    // const isBiddingPage =
    //     location.pathname === '/bid/view' || '/request/bidding';
    const isBiddingPage =
        location.pathname === '/bid/view' ||
        /^\/bid\/view(\/.*)?$/.test(location.pathname) ||
        location.pathname === '/request/bidding';

    return (
        <div
            className={`flex flex-col sm:flex-row items-start sm:items-center gap-2 ${addClass} ${
                !index && 'border-l-[1px] px-3'
            }`}
        >
            <img
                src={icon}
                alt={alt}
                className={`${icon === Users ? 'w-[17px]' : 'w-[14px]'}`}
            />
            <div className="flex flex-col sm:flex-row gap-2">
                {icon === TargetIcon && (
                    <img src={rs} alt="rs" className="w-[8px]" />
                )}
                <p className="text-[#303030] text-[14px] font-[400] capitalize max-h-[25px] shrinkToTwoLines break-all">
                    {value}

                    {isBiddingPage && (
                        <span className="ml-1">
                            {alt === 'date' && value === 1
                                ? 'Day'
                                : alt === 'date' && value !== 1
                                ? 'Days'
                                : ''}
                        </span>
                    )}

                    {/* update by me for not showing double days */}
                </p>
            </div>
        </div>
    );
};

const ArrowKey = ({ id, isBd, status }: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isNavigationOff = location.pathname.includes('view');
    const navigateTo = () => {
        if (isBd && !isNavigationOff) navigate('/bid/view/' + id);
    };
    return (
        <div className="flex w-full justify-end mt-3">
            {/* <img src={editAll} alt="editIcon" className='w-[15px]' /> */}
            <img
                src={arrow}
                alt={'ArrowIcon'}
                className="w-[26px] cursor-pointer"
                onClick={navigateTo}
            />
        </div>
    );
};

const MakePayment = ({
    isTrainer,
    status,
    id,
    role,
    isPaymentDone,
    isDr,
    isTr,
}: any) => {
    const navigate = useNavigate();
    const navigateTo = () => {
        if (!isTrainer) {
            navigate({
                pathname: '/checkout',
                search: createSearchParams({
                    id,
                    checkoutOrigin: isDr ? 'dr' : 'bd',
                }).toString(),
            });
        } else {
            toast.error(
                camelCaseConvertForErrorMessage(
                    'Students or Corporate are Allowed to do this Action'
                )
            );
        }
    };
    return (
        <button
            className={`p-2 px-5 rounded-[4px]   ${
                isPaymentDone
                    ? 'bg-[#33BD87]'
                    : isTrainer
                    ? 'bg-yellow-500'
                    : 'bg-[#33BD87]'
            } text-white text-[13px] font-[400] disabled:opacity-50`}
            disabled={status !== 'accepted' || isPaymentDone || isTrainer}
            onClick={navigateTo}
        >
            {isPaymentDone
                ? 'Paid'
                : isTrainer
                ? 'payment pending'
                : 'Make Payment'}
        </button>
    );
};

const AcceptOrReject = ({
    acceptFunction,
    rejectFunction,
    updateDirectRequest,
    id,
    isTrainer,
}: any) => {
    return (
        <>
            {isTrainer && (
                <div className="flex space-x-2 justify-end text-[12px]">
                    <button
                        className="border border-[#33BD87] text-[#33BD87] py-2 px-5 rounded-[4px]"
                        onClick={() => updateDirectRequest(id, 'accepted')}
                    >
                        Accept
                    </button>
                    <button
                        className="border border-[#EA4335] text-[#EA4335] py-2 px-5 rounded-[4px]"
                        onClick={() => updateDirectRequest(id, 'rejected')}
                    >
                        Reject
                    </button>
                </div>
            )}
        </>
    );
};

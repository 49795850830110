export const inputForTI = {
    name: '',
    country: '',
    state: '',
    city: '',
    address_line: '',
    contact_number: Number(''),
    industry: '',
    url: '',
    account_holder_name: '',
    account_number: '',
    bank_name: '',
    branch: '',
    ifsc_number: '',
};

export const inputForFL = {
    name: '',
    contact_number: '',
    country: '',
    state: '',
    city: '',
    linkedin_url: '',
    industry: '',
    resume_url: '',
    address_line: '',
    account_holder_name: '',
    account_number: '',
    bank_name: '',
    branch: '',
    ifsc_number: '',
};

export const inputForCO = {
    name: '',
    contact_number: '',
    country: '',
    state: '',
    city: '',
    company_url: '',
    address_line: '',
};

export const inputForST = {
    name: '',
    contact_number: '',
    country: '',
    state: '',
    city: '',
    address_line: '',
};

export const inputForInitialValues: any = {
    TI: {
        name: '',
        country: '',
        state: '',
        city: '',
        address_line: '',
        contact_number: Number(''),
        industry: '',
        url: '',
        account_holder_name: '',
        account_number: '',
        bank_name: '',
        branch: '',
        ifsc_number: '',
        achievements: '',
        corporate_training: false,
        placement_assistance: false,
        certificate_assistance: false,
        is_online_training: false,
        hands_on_lab_support: false,
        iso_certification: false,
    },
    FL: {
        name: '',
        contact_number: '',
        country: '',
        state: '',
        city: '',
        linkedin_url: '',
        industry: '',
        // resume_url: '',
        resume: {
            name: '',
            key: '',
            url: '',
        },
        profilePicture: {
            name: '',
            key: '',
            url: '',
        },
        achievements: '',
        address_line: '',
        account_holder_name: '',
        account_number: '',
        bank_name: '',
        branch: '',
        ifsc_number: '',
        skills: [],
        corporate_training: false,
        placement_assistance: false,
        certificate_assistance: false,
        is_online_training: false,
        hands_on_lab_support: false,
        iso_certification: false,
    },
    CO: {
        name: '',
        contact_number: '',
        country: '',
        state: '',
        city: '',
        company_url: '',
        address_line: '',
    },
    ST: {
        name: '',
        contact_number: '',
        country: '',
        state: '',
        city: '',
        address_line: '',
        profilePicture: {
            name: '',
            key: '',
            url: '',
        },
    },
};

import {useState} from 'react';
import Select from "../../../assets/Images/Select.svg";
import unSelect from "../../../assets/Images/unSelect.svg";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { postRecord } from '../../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from "../../../";
import { setSignValue } from "../../../Redux/Reducers/signinUserReducers";
import {PulseLoader} from "react-spinners/";
import curveLine from "../../../assets/Images/curveLine.png";
import Login_Logo from "../../../assets/Images/Login_Logo.svg";
import { camelCaseConvertForErrorMessage } from '../../../Services/commonFunctions';

export const SelectRoles = () => {
    // const {token} = useAppSelector(({signin}:any)=>signin["signinValue"]);
    let [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const role = searchParams.get("role");
    // console.log(role);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const roles = ["Training Institute", "Freelancer", "Corporate", "Student"];
    const roleJSON = [{
        name: "Training Institute",
        value:"TI"
    }, {
        name: "Freelancer",
        value: "FL"
        }, {
        name: "Corporate",
        value: "CO"
        }, {
        name: "Student",
        value: "ST"
    }]
    const [userRole, setUserRole] = useState(role??"")
    const [userRoleSelected, setUserRoleSelected] = useState();

    const selectRole = (role:any) => {
        // setUserRoleSelected(index);
        // if(role === "Training Institute"){
        // setUserRole("TI")
        // } else if(role === "Freelancer"){
        // setUserRole("FL")
        // } else if(role === "Corporate"){
        // setUserRole("CO")
        // } else {
        // setUserRole("ST")
        // }
    }

    const handleSubmit = async() => {
        if(userRole === "") {
            toast.error(
                camelCaseConvertForErrorMessage('Please Select Anyone Role')
            );
        } else {
            setLoading(true)
            const body = {
                "role":userRole
            }
            const response = await postRecord(body, "roleaction");
            if(response.status){
                dispatch(setSignValue({...setSignValue,token: response.accessToken,role: userRole}))
                // toast.success(response.message);
                navigate('/register/personal')
            } else {
                setLoading(false)
                toast.error(response.message);
            }
        }
    }

  return (
    <>
    {loading?
    <div className="w-7/12 lg:w-11/12 flex items-center justify-center mx-auto">
      <PulseLoader color="#0074FC" />
    </div>:
    <div className="bg-[#FCFBFC] w-7/12 lg:w-11/12 flex items-center justify-center">
      <div className="flex flex-col justify-center items-start w-9/12 lg:w-[472px] mx-auto space-y-6">
        <img src={Login_Logo} alt="Login_Logo" className="w-[190px]" />
        <div className="space-y-8">
            <div className='space-y-4'>
                <h1 className="text-[20px] lg:text-[22px] font-[400]">Select your role</h1>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-2">
                {roleJSON.map(({name,value},index)=>{
                return(
                    <div key={index} className={`border ${userRoleSelected === index ?'border-[#017EF2] border-[1px]':'border-[#EFEFEF] border-[1px]'} bg-white p-4 px-6 flex items-center gap-2 rounded-[6px] cursor-pointer`} onClick={()=>setUserRole(value)}>
                        <img src={value===userRole ? Select : unSelect} alt="userSelectedRole" className='w-5' />
                        <p>{name}</p>
                    </div>)
                })}
                </div>
            </div>
            <button className="bg-gradient-to-r from-[#0074FC] to-[#00E0B8] w-full p-4 text-[12px] font-[400] text-white rounded-[8px]" onClick={handleSubmit}>Next</button>
        </div>
      </div>
      <img src={curveLine} alt="" className="absolute bottom-2 right-0 rotate-90" />
    </div>
    }
    </>
  )
}





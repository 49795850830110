import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { compareTypes, compareState } from '../Types/comparecoursesType';

const initialState: any = {
    notificationData: {
        notifyResponse: [],
    },
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotify: (state: any, action: PayloadAction<any>) => {
            state.notificationData = {
                ...state.notificationData,
                ...action.payload,
            };
        },
    },
});

export const { setNotify } = notificationSlice.actions;
export default notificationSlice.reducer;

import React from 'react'
import { MoonLoader } from 'react-spinners'

export const Loader = () => {
  return (
     <div className="h-[100%] w-full flex items-center justify-center bg-black bg-opacity-[0.10] fixed overflow-auto top-0 py-10 z-[3]">
        <MoonLoader size={50} color={"#0074FC"} />
</div>
  )
}

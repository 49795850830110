import {useState} from 'react'

export const OrderWiseSort = ({ optionsForSort,filterData, sorted, startDiv }: any) => {
    // const optionsForSort = [
    //     'Newest',
    //     'Oldest',
    //     'Today',
    //     'Yesterday',
    //     'Last 7 days',
    //     'This month',
    // ];
    return (
        <div className={`${startDiv}`}>
            <select
                className="p-2 rounded-md cursor-pointer"
                value={sorted}
                onChange={filterData}
                title="notificationFilter"
            >
                {optionsForSort.map((sort:any, i:number) => {
                    return (
                        <option key={i} value={sort}>
                            {sort}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

import { RadioInput } from './RadioInput';
import { InputTag } from './InputTag';

import { Editor } from '@tiptap/core';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';

import {
    FaBold,
    FaHeading,
    FaItalic,
    FaListOl,
    FaListUl,
    FaQuoteLeft,
    FaRedo,
    FaStrikethrough,
    FaUnderline,
    FaUndo,
} from 'react-icons/fa';
import { useEffect } from 'react';

export const FieldsRight = ({
    role,
    customize,
    formik,
    disabled,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    onChangeInputValue,
    roleSelection,
}: any) => {
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            formik.setFieldValue('highlights', values.highlights + '\n');
        }
    };

    // Initialize the editor once and update it only when needed
    const editor = useEditor({
        extensions: [StarterKit, Underline],
        content: '', // initially empty
        editable: roleSelection,
    });

    // Update editor content when Formik values change
    useEffect(() => {
        if (editor && values.highlights !== editor.getHTML()) {
            editor.commands.setContent(values.highlights);
        }
    }, [values.highlights, editor]);

    // Sync editor content back to Formik on changes
    useEffect(() => {
        if (!editor) return;

        const updateFormik = () => {
            const html = editor.getHTML();
            formik.setFieldValue('highlights', html);
        };

        editor.on('update', updateFormik);

        return () => {
            editor.off('update', updateFormik);
        };
    }, [editor, formik]);

    if (!editor) {
        return null;
    }

    return (
        <div className="w-1/2">
            <div className="flex flex-col">
                <label className="text-[15px] font-[500]">
                    Is It Active?
                    <span className="ml-0.5 text-red-600">*</span>
                </label>
                <div className="flex justify-between mt-4 gap-12">
                    <RadioInput
                        name="isactive"
                        type={'radio'}
                        label={'Yes'}
                        checked={values.isactive === true}
                        className={`h-[55px] rounded-[8px]`}
                        onChange={() => onChangeInputValue('isactive', true)}
                    />
                    <RadioInput
                        name="isactive"
                        type={'radio'}
                        label={'No'}
                        checked={values.isactive === false}
                        className={`h-[55px] rounded-[8px]`}
                        onChange={() => onChangeInputValue('isactive', false)}
                    />
                </div>
            </div>
            <div className="flex gap-12">
                <InputTag
                    Tagwidth={'w-1/2'}
                    className={`h-[55px] mt-4 numberClass disabled:opacity-60`}
                    formik={formik}
                    disabled={disabled}
                    type="number"
                    name="discount_percentage"
                    label={'If Yes, Discount Percentage'}
                    max={'100'}
                    value={values.discount_percentage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.discount_percentage}
                    isTouched={touched.discount_percentage}
                    placeholder="eg. 10"
                />
                <InputTag
                    Tagwidth={'w-1/2'}
                    placeholder={'INR'}
                    name="fee"
                    label={'Fees'}
                    value={Number(values.fee).toLocaleString('en-IN')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formik.setFieldValue(
                            'fee',
                            e.target.value?.split(',').join('')
                        );
                    }}
                    onBlur={handleBlur}
                    error={errors.fee}
                    isTouched={touched.fee}
                    className={`h-[55px] mt-4`}
                />
            </div>
            {/* <InputTag
                name="highlights"
                label={'Highlights'}
                value={values.highlights}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.highlights}
                isTouched={touched.highlights}
                className={`h-[240px] mt-2 rounded-[8px] p-2 px-4 bg-white  disabled:opacity-50`}
            /> */}
            <div className="space-y-1 flex flex-col mb-12 relative">
                <h1 className="text-[15px] font-[500]">Highlights</h1>
                {/* <textarea
                    autoCorrect="false"
                    disabled={!roleSelection}
                    resize-none
                    className={`${
                        !roleSelection && 'opacity-50'
                    } h-[240px] mt-2 p-2 bg-white rounded-[8px] px-4 border-[0.7px] border-black disabled:opacity-50 w-full`}
                    value={values.highlights}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    name="highlights"
                >
                    {values.highlights}
                </textarea> */}
                <div className="bg-white p-4 rounded-lg border  border-black   mt-4">
                    <MenuBar editor={editor} />

                    <EditorContent
                        editor={editor}
                        className={`${
                            !roleSelection && 'opacity-50'
                        }  mt-2 p-2 bg-white rounded-[8px] px-4  disabled:opacity-50 w-full`}
                    />
                </div>
                {errors.highlights && touched.highlights && (
                    <p
                        className={`text-red-500 text-[12px] w-full text-end mt-1 absolute`}
                    >
                        {errors.highlights}
                    </p>
                )}
            </div>
            {/* <textarea  label={}  onChange={handleChange} onBlur={handleBlur} error={errors.highlights} isTouched={touched.highlights} className={`h-[55px] mt-4`} /> */}
        </div>
    );
};

interface MenuBarProps {
    editor: Editor | null;
}

const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
    if (!editor) {
        return null;
    }

    return (
        <div className="menuBar ">
            <div>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={editor.isActive('bold') ? 'is_active' : ''}
                >
                    <FaBold />
                </button>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={editor.isActive('italic') ? 'is_active' : ''}
                >
                    <FaItalic />
                </button>
                {/* <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleUnderline().run()
                    }
                    className={editor.isActive('underline') ? 'is_active' : ''}
                >
                    <FaUnderline />
                </button> */}
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleUnderline().run()
                    }
                    className={editor.isActive('underline') ? 'is_active' : ''}
                >
                    <FaUnderline />
                </button>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    className={editor.isActive('strike') ? 'is_active' : ''}
                >
                    <FaStrikethrough />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 2 }).run()
                    }
                    className={
                        editor.isActive('heading', { level: 2 })
                            ? 'is_active'
                            : ''
                    }
                >
                    <FaHeading />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 3 }).run()
                    }
                    className={
                        editor.isActive('heading', { level: 3 })
                            ? 'is_active'
                            : ''
                    }
                >
                    <FaHeading className="heading3" />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleBulletList().run()
                    }
                    className={editor.isActive('bulletList') ? 'is_active' : ''}
                >
                    <FaListUl />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleOrderedList().run()
                    }
                    className={
                        editor.isActive('orderedList') ? 'is_active' : ''
                    }
                >
                    <FaListOl />
                </button>
                {/* <button
                onClick={() =>
                    editor.chain().focus().toggleBlockquote().run()
                }
                className={editor.isActive('blockquote') ? 'is_active' : ''}
            >
                <FaQuoteLeft />
            </button> */}
            </div>
            <div>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().undo().run()}
                >
                    <FaUndo />
                </button>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().redo().run()}
                >
                    <FaRedo />
                </button>
            </div>
        </div>
    );
};

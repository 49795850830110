import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    showNotifyModal: false,
    showMenuModal: false,
    showNotifyAvailable: false
};

const ModalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        showNotificationModal: (state: any, action: PayloadAction<any>) => {
            state.showNotifyModal = action.payload;
        },
        showMenuCardModal: (state: any, action: PayloadAction<any>) => {
            state.showMenuModal = action.payload;
        },
        showNotificationIsAvailable: (state: any, action: PayloadAction<any>) => {
            state.showNotifyAvailable = action.payload;
        },
    },
});

export const {
    showNotificationModal,
    showMenuCardModal,
    showNotificationIsAvailable,
} = ModalSlice.actions;
export default ModalSlice.reducer;

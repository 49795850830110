import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export const CompareInternshipView = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    // Check if state exists
    if (!state || !state.internshipData) {
        toast.error('No internships selected for comparison');
        navigate(-1);
        return null;
    }

    const internships = state.internshipData;

    console.log(internships);

    return (
        <div className="bg-gray-100">
            <div className="w-10/12 mx-auto">
                {/* Header Section */}
                <div className="w-full mt-10 sticky top-0 z-[1] bg-gray-100 py-4 flex flex-col md:flex-row justify-between items-center">
                    <div className="text-center md:text-left">
                        <h1 className="text-xl md:text-2xl font-medium">
                            Compare Internships
                        </h1>
                        <p className="text-sm md:text-base font-medium mt-2">
                            <span
                                className="hover:text-blue-600 cursor-pointer"
                                onClick={() => navigate(-1)}
                            >
                                Search
                            </span>
                            <span className="ml-2 mr-2">{'>'}</span>
                            Comparing Internships
                        </p>
                    </div>
                    <button
                        className="text-sm md:text-base p-2 px-4 bg-blue-600 text-white rounded-md cursor-pointer mt-2 md:mt-0"
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </button>
                </div>

                {/* Comparison Section */}
                <div className="min-h-[500px] bg-white mt-10 mb-10 flex flex-col md:flex-row justify-between compare-internships">
                    {internships.map((internship, index) => (
                        <div key={index} className="w-full md:w-1/3 h-full p-4">
                            <div className="space-y-4 p-4 flex flex-col items-center bg-white shadow-md rounded-md">
                                <img
                                    src={internship.thumbnail}
                                    alt={internship.title}
                                    className="w-72 h-64 object-cover"
                                />
                                <div className="w-full text-center">
                                    <h1 className="text-lg md:text-xl font-medium break-words">
                                        {internship.title}
                                    </h1>
                                    <p className="text-sm md:text-base font-normal text-gray-600">
                                        by {internship.company}
                                    </p>
                                </div>
                                <div className="w-full">
                                    <p className="border-b py-2">
                                        <strong>Type:</strong>{' '}
                                        {internship.internship_type}
                                    </p>
                                    <p className="border-b py-2">
                                        <strong>Duration:</strong>{' '}
                                        {internship.duration}{' '}
                                        {internship.duration_unit}
                                    </p>
                                    <p className="border-b py-2">
                                        <strong>Mode:</strong> {internship.mode}
                                    </p>
                                    <p className="border-b py-2">
                                        <strong>Location:</strong>{' '}
                                        {internship.location || 'Not specified'}
                                    </p>
                                    <p className="border-b py-2">
                                        <strong>Days:</strong>{' '}
                                        {internship.days.join(', ')}
                                    </p>
                                    <p className="border-b py-2">
                                        <strong>Timings:</strong>{' '}
                                        {internship.start_time} -{' '}
                                        {internship.end_time}
                                    </p>
                                    <p className="border-b py-2">
                                        <strong>Start Date:</strong>{' '}
                                        {new Date(
                                            internship.start_date
                                        ).toLocaleDateString()}
                                    </p>
                                    {/* <p className="border-b py-2">
                                        <strong>Stipend:</strong>{' '}
                                        {internship.stipend
                                            ? `₹${internship.stipend} ${internship.stipend_frequency}`
                                            : 'Unpaid'}
                                    </p> */}
                                    <p className="border-b py-2">
                                        <strong>
                                            {internship.internship_type ===
                                            'Paid'
                                                ? 'Internship Fee'
                                                : 'Stipend'}
                                            :
                                        </strong>{' '}
                                        {internship.internship_type === 'Paid'
                                            ? `₹${internship.stipend}`
                                            : internship.stipend
                                            ? `₹${internship.stipend} ${internship.stipend_frequency}`
                                            : 'Unpaid'}
                                    </p>
                                    <p className="border-b py-2">
                                        <strong>Certificate:</strong>{' '}
                                        {internship.certificate ? 'Yes' : 'No'}
                                    </p>
                                    <p className="py-2">
                                        <strong>Application Deadline:</strong>{' '}
                                        {new Date(
                                            internship.application_deadline
                                        ).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

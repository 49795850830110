import { useState, useEffect, useRef, useMemo } from 'react';
import {
    useNavigate,
    useLocation,
    createSearchParams,
    useSearchParams,
} from 'react-router-dom';
import trisektLoginLogo from '../../assets/Images/trisektLoginLogo.svg';
import notify from '../../assets/Images/notify.svg';
import bgImage from '../../assets/Images/bgImage.svg';
import searchIcon from '../../assets/Images/searchIcon.svg';
import closeBtn from '../../assets/Images/closeBtn.svg';
import profileDropDown from '../../assets/Images/profileDropDown.svg';
import { useAppSelector } from '../..';
import { authentication } from '../../Firebase/firebase';
import { signOut } from 'firebase/auth';
import { readRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import { useAppDispatch } from '../..';
import { setDirectRequest } from '../../Redux/Reducers/directRequestReducers';
import { useDebounce } from '../../hooks/DebounceHook';
import { Advertisement } from '../Advertisement/Advertisement';
import {
    showNotificationModal,
    showMenuCardModal,
    showNotificationIsAvailable,
} from '../../Redux/Reducers/popupReducer';
import { setSignValue } from '../../Redux/Reducers/signinUserReducers';
import { ShowNotificationModal } from './ShowNotificationModal';
import newNotification from '../../assets/Images/newNotification.svg';
import { setSkillSearch } from '../../Redux/Reducers/skillBasedSearchReducers';
import { setNotify } from '../../Redux/Reducers/notificationReducer';
import { isTrainer } from '../../Services/commonFunctions';
import Profile from '../../assets/Images/Profile.svg';
import Logout from '../../assets/Images/Logout.svg';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const ConditionsHeader = ({ tokenExpired }: any) => {
    const navigate = useNavigate();
    const [
        { token, role, username, uid },
        { showMenuModal, showNotifyAvailable, showNotifyModal },
        { skills },
        { notifyResponse },
        { appConfig },
    ] = useAppSelector(
        ({ signin, modal, skill, notification, appConfig }: any) => [
            signin['signinValue'],
            modal,
            skill['skillBasedSearchData'],
            notification['notificationData'],
            appConfig['configData'],
        ]
    );

    const clickOnLogo = () => {
        navigate('/dashboard');
    };

    return (
        <div className="bg-white pt-6">
            <div className="w-11/12 mx-auto flex justify-between items-center">
                <img
                    src={trisektLoginLogo}
                    alt="Logo"
                    className="cursor-pointer"
                    onClick={clickOnLogo}
                />
                <div className="flex items-center gap-10">
                    <div className="flex items-center gap-4 border-l-[1px] px-6 cursor-pointer"></div>
                </div>
            </div>
        </div>
    );
};

const MenuTab = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const ProfilePage = () => {
        dispatch(showMenuCardModal(false));
        navigate('/profile');
    };

    const SignOut = () => {
        signOut(authentication)
            .then(() => {
                // Sign-out successful.
                // localStorage.setItem('searchTerm', '');
                //   dispatch(setSignValue({ token: false }));
                navigate('/login', { replace: true });
            })
            .catch((error) => {
                // An error happened.
            });
    };

    const menuDataSet = 'menuTab';

    return (
        <div
            className="flex flex-col justify-center rounded-[4px] space-y-3 p-4 h-[10%] md:w-[20%] lg:w-[15%] xl:w-[12%] 2xl:w-[10%] bg-white shadow-xl absolute md:top-20 lg:top-18 md:right-[1%] lg:right-[7%] xl:right-[6%] 2xl:right-[6%] z-[20]"
            data-modaltype={menuDataSet}
        >
            <div
                className="flex gap-3.5 cursor-pointer"
                onClick={ProfilePage}
                data-modaltype={menuDataSet}
            >
                <img src={Profile} alt="Profile" data-modaltype={menuDataSet} />
                <h1
                    className="text-[13px] font-[400]"
                    data-modaltype={menuDataSet}
                >
                    Profile
                </h1>
            </div>
            <div
                className="flex gap-3 cursor-pointer"
                onClick={SignOut}
                data-modaltype={menuDataSet}
            >
                <img src={Logout} alt="Logout" data-modaltype={menuDataSet} />
                <h1
                    className="text-[13px] font-[400]"
                    data-modaltype={menuDataSet}
                >
                    Logoutt
                </h1>
            </div>
        </div>
    );
};

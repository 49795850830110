// import { useState, useEffect, useRef } from 'react';
// import {
//     useNavigate,
//     useLocation,
//     createSearchParams,
//     useSearchParams,
// } from 'react-router-dom';
// import trisektLoginLogo from '../../assets/Images/trisektLoginLogo.svg';
// import notify from '../../assets/Images/notify.svg';
// import bgImage from '../../assets/Images/bgImage.svg';
// import searchIcon from '../../assets/Images/searchIcon.svg';
// import profileDropDown from '../../assets/Images/profileDropDown.svg';
// import { useAppSelector } from '../..';
// import { authentication } from '../../Firebase/firebase';
// import { signOut } from 'firebase/auth';
// import { readRecord } from '../../Services/backend/apiCalls';
// import toast from 'react-hot-toast';
// import { useAppDispatch } from '../..';
// import { setDirectRequest } from '../../Redux/Reducers/directRequestReducers';
// import { useDebounce } from '../../hooks/DebounceHook';
// import { Advertisement } from '../Advertisement/Advertisement';
// import {
//     showNotificationModal,
//     showMenuCardModal,
//     showNotificationIsAvailable,
// } from '../../Redux/Reducers/popupReducer';
// import { setSignValue } from '../../Redux/Reducers/signinUserReducers';
// import { ShowNotificationModal } from './ShowNotificationModal';
// import newNotification from '../../assets/Images/newNotification.svg';
// import { setSkillSearch } from '../../Redux/Reducers/skillBasedSearchReducers';
// import { setNotify } from '../../Redux/Reducers/notificationReducer';
// import { isTrainer } from '../../Services/commonFunctions';
// import Profile from '../../assets/Images/Profile.svg';
// import Logout from '../../assets/Images/Logout.svg';
// import { usePreloadHook } from '../../hooks/PreloadHook';
// // import { InternshipSearchSection } from './InternshipSearchSection';
// import { setInternshipResults } from '../../Redux/Reducers/internshipSearchReducer';

// export const Header = ({ tokenExpired }: any) => {
//     const dispatch = useAppDispatch();
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [searchParams] = useSearchParams();
//     const skill = searchParams.get('skill') || '';

//     const searchQuery = searchParams.get('search') || '';

//     const [searchRef, setSearchRef] = useState(
//         skill.length ? 'Search for Skills' : 'Search for Courses'
//     );
//     const [skillStorage, setSkillStorage] = useState(skill);
//     usePreloadHook();
//     const [
//         { role, username, uid },
//         { showMenuModal, showNotifyAvailable, showNotifyModal },
//         { appConfig },
//     ] = useAppSelector(
//         ({ signin, modal, skill, notification, appConfig }: any) => [
//             signin['signinValue'],
//             modal,
//             appConfig['configData'],
//         ]
//     );
//     const optionalValue = ['Search for Courses', 'Search for Skills'];

//     const notifyRef: any = useRef(false);
//     const menuRef: any = useRef(false);

//     const [searchTerm, setSearchTerm] = useState(
//         localStorage.getItem('searchTerm') || ''
//     );
//     const debouncedSearchTerm = useDebounce(searchTerm, 500);

//     const internshipState = useAppSelector(
//         (state: any) => state.internshipSearch.Value
//     );

//     console.log(internshipState);

//     // Define search options based on role
//     const getTISearchOptions = () => [
//         'Search for Courses',
//         'Search for Skills',
//     ];
//     const getSTSearchOptions = () => [
//         'Search for Courses',
//         'Search for Internships',
//     ];

//     const getSearchOptions = () => {
//         if (role === 'TI') return getTISearchOptions();
//         if (role === 'ST') return getSTSearchOptions();
//         return [];
//     };
//     const handleChangeDropdown = (value: string) => {
//         setSearchTerm('');
//         setSkillStorage('');

//         navigate('/internships/search');
//         setSearchRef(value);
//     };

//     const notifyIconRef: any = useRef();

//     const searchCourse = async () => {
//         const courseDataResponse = await readRecord(
//             { title: searchTerm },
//             'coursequery'
//         );
//         if (courseDataResponse.status) {
//             dispatch(
//                 setDirectRequest({
//                     input: courseDataResponse.data,
//                     minAmount: courseDataResponse.minAmount,
//                     maxAmount: courseDataResponse.maxAmount,
//                 })
//             );
//         } else {
//             dispatch(
//                 setDirectRequest({ input: [], minAmount: 0, maxAmount: 0 })
//             );
//         }
//         navigate('/course/search');
//     };

//     useEffect(() => {
//         if (skill.length && skill !== skillStorage) {
//             setSkillStorage(skill);
//             setSearchRef('Search for Skills');
//         }
//     }, [skill, skillStorage]);

//     useEffect(() => {
//         if (
//             debouncedSearchTerm?.length >= 3 &&
//             location.pathname.includes('course/search')
//         ) {
//             searchCourse();
//         } else {
//             dispatch(
//                 setDirectRequest({ input: [], minAmount: 0, maxAmount: 0 })
//             );
//         }
//     }, [debouncedSearchTerm]);

//     useEffect(() => {
//         if (searchQuery) {
//             setSearchTerm(searchQuery);
//             searchCourse();
//         }
//     }, [searchQuery]);

//     const closeNotifyModal = (event: any) => {
//         if (event?.target?.alt === 'notification') {
//             notifyRef.current = !notifyRef.current;
//             dispatch(showNotificationModal(notifyRef.current));
//         } else if (event?.target?.dataset?.modaltype !== 'notification') {
//             notifyRef.current = false;
//             dispatch(showNotificationModal(notifyRef.current));
//         }
//         if (event?.target?.dataset?.clickedelement === 'menuTab') {
//             menuRef.current = !menuRef.current;
//             dispatch(showMenuCardModal(menuRef.current));
//         } else if (event?.target?.dataset?.modaltype !== 'menuTab') {
//             menuRef.current = false;
//             dispatch(showMenuCardModal(menuRef.current));
//         }
//     };

//     useEffect(() => {
//         (async () => {
//             const Response = await readRecord({}, 'notifications');
//             if (Response.status) {
//                 const filterAvailableNotification = Response.data.filter(
//                     (idx: any) => idx['is_read'] === false
//                 );
//                 if (filterAvailableNotification.length > 0) {
//                     dispatch(showNotificationIsAvailable(true));
//                 }
//                 dispatch(setNotify({ notifyResponse: Response.data }));
//             } else toast.error(Response.message);
//         })();

//         document.addEventListener('click', closeNotifyModal);
//         return () => {
//             document.removeEventListener('click', closeNotifyModal);
//         };
//     }, []);

//     const CourseMenu = () => {
//         isTrainer(role) &&
//             navigate('/request/mycourse', { state: 'typeForHeaderClick' });
//         !isTrainer(role) &&
//             navigate('/request/bidding', { state: 'typeForHeaderClick' });
//     };

//     const handleChange = (e: any) => {
//         localStorage.setItem('searchTerm', e.target.value);
//         setSearchTerm(e.target.value);
//     };

//     const handleClickForSkills = () => {
//         dispatch(setSkillSearch({ skills: skillStorage }));
//         navigate({
//             pathname: '/show-interest',
//             search: createSearchParams({ skill: skillStorage }).toString(),
//         });
//     };

//     useEffect(() => {
//         if (!username) {
//             (async () => {
//                 const Response = await readRecord({ uid }, 'profiles');
//                 if (Response.status) {
//                     dispatch(
//                         setSignValue({
//                             ...setSignValue,
//                             username: Response.data.name,
//                         })
//                     );
//                 } else toast.error(Response.message);
//             })();
//         }
//     }, []);

//     const clickOnLogo = () => {
//         navigate('/dashboard');
//     };

//     const handleInternshipSearch = async () => {
//         if (searchTerm.trim().length >= 2) {
//             try {
//                 console.log('Searching for:', searchTerm);

//                 const internshipResponse = await readRecord(
//                     {
//                         title: searchTerm,
//                         // Add any other search parameters you want to send
//                     },
//                     'internships'
//                 );

//                 console.log(internshipResponse);

//                 if (internshipResponse) {
//                     // Dispatch internship results to state
//                     dispatch(
//                         setInternshipResults({
//                             input: internshipResponse,
//                             // Add any other data you want to store
//                         })
//                     );
//                 } else {
//                     // If no results, set empty array
//                     dispatch(
//                         setInternshipResults({
//                             input: [],
//                         })
//                     );
//                 }

//                 // Navigate to internship search results page
//                 navigate('/internships/search');
//             } catch (error) {
//                 console.error('Error searching internships:', error);
//                 toast.error('Error searching internships. Please try again.');
//             }
//         } else {
//             // Clear results if search term is too short
//             dispatch(
//                 setInternshipResults({
//                     input: [],
//                 })
//             );
//         }
//     };

//     useEffect(() => {
//         if (
//             debouncedSearchTerm?.length >= 2 &&
//             searchRef === 'Search for Internships' &&
//             location.pathname.includes('internships/search')
//         ) {
//             handleInternshipSearch();
//         } else if (searchRef === 'Search for Internships') {
//             dispatch(setInternshipResults({ input: [] }));
//         }
//     }, [debouncedSearchTerm, searchRef]);

//     return (
//         <div className="bg-white pt-6 px-4 sm:px-8">
//             {showNotifyModal && <ShowNotificationModal />}
//             {showMenuModal && <MenuTab />}
//             <div className="w-full flex flex-col md:flex-row justify-between items-center gap-4 md:gap-0">
//                 <img
//                     src={trisektLoginLogo}
//                     alt="Logo"
//                     className="cursor-pointer w-32 md:w-40"
//                     onClick={clickOnLogo}
//                 />
//                 <div className="flex flex-col md:flex-row items-center gap-4">
//                     {role === 'TI' && (
//                         <div>
//                             <SelectValue
//                                 optionalValue={optionalValue}
//                                 onChange={(
//                                     e: React.FormEvent<HTMLInputElement>
//                                 ) =>
//                                     handleChangeDropdown(e.currentTarget.value)
//                                 }
//                                 value={searchRef}
//                             />
//                         </div>
//                     )}

//                     {/* Single search input that changes based on searchRef */}
//                     <div className="relative flex items-center gap-2 w-full sm:w-auto">
//                         {searchRef === 'Search for Courses' ? (
//                             <input
//                                 name="Search"
//                                 placeholder="Search for Course"
//                                 value={searchTerm}
//                                 className="border-2 py-1 px-10 rounded-full w-full sm:w-80 border-gray-400 focus:border-[#2975FD] outline-none"
//                                 onChange={handleChange}
//                                 onKeyDown={(
//                                     event: React.KeyboardEvent<HTMLInputElement>
//                                 ) => {
//                                     if (event.key === 'Enter') {
//                                         searchCourse();
//                                     }
//                                 }}
//                             />
//                         ) : searchRef === 'Search for Skills' &&
//                           role === 'TI' &&
//                           appConfig['show_interest_action'] !== 'off' ? (
//                             <input
//                                 name="Search"
//                                 placeholder="Search for Skills"
//                                 value={skillStorage}
//                                 className="border-2 py-1 px-10 rounded-full w-full sm:w-80 border-gray-400 focus:border-[#2975FD] outline-none"
//                                 onChange={(e: any) =>
//                                     setSkillStorage(e.target.value)
//                                 }
//                                 onKeyDown={(
//                                     e: React.KeyboardEvent<HTMLInputElement>
//                                 ) => {
//                                     if (e.key === 'Enter') {
//                                         skillStorage.length &&
//                                             handleClickForSkills();
//                                     }
//                                 }}
//                             />
//                         ) : searchRef === 'Search for Internships' &&
//                           role === 'ST' ? (
//                             <input
//                                 name="Search"
//                                 placeholder="Search for Internships"
//                                 value={searchTerm}
//                                 className="border-2 py-1 px-10 rounded-full w-full sm:w-80 border-gray-400 focus:border-[#2975FD] outline-none"
//                                 onChange={handleChange}
//                                 onKeyDown={(
//                                     event: React.KeyboardEvent<HTMLInputElement>
//                                 ) => {
//                                     if (event.key === 'Enter') {
//                                         handleInternshipSearch();
//                                     }
//                                 }}
//                             />
//                         ) : null}
//                         <img
//                             src={searchIcon}
//                             alt="search"
//                             className="absolute left-3"
//                         />
//                     </div>

//                     {/* {searchRef === 'Search for Courses' ? (
//                         <div className="relative flex items-center gap-2 w-full md:w-auto mt-4">
//                             <input
//                                 name="Search"
//                                 placeholder="Search for Course"
//                                 value={searchTerm}
//                                 className="border-2 py-1 px-10 rounded-full w-full md:w-80 border-gray-400 focus:border-[#2975FD] outline-none text-sm sm:text-base"
//                                 onChange={handleChange}
//                                 onKeyDown={(
//                                     event: React.KeyboardEvent<HTMLInputElement>
//                                 ) => {
//                                     if (event.key === 'Enter') {
//                                         searchCourse();
//                                     }
//                                 }}
//                             />
//                             <img
//                                 src={searchIcon}
//                                 alt="search"
//                                 className="absolute left-3 w-4 h-4"
//                             />
//                         </div>
//                     ) : (
//                         role === 'TI' &&
//                         appConfig['show_interest_action'] !== 'off' && (
//                             <div className="relative flex items-center gap-2 w-full md:w-auto">
//                                 <input
//                                     name="Search"
//                                     placeholder="Search for Skills"
//                                     value={skillStorage}
//                                     className="border-2 py-1 px-10 rounded-full w-full md:w-80 border-gray-400 focus:border-[#2975FD] outline-none text-sm sm:text-base"
//                                     onChange={(e: any) =>
//                                         setSkillStorage(e.target.value)
//                                     }
//                                     onKeyDown={(
//                                         e: React.KeyboardEvent<HTMLInputElement>
//                                     ) => {
//                                         if (e.key === 'Enter') {
//                                             skillStorage.length &&
//                                                 handleClickForSkills();
//                                         }
//                                     }}
//                                 />
//                                 <img
//                                     src={searchIcon}
//                                     alt="search"
//                                     className="absolute left-3 w-4 h-4"
//                                 />
//                             </div>
//                         )
//                     )} */}
//                     <h1
//                         className="text-[#2975FD] text-[14px] md:text-[16px] font-[500] cursor-pointer"
//                         onClick={CourseMenu}
//                     >
//                         {isTrainer(role) ? 'My Teaching' : 'My Learning'}
//                     </h1>
//                     <img
//                         src={showNotifyAvailable ? newNotification : notify}
//                         alt="notification"
//                         className="cursor-pointer w-5 h-5"
//                         ref={notifyIconRef}
//                     />
//                     <div
//                         className="flex items-center gap-4 border-l-[1px] px-4 cursor-pointer"
//                         data-clickedelement={'menuTab'}
//                     >
//                         <div
//                             className="bg-gradient-to-t from-[#2975FD] to-[#01DDB7] rounded-full px-3 py-2 text-white text-sm sm:text-base"
//                             data-clickedelement={'menuTab'}
//                         >
//                             {username && username[0].toUpperCase()}
//                         </div>
//                         <img
//                             src={profileDropDown}
//                             className={`$${
//                                 showMenuModal && 'rotate-180'
//                             } transition w-4 h-4`}
//                             alt="DropDown"
//                             data-clickedelement={'menuTab'}
//                         />
//                     </div>
//                 </div>
//                 {role === 'ST' && (
//                     <h1
//                         className="
//             text-[10px]
//             sm:text-[14px]
//             md:text-[12px]
//             absolute
//             right-4
//             top-16
//             sm:right-auto
//             sm:top-auto
//             md:right-[386px]
//             md:top-[72px]
//             font-medium
//             text-[#2975FD]
//             cursor-pointer
//             text-center
//             w-full
//             md:w-auto  mt-2"
//                         // className="text-[12px] sm:text-[14px] md:text-[14px] absolute right-4 top-16 sm:right-1/4 sm:top-[70px] md:right-[386px] md:top-[72px] font-medium text-[#2975FD] cursor-pointer text-center "
//                         onClick={() =>
//                             navigate('/opportunity', { state: { role } })
//                         }
//                     >
//                         Need guidance in choosing a course?
//                     </h1>
//                 )}
//             </div>
//             <div className="mt-8 w-full">
//                 {location.pathname.includes('/profile') ? (
//                     <img
//                         src={bgImage}
//                         alt="background"
//                         className="mt-4 object-cover w-full h-[150px] md:h-[290px] z-0"
//                     />
//                 ) : (
//                     <Advertisement />
//                 )}
//             </div>
//         </div>
//     );
// };

// const SelectValue = ({ optionalValue, value, onChange }: any) => {
//     return (
//         <select
//             onChange={onChange}
//             className="cursor-pointer text-sm sm:text-base border-2 rounded-md p-1"
//             title="search select"
//             value={value}
//         >
//             {optionalValue.map((opt: any, index: number) => (
//                 <option key={index} value={opt}>
//                     {opt}
//                 </option>
//             ))}
//         </select>
//     );
// };

// const MenuTab = () => {
//     const navigate = useNavigate();
//     const dispatch = useAppDispatch();

//     const ProfilePage = () => {
//         dispatch(showMenuCardModal(false));
//         navigate('/profile');
//     };

//     const SignOut = () => {
//         signOut(authentication)
//             .then(() => {
//                 localStorage.setItem('searchTerm', '');
//                 localStorage.removeItem('token');
//                 navigate('/login', { replace: true });
//                 toast.success('Logged out successfully');
//             })
//             .catch((error) => {
//                 // An error happened.
//             });
//     };

//     const menuDataSet = 'menuTab';

//     return (
//         <div
//             className="flex flex-col justify-center rounded-[4px] space-y-3 p-4 w-[90%] sm:w-[60%] md:w-[20%] bg-white shadow-xl absolute top-20 right-4 md:right-[6%] z-[20] text-sm sm:text-base"
//             data-modaltype={menuDataSet}
//         >
//             <div
//                 className="flex gap-3.5 cursor-pointer items-center"
//                 onClick={ProfilePage}
//                 data-modaltype={menuDataSet}
//             >
//                 <img
//                     src={Profile}
//                     alt="Profile"
//                     data-modaltype={menuDataSet}
//                     className="w-5 h-5"
//                 />
//                 <h1
//                     className="text-[13px] font-[400]"
//                     data-modaltype={menuDataSet}
//                 >
//                     Profile
//                 </h1>
//             </div>
//             <div
//                 className="flex gap-3 cursor-pointer items-center"
//                 onClick={SignOut}
//                 data-modaltype={menuDataSet}
//             >
//                 <img
//                     src={Logout}
//                     alt="Logout"
//                     data-modaltype={menuDataSet}
//                     className="w-5 h-5"
//                 />
//                 <h1
//                     className="text-[13px] font-[400]"
//                     data-modaltype={menuDataSet}
//                 >
//                     Logout
//                 </h1>
//             </div>
//         </div>
//     );
// };

import { useState, useEffect, useRef } from 'react';
import {
    useNavigate,
    useLocation,
    createSearchParams,
    useSearchParams,
} from 'react-router-dom';
import trisektLoginLogo from '../../assets/Images/trisektLoginLogo.svg';
import notify from '../../assets/Images/notify.svg';
import bgImage from '../../assets/Images/bgImage.svg';
import searchIcon from '../../assets/Images/searchIcon.svg';
import profileDropDown from '../../assets/Images/profileDropDown.svg';
import { useAppSelector } from '../..';
import { authentication } from '../../Firebase/firebase';
import { signOut } from 'firebase/auth';
import { readRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import { useAppDispatch } from '../..';
import { setDirectRequest } from '../../Redux/Reducers/directRequestReducers';
import { useDebounce } from '../../hooks/DebounceHook';
import { Advertisement } from '../Advertisement/Advertisement';
import {
    showNotificationModal,
    showMenuCardModal,
    showNotificationIsAvailable,
} from '../../Redux/Reducers/popupReducer';
import { setSignValue } from '../../Redux/Reducers/signinUserReducers';
import { ShowNotificationModal } from './ShowNotificationModal';
import newNotification from '../../assets/Images/newNotification.svg';
import { setSkillSearch } from '../../Redux/Reducers/skillBasedSearchReducers';
import { setNotify } from '../../Redux/Reducers/notificationReducer';
import { isTrainer } from '../../Services/commonFunctions';
import Profile from '../../assets/Images/Profile.svg';
import Logout from '../../assets/Images/Logout.svg';
import { usePreloadHook } from '../../hooks/PreloadHook';
// import { InternshipSearchSection } from './InternshipSearchSection';
import { setInternshipResults } from '../../Redux/Reducers/internshipSearchReducer';

export const Header = ({ tokenExpired }: any) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const skill = searchParams.get('skill') || '';
    const searchQuery = searchParams.get('search') || '';

    const [searchRef, setSearchRef] = useState(
        skill.length ? 'Search for Skills' : 'Search for Courses'
    );
    const [skillStorage, setSkillStorage] = useState(skill);
    usePreloadHook();
    const [
        { role, username, uid },
        { showMenuModal, showNotifyAvailable, showNotifyModal },
        { appConfig },
    ] = useAppSelector(
        ({ signin, modal, skill, notification, appConfig }: any) => [
            signin['signinValue'],
            modal,
            appConfig['configData'],
        ]
    );
    const optionalValue = ['Search for Courses', 'Search for Skills'];
    const notifyRef: any = useRef(false);
    const menuRef: any = useRef(false);

    const [profileData, setProfileData] = useState({} as any);

    const [searchTerm, setSearchTerm] = useState(
        localStorage.getItem('searchTerm') || ''
    );
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const internshipState = useAppSelector(
        (state) => state.internshipSearch.Value
    );
    console.log('All Internship State:', internshipState);

    // Define search options based on role
    const getTISearchOptions = () => [
        'Search for Courses',
        'Search for Skills',
    ];
    const getSTSearchOptions = () => [
        'Search for Courses',
        'Search for Internships',
    ];

    const getSearchOptions = () => {
        if (role === 'TI') return getTISearchOptions();
        if (role === 'ST') return getSTSearchOptions();
        return [];
    };
    const handleChangeDropdown = (value: string) => {
        setSearchTerm('');
        setSkillStorage('');

        navigate('/internships/search');
        setSearchRef(value);
    };

    const searchCourse = async () => {
        const courseDataResponse = await readRecord(
            { title: searchTerm },
            'coursequery'
        );
        const allTI = await readRecord({}, 'showallTI');

        if (courseDataResponse.status) {
            dispatch(
                setDirectRequest({
                    allTi: allTI.courses,
                    input: courseDataResponse.data,
                    minAmount: courseDataResponse.minAmount,
                    maxAmount: courseDataResponse.maxAmount,
                })
            );
        } else {
            dispatch(
                setDirectRequest({ input: [], minAmount: 0, maxAmount: 0 })
            );
        }
        navigate('/course/search');
    };

    useEffect(() => {
        if (skill.length && skill !== skillStorage) {
            setSkillStorage(skill);
            setSearchRef('Search for Skills');
        }
    }, [skill, skillStorage]);

    useEffect(() => {
        if (
            debouncedSearchTerm?.length >= 3 &&
            location.pathname.includes('course/search')
        ) {
            searchCourse();
        } else {
            dispatch(
                setDirectRequest({ input: [], minAmount: 0, maxAmount: 0 })
            );
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (searchQuery) {
            setSearchTerm(searchQuery);
            searchCourse();
        }
    }, [searchQuery]);

    const closeNotifyModal = (event: any) => {
        if (event?.target?.alt === 'notification') {
            notifyRef.current = !notifyRef.current;
            dispatch(showNotificationModal(notifyRef.current));
        } else if (event?.target?.dataset?.modaltype !== 'notification') {
            notifyRef.current = false;
            dispatch(showNotificationModal(notifyRef.current));
        }
        if (event?.target?.dataset?.clickedelement === 'menuTab') {
            menuRef.current = !menuRef.current;
            dispatch(showMenuCardModal(menuRef.current));
        } else if (event?.target?.dataset?.modaltype !== 'menuTab') {
            menuRef.current = false;
            dispatch(showMenuCardModal(menuRef.current));
        }
    };

    useEffect(() => {
        (async () => {
            const Response = await readRecord({}, 'notifications');
            if (Response.status) {
                const filterAvailableNotification = Response.data.filter(
                    (idx: any) => idx['is_read'] === false
                );
                if (filterAvailableNotification.length > 0) {
                    dispatch(showNotificationIsAvailable(true));
                }
                dispatch(setNotify({ notifyResponse: Response.data }));
            } else toast.error(Response.message);
        })();

        document.addEventListener('click', closeNotifyModal);
        return () => {
            document.removeEventListener('click', closeNotifyModal);
        };
    }, []);

    const CourseMenu = () => {
        isTrainer(role) &&
            navigate('/request/mycourse', { state: 'typeForHeaderClick' });
        !isTrainer(role) &&
            navigate('/request/bidding', { state: 'typeForHeaderClick' });
    };

    const handleChange = (e: any) => {
        localStorage.setItem('searchTerm', e.target.value);
        setSearchTerm(e.target.value);
    };

    const handleClickForSkills = () => {
        dispatch(setSkillSearch({ skills: skillStorage }));
        navigate({
            pathname: '/show-interest',
            search: createSearchParams({ skill: skillStorage }).toString(),
        });
    };
    useEffect(() => {
        if (!username) {
            (async () => {
                const Response = await readRecord({ uid }, 'profiles');

                console.log(Response);

                if (Response.status) {
                    dispatch(
                        setSignValue({
                            ...setSignValue,
                            username: Response.data.name,
                        })
                    );
                } else toast.error(Response.message);
            })();
        }
    }, []);

    const clickOnLogo = () => {
        navigate('/dashboard');
    };

    useEffect(() => {
        (async () => {
            const ProfileView = await readRecord({ uid }, 'profiles');
            if (ProfileView.status) {
                setProfileData(ProfileView.data);
            }
            console.log(ProfileView);
        })();
    }, []);

    const handleInternshipSearch = async () => {
        if (searchTerm.trim().length >= 2) {
            try {
                console.log('Searching for:', searchTerm);

                const internshipResponse = await readRecord(
                    {
                        title: searchTerm,
                        // Add any other search parameters you want to send
                    },
                    'internships'
                );

                console.log(internshipResponse);

                if (internshipResponse) {
                    // Dispatch internship results to state
                    dispatch(
                        setInternshipResults({
                            input: internshipResponse,
                            // Add any other data you want to store
                        })
                    );
                } else {
                    // If no results, set empty array
                    dispatch(
                        setInternshipResults({
                            input: [],
                        })
                    );
                }

                // Navigate to internship search results page
                navigate('/internships/search');
            } catch (error) {
                console.error('Error searching internships:', error);
                toast.error('Error searching internships. Please try again.');
            }
        } else {
            // Clear results if search term is too short
            dispatch(
                setInternshipResults({
                    input: [],
                })
            );
        }
    };

    // Add useEffect for debounced search similar to course search
    useEffect(() => {
        if (
            debouncedSearchTerm?.length >= 2 &&
            searchRef === 'Search for Internships' &&
            location.pathname.includes('internships/search')
        ) {
            handleInternshipSearch();
        } else if (searchRef === 'Search for Internships') {
            dispatch(setInternshipResults({ input: [] }));
        }
    }, [debouncedSearchTerm, searchRef]);

    return (
        <div className="bg-white pt-6">
            {showNotifyModal && <ShowNotificationModal />}

            {showMenuModal && <MenuTab />}
            <div className="w-11/12 mx-auto flex justify-between items-center flex-wrap gap-4">
                <img
                    src={trisektLoginLogo}
                    alt="Logo"
                    className="cursor-pointer"
                    onClick={clickOnLogo}
                />

                <div className="flex items-center gap-4 flex-wrap">
                    {(role === 'TI' || role === 'ST') && (
                        <div className="w-full sm:w-auto">
                            <SelectValue
                                optionalValue={getSearchOptions()}
                                onChange={(
                                    e: React.FormEvent<HTMLInputElement>
                                ) =>
                                    handleChangeDropdown(e.currentTarget.value)
                                }
                                value={searchRef}
                            />
                        </div>
                    )}

                    {/* Single search input that changes based on searchRef */}
                    <div className="relative flex items-center gap-2 w-full sm:w-auto">
                        {searchRef === 'Search for Courses' ? (
                            <input
                                name="Search"
                                placeholder="Search for Course"
                                value={searchTerm}
                                className="border-2 py-1 px-10 rounded-full w-full sm:w-80 border-gray-400 focus:border-[#2975FD] outline-none"
                                onChange={handleChange}
                                onKeyDown={(
                                    event: React.KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (event.key === 'Enter') {
                                        searchCourse();
                                    }
                                }}
                            />
                        ) : searchRef === 'Search for Skills' &&
                          role === 'TI' &&
                          appConfig['show_interest_action'] !== 'off' ? (
                            <input
                                name="Search"
                                placeholder="Search for Skills"
                                value={skillStorage}
                                className="border-2 py-1 px-10 rounded-full w-full sm:w-80 border-gray-400 focus:border-[#2975FD] outline-none"
                                onChange={(e: any) =>
                                    setSkillStorage(e.target.value)
                                }
                                onKeyDown={(
                                    e: React.KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (e.key === 'Enter') {
                                        skillStorage.length &&
                                            handleClickForSkills();
                                    }
                                }}
                            />
                        ) : searchRef === 'Search for Internships' &&
                          role === 'ST' ? (
                            <input
                                name="Search"
                                placeholder="Search for Internships"
                                value={searchTerm}
                                className="border-2 py-1 px-10 rounded-full w-full sm:w-80 border-gray-400 focus:border-[#2975FD] outline-none"
                                onChange={handleChange}
                                onKeyDown={(
                                    event: React.KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (event.key === 'Enter') {
                                        handleInternshipSearch();
                                    }
                                }}
                            />
                        ) : null}
                        <img
                            src={searchIcon}
                            alt="search"
                            className="absolute left-3"
                        />
                    </div>

                    {/* {role === 'TI' && (
                        <div className="w-full sm:w-auto">
                            <SelectValue
                                optionalValue={optionalValue}
                                onChange={(
                                    e: React.FormEvent<HTMLInputElement>
                                ) =>
                                    handleChangeDropdown(e.currentTarget.value)
                                }
                                value={searchRef}
                            />
                        </div>
                    )}
                    {searchRef === 'Search for Courses' ? (
                        <div className="relative flex items-center gap-2 w-full sm:w-auto">
                            <input
                                name="Search"
                                placeholder="Search for Course"
                                value={searchTerm}
                                className="border-2 py-1 px-10 rounded-full w-full sm:w-80 border-gray-400 focus:border-[#2975FD] outline-none"
                                onChange={handleChange}
                                onKeyDown={(
                                    event: React.KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (event.key === 'Enter') {
                                        searchCourse();
                                    }
                                }}
                            />
                            <img
                                src={searchIcon}
                                alt="search"
                                className="absolute left-3"
                            />
                        </div>
                    ) : (
                        role === 'TI' &&
                        appConfig['show_interest_action'] !== 'off' && (
                            <div className="relative flex items-center gap-2 w-full sm:w-auto">
                                <input
                                    name="Search"
                                    placeholder="Search for Skills"
                                    value={skillStorage}
                                    className="border-2 py-1 px-10 rounded-full w-full sm:w-80 border-gray-400 focus:border-[#2975FD] outline-none"
                                    onChange={(e: any) =>
                                        setSkillStorage(e.target.value)
                                    }
                                    onKeyDown={(
                                        e: React.KeyboardEvent<HTMLInputElement>
                                    ) => {
                                        if (e.key === 'Enter') {
                                            skillStorage.length &&
                                                handleClickForSkills();
                                        }
                                    }}
                                />
                                <img
                                    src={searchIcon}
                                    alt="search"
                                    className="absolute left-3"
                                />
                            </div>
                        )
                    )} */}

                    <h1
                        className="text-[#2975FD] text-[16px] font-[500] cursor-pointer"
                        onClick={CourseMenu}
                    >
                        {isTrainer(role) ? 'My Teaching' : 'My Learning'}
                    </h1>

                    <img
                        src={showNotifyAvailable ? newNotification : notify}
                        alt="notification"
                        className="cursor-pointer"
                        ref={notifyRef}
                    />
                    <div
                        className="flex items-center gap-2 sm:gap-4 border-l-[1px] px-4 sm:px-6 cursor-pointer"
                        data-clickedelement={'menuTab'}
                    >
                        <div
                            className="bg-gradient-to-t from-[#2975FD] to-[#01DDB7] rounded-full px-3 sm:px-4 p-2 text-white"
                            data-clickedelement={'menuTab'}
                        >
                            {username && username[0].toUpperCase()}
                        </div>
                        <img
                            src={profileDropDown}
                            className={`${
                                showMenuModal && 'rotate-180'
                            } transition`}
                            alt="DropDown"
                            data-clickedelement={'menuTab'}
                        />
                    </div>
                </div>

                {role === 'ST' && (
                    <h1
                        className="text-[14px] w-full text-center   -ml-14 sm:w-auto sm:absolute sm:right-2 sm:top-16 sm:right-1/4 sm:top-[70px] md:right-[386px] md:top-[72px] font-medium text-[#2975FD] cursor-pointer"
                        onClick={() =>
                            navigate('/opportunity', { state: { role } })
                        }
                    >
                        Need Guidance in Choosing a Course?
                    </h1>
                )}
            </div>
            {/* <InternshipSearchSection /> */}

            <div className="mt-8 w-full">
                {location.pathname.includes('/profile') ? (
                    <img
                        src={bgImage}
                        alt="background"
                        className="mt-4 object-cover w-full h-[290px] z-0"
                    />
                ) : (
                    <Advertisement />
                )}
            </div>
        </div>
    );
};

const SelectValue = ({ optionalValue, value, onChange }: any) => {
    return (
        <select
            onChange={onChange}
            className="cursor-pointer w-full sm:w-auto"
            title="search select"
            value={value}
        >
            {optionalValue.map((opt: any, index: number) => (
                <option key={index} value={opt}>
                    {opt}
                </option>
            ))}
        </select>
    );
};

const MenuTab = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const ProfilePage = () => {
        dispatch(showMenuCardModal(false));
        navigate('/profile');
    };

    const SignOut = () => {
        signOut(authentication)
            .then(() => {
                localStorage.setItem('searchTerm', '');
                localStorage.removeItem('token');
                navigate('/login', { replace: true });
                toast.success('Logged out successfully');
            })
            .catch((error) => {
                // An error happened.
            });
    };

    const menuDataSet = 'menuTab';

    return (
        <div
            className="flex flex-col justify-center rounded-[4px] space-y-3 p-4 h-[10%] w-[60%] sm:w-[20%] md:w-[20%] lg:w-[15%] xl:w-[12%] 2xl:w-[10%] bg-white shadow-xl absolute top-16 sm:top-20 lg:top-18 right-2 sm:right-[7%] md:right-[6%] z-[20]"
            data-modaltype={menuDataSet}
        >
            <div
                className="flex gap-3.5 cursor-pointer"
                onClick={ProfilePage}
                data-modaltype={menuDataSet}
            >
                <img src={Profile} alt="Profile" data-modaltype={menuDataSet} />
                <h1
                    className="text-[13px] font-[400]"
                    data-modaltype={menuDataSet}
                >
                    Profile
                </h1>
            </div>
            <div
                className="flex gap-3 cursor-pointer"
                onClick={SignOut}
                data-modaltype={menuDataSet}
            >
                <img src={Logout} alt="Logout" data-modaltype={menuDataSet} />
                <h1
                    className="text-[13px] font-[400]"
                    data-modaltype={menuDataSet}
                >
                    Logout
                </h1>
            </div>
        </div>
    );
};

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../../Elements/Input/Input';
import { forgotpassword, postRecord } from '../../../Services/backend/apiCalls';
import { useFormik } from 'formik';
import { CreateValidationEmailSchema } from '../../../Services/Formik/Formik';
import toast from 'react-hot-toast';
import curveLine from '../../../assets/Images/curveLine.png';
import Login_Logo from '../../../assets/Images/Login_Logo.svg';
// import { URLConfigForPasswordChange } from '../../../config';

export const EmailVerification = () => {
    const navigate = useNavigate();
    const intervalRef = useRef<any>(null);
    const [isSendClicked, setIsSendClicked] = useState(false);
    const countDownTime = 30;

    useEffect(() => {
        return () => intervalRef.current && clearInterval(intervalRef.current);
    }, []);

    const [count, setCount] = useState(countDownTime);

    const handleClick = () => {
        intervalRef.current = setInterval(() => {
            setCount((prevCount) => prevCount - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(intervalRef.current);
            setIsSendClicked(false);
            setCount(countDownTime);
        }, countDownTime * 1000);
    };

    const handleSubmit = async () => {
        setIsSendClicked(true);
        const emailsendRequest = { ...formik.values };
        console.log('39', emailsendRequest);

        const response = await postRecord(
            emailsendRequest,
            'sendpasswordresetemail'
        );
        console.log(response, 'response');
        if (response.status) {
            handleClick();
            toast.success(response.message);
            console.log('sdfsdsjkg');
        } else {
            setIsSendClicked(false);
            toast.error(response.message);
        }
        // formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            redirect_url: `${document.location.origin}/confirm-password`,
        },
        validationSchema: CreateValidationEmailSchema,
        onSubmit: () => {
            handleSubmit();
        },
    });

    return (
        <div className="bg-[#FBFCFC] w-7/12 lg:w-11/12">
            <img
                src={curveLine}
                alt="CurveLine"
                className="absolute top-0 right-0"
            />
            <form
                className="flex flex-col justify-center items-start w-[222px] lg:w-[292px] space-y-6 mx-auto h-[100vh]"
                onSubmit={formik.handleSubmit}
            >
                <div>
                    <img
                        src={Login_Logo}
                        alt="Login_Logo"
                        className="w-[190px]"
                    />
                </div>
                <h1 className="text-[24px] font-semibold">Forgot Password?</h1>
                <Input
                    autoFocus
                    labeldesign={'mb-4'}
                    disabled={isSendClicked}
                    placeholder="Enter Email Id"
                    design={'w-full'}
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.email}
                    isTouched={formik.touched.email}
                />
                {isSendClicked ? (
                    <div>
                        <p>
                            Resend Reset Link in{' '}
                            <span className="text-blue-600 mr-1">{count}</span>
                            seconds
                        </p>
                        <p
                            className="bg-white w-full mt-2 p-3 text-[12px] font-[400] text-black text-center cursor-pointer border-[#EFEFEF] border-[1px] rounded-md"
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </p>
                    </div>
                ) : (
                    <div className="w-full">
                        <button
                            className="bg-gradient-to-r from-[#0074FC] to-[#00E0B8] w-full p-3 text-[12px] font-[400] text-white rounded-md"
                            type="submit"
                        >
                            Send
                        </button>
                        <p
                            className="bg-white w-full p-3 text-[12px] mt-4 font-[400] text-black text-center cursor-pointer border-[#EFEFEF] border-[1px] rounded-md"
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </p>
                    </div>
                )}
            </form>
        </div>
    );
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { compareTypes, compareState } from '../Types/comparecoursesType';

const initialState: compareState = {
    compareCoursesData: {
        comparecourse: [],
        cardState: false,
        bookedBatchTimeAllCourse: false,
        bookedBatchTime: [],
        isSaveAchivement:false
    },
};

const compareCourseSlice = createSlice({
    name: 'compare',
    initialState,
    reducers: {
        setCompareCourse: (state: compareState, action: PayloadAction<any>) => {
            state.compareCoursesData = {
                ...state.compareCoursesData,
                ...action.payload,
            };
        },
        bookedBatchTimeAllCourseHandler: (
            state: compareState,
            action: PayloadAction<any>
        ) => {
            state.compareCoursesData = {
                ...state.compareCoursesData,
                bookedBatchTimeAllCourse: action.payload,
            };
        },
        bookedBatchTimeHandler: (
            state: compareState,
            action: PayloadAction<any>
        ) => {
            state.compareCoursesData = {
                ...state.compareCoursesData,
                bookedBatchTime: action.payload,
            };
        },
        saveProfile: (
            state: compareState,
            action: PayloadAction<any>
        ) => {
            state.compareCoursesData = {
                ...state.compareCoursesData,
                isSaveAchivement: action.payload,
            };
        },
    },
});

export const {
    setCompareCourse,
    bookedBatchTimeAllCourseHandler,
    bookedBatchTimeHandler,
    saveProfile
} = compareCourseSlice.actions;
export default compareCourseSlice.reducer;

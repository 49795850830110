import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { readRecord, postRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import arrow from '../../assets/Images/RightArrow.svg';
import { storage } from '../../Firebase/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export const InternshipInfo = () => {
    const { id } = useParams();
    const [internship, setInternship] = useState(null);
    const [hasApplied, setHasApplied] = useState(false);
    const [loading, setLoading] = useState(true);
    const [applying, setApplying] = useState(false);
    const [userIdTi, setUserIdTi] = useState(null);
    const navigate = useNavigate();
    const [uploadingResume, setUploadingResume] = useState(false);
    const [resumeURL, setResumeURL] = useState(null);

    useEffect(() => {
        const checkIfApplied = (internshipData) => {
            const userIdSt = localStorage.getItem('uid');
            if (
                internshipData.user_idSt &&
                internshipData.user_idSt.includes(userIdSt)
            ) {
                setHasApplied(true);
            }
        };

        const fetchInternship = async () => {
            try {
                const internshipData = await readRecord(
                    {},
                    `internships/${id}`
                );
                setInternship(internshipData);
                setUserIdTi(internshipData.user_id);
                checkIfApplied(internshipData);
                console.log(internshipData);
            } catch (error) {
                console.error('Error fetching internship details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInternship();
    }, [id]);

    const updateInternshipWithUserId = async () => {
        const userIdSt = localStorage.getItem('uid');
        if (!userIdSt || !resumeURL) return;

        try {
            await postRecord(
                { user_idSt: userIdSt },
                `internships/${id}/update-user`
            );

            await postRecord(
                { resume: resumeURL },
                `profiles/${userIdSt}/update-resume`
            );
        } catch (error) {
            console.error('Error updating internship or profile:', error);
        }
    };

    const afterApplySuccess = () => {
        updateInternshipWithUserId();
    };

    const handleApplyNow = async () => {
        setApplying(true);
        try {
            const userIdSt = localStorage.getItem('uid');
            const response = await postRecord(
                { internshipId: id, userIdTi, userIdSt },
                'apply-internship'
            );

            if (response.status) {
                toast.success('Application successfully sent.');
                setHasApplied(true);
                afterApplySuccess();
                navigate('/dashboard');
            } else {
                alert('Failed to apply for internship.');
            }
        } catch (error) {
            console.error('Error applying for internship:', error);
            alert('An error occurred while applying for the internship.');
        } finally {
            setApplying(false);
        }
    };

    const handleResumeUpload = (file) => {
        const fileName = `${id}-${new Date().getTime()}_resume`;
        const storageRef = ref(storage, `/resumes/${fileName}`);
        setUploadingResume(true);

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            'state_changed',
            () => {},
            (error) => {
                setUploadingResume(false);
                console.error('Error uploading resume:', error);
                toast.error('Failed to upload resume.');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setResumeURL(url);
                    setUploadingResume(false);
                    toast.success('Resume uploaded successfully!');
                });
            }
        );
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-blue-500 h-12 w-12 mb-10"></div>
            </div>
        );
    }

    if (!internship) {
        return (
            <p className="text-center text-red-500">
                Failed to load internship details.
            </p>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex gap-2 items-center mt-5 w-full mx-auto cursor-pointer mb-4">
                <img
                    src={arrow}
                    alt="ArrowForBack"
                    className="rotate-180 w-[17px] h-[17px]"
                    onClick={() => navigate(-1)}
                />
                <p
                    className="text-[16px] sm:text-[18px] font-[400] text-[#3061F4]"
                    onClick={() => navigate(-1)}
                >
                    Back
                </p>
            </div>
            <div className="flex flex-col md:flex-row justify-between items-start space-y-8 md:space-y-0">
                <div className="md:w-2/3 mr-5">
                    <img
                        src={
                            internship.thumbnail ||
                            'https://via.placeholder.com/500x300?text=Internship+Image'
                        }
                        alt={internship.title}
                        className="w-full h-48 object-cover rounded-lg mb-4"
                    />
                    <h1 className="text-3xl font-bold mb-2 mt-20">
                        {internship.title || 'Internship Title'}
                    </h1>
                    <p className="mb-1">
                        <strong>Company: </strong>
                        {internship.company || 'N/A'}
                    </p>
                    <p className="text-lg mb-2 border-b pb-4 text-justify">
                        <strong>Description: </strong>
                        {internship.description || 'No description available.'}
                    </p>
                    <h2 className="text-lg font-semibold mb-2">Highlights</h2>
                    <p>
                        <strong>Days:</strong>{' '}
                        {internship.days?.join(', ') || 'Not specified'}
                    </p>
                    <p>
                        <strong>Location:</strong>{' '}
                        {internship.location || 'Not specified'}
                    </p>
                    <p>
                        <strong>Active:</strong>{' '}
                        {internship.is_active ? 'Yes' : 'No'}
                    </p>
                    {/* <p>
                        <strong>Start Date:</strong>
                        {new Date(internship.start_date).toLocaleDateString(
                            'en-US',
                            { year: 'numeric', month: 'short', day: 'numeric' }
                        )}
                    </p> */}
                    <p>
                        <strong>Start Date:</strong>{' '}
                        {(() => {
                            try {
                                const date = new Date(internship.start_date);
                                return date.getTime() > 0
                                    ? date.toLocaleDateString('en-US', {
                                          year: 'numeric',
                                          month: 'short',
                                          day: 'numeric',
                                      })
                                    : 'No date provided';
                            } catch {
                                return 'No date provided';
                            }
                        })()}
                    </p>
                    <div className="mt-6 space-y-2">
                        <p>
                            <strong>Application Deadline: </strong>
                            {new Date(
                                internship.application_deadline
                            ).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            })}
                        </p>
                        <p>
                            <strong>Requirements:</strong>
                            {internship.requirements || 'Not specified'}
                        </p>
                    </div>
                </div>

                <div className="md:w-1/3 bg-white shadow-lg rounded-lg p-6">
                    <h2 className="text-xl font-semibold mb-4">
                        Internship Details
                    </h2>
                    <div className="space-y-2">
                        <p>
                            <strong>Mode:</strong>{' '}
                            {internship.mode || 'Not specified'}
                        </p>
                        <p>
                            <strong>Type:</strong>{' '}
                            {internship.internship_type || 'Not specified'}
                        </p>
                        <p>
                            <strong>Certificate:</strong>{' '}
                            {internship.certificate ? 'Yes' : 'No'}
                        </p>
                        <p>
                            <strong>Duration:</strong>
                            {internship.duration
                                ? `${internship.duration} ${internship.duration_unit}`
                                : 'Not specified'}
                        </p>
                        {/* <p>
                            <strong>Stipend:</strong>
                            {internship.stipend
                                ? `${internship.stipend.toLocaleString()} INR ${
                                      internship.stipend_frequency
                                  }`
                                : 'Not Provided'}
                        </p> */}
                        <p>
                            <strong>
                                {internship.internship_type === 'Stipend'
                                    ? 'Stipend: '
                                    : internship.internship_type === 'Paid'
                                    ? 'Internship Fees: '
                                    : 'Stipend: '}
                            </strong>
                            {internship.stipend
                                ? `${internship.stipend.toLocaleString()} INR ${
                                      internship.stipend_frequency
                                  }`
                                : 'Not Provided'}
                        </p>
                        <div className="form-group">
                            <label className="block mb-2 font-semibold">
                                Upload Resume
                            </label>
                            <input
                                type="file"
                                onChange={(e) =>
                                    e.target.files &&
                                    handleResumeUpload(e.target.files[0])
                                }
                                className="w-full px-4 py-2 border border-gray-300 rounded"
                                disabled={hasApplied} // Disable if already applied
                                accept="application/pdf"
                            />
                            {uploadingResume && <p>Uploading resume...</p>}
                            {resumeURL && (
                                <p className="text-green-500">
                                    Resume uploaded successfully.
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="mt-6">
                        {hasApplied ? (
                            <p className="text-gray-600">
                                You have already applied for this internship.
                            </p>
                        ) : (
                            <button
                                onClick={handleApplyNow}
                                className={`w-full py-2 rounded-md transition ease-in-out ${
                                    applying || !resumeURL
                                        ? 'bg-gray-400 text-gray-700 cursor-not-allowed text-white'
                                        : 'bg-blue-500 text-white hover:bg-blue-600'
                                }`}
                                disabled={applying || !resumeURL}
                            >
                                {applying ? 'Applying...' : 'Apply Now'}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InternshipInfo;

import React from 'react';

export const FreetextDropdown = ({ places, onClick }: any) => {
    return (
        <div className="w-full bg-gray-100 py-1 h-[100px] scroll-smooth overflow-y-scroll absolute border cursor-pointer">
            {places.map((val: any, i: any) => {
                return (
                    <div key={i} onClick={() => onClick(val.place, val.id)}>
                        <h1 className="text-black p-2">{val.place}</h1>
                    </div>
                );
            })}
        </div>
    );
};

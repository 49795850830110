import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

export const daysConversionAndSort = (daysArray: string[]) => {
    const removeDuplicates = [...new Set(daysArray)];
    const resultArray:string[] = [];
    const daysAvailable = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    for (let i of daysAvailable) {
        if (removeDuplicates.includes(i.toLowerCase())) resultArray.push(i.slice(0,3));
    }
    return resultArray.join(", ");
}

export const getTomorrowDate = () => {
        const today = new Date()
        let tomorrow =  new Date()
        tomorrow.setDate(today.getDate() + 1)
        const offset = tomorrow.getTimezoneOffset()
        tomorrow = new Date(tomorrow.getTime() - (offset*60*1000))
        return tomorrow.toISOString().split('T')[0];
}
    
export const daysTypeConversion = (count: number, type: string) => {
    if (count > 1 && !type.includes("s")) {
        return type + "s";
    }
    else if (count === 1 && type.includes("s")) {
        return type.split("s")[0];
    }
    else {
        return type;
    }
}

export const isTrainer = (role:string) => {
    return ["TI", "FL"].includes(role);
}

export const actionText = (status:string,isTrainer:boolean,request:boolean) => {
        switch (true) {
            case isTrainer && request:
                return status === "accepted" ? "Accepted for" : "Rejected for";
            case !isTrainer && request:
                return status === "accepted" ? "Accepted by" : "Rejected by";
            case isTrainer && !request:
                return status === "accepted" ? "Accepted by" : "Rejected by";
            case !isTrainer && !request:
                return status === "accepted" ? "Accepted for" : "Rejected by";
            default:
                return status;
        }
}
    
export const NavigateToSignInWithRouteParams = (response: any) => {
  // console.log({response});
  const origin = window.location.origin;
  const search = window.location.search;
  sessionStorage.setItem("lastExpiredRoute", window.location.pathname+search);
  window.location.href = `${origin}/login?errormessage=${response.data.message}`;
  // toast.error(response.data.message);
    // const fromRoute = locationHref.split(window.location.host)[1];
    // navigate("/login", { state: { from: fromRoute } });
}
export const camelCaseConvertForErrorMessage = (message:any) => {
    const RESPONSE_CAMEL_AVOID_WORDS = ["in", "the", "with", "and", "or", "not", "didn't", "is", "this", "without", "your", "cannot", "be", "id", "make", "are", "which", "added"];
    const messageArray = message?.split(" ");
    const convertedArray = messageArray?.map((msg:any) => {
        if (RESPONSE_CAMEL_AVOID_WORDS?.includes(msg))
            return msg;
        let result = msg?.slice(0, msg.length).toLowerCase();
        return result?.charAt(0).toUpperCase() + result.slice(1);
    }); return convertedArray?.join(" ");
}

export const onKeyDown = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
}

var sBrowser, sUsrAg = navigator.userAgent;

// The order matters here, and this may report false positives for unlisted browsers.

if (sUsrAg.indexOf("Firefox") > -1) {
  sBrowser = "Mozilla Firefox";
  // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
} else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
  sBrowser = "Samsung Internet";
  // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
} else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
  sBrowser = "Opera";
  // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
} else if (sUsrAg.indexOf("Trident") > -1) {
  sBrowser = "Microsoft Internet Explorer";
  // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
} else if (sUsrAg.indexOf("Edge") > -1) {
  sBrowser = "Microsoft Edge";
  // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
} else if (sUsrAg.indexOf("Chrome") > -1) {
  sBrowser = "Google Chrome or Chromium";
  // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
} else if (sUsrAg.indexOf("Safari") > -1) {
  sBrowser = "Apple Safari";
  // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
} else {
  sBrowser = "unknown";
}

export { sBrowser };
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from './Card';
import { readRecord, updateRecord } from '../../Services/backend/apiCalls';
import ScrollY from '../../assets/Images/ScrollY.svg';
import { DashBoardLoader } from './NoDataChecker';
import { useAppSelector } from '../..';
import toast from 'react-hot-toast';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const CourseThroughPortal = ({ trainings }: any) => {
    const [coursesQuery, setCoursesQuery] = useState('upcoming');
    const navigate = useNavigate();
    const [courses, setCourses] = useState(trainings);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const { signin }: any = useAppSelector((state) => state);
    const scrollRef: any = useRef();
    const { role } = signin.signinValue;
    const isTrainer = ['TI', 'FL'].includes(role);
    const user = isTrainer ? 'Trainee' : 'Trainer';

    const [roleType, setRoleType] = useState(
        user === 'Trainee' ? 'Teaching' : 'Learning'
    );
    const fetchStatus = async (status: any) => {
        console.log('upcoming;;;;;;;;;;;;;;;');

        setCoursesQuery(status);
        setLoading(true);
        // page, signin.signinValue.token, status
        const coursesData = await readRecord({ page: 0, status }, 'trainings');
        console.log(coursesData, 'DD_!!');
        if (coursesData.status) {
            setCourses(coursesData.data);
        } else toast.error(coursesData.message);
        setLoading(false);
    };

    useEffect(() => {
        if (trainings !== courses) {
            setCourses(trainings);
        }
    }, [trainings]);

    const updateTraining = async (
        id: number,
        status: string,
        isDr: boolean = false
    ) => {
        const updatedResponse = await updateRecord(
            {
                id,
                status: 'completed',
                ...(isDr && {
                    origin: 'DR',
                }),
            },
            'trainings'
        );
        if (updatedResponse.status) {
            const filteredArray = courses.filter((data: any) => data.id !== id);
            setCourses(filteredArray);
            toast.success(`Training ${status} successfully`);
        } else {
            toast.error(updatedResponse.message);
        }
    };

    const courseMap = (
        <div
            className="mx-auto flex relative space-x-4 overflow-x-auto scrollBar scroll-smooth snap-x snap-mandatory"
            ref={scrollRef}
        >
            {courses.map((course: any, index: number) => {
                const isDr = course.course !== null;
                return (
                    <Card
                        id={course.id}
                        order_id={course.order_id}
                        key={index}
                        cardColor={'bg-[#F2F4F5B2]'}
                        cardType="CTP"
                        title={
                            isDr ? course.course.title : course.bidding.title
                        }
                        courseDescription={
                            isDr
                                ? course.course.description
                                : course.bidding.description
                        }
                        user_id={course[user]['user_id']}
                        userName={course[user]['name']}
                        role={course[user]['role']}
                        date={
                            coursesQuery === 'completed'
                                ? course.completed_date
                                : course.start_date
                        }
                        width={'w-[292px]'}
                        status={coursesQuery}
                        endDate={course.end_date}
                        isTrainee={!isTrainer}
                        updateTraining={updateTraining}
                        checkIsDirectRequest={isDr}
                        bid_id={course.bid_id}
                        dr_id={course.dr_id}
                        course_id={course.course_id}
                        reviewStatus={course?.reviews}
                    />
                );
            })}
        </div>
    );

    return (
        <div className="py-6 bg-white">
            <div className="w-[95%] mx-auto">
                <div className="py-2">
                    <h1 className="text-[32px] font-[500] text-center">
                        Your {roleType} Schedule{' '}
                    </h1>
                    <div className="w-[50px] h-[3px] mt-1 rounded-full m-auto bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>
                </div>
                {/* <div className="flex items-center space-x-6 justify-center mt-6 mx-auto w-2/4">
                    <button
                        className={`${
                            coursesQuery === 'upcoming'
                                ? 'bg-[#E7EFFF] text-[#2975FD]'
                                : 'bg-white border border-[#EEEFF3] text-[#A8ABBB]'
                        } text-[14px] rounded-[8px] py-3 px-8 cursor-pointer outline-none`}
                        onClick={() => fetchStatus('upcoming')}
                    >
                        Upcoming
                    </button>
                    <button
                        className={`${
                            coursesQuery === 'pending'
                                ? 'bg-[#E7EFFF] text-[#2975FD]'
                                : 'bg-white border border-[#EEEFF3] text-[#A8ABBB]'
                        } text-[14px] rounded-[8px] py-3 px-8 cursor-pointer outline-none`}
                        onClick={() => fetchStatus('pending')}
                    >
                        In Progress
                    </button>
                    <button
                        className={`${
                            coursesQuery === 'completed'
                                ? 'bg-[#E7EFFF] text-[#2975FD]'
                                : 'bg-white border border-[#EEEFF3] text-[#A8ABBB]'
                        } text-[14px] rounded-[8px] py-3 px-8 cursor-pointer outline-none`}
                        onClick={() => fetchStatus('completed')}
                    >
                        Completed
                    </button>
                    <button
                        className={`${
                            coursesQuery === 'cancelled'
                                ? 'bg-[#E7EFFF] text-[#2975FD]'
                                : 'bg-white border border-[#EEEFF3] text-[#A8ABBB]'
                        } text-[14px] rounded-[8px] py-3 px-8 cursor-pointer outline-none`}
                        onClick={() => fetchStatus('cancelled')}
                    >
                        Cancelled
                    </button>
                </div> */}
                <div className="flex flex-col sm:flex-row items-center space-x-0 sm:space-x-6 justify-center mt-6 mx-auto w-full sm:w-2/4">
                    <button
                        className={`${
                            coursesQuery === 'upcoming'
                                ? 'bg-[#E7EFFF] text-[#2975FD]'
                                : 'bg-white border border-[#EEEFF3] text-[#A8ABBB]'
                        } text-[14px] rounded-[8px] py-3 px-6 sm:px-8 cursor-pointer outline-none w-full sm:w-auto`}
                        onClick={() => fetchStatus('upcoming')}
                    >
                        Upcoming
                    </button>
                    <button
                        className={`${
                            coursesQuery === 'pending'
                                ? 'bg-[#E7EFFF] text-[#2975FD]'
                                : 'bg-white border border-[#EEEFF3] text-[#A8ABBB]'
                        } text-[14px] rounded-[8px] py-3 px-6 sm:px-8 cursor-pointer outline-none w-full sm:w-auto`}
                        onClick={() => fetchStatus('pending')}
                    >
                        In Progress
                    </button>
                    <button
                        className={`${
                            coursesQuery === 'completed'
                                ? 'bg-[#E7EFFF] text-[#2975FD]'
                                : 'bg-white border border-[#EEEFF3] text-[#A8ABBB]'
                        } text-[14px] rounded-[8px] py-3 px-6 sm:px-8 cursor-pointer outline-none w-full sm:w-auto`}
                        onClick={() => fetchStatus('completed')}
                    >
                        Completed
                    </button>
                    <button
                        className={`${
                            coursesQuery === 'cancelled'
                                ? 'bg-[#E7EFFF] text-[#2975FD]'
                                : 'bg-white border border-[#EEEFF3] text-[#A8ABBB]'
                        } text-[14px] rounded-[8px] py-3 px-6 sm:px-8 cursor-pointer outline-none w-full sm:w-auto`}
                        onClick={() => fetchStatus('cancelled')}
                    >
                        Cancelled
                    </button>
                </div>

                {/* update by me */}
                {courses.length > 4 && (
                    <div className="flex justify-end">
                        <p
                            className="py-3 pr-8 cursor-pointer text-[14px] font-[500] text-[#5F5F5F] hover:text-[#267DF8]"
                            onClick={() =>
                                navigate('/request/training', {
                                    state: 'typeForDashboard',
                                })
                            }
                        >
                            See All
                        </p>
                    </div>
                )}
                <div className="flex items-center justify-around relative space-x-5 h-[350px]">
                    {!loading && (
                        <img
                            src={ScrollY}
                            alt="ScrollY"
                            className="cursor-pointer"
                            onClick={() =>
                                (scrollRef.current.scrollLeft -= 1000)
                            }
                        />
                    )}
                    <DashBoardLoader
                        children={courseMap}
                        data={courses}
                        loading={loading}
                    />
                    {/* </div> */}
                    {!loading && (
                        <img
                            src={ScrollY}
                            alt="ScrollY"
                            className="rotate-180 cursor-pointer"
                            onClick={() =>
                                (scrollRef.current.scrollLeft += 1000)
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

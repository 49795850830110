import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../..';
import { isTrainer } from '../../Services/commonFunctions';
import { Card } from './Card';
import { CourseMenu } from '../CourseMenu/CourseMenu';
import arrow from '../../assets/Images/RightArrow.svg';
import { SuggestionCard } from './SuggestionCard';
import InternshipCard from '../Internship/InternshipCard';
import { AllInternshipsPage } from '../Internship/AllInternshipsPage';
import { AppliedStudentInternship } from '../Internship/AppliedStudentInternship';

export const RequestPage = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const selectedSection = params.requestorigin;
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [pageName, setPageName] = useState('');
    const [sorted, setSorted] = useState('Newest');
    const [{ role }, { appConfig }] = useAppSelector(
        ({ signin, appConfig }: any) => [
            signin['signinValue'],
            appConfig['configData'],
        ]
    );
    const userType = ['TI', 'FL'].includes(role) ? 'trainee' : 'trainer';
    const checkTrainer = ['TI', 'FL'].includes(role);

    console.log(appConfig);

    useEffect(() => {
        if (selectedSection === 'training') {
            setStatus('upcoming');
        } else if (selectedSection === 'myinternship') {
            setStatus('active'); // Customize as needed for internships
        } else {
            setStatus('accepted');
        }
    }, [selectedSection]);

    useEffect(() => {
        const subText =
            userType === 'trainee' ? 'Ongoing Bids Just For You' : '';
        setPageName(
            selectedSection === 'booknow'
                ? 'Course Details'
                : selectedSection === 'bidding'
                ? 'Bidding Request'
                : selectedSection === 'training'
                ? 'Trainings'
                : selectedSection === 'myinternship'
                ? 'My Internships'
                : `Courses Picked Based on Your Preferences ${subText}`
        );
    }, [selectedSection, userType]);

    const optionsForSort = ['Newest', 'Oldest'];

    const myCourseChange = () => {
        setLoading(true);
        navigate('/request/mycourse', { state: 'typeForHeaderClick' });
    };
    const biddingChange = () => {
        setLoading(true);
        navigate('/request/bidding', { state: 'typeForHeaderClick' });
    };
    const directChange = () => {
        setLoading(true);
        navigate('/request/booknow', { state: 'typeForHeaderClick' });
    };
    const trainingChange = () => {
        setLoading(true);
        navigate('/request/training', { state: 'typeForHeaderClick' });
    };
    const myInternshipChange = () => {
        setLoading(true);
        navigate('/request/myinternship', { state: 'typeForHeaderClick' });
    };

    return (
        <div className="bg-[#F6F7F8] min-h-screen">
            {appConfig && appConfig['cms_action'] !== 'off' && (
                <div className="w-11/12 mx-auto">
                    {state && state === 'typeForHeaderClick' && (
                        <div className="w-full sticky top-0 z-[1] bg-[#F6F7F8] py-4">
                            {/* Responsive flex layout */}
                            <div className="flex flex-col sm:flex-row sm:items-center flex-wrap justify-between h-auto sm:h-[42px] space-y-2 sm:space-y-0">
                                <div className="sm:flex-row sm:flex-wrap flex flex-col gap-2    items-baseline space-x-2 sm:space-x-4 sm:items-end mb-5">
                                    {isTrainer(role) && (
                                        <h1
                                            className={`text-[14px] sm:text-[16px] font-[500] cursor-pointer ${
                                                selectedSection === 'mycourse'
                                                    ? 'text-[#085BEB] underline underline-offset-[14px]'
                                                    : 'text-[#323232]'
                                            }`}
                                            onClick={myCourseChange}
                                        >
                                            My Courses
                                        </h1>
                                    )}
                                    {appConfig &&
                                        appConfig['bidding_action'] !==
                                            'off' && (
                                            <h1
                                                className={`text-[14px] sm:text-[16px] font-[500] cursor-pointer ${
                                                    selectedSection ===
                                                    'bidding'
                                                        ? 'text-[#085BEB] underline underline-offset-[14px]'
                                                        : 'text-[#323232]'
                                                }`}
                                                onClick={biddingChange}
                                            >
                                                {userType === 'trainee'
                                                    ? 'Current Bids'
                                                    : 'Bid Requests'}
                                            </h1>
                                        )}
                                    {appConfig &&
                                        appConfig['direct_request_action'] !==
                                            'off' && (
                                            <h1
                                                className={`text-[14px] sm:text-[16px] font-[500] cursor-pointer ${
                                                    selectedSection ===
                                                    'booknow'
                                                        ? 'text-[#085BEB] underline underline-offset-[14px]'
                                                        : 'text-[#323232]'
                                                }`}
                                                onClick={directChange}
                                            >
                                                Course Requests
                                            </h1>
                                        )}
                                    <h1
                                        className={`text-[14px] sm:text-[16px] font-[500] cursor-pointer ${
                                            selectedSection === 'training'
                                                ? 'text-[#085BEB] underline underline-offset-[14px]'
                                                : 'text-[#323232]'
                                        }`}
                                        onClick={trainingChange}
                                    >
                                        Schedule
                                    </h1>
                                    <h1
                                        className={`text-[14px] sm:text-[16px] font-[500] cursor-pointer ${
                                            selectedSection === 'myinternship'
                                                ? 'text-[#085BEB] underline underline-offset-[14px]'
                                                : 'text-[#323232]'
                                        }`}
                                        onClick={myInternshipChange}
                                    >
                                        {/* My Internship */}

                                        {/* {role === 'TI' &&
                                        appConfig.show_internship
                                            ? 'My Internship'
                                            : ''} */}
                                        {['TI', 'ST'].includes(role) &&
                                        appConfig.show_internship
                                            ? role === 'TI'
                                                ? 'My Internship'
                                                : 'Internships'
                                            : ''}
                                    </h1>
                                </div>
                                {selectedSection === 'mycourse' &&
                                    isTrainer(role) &&
                                    appConfig['cms_action'] === 'edit' && (
                                        <div className="flex flex-col sm:flex-row gap-5">
                                            <button
                                                className="bg-[#065FEF] w-full md:w-[120px] h-[42px] rounded-[4px] text-white text-[13px] cursor-pointer"
                                                onClick={() => {
                                                    navigate('/course/add');
                                                }}
                                            >
                                                Add Course
                                            </button>
                                        </div>
                                    )}
                                {selectedSection === 'bidding' &&
                                    !isTrainer(role) &&
                                    appConfig['bidding_action'] === 'edit' &&
                                    appConfig['cms_action'] === 'edit' && (
                                        <div className="flex flex-col sm:flex-row gap-5">
                                            <button
                                                className="bg-[#065FEF] w-full md:w-[120px] h-[42px] rounded-[4px] text-white text-[13px] cursor-pointer"
                                                onClick={() =>
                                                    navigate('/bid/add')
                                                }
                                            >
                                                New Bid
                                            </button>
                                        </div>
                                    )}

                                {selectedSection === 'myinternship' &&
                                    isTrainer(role) &&
                                    appConfig['cms_action'] === 'edit' && (
                                        <div className="flex flex-col sm:flex-row gap-5">
                                            <button
                                                className="bg-[#065FEF] w-full md:w-[120px] h-[42px] rounded-[4px] text-white text-[13px] cursor-pointer"
                                                onClick={() => {
                                                    navigate('/add-internship');
                                                }}
                                            >
                                                Add Internship
                                            </button>
                                        </div>
                                    )}
                            </div>
                            <div className="border-b-2 mt-2"></div>
                        </div>
                    )}
                    <div className="bg-[#F6F7F8] mb-10">
                        {state && state !== 'typeForHeaderClick' && (
                            <>
                                <div className="flex gap-2 items-center mt-4 sm:mt-10 w-full mx-auto cursor-pointer">
                                    <img
                                        src={arrow}
                                        alt="ArrowForBack"
                                        className="rotate-180 w-[17px] h-[17px]"
                                        onClick={() => navigate('/dashboard')}
                                    />
                                    <p
                                        className="text-[16px] sm:text-[18px] font-[400] text-[#3061F4]"
                                        onClick={() => navigate('/dashboard')}
                                    >
                                        Back
                                    </p>
                                </div>
                                {state !== 'typeForHeaderClick' &&
                                    selectedSection === 'suggestion' && (
                                        <p className="text-[16px] sm:text-[18px] font-[500] mt-4 sm:mt-10">
                                            {pageName}
                                        </p>
                                    )}
                            </>
                        )}
                        {selectedSection === 'mycourse' && <CourseMenu />}
                        {/* {selectedSection && selectedSection !== 'mycourse' && (
                            <div
                                className={`h-auto bg-[#F6F7F8] ${
                                    state !== 'typeForHeaderClick' && 'mt-4'
                                }`}
                            >
                                {selectedSection === 'myinternship' ? (
                                    <InternshipCard />
                                ) : selectedSection !== 'suggestion' ? (
                                    <Card
                                        status={status}
                                        request={selectedSection}
                                        origin={selectedSection}
                                        loading={loading}
                                        setLoading={setLoading}
                                        checkTrainer={checkTrainer}
                                        pageName={pageName}
                                        setStatus={setStatus}
                                        paramsState={
                                            state !== 'typeForHeaderClick'
                                        }
                                        state={state}
                                        appConfig={appConfig}
                                    />
                                ) : (
                                    <SuggestionCard
                                        request={selectedSection}
                                        appConfig={appConfig}
                                    />
                                )}
                            </div>
                        )} */}
                        {selectedSection && selectedSection !== 'mycourse' && (
                            <div
                                className={`h-auto bg-[#F6F7F8] ${
                                    state !== 'typeForHeaderClick' && 'mt-4'
                                }`}
                            >
                                {selectedSection === 'myinternship' ? (
                                    role === 'TI' ? (
                                        <InternshipCard />
                                    ) : role === 'ST' ? (
                                        // Replace this with your student internship component
                                        <div className="p-4">
                                            <h2 className="text-xl font-semibold">
                                                Internships where you applied
                                            </h2>
                                            {/* Add your student internship content here */}
                                            <AppliedStudentInternship />
                                        </div>
                                    ) : null
                                ) : selectedSection !== 'suggestion' ? (
                                    <Card
                                        status={status}
                                        request={selectedSection}
                                        origin={selectedSection}
                                        loading={loading}
                                        setLoading={setLoading}
                                        checkTrainer={checkTrainer}
                                        pageName={pageName}
                                        setStatus={setStatus}
                                        paramsState={
                                            state !== 'typeForHeaderClick'
                                        }
                                        state={state}
                                        appConfig={appConfig}
                                    />
                                ) : (
                                    <SuggestionCard
                                        request={selectedSection}
                                        appConfig={appConfig}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export const RequestStatus = ({
    paramsState,
    pageName,
    status,
    request,
    setStatus,
    setLoading,
    checkTrainer,
}: any) => {
    const handleClick = (e: any) => {
        setStatus(e.target.title);
        setLoading(true);
    };
    return (
        <div className="w-full relative">
            {paramsState && (
                <p className="text-[16px] sm:text-[18px] font-[500] absolute">
                    {pageName}
                </p>
            )}
            {paramsState && <PageType text={pageName} request={request} />}
            {request !== 'suggestion' && (
                <div className="flex flex-col sm:flex-row flex-wrap items-center justify-center gap-4 sm:space-x-8">
                    <button
                        className={`${
                            status === 'accepted' || status === 'upcoming'
                                ? 'bg-[#E0FFF3] text-[#33BD87]'
                                : 'bg-white text-[#A8ABBB]'
                        } p-2.5 px-4 sm:px-10 text-[14px] font-[500] rounded-[8px]`}
                        title={`${
                            request !== 'training' ? 'accepted' : 'upcoming'
                        }`}
                        onClick={handleClick}
                    >{`${
                        request !== 'training' ? 'Accepted' : 'Upcoming'
                    }`}</button>
                    <button
                        className={`${
                            status === 'pending'
                                ? 'bg-[#FFF3D2] text-[#ECB833]'
                                : 'bg-white text-[#A8ABBB]'
                        } p-2 px-4 sm:px-10 text-[14px] font-[400] rounded-[8px]`}
                        title="pending"
                        onClick={handleClick}
                    >
                        In Progress
                    </button>
                    {request === 'bidding' && !checkTrainer ? null : (
                        <button
                            className={`${
                                status === 'rejected' || status === 'closed'
                                    ? 'bg-[#FFDADA] text-[#FB5935]'
                                    : status === 'completed'
                                    ? 'bg-[#E7EFFF] text-[#2975FD]'
                                    : 'bg-white text-[#A8ABBB]'
                            } p-2.5 px-4 sm:px-10 text-[14px] font-[400] rounded-[8px]`}
                            title={`${
                                request === 'booknow'
                                    ? 'rejected'
                                    : request === 'bidding'
                                    ? 'rejected'
                                    : 'completed'
                            }`}
                            onClick={handleClick}
                        >
                            {`${
                                request === 'booknow'
                                    ? 'Rejected'
                                    : request === 'bidding'
                                    ? 'Rejected'
                                    : 'Completed'
                            }`}
                        </button>
                    )}
                    {request === 'training' && (
                        <button
                            className={`${
                                status === 'Cancelled'
                                    ? 'bg-[#FFF3D2] text-[#ECB833]'
                                    : 'bg-white text-[#A8ABBB]'
                            } p-2 px-4 sm:px-10 text-[14px] font-[400] rounded-[8px]`}
                            title="Cancelled"
                            onClick={handleClick}
                        >
                            Cancelled
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

const PageType = ({ text, request }: any) => {
    return (
        <div className="absolute left-0">
            {request === 'suggestion' && (
                <p className="text-[13px] font-[400] text-[#5B5B5B] pt-1">
                    Request you may be interested with
                </p>
            )}
        </div>
    );
};

import moment from "moment";

export const dateFormat=(date:any)=>{
    let datetext;
        datetext= moment(date).format('DD-MMM-YYYY  h:mm A')
    return(
        datetext
    )
}

export const Feedback = ({ status, userRole, username, checkUser }: any) => {
    return status.reply !== null ? (
        <div>
            <div className="mt-8 flex justify-between items-start">
                <div className="flex space-x-3 items-center">
                    <div className="w-[45px] h-[45px] bg-black rounded-full text-white flex items-center justify-center">
                        {status['Trainer']['name'][0]}
                    </div>
                    <div className="flex flex-col">
                        <div className="flex items-center">
                            <h1 className="text-[18px] font-[600]">
                                {checkUser ? 'You' : status['Trainer']['name']}
                            </h1>
                        </div>
                        <p>{dateFormat(status['updatedAt'])}</p>
                    </div>
                </div>
            </div>
            <div className="w-11/12 xl:w-11/12 mx-auto mt-4 ml-14">
                <p className="text-[14px] font-[400] min-h-[30px] h-auto text-[#8B8B8B]">
                    {status['reply'] === null
                        ? 'no description'
                        : status['reply']}
                </p>
            </div>
        </div>
    ) : null;
}
// import React, { useCallback, useEffect, useState } from 'react';
// import { Header } from '../Header/Header';
// import { Footer } from '../Footer/Footer';
// import { DayField } from '../Elements/DayFeild';
// import { useFormik } from 'formik';
// import { NewBidSchema } from '../../Services/Formik/Formik';
// import { convertedTimeToNumber } from '../AddCourse/ConverterTimeToNumber';
// import {
//     postRecord,
//     readRecord,
//     updateRecord,
// } from '../../Services/backend/apiCalls';
// import { useAppDispatch, useAppSelector } from '../..';
// import { toast } from 'react-hot-toast';
// import { useDebounce } from '../../hooks/DebounceHook';
// import { getTomorrowDate, onKeyDown } from '../../Services/commonFunctions';
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { setSignValue } from '../../Redux/Reducers/signinUserReducers';
// import { RadioInput } from '../AddCourse/RadioInput';
// import { Tags } from '../Elements/Tags';

// import { debounce } from 'lodash'; // lodash's debounce function
// import { Editor } from '@tiptap/core';
// import { useEditor, EditorContent } from '@tiptap/react';
// import StarterKit from '@tiptap/starter-kit';
// import Underline from '@tiptap/extension-underline';

// import {
//     FaBold,
//     FaHeading,
//     FaItalic,
//     FaListOl,
//     FaListUl,
//     FaQuoteLeft,
//     FaRedo,
//     FaStrikethrough,
//     FaUnderline,
//     FaUndo,
// } from 'react-icons/fa';

// export const NewBid = () => {
//     const params = useParams();
//     const location = useLocation();
//     const navigate = useNavigate();
//     const dispatch = useAppDispatch();
//     const isEdit = location.pathname.includes('edit');

//     const [editorContent, setEditorContent] = useState('');

//     const [initialDate, setInitialDate] = useState('');
//     const formInput: any = {
//         title: '',
//         address: '',
//         days: 0,
//         start_date: '',
//         start_time: '',
//         end_time: '',
//         trainees_count: 0,
//         description: '',
//         preferred_days: [],
//         mode: 'online',
//         tags: [],
//     };

//     const formik = useFormik({
//         initialValues: formInput,
//         validationSchema: NewBidSchema,
//         onSubmit: formSubmitAction,
//     });

//     const {
//         values,
//         errors,
//         touched,
//         handleBlur,
//         handleSubmit,
//         handleChange,
//         setValues,
//         setFieldValue,
//         setFieldError,
//     } = formik;

//     useEffect(() => {
//         if (isEdit) {
//             (async () => {
//                 const bidData = await readRecord({ id: params.id }, 'biddings');
//                 if (bidData.status && bidData.data) {
//                     const {
//                         title,
//                         address,
//                         description,
//                         days,
//                         preferred_days,
//                         trainees_count,
//                         start_date,
//                         preferred_time,
//                         mode,
//                         tags,
//                     } = bidData.data;

//                     setInitialDate(preferred_days);
//                     setValues(
//                         {
//                             ...values,
//                             title,
//                             address,
//                             description,
//                             days,
//                             preferred_days,
//                             mode,
//                             trainees_count,
//                             start_date: setDate(start_date),
//                             tags,
//                             start_time: preferred_time.split('-')[0],
//                             end_time: preferred_time.split('-')[1],
//                         },
//                         true
//                     );
//                 } else toast.error(bidData.message);
//             })();
//         }
//     }, []);

//     async function formSubmitAction() {
//         if (
//             values.end_time.length &&
//             convertedTimeToNumber(values.end_time) <
//                 convertedTimeToNumber(values.start_time)
//         ) {
//             setFieldError(
//                 'start_time',
//                 'start time should be less than end time'
//             );
//             setFieldError(
//                 'end_time',
//                 'end time should be greater than start time'
//             );
//             return;
//         }
//         const { start_time, end_time, ...rest } = values;
//         const bidData = {
//             ...rest,
//             ...(isEdit && { id: Number(params.id) }),
//             preferred_time: start_time + '-' + end_time,
//         };

//         const bidAPIActionFunctions = isEdit ? updateRecord : postRecord;

//         const bidPostData = await bidAPIActionFunctions(bidData, 'biddings');
//         if (bidPostData.status) {
//             toast.success(isEdit ? 'Bid updated' : 'New Bidding created');
//             const navigateRoute = isEdit
//                 ? {
//                       pathname: `/bid/view/${rest.title}/${params.id}`,
//                       search: `?status=pending`,
//                   }
//                 : `/bid/view/${bidPostData.data.title}/${bidPostData.data.id}`;
//             navigate(navigateRoute, { state: 'typeForDashboard' });
//         } else {
//             toast.error(bidPostData.message);
//         }
//     }

//     const daysHandleChange = (day: string) => {
//         if (values.preferred_days.includes(day)) {
//             const excludedvalues = values.preferred_days.filter(
//                 (item: any) => day !== item
//             );
//             return setFieldValue('preferred_days', excludedvalues);
//         }
//         setFieldValue('preferred_days', [...values.preferred_days, day]);
//     };

//     const setDate = (dateText: string) => {
//         return dateText.split('T')[0];
//     };

//     const editor = useEditor({
//         extensions: [StarterKit, Underline],
//         content: '', // Initializes with the description field's content from Formik
//         onUpdate: ({ editor }) => {
//             setFieldValue('description', editor.getHTML()); // Syncs the HTML content with Formik
//         },
//     });

//     const debouncedUpdate = useCallback(
//         debounce((content) => {
//             setFieldValue('description', content);
//         }, 300),
//         [] // Dependencies can be adjusted based on your needs
//     );

//     useEffect(() => {
//         if (editor) {
//             editor.on('update', ({ editor }) => {
//                 const html = editor.getHTML();
//                 setEditorContent(html);
//                 debouncedUpdate(html);
//             });
//         }
//     }, [editor, debouncedUpdate]);

//     useEffect(() => {
//         if (editor && values.description !== editorContent) {
//             editor.commands.setContent(values.description);
//         }
//     }, [editor, values.description, editorContent]);

//     return (
//         <form
//             className="h-auto bg-[#F6F7F8]"
//             onSubmit={handleSubmit}
//             onKeyDown={onKeyDown}
//         >
//             <div className="w-11/12 mx-auto">
//                 <div className="w-full mt-10 sticky top-0 z-[1] bg-[#F6F7F8] py-4">
//                     <div className="w-11/12 mx-auto">
//                         <h1 className="text-[13px] font-[400]">
//                             <span
//                                 className="cursor-pointer"
//                                 onClick={() =>
//                                     navigate(`/request/bidding`, {
//                                         state: 'typeForHeaderClick',
//                                     })
//                                 }
//                             >
//                                 Bidding
//                             </span>
//                             {' > '}My Request
//                         </h1>
//                         <div>
//                             <div className="flex justify-between mt-6">
//                                 <h1 className="ml-1 text-[25px] font-[600]">
//                                     Course Detailssss
//                                 </h1>
//                                 <button
//                                     className="bg-[#065FEF] disabled:opacity-50 rounded-[4px] w-[106px] h-[42px] text-white text-[13px] font-[400] cursor-pointer"
//                                     disabled={Object.keys(errors).length !== 0}
//                                     type="submit"
//                                 >
//                                     Submit
//                                 </button>
//                             </div>
//                             <div className="border-b-2 mt-2"></div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="w-11/12 mx-auto mt-10">
//                     <InputTag
//                         name="title"
//                         value={values.title}
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         error={errors.title}
//                         isTouched={touched.title}
//                         type={'text'}
//                         label={'Title'}
//                         className={'h-[55px] mt-4'}
//                         focus={'focus'}
//                     />
//                     <Tags
//                         tags={values.tags}
//                         values={values}
//                         setFieldValue={setFieldValue}
//                         formik={formik}
//                     />
//                     <InputTag
//                         name="address"
//                         value={values.address}
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         error={errors.address}
//                         isTouched={touched.address}
//                         type={'text'}
//                         label={'Venue'}
//                         className={'h-[55px] mt-4'}
//                     />
//                     <div className="flex justify-between space-x-8">
//                         <InputTag
//                             maxlength="4"
//                             pattern="[1-9][0-9]{3}"
//                             max={'9999-12-31'}
//                             name="start_date"
//                             min={initialDate ? '' : getTomorrowDate()}
//                             value={values.start_date}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             error={errors.start_date}
//                             isTouched={touched.start_date}
//                             type={'date'}
//                             label={'Preferred Date'}
//                             className={'h-[55px] mt-4'}
//                             width={'w-4/5'}
//                         />
//                         <InputTag
//                             name="days"
//                             type={'number'}
//                             label={'No. of days'}
//                             className={'h-[55px] mt-4'}
//                             width={'w-4/5'}
//                             value={values.days}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             error={errors.days}
//                             isTouched={touched.days}
//                         />
//                     </div>
//                     <DayField
//                         title={'Preferred Days'}
//                         value={values.preferred_days}
//                         onChange={daysHandleChange}
//                         isTouched={touched.preferred_days}
//                         error={errors.preferred_days}
//                     />
//                     {/* <InputTag name='preferreddays' type={"text"} label={"Preferred Days"} className={"h-[55px] mt-4"} /> */}
//                     <div className="flex justify-between space-x-8">
//                         <div className="w-4/5 flex items-center space-x-4">
//                             <InputTag
//                                 name="start_time"
//                                 type={'time'}
//                                 label={'Start Time'}
//                                 value={values.start_time}
//                                 onChange={handleChange}
//                                 onBlur={handleBlur}
//                                 isTouched={touched.start_time}
//                                 error={errors.start_time}
//                                 className={'h-[55px] mt-4'}
//                                 width={'w-10/12'}
//                             />
//                             <InputTag
//                                 name="end_time"
//                                 type={'time'}
//                                 label={'End Time'}
//                                 value={values.end_time}
//                                 onChange={handleChange}
//                                 onBlur={handleBlur}
//                                 isTouched={touched.end_time}
//                                 error={errors.end_time}
//                                 className={'h-[55px] mt-4'}
//                                 width={'w-10/12'}
//                             />
//                         </div>
//                         <InputTag
//                             name="trainees_count"
//                             value={values.trainees_count}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             isTouched={touched.trainees_count}
//                             error={errors.trainees_count}
//                             type={'number'}
//                             label={'Head Count'}
//                             className={'h-[55px] mt-4'}
//                             width={'w-4/5'}
//                         />
//                     </div>
//                     <div className="w-1/2">
//                         <p className="text-[15px] font-[500] mb-4">Mode</p>
//                         <div className="flex items-center space-x-3">
//                             <RadioInput
//                                 name="mode"
//                                 type={'radio'}
//                                 label={'Online'}
//                                 checked={values.mode === 'online'}
//                                 className={'h-[55px] rounded-[8px]'}
//                                 onChange={() => setFieldValue('mode', 'online')}
//                             />
//                             <RadioInput
//                                 name="mode"
//                                 type={'radio'}
//                                 label={'Offline'}
//                                 checked={values.mode === 'offline'}
//                                 className={'h-[55px] rounded-[8px]'}
//                                 onChange={() =>
//                                     setFieldValue('mode', 'offline')
//                                 }
//                             />
//                         </div>
//                     </div>
//                     {/* <InputTag
//                         name="description"
//                         value={values.description}
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         isTouched={touched.description}
//                         error={errors.description}
//                         type={'text'}
//                         label={'Additional Notes (If Any)'}
//                         className={'h-[185px] mt-4'}
//                     /> */}

//                     {/* update by me */}

//                     <div className="flex flex-col mb-12 relative ">
//                         <label className="text-sm font-medium">
//                             Additional Notes (If Any)
//                             <span className="ml-1 text-red-600">*</span>
//                         </label>

//                         <div className="bg-white p-4 rounded-lg border  border-black   mt-4  ">
//                             {editor && (
//                                 <div>
//                                     <MenuBar editor={editor} />
//                                     <EditorContent
//                                         editor={editor}
//                                         className="outline-none p-2 h-full w-full overflow-y-auto break-words "
//                                     />
//                                 </div>
//                             )}
//                             {typeof errors.description === 'string' &&
//                                 touched.description && (
//                                     <p className="text-red-500 text-xs absolute right-2 bottom-2">
//                                         {errors.description}
//                                     </p>
//                                 )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </form>
//     );
// };

// const InputTag = ({
//     focus,
//     label,
//     type,
//     className,
//     placeholder,
//     error,
//     isTouched,
//     width,
//     ...rest
// }: any) => {
//     const errorValue = isTouched && error;
//     const isTextarea = ['description'].includes(rest.name);
//     return (
//         <div className={`flex flex-col mb-14 relative ${width ? width : ''}`}>
//             {label ? (
//                 <label className="text-[15px] font-[500]">{label}</label>
//             ) : null}
//             {isTextarea ? (
//                 <textarea
//                     className={`${className} bg-white outline-none rounded-[8px] px-4 py-4 border-[0.7px] border-black`}
//                     {...rest}
//                 />
//             ) : (
//                 <input
//                     autoFocus={focus && true}
//                     placeholder={placeholder ? placeholder : ''}
//                     className={`${className} bg-white outline-none rounded-[8px] px-4 border-[0.7px] border-black`}
//                     type={type}
//                     {...rest}
//                 />
//             )}
//             {errorValue && (
//                 <p className="text-red-500 text-[12px] absolute right-4 bottom-[-30px]">
//                     {error}
//                 </p>
//             )}
//         </div>
//     );
// };

// interface MenuBarProps {
//     editor: Editor | null;
// }

// const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
//     if (!editor) {
//         return null;
//     }

//     return (
//         <div className="menuBar ">
//             <div>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().toggleBold().run()}
//                     className={editor.isActive('bold') ? 'is_active' : ''}
//                 >
//                     <FaBold />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().toggleItalic().run()}
//                     className={editor.isActive('italic') ? 'is_active' : ''}
//                 >
//                     <FaItalic />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleUnderline().run()
//                     }
//                     className={editor.isActive('underline') ? 'is_active' : ''}
//                 >
//                     <FaUnderline />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().toggleStrike().run()}
//                     className={editor.isActive('strike') ? 'is_active' : ''}
//                 >
//                     <FaStrikethrough />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleHeading({ level: 2 }).run()
//                     }
//                     className={
//                         editor.isActive('heading', { level: 2 })
//                             ? 'is_active'
//                             : ''
//                     }
//                 >
//                     <FaHeading />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleHeading({ level: 3 }).run()
//                     }
//                     className={
//                         editor.isActive('heading', { level: 3 })
//                             ? 'is_active'
//                             : ''
//                     }
//                 >
//                     <FaHeading className="heading3" />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleBulletList().run()
//                     }
//                     className={editor.isActive('bulletList') ? 'is_active' : ''}
//                 >
//                     <FaListUl />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleOrderedList().run()
//                     }
//                     className={
//                         editor.isActive('orderedList') ? 'is_active' : ''
//                     }
//                 >
//                     <FaListOl />
//                 </button>
//                 {/* <button
//                 onClick={() =>
//                     editor.chain().focus().toggleBlockquote().run()
//                 }
//                 className={editor.isActive('blockquote') ? 'is_active' : ''}
//             >
//                 <FaQuoteLeft />
//             </button> */}
//             </div>
//             <div>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().undo().run()}
//                 >
//                     <FaUndo />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().redo().run()}
//                 >
//                     <FaRedo />
//                 </button>
//             </div>
//         </div>
//     );
// };

// import React, { useCallback, useEffect, useState } from 'react';
// import { Header } from '../Header/Header';
// import { Footer } from '../Footer/Footer';
// import { DayField } from '../Elements/DayFeild';
// import { useFormik } from 'formik';
// import { NewBidSchema } from '../../Services/Formik/Formik';
// import { convertedTimeToNumber } from '../AddCourse/ConverterTimeToNumber';
// import {
//     postRecord,
//     readRecord,
//     updateRecord,
// } from '../../Services/backend/apiCalls';
// import { useAppDispatch, useAppSelector } from '../..';
// import { toast } from 'react-hot-toast';
// import { useDebounce } from '../../hooks/DebounceHook';
// import { getTomorrowDate, onKeyDown } from '../../Services/commonFunctions';
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { setSignValue } from '../../Redux/Reducers/signinUserReducers';
// import { RadioInput } from '../AddCourse/RadioInput';
// import { Tags } from '../Elements/Tags';

// import { debounce } from 'lodash'; // lodash's debounce function
// import { Editor } from '@tiptap/core';
// import { useEditor, EditorContent } from '@tiptap/react';
// import StarterKit from '@tiptap/starter-kit';
// import Underline from '@tiptap/extension-underline';

// import {
//     FaBold,
//     FaHeading,
//     FaItalic,
//     FaListOl,
//     FaListUl,
//     FaStrikethrough,
//     FaUnderline,
//     FaUndo,
//     FaRedo,
// } from 'react-icons/fa';

// export const NewBid = () => {
//     const params = useParams();
//     const location = useLocation();
//     const navigate = useNavigate();
//     const dispatch = useAppDispatch();
//     const isEdit = location.pathname.includes('edit');

//     const [editorContent, setEditorContent] = useState('');

//     const [initialDate, setInitialDate] = useState('');
//     const formInput: any = {
//         title: '',
//         address: '',
//         days: 0,
//         start_date: '',
//         start_time: '',
//         end_time: '',
//         trainees_count: 0,
//         description: '',
//         preferred_days: [],
//         mode: 'online',
//         tags: [],
//     };

//     const formik = useFormik({
//         initialValues: formInput,
//         validationSchema: NewBidSchema,
//         onSubmit: formSubmitAction,
//     });

//     const {
//         values,
//         errors,
//         touched,
//         handleBlur,
//         handleSubmit,
//         handleChange,
//         setValues,
//         setFieldValue,
//         setFieldError,
//     } = formik;

//     useEffect(() => {
//         if (isEdit) {
//             (async () => {
//                 const bidData = await readRecord({ id: params.id }, 'biddings');
//                 if (bidData.status && bidData.data) {
//                     const {
//                         title,
//                         address,
//                         description,
//                         days,
//                         preferred_days,
//                         trainees_count,
//                         start_date,
//                         preferred_time,
//                         mode,
//                         tags,
//                     } = bidData.data;

//                     setInitialDate(preferred_days);
//                     setValues(
//                         {
//                             ...values,
//                             title,
//                             address,
//                             description,
//                             days,
//                             preferred_days,
//                             mode,
//                             trainees_count,
//                             start_date: setDate(start_date),
//                             tags,
//                             start_time: preferred_time.split('-')[0],
//                             end_time: preferred_time.split('-')[1],
//                         },
//                         true
//                     );
//                 } else toast.error(bidData.message);
//             })();
//         }
//     }, []);

//     async function formSubmitAction() {
//         if (
//             values.end_time.length &&
//             convertedTimeToNumber(values.end_time) <
//                 convertedTimeToNumber(values.start_time)
//         ) {
//             setFieldError(
//                 'start_time',
//                 'start time should be less than end time'
//             );
//             setFieldError(
//                 'end_time',
//                 'end time should be greater than start time'
//             );
//             return;
//         }
//         const { start_time, end_time, ...rest } = values;
//         const bidData = {
//             ...rest,
//             ...(isEdit && { id: Number(params.id) }),
//             preferred_time: start_time + '-' + end_time,
//         };

//         const bidAPIActionFunctions = isEdit ? updateRecord : postRecord;

//         const bidPostData = await bidAPIActionFunctions(bidData, 'biddings');
//         if (bidPostData.status) {
//             toast.success(isEdit ? 'Bid updated' : 'New Bidding created');
//             const navigateRoute = isEdit
//                 ? {
//                       pathname: `/bid/view/${rest.title}/${params.id}`,
//                       search: `?status=pending`,
//                   }
//                 : `/bid/view/${bidPostData.data.title}/${bidPostData.data.id}`;
//             navigate(navigateRoute, { state: 'typeForDashboard' });
//         } else {
//             toast.error(bidPostData.message);
//         }
//     }

//     const daysHandleChange = (day: string) => {
//         if (values.preferred_days.includes(day)) {
//             const excludedvalues = values.preferred_days.filter(
//                 (item: any) => day !== item
//             );
//             return setFieldValue('preferred_days', excludedvalues);
//         }
//         setFieldValue('preferred_days', [...values.preferred_days, day]);
//     };

//     const setDate = (dateText: string) => {
//         return dateText.split('T')[0];
//     };

//     const editor = useEditor({
//         extensions: [StarterKit, Underline],
//         content: '', // Initializes with the description field's content from Formik
//         onUpdate: ({ editor }) => {
//             setFieldValue('description', editor.getHTML()); // Syncs the HTML content with Formik
//         },
//     });

//     const debouncedUpdate = useCallback(
//         debounce((content) => {
//             setFieldValue('description', content);
//         }, 300),
//         [] // Dependencies can be adjusted based on your needs
//     );

//     useEffect(() => {
//         if (editor) {
//             editor.on('update', ({ editor }) => {
//                 const html = editor.getHTML();
//                 setEditorContent(html);
//                 debouncedUpdate(html);
//             });
//         }
//     }, [editor, debouncedUpdate]);

//     useEffect(() => {
//         if (editor && values.description !== editorContent) {
//             editor.commands.setContent(values.description);
//         }
//     }, [editor, values.description, editorContent]);

//     return (
//         <form
//             className="h-auto bg-[#F6F7F8] w-full md:w-11/12 mx-auto"
//             onSubmit={handleSubmit}
//             onKeyDown={onKeyDown}
//         >
//             <div className="w-full mt-10 sticky top-0 z-[1] bg-[#F6F7F8] py-4">
//                 <div className="w-11/12 mx-auto">
//                     <h1 className="text-[13px] font-[400]">
//                         <span
//                             className="cursor-pointer"
//                             onClick={() =>
//                                 navigate(`/request/bidding`, {
//                                     state: 'typeForHeaderClick',
//                                 })
//                             }
//                         >
//                             Bidding
//                         </span>
//                         {' > '}My Request
//                     </h1>
//                     <div>
//                         <div className="flex justify-between mt-6">
//                             <h1 className="ml-1 text-[25px] font-[600]">
//                                 Course Details
//                             </h1>
//                             <button
//                                 className="bg-[#065FEF] disabled:opacity-50 rounded-[4px] w-[106px] h-[42px] text-white text-[13px] font-[400] cursor-pointer"
//                                 disabled={Object.keys(errors).length !== 0}
//                                 type="submit"
//                             >
//                                 Submit
//                             </button>
//                         </div>
//                         <div className="border-b-2 mt-2"></div>
//                     </div>
//                 </div>
//             </div>

//             <div className="w-11/12 mx-auto mt-10">
//                 <InputTag
//                     name="title"
//                     value={values.title}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     error={errors.title}
//                     isTouched={touched.title}
//                     type={'text'}
//                     label={'Title'}
//                     className={'h-[55px] mt-4'}
//                 />
//                 <Tags
//                     tags={values.tags}
//                     values={values}
//                     setFieldValue={setFieldValue}
//                     formik={formik}
//                 />
//                 <InputTag
//                     name="address"
//                     value={values.address}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                     error={errors.address}
//                     isTouched={touched.address}
//                     type={'text'}
//                     label={'Venue'}
//                     className={'h-[55px] mt-4'}
//                 />
//                 <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-8">
//                     <InputTag
//                         maxlength="4"
//                         pattern="[1-9][0-9]{3}"
//                         max={'9999-12-31'}
//                         name="start_date"
//                         min={initialDate ? '' : getTomorrowDate()}
//                         value={values.start_date}
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         error={errors.start_date}
//                         isTouched={touched.start_date}
//                         type={'date'}
//                         label={'Preferred Date'}
//                         className={'h-[55px] mt-4'}
//                     />
//                     <InputTag
//                         name="days"
//                         type={'number'}
//                         label={'No. of days'}
//                         className={'h-[55px] mt-4'}
//                         value={values.days}
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         error={errors.days}
//                         isTouched={touched.days}
//                     />
//                 </div>

//                 <DayField
//                     title={'Preferred Days'}
//                     value={values.preferred_days}
//                     onChange={daysHandleChange}
//                     isTouched={touched.preferred_days}
//                     error={errors.preferred_days}
//                 />

//                 <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-8">
//                     <div className="flex flex-col md:flex-row w-full items-start space-y-4 md:space-y-0 md:space-x-8">
//                         <InputTag
//                             name="start_time"
//                             type={'time'}
//                             label={'Start Time'}
//                             value={values.start_time}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             isTouched={touched.start_time}
//                             error={errors.start_time}
//                             className={'h-[55px] mt-4'}
//                         />
//                         <InputTag
//                             name="end_time"
//                             type={'time'}
//                             label={'End Time'}
//                             value={values.end_time}
//                             onChange={handleChange}
//                             onBlur={handleBlur}
//                             isTouched={touched.end_time}
//                             error={errors.end_time}
//                             className={'h-[55px] mt-4'}
//                         />
//                     </div>
//                     <InputTag
//                         name="trainees_count"
//                         value={values.trainees_count}
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         isTouched={touched.trainees_count}
//                         error={errors.trainees_count}
//                         type={'number'}
//                         label={'Head Count'}
//                         className={'h-[55px] mt-4'}
//                     />
//                 </div>

//                 <div className="w-full md:w-1/2">
//                     <p className="text-[15px] font-[500] mb-4">Mode</p>
//                     <div className="flex items-center space-x-3">
//                         <RadioInput
//                             name="mode"
//                             type={'radio'}
//                             label={'Online'}
//                             checked={values.mode === 'online'}
//                             className={'h-[55px] rounded-[8px]'}
//                             onChange={() => setFieldValue('mode', 'online')}
//                         />
//                         <RadioInput
//                             name="mode"
//                             type={'radio'}
//                             label={'Offline'}
//                             checked={values.mode === 'offline'}
//                             className={'h-[55px] rounded-[8px]'}
//                             onChange={() => setFieldValue('mode', 'offline')}
//                         />
//                     </div>
//                 </div>

//                 <div className="flex flex-col mb-12 relative">
//                     <label className="text-sm font-medium">
//                         Additional Notes (If Any)
//                         <span className="ml-1 text-red-600">*</span>
//                     </label>

//                     <div className="bg-white p-4 rounded-lg border border-black mt-4">
//                         {editor && (
//                             <div>
//                                 <MenuBar editor={editor} />
//                                 <EditorContent
//                                     editor={editor}
//                                     className="outline-none p-2 h-full w-full overflow-y-auto break-words"
//                                 />
//                             </div>
//                         )}
//                         {typeof errors.description === 'string' &&
//                             touched.description && (
//                                 <p className="text-red-500 text-xs absolute right-2 bottom-2">
//                                     {errors.description}
//                                 </p>
//                             )}
//                     </div>
//                 </div>
//             </div>
//         </form>
//     );
// };

// const InputTag = ({
//     focus,
//     label,
//     type,
//     className,
//     placeholder,
//     error,
//     isTouched,
//     width,
//     ...rest
// }: any) => {
//     const errorValue = isTouched && error;
//     const isTextarea = ['description'].includes(rest.name);
//     return (
//         <div className={`flex flex-col mb-14 relative ${width ? width : ''}`}>
//             {label ? (
//                 <label className="text-[15px] font-[500]">{label}</label>
//             ) : null}
//             {isTextarea ? (
//                 <textarea
//                     className={`${className} bg-white outline-none rounded-[8px] px-4 py-4 border-[0.7px] border-black`}
//                     {...rest}
//                 />
//             ) : (
//                 <input
//                     autoFocus={focus && true}
//                     placeholder={placeholder ? placeholder : ''}
//                     className={`${className} bg-white outline-none rounded-[8px] px-4 border-[0.7px] border-black`}
//                     type={type}
//                     {...rest}
//                 />
//             )}
//             {errorValue && (
//                 <p className="text-red-500 text-[12px] absolute right-4 bottom-[-30px]">
//                     {error}
//                 </p>
//             )}
//         </div>
//     );
// };

// interface MenuBarProps {
//     editor: Editor | null;
// }

// const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
//     if (!editor) {
//         return null;
//     }

//     return (
//         <div className="menuBar ">
//             <div>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().toggleBold().run()}
//                     className={editor.isActive('bold') ? 'is_active' : ''}
//                 >
//                     <FaBold />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().toggleItalic().run()}
//                     className={editor.isActive('italic') ? 'is_active' : ''}
//                 >
//                     <FaItalic />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleUnderline().run()
//                     }
//                     className={editor.isActive('underline') ? 'is_active' : ''}
//                 >
//                     <FaUnderline />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().toggleStrike().run()}
//                     className={editor.isActive('strike') ? 'is_active' : ''}
//                 >
//                     <FaStrikethrough />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleHeading({ level: 2 }).run()
//                     }
//                     className={
//                         editor.isActive('heading', { level: 2 })
//                             ? 'is_active'
//                             : ''
//                     }
//                 >
//                     <FaHeading />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleHeading({ level: 3 }).run()
//                     }
//                     className={
//                         editor.isActive('heading', { level: 3 })
//                             ? 'is_active'
//                             : ''
//                     }
//                 >
//                     <FaHeading className="heading3" />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleBulletList().run()
//                     }
//                     className={editor.isActive('bulletList') ? 'is_active' : ''}
//                 >
//                     <FaListUl />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() =>
//                         editor.chain().focus().toggleOrderedList().run()
//                     }
//                     className={
//                         editor.isActive('orderedList') ? 'is_active' : ''
//                     }
//                 >
//                     <FaListOl />
//                 </button>
//             </div>
//             <div>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().undo().run()}
//                 >
//                     <FaUndo />
//                 </button>
//                 <button
//                     type="button"
//                     onClick={() => editor.chain().focus().redo().run()}
//                 >
//                     <FaRedo />
//                 </button>
//             </div>
//         </div>
//     );
// };

import React, { useCallback, useEffect, useState } from 'react';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import { DayField } from '../Elements/DayFeild';
import { useFormik } from 'formik';
import { NewBidSchema } from '../../Services/Formik/Formik';
import { convertedTimeToNumber } from '../AddCourse/ConverterTimeToNumber';
import {
    postRecord,
    readRecord,
    updateRecord,
} from '../../Services/backend/apiCalls';
import { useAppDispatch, useAppSelector } from '../..';
import { toast } from 'react-hot-toast';
import { useDebounce } from '../../hooks/DebounceHook';
import { getTomorrowDate, onKeyDown } from '../../Services/commonFunctions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSignValue } from '../../Redux/Reducers/signinUserReducers';
import { RadioInput } from '../AddCourse/RadioInput';

import { debounce } from 'lodash'; // lodash's debounce function
import { Editor } from '@tiptap/core';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';

import {
    FaBold,
    FaHeading,
    FaItalic,
    FaListOl,
    FaListUl,
    FaStrikethrough,
    FaUnderline,
    FaUndo,
    FaRedo,
} from 'react-icons/fa';

// Tags component with mobile responsiveness
export const Tags = ({ tags, setFieldValue, formik }: any) => {
    const [inputValue, setInputValue] = useState('');
    const [isMobile, setIsMobile] = useState(false);

    // Effect to detect screen size and set isMobile flag
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Consider mobile for screens smaller than 768px
        };

        // Initial check
        handleResize();

        // Add event listener for resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleKeyDown = (e: any) => {
        if (!isMobile && e.key === 'Enter' && inputValue.trim() !== '') {
            e.preventDefault();
            addTag(inputValue.trim());
        }
    };

    const addTag = (tag: string) => {
        if (tag && !tags.includes(tag)) {
            setFieldValue('tags', [...tags, tag]);
            setInputValue('');
        }
    };

    const removeTag = (tag: string) => {
        const newTags = tags.filter((t: string) => t !== tag);
        setFieldValue('tags', newTags);
    };

    return (
        <div className="mb-4">
            <label className="text-[15px] font-[500]">Tags</label>
            <div className="flex flex-wrap items-center mt-2 space-x-2">
                {tags.map((tag: string, index: number) => (
                    <div
                        key={index}
                        className="bg-[#085BEB] text-white px-4 py-1 rounded-[15px] flex items-center space-x-2"
                    >
                        <span>{tag}</span>
                        <button
                            type="button"
                            className="ml-2 text-white"
                            onClick={() => removeTag(tag)}
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </div>

            <div className="mt-2 flex space-x-2 relative mb-12">
                <input
                    type="text"
                    className="bg-white outline-none rounded-[8px] px-4 py-2 border-[0.7px] border-black flex-1"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter a tag"
                />

                {/* Conditionally render "Add" button only for mobile screens */}
                {isMobile && (
                    <button
                        type="button"
                        className="bg-[#085BEB] text-white px-4 py-2 rounded-[8px] absolute top-12"
                        onClick={() => addTag(inputValue.trim())}
                    >
                        Add
                    </button>
                )}
            </div>
        </div>
    );
};

export const NewBid = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isEdit = location.pathname.includes('edit');

    const [editorContent, setEditorContent] = useState('');
    const [initialDate, setInitialDate] = useState('');

    const formInput: any = {
        title: '',
        address: '',
        days: 0,
        start_date: '',
        start_time: '',
        end_time: '',
        trainees_count: 0,
        description: '',
        preferred_days: [],
        mode: 'online',
        tags: [],
    };

    const formik = useFormik({
        initialValues: formInput,
        validationSchema: NewBidSchema,
        onSubmit: formSubmitAction,
    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        setValues,
        setFieldValue,
        setFieldError,
    } = formik;

    useEffect(() => {
        if (isEdit) {
            (async () => {
                const bidData = await readRecord({ id: params.id }, 'biddings');
                if (bidData.status && bidData.data) {
                    const {
                        title,
                        address,
                        description,
                        days,
                        preferred_days,
                        trainees_count,
                        start_date,
                        preferred_time,
                        mode,
                        tags,
                    } = bidData.data;

                    setInitialDate(preferred_days);
                    setValues(
                        {
                            ...values,
                            title,
                            address,
                            description,
                            days,
                            preferred_days,
                            mode,
                            trainees_count,
                            start_date: setDate(start_date),
                            tags,
                            start_time: preferred_time.split('-')[0],
                            end_time: preferred_time.split('-')[1],
                        },
                        true
                    );
                } else toast.error(bidData.message);
            })();
        }
    }, []);

    async function formSubmitAction() {
        if (
            values.end_time.length &&
            convertedTimeToNumber(values.end_time) <
                convertedTimeToNumber(values.start_time)
        ) {
            setFieldError(
                'start_time',
                'start time should be less than end time'
            );
            setFieldError(
                'end_time',
                'end time should be greater than start time'
            );
            return;
        }
        const { start_time, end_time, ...rest } = values;
        const bidData = {
            ...rest,
            ...(isEdit && { id: Number(params.id) }),
            preferred_time: start_time + '-' + end_time,
        };

        const bidAPIActionFunctions = isEdit ? updateRecord : postRecord;

        const bidPostData = await bidAPIActionFunctions(bidData, 'biddings');
        if (bidPostData.status) {
            toast.success(isEdit ? 'Bid updated' : 'New Bidding created');
            const navigateRoute = isEdit
                ? {
                      pathname: `/bid/view/${rest.title}/${params.id}`,
                      search: `?status=pending`,
                  }
                : `/bid/view/${bidPostData.data.title}/${bidPostData.data.id}`;
            navigate(navigateRoute, { state: 'typeForDashboard' });
        } else {
            toast.error(bidPostData.message);
        }
    }

    const daysHandleChange = (day: string) => {
        if (values.preferred_days.includes(day)) {
            const excludedvalues = values.preferred_days.filter(
                (item: any) => day !== item
            );
            return setFieldValue('preferred_days', excludedvalues);
        }
        setFieldValue('preferred_days', [...values.preferred_days, day]);
    };

    const setDate = (dateText: string) => {
        return dateText.split('T')[0];
    };

    const editor = useEditor({
        extensions: [StarterKit, Underline],
        content: '', // Initializes with the description field's content from Formik
        onUpdate: ({ editor }) => {
            setFieldValue('description', editor.getHTML()); // Syncs the HTML content with Formik
        },
    });

    const debouncedUpdate = useCallback(
        debounce((content) => {
            setFieldValue('description', content);
        }, 300),
        [] // Dependencies can be adjusted based on your needs
    );

    useEffect(() => {
        if (editor) {
            editor.on('update', ({ editor }) => {
                const html = editor.getHTML();
                setEditorContent(html);
                debouncedUpdate(html);
            });
        }
    }, [editor, debouncedUpdate]);

    useEffect(() => {
        if (editor && values.description !== editorContent) {
            editor.commands.setContent(values.description);
        }
    }, [editor, values.description, editorContent]);

    return (
        <form
            className="h-auto bg-[#F6F7F8] w-full md:w-11/12 mx-auto"
            onSubmit={handleSubmit}
            onKeyDown={onKeyDown}
        >
            <div className="w-full mt-10 sticky top-0 z-[1] bg-[#F6F7F8] py-4">
                <div className="w-11/12 mx-auto">
                    <h1 className="text-[13px] font-[400]">
                        <span
                            className="cursor-pointer"
                            onClick={() =>
                                navigate(`/request/bidding`, {
                                    state: 'typeForHeaderClick',
                                })
                            }
                        >
                            Bidding
                        </span>
                        {' > '}My Request
                    </h1>
                    <div>
                        <div className="flex justify-between mt-6">
                            <h1 className="ml-1 text-[25px] font-[600]">
                                Course Details
                            </h1>
                            <button
                                className="bg-[#065FEF] disabled:opacity-50 rounded-[4px] w-[106px] h-[42px] text-white text-[13px] font-[400] cursor-pointer"
                                disabled={Object.keys(errors).length !== 0}
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                        <div className="border-b-2 mt-2"></div>
                    </div>
                </div>
            </div>

            <div className="w-11/12 mx-auto mt-10">
                <InputTag
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.title}
                    isTouched={touched.title}
                    type={'text'}
                    label={'Title'}
                    className={'h-[55px] mt-4'}
                />
                <Tags
                    tags={values.tags}
                    setFieldValue={setFieldValue}
                    formik={formik}
                />
                <InputTag
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.address}
                    isTouched={touched.address}
                    type={'text'}
                    label={'Venue'}
                    className={'h-[55px] mt-4'}
                />
                <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-8">
                    <InputTag
                        maxlength="4"
                        pattern="[1-9][0-9]{3}"
                        max={'9999-12-31'}
                        name="start_date"
                        min={initialDate ? '' : getTomorrowDate()}
                        value={values.start_date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.start_date}
                        isTouched={touched.start_date}
                        type={'date'}
                        label={'Preferred Date'}
                        className={'h-[55px] mt-4'}
                    />
                    <InputTag
                        name="days"
                        type={'number'}
                        label={'No. of days'}
                        className={'h-[55px] mt-4'}
                        value={values.days}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.days}
                        isTouched={touched.days}
                    />
                </div>

                <DayField
                    title={'Preferred Days'}
                    value={values.preferred_days}
                    onChange={daysHandleChange}
                    isTouched={touched.preferred_days}
                    error={errors.preferred_days}
                />

                <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-8">
                    <div className="flex flex-col md:flex-row w-full items-start space-y-4 md:space-y-0 md:space-x-8">
                        <InputTag
                            name="start_time"
                            type={'time'}
                            label={'Start Time'}
                            value={values.start_time}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isTouched={touched.start_time}
                            error={errors.start_time}
                            className={'h-[55px] mt-4'}
                        />
                        <InputTag
                            name="end_time"
                            type={'time'}
                            label={'End Time'}
                            value={values.end_time}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isTouched={touched.end_time}
                            error={errors.end_time}
                            className={'h-[55px] mt-4'}
                        />
                    </div>
                    <InputTag
                        name="trainees_count"
                        value={values.trainees_count}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isTouched={touched.trainees_count}
                        error={errors.trainees_count}
                        type={'number'}
                        label={'Head Count'}
                        className={'h-[55px] mt-4'}
                    />
                </div>

                <div className="w-full md:w-1/2">
                    <p className="text-[15px] font-[500] mb-4">Mode</p>
                    <div className="flex items-center space-x-3">
                        <RadioInput
                            name="mode"
                            type={'radio'}
                            label={'Online'}
                            checked={values.mode === 'online'}
                            className={'h-[55px] rounded-[8px]'}
                            onChange={() => setFieldValue('mode', 'online')}
                        />
                        <RadioInput
                            name="mode"
                            type={'radio'}
                            label={'Offline'}
                            checked={values.mode === 'offline'}
                            className={'h-[55px] rounded-[8px]'}
                            onChange={() => setFieldValue('mode', 'offline')}
                        />
                    </div>
                </div>

                <div className="flex flex-col mb-12 relative">
                    <label className="text-sm font-medium">
                        Additional Notes (If Any)
                        <span className="ml-1 text-red-600">*</span>
                    </label>

                    <div className="bg-white p-4 rounded-lg border border-black mt-4">
                        {editor && (
                            <div>
                                <MenuBar editor={editor} />
                                <EditorContent
                                    editor={editor}
                                    className="outline-none p-2 h-full w-full overflow-y-auto break-words"
                                />
                            </div>
                        )}
                        {typeof errors.description === 'string' &&
                            touched.description && (
                                <p className="text-red-500 text-xs absolute right-2 bottom-2">
                                    {errors.description}
                                </p>
                            )}
                    </div>
                </div>
            </div>
        </form>
    );
};

// InputTag component remains the same as in your previous code

interface MenuBarProps {
    editor: Editor | null;
}

const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
    if (!editor) {
        return null;
    }

    return (
        <div className="menuBar ">
            <div>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={editor.isActive('bold') ? 'is_active' : ''}
                >
                    <FaBold />
                </button>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={editor.isActive('italic') ? 'is_active' : ''}
                >
                    <FaItalic />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleUnderline().run()
                    }
                    className={editor.isActive('underline') ? 'is_active' : ''}
                >
                    <FaUnderline />
                </button>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    className={editor.isActive('strike') ? 'is_active' : ''}
                >
                    <FaStrikethrough />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 2 }).run()
                    }
                    className={
                        editor.isActive('heading', { level: 2 })
                            ? 'is_active'
                            : ''
                    }
                >
                    <FaHeading />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleHeading({ level: 3 }).run()
                    }
                    className={
                        editor.isActive('heading', { level: 3 })
                            ? 'is_active'
                            : ''
                    }
                >
                    <FaHeading className="heading3" />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleBulletList().run()
                    }
                    className={editor.isActive('bulletList') ? 'is_active' : ''}
                >
                    <FaListUl />
                </button>
                <button
                    type="button"
                    onClick={() =>
                        editor.chain().focus().toggleOrderedList().run()
                    }
                    className={
                        editor.isActive('orderedList') ? 'is_active' : ''
                    }
                >
                    <FaListOl />
                </button>
            </div>
            <div>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().undo().run()}
                >
                    <FaUndo />
                </button>
                <button
                    type="button"
                    onClick={() => editor.chain().focus().redo().run()}
                >
                    <FaRedo />
                </button>
            </div>
        </div>
    );
};

const InputTag = ({
    focus,
    label,
    type,
    className,
    placeholder,
    error,
    isTouched,
    width,
    ...rest
}: any) => {
    const errorValue = isTouched && error;
    const isTextarea = ['description'].includes(rest.name);
    return (
        <div className={`flex flex-col mb-14 relative ${width ? width : ''}`}>
            {label ? (
                <label className="text-[15px] font-[500]">{label}</label>
            ) : null}
            {isTextarea ? (
                <textarea
                    className={`${className} bg-white outline-none rounded-[8px] px-4 py-4 border-[0.7px] border-black`}
                    {...rest}
                />
            ) : (
                <input
                    autoFocus={focus && true}
                    placeholder={placeholder ? placeholder : ''}
                    className={`${className} bg-white outline-none rounded-[8px] px-4 border-[0.7px] border-black`}
                    type={type}
                    {...rest}
                />
            )}
            {errorValue && (
                <p className="text-red-500 text-[12px] absolute right-4 bottom-[-30px]">
                    {error}
                </p>
            )}
        </div>
    );
};

import { RadioInput } from "./RadioInput";
import { DragAndDrop } from "./DragAndDrop";

export const FieldsLeft=({imageKeyURL,role,customize,setResumeStatus,deleteUploadedImage,setImageURL,setPreviewURL,uploadResumeFile,resumeStatus,imageURL,previewUrl,handleChange,handleBlur,touched,values,errors,onChangeInputValue}:any)=>{
    return (
        <div className="w-1/2">
            {/* <InputTag name='duration' label={"Duration"} value={values.duration} onChange={handleChange} onBlur={handleBlur} error={errors.duration} isTouched={touched.duration} className={"h-[55px] mt-4"} /> */}
            <div className="flex flex-col">
                <label className="text-[15px] font-[500]">
                Certificate Guidance
                    <span className="ml-0.5 text-red-600">*</span>
                </label>
                <div className="flex justify-between mt-4 gap-12">
                    <RadioInput
                        name="certification_guidance"
                        type={'radio'}
                        label={'Yes'}
                        checked={values.certification_guidance === true}
                        className={`h-[55px] rounded-[8px]`}
                        onChange={() =>
                            onChangeInputValue('certification_guidance', true)
                        }
                    />
                    <RadioInput
                        name="certification_guidance"
                        type={'radio'}
                        label={'No'}
                        checked={values.certification_guidance === false}
                        className={`h-[55px] rounded-[8px]`}
                        onChange={() =>
                            onChangeInputValue('certification_guidance', false)
                        }
                    />
                </div>
            </div>
            <div className="flex flex-col">
                <label className="text-[15px] font-[500]">
                Discounts (Any)
                    <span className="ml-0.5 text-red-600">*</span>
                </label>
                <div className="flex justify-between mt-4 gap-12">
                    <RadioInput
                        name="discount_available"
                        type={'radio'}
                        label={'Yes'}
                        checked={values.discount_available === true}
                        className={`h-[55px] rounded-[8px]`}
                        onChange={() =>
                            onChangeInputValue('discount_available', true)
                        }
                    />
                    <RadioInput
                        name="discount_available"
                        type={'radio'}
                        label={'No'}
                        checked={values.discount_available === false}
                        className={`h-[55px] rounded-[8px]`}
                        onChange={() =>
                            onChangeInputValue('discount_available', false)
                        }
                    />
                </div>
            </div>
            {/* <InputTag type={"text"} label={"Coupon"} className={"h-[55px] mt-4"} /> */}
            <DragAndDrop
                imageKeyURL={imageKeyURL}
                role={role}
                setResumeStatus={setResumeStatus}
                deleteImg={deleteUploadedImage}
                setPreviewURL={setPreviewURL}
                name="image"
                label={'Thumbnail'}
                value={values.image}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.image}
                isTouched={touched.image}
                className={'h-[236px] mt-4 mb-[50px]'}
                uploadResumeFile={uploadResumeFile}
                resumeStatus={resumeStatus}
                imageURL={imageURL}
                setImageURL={setImageURL}
                previewUrl={previewUrl}
            />
        </div>
    );
}
import { useState } from 'react';
import { useFormik } from 'formik';
import { inputForFL } from './FormikProfile';
import { profileForFL } from '../../Services/Formik/Formik';
import { useAppSelector } from '../..';
import { storage } from '../../Firebase/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import toast from 'react-hot-toast';
import { UserInfo } from './Userinfo';
import { ContactInfo } from './Contactinfo';
import { OtherInfo } from './Otherinfo';
import { BankDetails } from './Bankdetails';
import { Achievements } from './Achievements';

export const FLprofile = ({
    resumeStatus,
    resumefile,
    deleteFile,
    id,
    formik,
    role,
    isViewable,
    email,
    CityInfo,
    search,
    places,
    dropdownVal,
    selectedInputValue,
}: any) => {
    // const [resumeStatus, setResumeStatus] = useState('Browser');
    const { uid: userUID, token } = useAppSelector(
        ({ signin }: any) => signin['signinValue']
    );
    const uid = id ? id : userUID;

    // const uploadResumeFile = async (e: any) => {
    //     const fileName = uid + '_resume';
    //     const file = e.target.files[0];
    //     setResumeStatus('uploading...');
    //     const storageRef = ref(storage, `/resumes/${fileName}`);
    //     const uploadTask = uploadBytesResumable(storageRef, file);
    //     uploadTask.on(
    //         'state_changed',
    //         (snapshot: any) => {},
    //         (err) => console.log(err),
    //         () => {
    //             // download url
    //             getDownloadURL(uploadTask.snapshot.ref).then((url) => {
    //                 formik.setFieldValue('resume_url', url);
    //                 toast.success('Uploaded successfully');
    //                 setResumeStatus('Browser');
    //             });
    //         }
    //     );
    // };

    return (
        <div className="space-y-3">
            <UserInfo
                role={role}
                // uploadResume={uploadResumeFile}
                formik={formik}
                cardTitle={'Personal'}
                isViewable={isViewable}
                resumefile={resumefile}
                deleteFile={deleteFile}
                resumeStatus={resumeStatus}
            />
            <ContactInfo
                isViewable={isViewable}
                role={role}
                formik={formik}
                email={email}
                CityInfo={CityInfo}
                search={search}
                places={places}
                dropdownVal={dropdownVal}
                selectedInputValue={selectedInputValue}
            />
            {!isViewable && <OtherInfo formik={formik} />}
            {!isViewable && <BankDetails formik={formik} />}
            {!isViewable && <Achievements formik={formik} />}
        </div>
    );
};

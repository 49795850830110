import React from 'react';
import { BeatLoader } from 'react-spinners';

export const Checking = () => {
    return (
        <div className="flex justify-center items-center h-screen">
            <BeatLoader size={15} color="#0074FC" />
        </div>
    );
};

import { PayloadAction , createSlice } from "@reduxjs/toolkit";
import { viewTypes, viewState } from "../Types/viewCourseType";

const initialState: viewState = {
    ReviewData: {
        reviews:[]
    }
}

const viewCourseSlice = createSlice({
    name: "view",
    initialState,
    reducers: {
        setViewCourse: (state: viewState, action: PayloadAction<any>) => {
            state.ReviewData = {...state.ReviewData,...action.payload};
        }
    }
})

export const { setViewCourse } = viewCourseSlice.actions;
export default viewCourseSlice.reducer;
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import {
    SignIn,
    storeLoggedUserDetails,
} from './Components/LoginFunctionality/Signin/Signin';
import { SignUp } from './Components/LoginFunctionality/Signup/Signup';
import { SelectRoles } from './Components/LoginFunctionality/SelectRole/SelectRoles';
import { Respond } from './Components/LoginFunctionality/Respond.tsx/Respond';
import { CompletedStatus } from './Components/CompletedStatus/CompletedStatus';
import { LearningPlatform } from './Components/LearningPlatform/LearningPlatform';
import { EmailVerification } from './Components/LoginFunctionality/Signin/EmailVerification';
import { Toaster } from 'react-hot-toast';
import { Illustration } from './Illustration';
import { Profile } from './Components/Profile/Profile';
import { Dashboard } from './Components/Dashboard/Dashboard';
import { AddCourse } from './Components/AddCourse/AddCourse';
import { CourseMenu } from './Components/CourseMenu/CourseMenu';
import { ViewCourse } from './Components/ViewCourse/ViewCourse';
import { RequestPage } from './Components/CMS/RequestPage';
import { NewBid } from './Components/CMS/NewBid';
import { BidDetails } from './Components/CMS/BidDetails';
import { Margin } from './Margin';
import { SearchCourse } from './Components/CMS/SearchCourse';
import { Checkout } from './Components/CMS/Checkout';
import { CompareCourse } from './Components/CourseCompare/CompareCourse';
import { ShowInterest } from './Components/ShowInterest/ShowInterest';
import { NotificationPage } from './Components/Header/NotificationPage';
import { Payment } from './Components/CMS/Payment';
import { useAppSelector } from '.';
import { Cancellation } from './Components/CMS/Cancellation';
import { Loader } from './Components/Elements/Loader';
import { AuthChecker } from './Components/AuthChecker';
import NetworkError from './Components/ErrorRoutes/NetworkError';
import { ConfirmPassword } from './Components/LoginFunctionality/Signin/ConfirmPassword';
import { RespondForPassword } from './Components/LoginFunctionality/Signin/RespondForPassword';
import { ProfileViewPage } from './Components/Profile/ProfileViewPage';
import { Checking } from './Components/LoginFunctionality/Signin/Checking';

import { ConditionsMargin } from './conditionMargin';

import Accordion from './Components/Header/Accordion';

import ResendEmailVerificationLink from './Components/LoginFunctionality/Signin/ResendEmailVerificationLink';
import InternshipDetailTi from './Components/Internship/InternshipDetailTi';
import AddInternship from './Components/Internship/AddInternship';
import InternshipInfo from './Components/Internship/InternshipInfo';
import { AllInternshipsPage } from './Components/Internship/AllInternshipsPage';
import { SearchInternship } from './Components/Internship/SearchInternship';
import { CompareInternshipView } from './Components/Internship/CompareInternshipView';

// Terms & Conditions
const App = () => {
    const { isLoading } = useAppSelector(
        ({ signin }: any) => signin.signinValue
    );
    // const user = useAuth();
    // const location = useLocation();
    // console.log(location.pathname);
    // console.log(user);
    return (
        <div className="font-poppins">
            <Toaster />
            {isLoading && <Loader />}
            <Routes>
                {/* <Route path="/" element={<Navigate to={"/dashboard"}/>} /> */}
                <Route
                    path="/confirm-password"
                    element={
                        <Illustration>
                            <ConfirmPassword />
                        </Illustration>
                    }
                />
                <Route path="/" element={<AuthChecker />} />

                {/* <Route path="/" element={<Illustration><SignIn /></Illustration>} /> */}
                <Route path="/networkerror" element={<NetworkError />} />

                <Route
                    path="/login"
                    element={
                        <Illustration>
                            <SignIn />
                        </Illustration>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <Illustration>
                            <SignUp />
                        </Illustration>
                    }
                />
                <Route path="/terms-condition" element={<ConditionsMargin />} />
                <Route
                    path="/role"
                    element={
                        <Illustration>
                            <SelectRoles />
                        </Illustration>
                    }
                />
                <Route
                    path="/respond"
                    element={
                        <Illustration>
                            <Respond />
                        </Illustration>
                    }
                />
                <Route path="/checking" element={<Checking />} />

                <Route
                    path="/respondforpassword"
                    element={
                        <Illustration>
                            <RespondForPassword />
                        </Illustration>
                    }
                />
                <Route
                    path="/register/status"
                    element={
                        <Illustration>
                            <CompletedStatus />
                        </Illustration>
                    }
                />
                <Route
                    path="/register/personal"
                    element={
                        <Illustration>
                            <LearningPlatform />
                        </Illustration>
                    }
                />
                <Route
                    path="/forgot-password"
                    element={
                        <Illustration>
                            <EmailVerification />
                        </Illustration>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <Margin>
                            <Profile />
                        </Margin>
                    }
                />
                <Route
                    path="/profile/view/:uid"
                    element={
                        <Margin>
                            <ProfileViewPage />
                        </Margin>
                    }
                />
                {/* <Route
                    path="/profile/view/:uid"
                    element={
                        <Margin>
                            <ProfileViewPage />
                        </Margin>
                    }
                /> */}
                {/* <Route path="/profile/view/:id" element={<Margin><Profile /></Margin>} /> */}
                <Route
                    path="/dashboard"
                    element={
                        <Margin>
                            <Dashboard />
                        </Margin>
                    }
                />
                <Route
                    path="/course/add"
                    element={
                        <Margin>
                            <AddCourse />
                        </Margin>
                    }
                />
                {/* <Route path="/course/edit/:id" element={<Margin><AddCourse /></Margin>} /> */}
                <Route
                    path="/course/customize/:id"
                    element={
                        <Margin>
                            <AddCourse />
                        </Margin>
                    }
                />
                <Route
                    path="/booknow/:title/:id"
                    element={
                        <Margin>
                            <ViewCourse />
                        </Margin>
                    }
                />
                <Route
                    path="/courses"
                    element={
                        <Margin>
                            <CourseMenu />
                        </Margin>
                    }
                />
                <Route
                    path="/course/view/:title/:id"
                    element={
                        <Margin>
                            <ViewCourse />
                        </Margin>
                    }
                />
                <Route
                    path="/course/edit/:title/:id"
                    element={
                        <Margin>
                            <AddCourse />
                        </Margin>
                    }
                />

                {/* <Route path="/course/view/:id" element={<Margin><ViewCourse /></Margin>} /> */}
                <Route
                    path="/course/:title/documents/:id"
                    element={
                        <Margin>
                            <ViewCourse />
                        </Margin>
                    }
                />

                <Route
                    path="/request"
                    element={
                        <Margin>
                            <RequestPage />
                        </Margin>
                    }
                />
                <Route
                    path="/request/:requestorigin"
                    element={
                        <Margin>
                            <RequestPage />
                        </Margin>
                    }
                />
                <Route
                    path="/bid/add"
                    element={
                        <Margin>
                            <NewBid />
                        </Margin>
                    }
                />
                {/* <Route path="/bid/edit/:id" element={<Margin><NewBid /></Margin>} /> */}
                <Route
                    path="/bid/edit/:title/:id"
                    element={
                        <Margin>
                            <NewBid />
                        </Margin>
                    }
                />

                <Route
                    path="/bid/view/:title/:id"
                    element={
                        <Margin>
                            <BidDetails />
                        </Margin>
                    }
                />

                <Route
                    path="/bid/view/:id"
                    element={
                        <Margin>
                            <BidDetails />
                        </Margin>
                    }
                />

                <Route
                    path="/course/search"
                    element={
                        <Margin>
                            <SearchCourse />
                        </Margin>
                    }
                />
                <Route
                    path="/checkout"
                    element={
                        <Margin>
                            <Checkout />
                        </Margin>
                    }
                />
                {/* <Route path */}
                {/* <Route path="/book-now" element={<Margin><BooknowPopup /></Margin>} /> */}
                <Route
                    path="/comparing-course"
                    element={
                        <Margin>
                            <CompareCourse />
                        </Margin>
                    }
                />
                <Route
                    path="/show-interest"
                    element={
                        <Margin>
                            <ShowInterest />
                        </Margin>
                    }
                />
                <Route
                    path="/notification"
                    element={
                        <Margin>
                            <NotificationPage />
                        </Margin>
                    }
                />
                <Route
                    path="/cancellation"
                    element={
                        <Margin>
                            <Cancellation />
                        </Margin>
                    }
                />
                <Route path="/payment" element={<Payment />} />
                <Route
                    path="/opportunity"
                    element={
                        <Margin>
                            <Accordion />
                        </Margin>
                    }
                />
                <Route
                    path="/email-verification"
                    element={
                        <Illustration>
                            <ResendEmailVerificationLink />
                        </Illustration>
                    }
                />
                <Route
                    path="/internshipTi/:id"
                    element={
                        <Margin>
                            <InternshipDetailTi />
                        </Margin>
                    }
                />
                <Route
                    path="/edit-internship/:id"
                    element={
                        <Margin>
                            <AddInternship />
                        </Margin>
                    }
                />
                <Route
                    path="/add-internship"
                    element={
                        <Margin>
                            <AddInternship />
                        </Margin>
                    }
                />
                <Route
                    path="/internship-info/:id"
                    element={
                        <Margin>
                            {/* <InternshipInformationPage /> */}
                            <InternshipInfo />
                        </Margin>
                    }
                />
                <Route
                    path="/all-intership"
                    element={
                        <Margin>
                            <AllInternshipsPage />
                        </Margin>
                    }
                />
                <Route
                    path="/internships/search"
                    element={
                        <Margin>
                            <SearchInternship />
                        </Margin>
                    }
                />

                <Route
                    path="/comparing-internships"
                    element={
                        <Margin>
                            <CompareInternshipView />
                        </Margin>
                    }
                />
            </Routes>
        </div>
    );
};

export default App;

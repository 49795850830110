import React, { useRef } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

interface buttonInterface{
  buttonText: string,
  rest: any,
  action: any,
  ref:any
}

export const Button = ({onClick, btnName, className, disabled, type}:any) => {
  return (
    <button className={`${className} ${disabled ? "bg-[#435a70]" : ""}`} type={type ? type : ""} disabled={disabled ? disabled : false} onClick={onClick}>{btnName}</button>
  )
}


export const ButtonCompWithActionController = ({
    buttonText,
    Ref,
    action,
    ...rest
}: any) => {
    const navigate = useNavigate();
    const clickAction = async () => {
        Ref.current.disabled = true;
        await action();
        if (Ref.current !== null) {
            Ref.current.disabled = false;
        }
    };
    return (
        <div className="flex items-center gap-2">
            <button ref={Ref} {...rest} onClick={clickAction}>
                {buttonText}
            </button>
        </div>
    );
};
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { readRecord } from '../../Services/backend/apiCalls';
import { DashBoardLoader } from '../Dashboard/NoDataChecker';
import arrow from '../../assets/Images/RightArrow.svg';

interface Internship {
    application_deadline: string | number | Date;
    id: number;
    title: string;
    company: string;
    createdAt: string;
    description: string;
    thumbnail?: string;
    internship_type?: string;
    user_idSt: string[] | null;
    start_date: string;
}

// Inline custom CSS for line clamping
const lineClampStyle = `
  .line-clamp-2 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
`;

export const AllInternshipsPage = () => {
    const [internships, setInternships] = useState<Internship[]>([]);
    const [userId, setUserId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserId = localStorage.getItem('uid');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);

    useEffect(() => {
        const fetchInternships = async () => {
            setLoading(true);
            try {
                const data = await readRecord({}, 'internships');
                const currentDate = new Date();

                // Filter internships based on application_deadline
                const activeInternships = data.filter(
                    (internship: Internship) => {
                        return (
                            new Date(internship.application_deadline) >
                            currentDate
                        );
                    }
                );

                setInternships(activeInternships);
                console.log(activeInternships);
            } catch (error) {
                console.error('Error fetching internships:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInternships();
    }, []);
    if (loading) {
        return (
            <div className="w-11/12 mx-auto py-6 min-h-screen flex flex-col items-center justify-center">
                <div className="w-16 h-16 border-4 border-t-blue-500 border-b-blue-500 rounded-full animate-spin"></div>
                <p className="mt-4 text-lg text-gray-600">
                    Loading internships...
                </p>
            </div>
        );
    }
    return (
        <>
            {/* Inject custom CSS into the component */}

            <style>{lineClampStyle}</style>

            {
                <div className="w-11/12 mx-auto py-6  ">
                    <h1 className="text-[35px] font-[500] text-center mt-6">
                        All Internships
                    </h1>
                    <div className="flex gap-2 items-center  mt-5 w-full mx-auto cursor-pointer mb-4">
                        <img
                            src={arrow}
                            alt="ArrowForBack"
                            className="rotate-180 w-[17px] h-[17px]"
                            onClick={() => navigate(-1)}
                        />
                        <p
                            className="text-[16px] sm:text-[18px] font-[400] text-[#3061F4]"
                            onClick={() => navigate(-1)}
                        >
                            Back
                        </p>
                    </div>
                    <div className="mt-6">
                        {internships.map((internship) => {
                            const hasApplied: boolean = !!(
                                userId &&
                                Array.isArray(internship.user_idSt) &&
                                internship.user_idSt.includes(userId)
                            );

                            return (
                                <div
                                    key={internship.id}
                                    className="bg-white p-6 rounded-lg shadow-lg mb-6 flex flex-col sm:flex-row justify-between items-center"
                                >
                                    <div className="flex flex-col sm:flex-row items-center sm:items-start sm:space-x-6 w-full">
                                        <img
                                            src={
                                                internship.thumbnail ||
                                                'https://via.placeholder.com/150?text=No+Image'
                                            }
                                            alt={internship.title}
                                            className="w-full sm:w-24 h-40 sm:h-24 object-cover rounded-lg mb-4 sm:mb-0"
                                        />
                                        <div className="text-center sm:text-left w-full">
                                            <h2 className="text-lg sm:text-[24px] font-[500] truncate">
                                                {internship.title}
                                            </h2>
                                            <p className="text-gray-600 text-sm sm:text-base truncate">
                                                {internship.company ||
                                                    'No company info'}
                                            </p>
                                            {/* <p className="text-gray-600 text-sm sm:text-base truncate">
                                                {internship.internship_type ||
                                                    'No internship type'}
                                            </p> */}
                                            <p className="text-gray-600 text-sm sm:text-base truncate">
                                                {internship.internship_type ===
                                                'Stipend'
                                                    ? 'Paid (With Stipend)'
                                                    : internship.internship_type ===
                                                      'Paid'
                                                    ? 'Paid (By Student)'
                                                    : internship.internship_type ===
                                                      'Free'
                                                    ? 'Free'
                                                    : 'No internship type'}
                                            </p>
                                            {/* <p className="text-gray-600 text-sm sm:text-base">
                                                Start Date{' '}
                                                {new Date(
                                                    internship.createdAt
                                                ).toLocaleDateString()}
                                            </p> */}
                                            <p className="text-gray-500">
                                                Started Date on:{' '}
                                                {(() => {
                                                    const date =
                                                        internship.start_date;
                                                    if (!date)
                                                        return 'No date provided';

                                                    const parsedDate = new Date(
                                                        date
                                                    );
                                                    return parsedDate.getTime() >
                                                        0
                                                        ? parsedDate.toDateString()
                                                        : 'No date provided';
                                                })()}
                                            </p>
                                            <p className="text-gray-600 text-sm sm:text-base line-clamp-2">
                                                {internship.description ||
                                                    'No description available'}
                                            </p>
                                        </div>
                                    </div>

                                    <button
                                        className={`mt-4 sm:mt-0 py-2 px-4 text-white font-semibold rounded-md w-full sm:w-auto ${
                                            hasApplied
                                                ? 'bg-gray-400 cursor-not-allowed'
                                                : 'bg-gradient-to-r from-[#04D5BD] to-[#267DF8]'
                                        } flex items-center justify-center`}
                                        onClick={() =>
                                            !hasApplied &&
                                            navigate(
                                                `/internship-info/${internship.id}`
                                            )
                                        }
                                        disabled={hasApplied}
                                    >
                                        <span className="truncate">
                                            {hasApplied
                                                ? 'Already Applied'
                                                : 'Apply'}
                                        </span>
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
        </>
    );
};

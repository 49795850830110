import React, {useState} from 'react';

export const Dropdown = ({role, label, labeldesign, Cities, onChange, val, disabled, ...rest}:any) => {
  const [countryName, setCountryName] = useState([]);
  return (
      <div
          className={`flex flex-col justify-between ${
              role === 'FL' && 'w-5/12'
          }`}
      >
          <label className={`${labeldesign ? labeldesign : 'text-lg'}`}>
              {label}
          </label>
          <select
              disabled={disabled}
              className={`capitalize border-[0.7px] border-black py-2.5 p-1 rounded-lg ${
                  disabled && 'bg-[#F6F7F8] opacity-60'
              } ${labeldesign ? labeldesign : 'text-lg'}`}
              value={val}
              onChange={onChange}
              {...rest}
          >
              {Cities.map((val: any, i: any) => {
                  //   console.log(val, "val")
                  return (
                      <option className="border" key={i} value={val}>
                          {val}
                      </option>
                  );
              })}
          </select>
      </div>
  );
}

import { PayloadAction , createSlice } from "@reduxjs/toolkit";
import { zipcodeTypes, zipcodeState } from "../Types/zipcodeType";

const initialState: zipcodeState = {
    zipcodeValue: {
        city: "",
        state: "",
        country: ""
    }
}

const zipcodeSlice = createSlice({
    name: "zipcode",
    initialState,
    reducers: {
        setZipcode: (state: zipcodeState, action: PayloadAction<zipcodeTypes>) => {
            state.zipcodeValue = action.payload;
        }
    }
})

export const { setZipcode } = zipcodeSlice.actions;
export default zipcodeSlice.reducer;
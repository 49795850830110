import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { skillsTypes, skillsState } from '../Types/skillBasedSearchType';

const initialState: skillsState = {
    skillBasedSearchData: {
        skills: '',
        skillBasedResponse: [],
    }, 
};

const compareCourseSlice = createSlice({
    name: 'skill',
    initialState,
    reducers: {
        setSkillSearch: (state: skillsState, action: PayloadAction<any>) => {
            state.skillBasedSearchData = {
                ...state.skillBasedSearchData,
                ...action.payload,
            };
        },
    },
});

export const { setSkillSearch } = compareCourseSlice.actions;
export default compareCourseSlice.reducer;

import React, { useEffect } from 'react'
import { toast } from 'react-hot-toast';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { PulseLoader } from 'react-spinners';
import { readRecord } from '../../Services/backend/apiCalls';

export const Payment = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const order_id: string = searchParams.get("order_id") || "";
    // console.log(order_id);
    useEffect(() => {
        if (order_id) {
            (async () => {
            const orderDetails = await readRecord({ order_id }, 'orders');
            // console.log(orderDetails); 
            if (orderDetails.status &&orderDetails.paymentDetails[0].payment_status === 'SUCCESS') {
                toast.success(
                    orderDetails.paymentDetails[orderDetails.paymentDetails.length-1]
                        .payment_message
                );
                navigate('/dashboard');
            }
            else {
                navigate({
                    pathname: "/checkout",
                    search: createSearchParams({
                        id: orderDetails?.orderDetails?.order_tags?.origin_id,
                        checkoutOrigin:
                            orderDetails?.orderDetails?.order_tags?.origin,
                    }).toString()
                })
                toast.error(
                    orderDetails.paymentDetails[
                        orderDetails.paymentDetails.length - 1
                    ].error_details?.error_description
                );
            }

            
        })()
        }
    }, [])
    // if (!order_id) {
    //     console.log("hey");
    //     return 
    // }
    return (
        <>
           {/* <div id='paymentForm'></div> */}
        <div className="h-[100vh] flex justify-center items-center">
            <PulseLoader color="#0074FC" />
        </div>
      
      </>
  );
}

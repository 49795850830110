import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../..";
import {   postRecord, updateRecord } from "../../Services/backend/apiCalls";
import toast from "react-hot-toast";
import unfilledstar from '../../assets/Images/unfilledstar.svg';
import filledstar from '../../assets/Images/filledstar.svg';
import { camelCaseConvertForErrorMessage } from "../../Services/commonFunctions";
import { PulseLoader } from 'react-spinners';

export const SendReview = ({
    addNewPost,
    id,
    input,
    setInput,
    rating,
    setRating,
    checkPostMethod,
    hover,
    setHover,
    updateReview,
    loading,
    setLoading
}: any) => {
    const navigate = useNavigate();
    // const [input, setInput] = useState('');
    // const [loading, setLoading] = useState(false);
    const { username } = useAppSelector(
        ({ signin }: any) => signin['signinValue']
    );
    //   const [rating, setRating] = useState(0);
    // const [hover, setHover] = useState(0);

    const PostReview = async () => {
        if (rating <= 0) {
            toast.error("Please rate us");
            return
        }
        setLoading(true);
        const body = {
            rating: rating,
            description: input,
            course_id: Number(id),
        };
        const ReviewsPosting = await postRecord(body, 'reviews');
        if (ReviewsPosting.status) {
            toast.success(ReviewsPosting.message);
            addNewPost(ReviewsPosting.data);
            // setTextareaHeight(true);
            setInput('');
            setRating(0);
            setHover(0);
        } else toast.error(ReviewsPosting.message);
        setLoading(false);
        // }
    };

    // const updateReview = async () => {
    //     const body = {
    //         id: updateId,
    //         rating: rating,
    //         description: input,
    //     };
    //     console.log(body, "body")
    //     const ReviewsUpdate = await updateRecord(body, 'reviews');
    //     if (ReviewsUpdate) {
    //         toast.success(ReviewsUpdate.message);
    //         setInput('');
    //         setRating(0);
    //         setHover(0);
    //         setCheckPostMethod(false)
    //     } else toast.error(ReviewsUpdate.message);
    // };

    return (
        <div className="flex justify-between mt-6">
            <div className="flex gap-6 w-10/12">
                <div className="flex gap-4">
                    <div className="h-[80px] flex items-end">
                        <p className="w-[45px] h-[45px] bg-black flex items-center justify-center text-white rounded-full">
                            {username?.charAt(0)}
                        </p>
                    </div>
                    <div className="flex flex-col items-start justify-between">
                        <h1 className="text-[16px] font-[300] text-[#979797]">
                            Write a review
                        </h1>
                        <textarea
                            placeholder="Type a review"
                            className={`min-h-[50px] w-[200px] xl:w-[357px] border p-2.5 rounded-[8px] break-words`}
                            value={input}
                            onChange={(e: any) => setInput(e.target.value)}
                        ></textarea>
                    </div>
                </div>
                <div className="h-[80px] flex flex-col items-start justify-between">
                    <h1 className="text-[16px] font-[300] text-[#979797]">
                        Rate a review
                    </h1>
                    <div className={`flex items-center space-x-1 h-[45px]`}>
                        {[...Array(5)].map((star, index) => {
                            index += 1;
                            return (
                                <img
                                    alt="star"
                                    onMouseEnter={() => setHover(index)}
                                    onClick={() => setRating(index)}
                                    onMouseLeave={() => setHover(rating)}
                                    src={
                                        index <= (hover || rating)
                                            ? filledstar
                                            : unfilledstar
                                    }
                                    className="w-[18px] h-[18px] cursor-pointer"
                                    key={index}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="flex items-end justify-center h-[80px]">
                {loading ? (
                    <div className="bg-[#3061F4] w-[99px] h-[40px] rounded-[4px] flex justify-center items-center">
                        <PulseLoader color="#ffffff" size={8} />
                    </div>
                ) : (
                    <button
                        className="bg-[#3061F4] w-[99px] h-[40px] rounded-[4px] text-white text-[14px] font-[500]"
                        onClick={checkPostMethod?updateReview:PostReview}
                    >
                        {checkPostMethod?'Update':'POST'}
                    </button>
                )}
            </div>
        </div>
    );
};
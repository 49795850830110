export const InputJSON: any = {
    TI: {
        name: '',
        country: '',
        state: '',
        branch_id: '',
        city: '',
        address_line: '',
        contact_number: '',
        industry: '',
        url: '',
        achievements: 'Teaching institute with excellent results',
        gst_number: '',
        // profilePicture: { name: '', key: '', url: '' },
    },
    FL: {
        first_name: '',
        last_name: '',
        contact_number: '',
        country: '',
        state: '',
        city: '',
        linkedin_url: '',
        industry: '',
        resume: { name: '', key: '', url: '' },
        address_line: '',
        skills: '',
        profilePicture: { name: '', key: '', url: '' },
    },
    CO: {
        organization_name: '',
        contact_number: '',
        country: '',
        state: '',
        city: '',
        company_url: '',
        address_line: '',
        // profilePicture: { name: '', key: '', url: '' },
    },
    ST: {
        first_name: '',
        last_name: '',
        contact_number: '',
        country: '',
        state: '',
        city: '',
        address_line: '',
        // profilePicture: { name: '', key: '', url: '' },
    },
};

import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../..';
import { StarRating } from '../Elements/StarRating';
import { SendReview } from './SendReview';
import { RoleCard } from '../CMS/Role';
import moment from 'moment';
import { setViewCourse } from '../../Redux/Reducers/viewCourseReducers';
import { ReplayFeedback } from './FeedbackReply';
import { DeleteButton } from './Buttons';
import { EditButton } from './Buttons';
import { updateRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';

export const dateFormat = (date: any) => {
    let datetext;
    datetext = moment(date).format('DD-MMM-YYYY  h:mm A');
    return datetext;
};

export const Review = ({
    trainer_id,
    role,
    id,
    reviewsData,
    setReviewsData,
    setSelectUserId,
    selectUserId,
    callDeleteFunc,
    removeReviewId,
    reviewShow,
}: any) => {
    // console.log({role,reviewShow});
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [clickedReplyId, setClickedReplyId] = useState(null);
    const [input, setInput] = useState('');
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [checkPostMethod, setCheckPostMethod] = useState(false);
    const [updateId, setUpdateId] = useState(null);
    const [loading, setLoading] = useState(false);
    // const [reviewStatus, setReviewStatus] = useState(data);
    const { token, uid, username } = useAppSelector(
        ({ signin }: any) => signin['signinValue']
    );
    const { reviews } = useAppSelector(({ view }: any) => view['ReviewData']);  
    // console.log({reviews});
    const userRole = ['ST', 'CO'].includes(role);

    const addNewPost = (data: any) => {
        const bodyNEW = {
            ...data,
            Trainee: {
                user_id: data.user_id,
                name: username,
                role: role,
            },
        };
        // const addedReview = [...reviewsData];
        const addedReview = [...reviews];
        addedReview.unshift(bodyNEW);
        // setReviewsData([...addedReview])
        dispatch(setViewCourse({ reviews: [...addedReview] }));
    };

    // const removeReviewId = (ID: any) => {
    //     const removedIds = reviews.filter((item: any) => item['id'] !== ID);
    //     console.log(removedIds);
    //     dispatch(setViewCourse({ reviews: [...removedIds] }));
    // };

    const updateReview = async () => {
        const index = reviews.findIndex((idx: any) => idx.id === updateId);
        if (rating <= 0) {
            toast.error("Please rate us");
            return 
        }
        setLoading(true);
        const body = {
            id: updateId,
            rating: rating,
            description: input,
        };
        const ReviewsUpdate = await updateRecord(body, 'reviews');
        // console.log(ReviewsUpdate, 'ReviewsUpdate');
        if (ReviewsUpdate.status) {
            toast.success(ReviewsUpdate.message);
            const updatedObj = {
                ...reviews[index],
                rating: rating,
                description: input,
            };
            const updatedArray = [
                ...reviews.slice(0, index),
                updatedObj,
                ...reviews.slice(index + 1),
            ];
            dispatch(setViewCourse({ reviews: updatedArray }));
            setInput('');
            setRating(0);
            setHover(0);
            setCheckPostMethod(false);
        } else toast.error(ReviewsUpdate.message);
        setLoading(false);
    };

    const callEditFunc = (status: any) => {
        setInput(status.description);
        setRating(status.rating);
        setCheckPostMethod(true);
        setUpdateId(status.id);
        // console.log(status, 'status');
    };

    // useEffect(() => {
    //     console.log(userRole && reviewShow);
    // }, [userRole || reviewShow]);
    return (
        <div className="bg-white mt-10">
            <div className="bg-[#F3F7FB] flex flex-col items-center justify-center">
                <h1 className="text-[18px] font-[600] text-[#2D5EF4]">
                    Review
                </h1>
                <div className="border-b-4 border-[#2D5EF4] w-full mt-4"></div>
            </div>
            <div className="w-11/12 mx-auto py-6">
                {reviews && reviews.length > 0 && (
                    <h1 className="text-[20px] font-[500] mt-4">All Reviews</h1>
                )}
                {userRole && reviewShow && (
                    <SendReview
                        addNewPost={addNewPost}
                        id={id}
                        input={input}
                        setInput={setInput}
                        rating={rating}
                        setRating={setRating}
                        checkPostMethod={checkPostMethod}
                        hover={hover}
                        setHover={setHover}
                        updateReview={updateReview}
                        loading={loading}
                        setLoading={setLoading}
                    />
                )}
                {!reviews?.length && (
                    <p className={`text-center text-[#8B8B8B] mt-10`}>
                        No reviews
                    </p>
                )}
                {reviews &&
                    reviews.map((status: any, i: number) => {
                        return (
                            <div key={i} className="mt-16">
                                <div className="mt-8 flex justify-between items-start">
                                    <div className="flex space-x-3 items-center">
                                        <div className="w-[45px] h-[45px] bg-black rounded-full text-white flex items-center justify-center">
                                            {status['Trainee']['name'][0]}
                                        </div>
                                        <div className="flex flex-col">
                                            <div className="flex items-center">
                                                <h1 className="text-[18px] font-[600]">
                                                    {/* {status['Trainee']['name']} */}
                                                    {uid === status['user_id']
                                                        ? 'You'
                                                        : status['Trainee'][
                                                              'name'
                                                          ]}
                                                </h1>
                                                <RoleCard
                                                    role={
                                                        status['Trainee'][
                                                            'role'
                                                        ]
                                                    }
                                                />
                                            </div>
                                            <p>
                                                {dateFormat(
                                                    status['createdAt']
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <StarRating
                                            isClickable={false}
                                            rate={Math.floor(status['rating'])}
                                            noPointer={'noPointer'}
                                        />
                                        <p className="text-[#8B8B8B] text-[16px] font-[300]">
                                            {status['rating']}
                                        </p>
                                    </div>
                                </div>
                                <div className="w-11/12 xl:w-11/12 mx-auto mt-4 ml-14">
                                    <p className="text-[14px] font-[400] min-h-[30px] h-auto text-[#8B8B8B]">
                                        {status['description'] === ''
                                            ? 'no description'
                                            : status['description']}
                                    </p>
                                    {uid === status['user_id'] && (
                                        <div className="flex gap-4">
                                            {/* <EditButton status={status} /> */}
                                            <h1
                                                className="text-[#267DF8] text-[14px] font-[500] mt-4 cursor-pointer"
                                                // onClick={deleteReviewDetails}
                                                onClick={() =>
                                                    callEditFunc(status)
                                                }
                                            >
                                                Edit
                                            </h1>
                                            <h1
                                                className="text-[#267DF8] text-[14px] font-[500] mt-4 cursor-pointer"
                                                // onClick={deleteReviewDetails}
                                                onClick={() =>
                                                    callDeleteFunc(status)
                                                }
                                            >
                                                Delete
                                            </h1>
                                        </div>
                                    )}
                                    {status.admin_id === trainer_id && (
                                        <ReplayFeedback
                                            index={i}
                                            addNewPost={addNewPost}
                                            removeReviewId={removeReviewId}
                                            id={id}
                                            userRole={userRole}
                                            clickedReplyId={clickedReplyId}
                                            setClickedReplyId={
                                                setClickedReplyId
                                            }
                                            status={status}
                                            checkUser={
                                                uid === status['admin_id']
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export const InputTag=({focus,formik,label,className,placeholder,error,isTouched,value,Tagwidth,disabled,...rest}:any)=>{
    const errorValue = isTouched && error;
    const isTextAreaInput = ["Highlights", "Description"].includes(label);
    return (
        <div className={`flex flex-col mb-12 relative ${Tagwidth && Tagwidth}`}>
            {label ? (
                <label className="text-[15px] font-[500]">
                    {label}
                    <span
                        className={`ml-0.5 ${
                            label !== 'Highlights' && 'text-red-600'
                        }`}
                    >
                        {label !== 'Highlights' && '*'}
                        {/* {label === 'Discount Available' && value
                            ? label === 'Discount Percentage' && '*'
                            : ''} */}
                    </span>
                </label>
            ) : null}
            {!isTextAreaInput && (
                <input
                    autoFocus={focus && true}
                    placeholder={placeholder ? placeholder : ''}
                    value={value}
                    disabled={disabled ? disabled : null}
                    className={`${className} ${
                        disabled && 'opacity-50'
                    } bg-white rounded-[8px] px-4 border-[0.7px] border-black ${
                        placeholder
                            ? 'placeholder:text-start pr-4 text-[15px] font-[500]'
                            : ''
                    }`}
                    {...rest}
                />
            )}
            {isTextAreaInput && (
                <textarea
                    disabled={disabled ? disabled : null}
                    className={`${className} border-[0.7px] border-black`}
                    {...rest}
                    value={value}
                >
                    {value}
                </textarea>
            )}
            {errorValue && (
                <p className="text-red-500 text-[12px] absolute right-4 bottom-[-30px]">
                    {error}
                </p>
            )}
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import arrow from '../../assets/Images/RightArrow.svg';
import { readRecord, deleteRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import { ModalBox } from '../UI_Modal/UI_Modal';

const InternshipDetailTi = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [internship, setInternship] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false); // State for modal visibility

    useEffect(() => {
        const fetchInternshipDetails = async () => {
            setLoading(true); // Start loading
            try {
                const data = await readRecord({}, `ti-internships/${id}`);
                if (data.success) {
                    setInternship(data.data);
                    console.log(data.data);
                } else {
                    setError('Internship not found.');
                }
            } catch (error) {
                console.error('Error fetching internship details:', error);
                setError(
                    'Failed to fetch internship details. Please try again later.'
                );
                toast.error('Failed to fetch internship details.'); // Optional toast notification for errors
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchInternshipDetails();
    }, [id]);

    const handleEdit = () => {
        navigate(`/edit-internship/${id}`);
    };

    // Show modal instead of direct confirmation
    const openDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = async (flag) => {
        setShowDeleteModal(false);
        if (flag === 'yes') {
            try {
                const data = await deleteRecord({}, `ti-internships/${id}`);
                toast.success('Internship deleted successfully');
                navigate(-1);
            } catch (error) {
                console.error('Error deleting internship:', error);
                toast.error('Failed to delete the internship.');
            }
        }
    };

    const handleDelete = async () => {
        const confirmDelete = window.confirm(
            'Are you sure you want to delete this internship?'
        );
        if (confirmDelete) {
            try {
                const data = await deleteRecord({}, `ti-internships/${id}`);
                toast.success('Internship deleted successfully');
                navigate(-1);
            } catch (error) {
                console.error('Error deleting internship:', error);
                toast.error('Failed to delete the internship.');
            }
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-blue-600 h-12 w-12 mb-24"></div>
            </div>
        );
    }

    if (error) {
        return <p className="text-red-500 text-center">{error}</p>;
    }

    return (
        <div className="p-6 bg-gray-50 min-h-screen">
            <div className="flex gap-2 items-center mt-5 w-full mx-auto cursor-pointer mb-4">
                <img
                    src={arrow}
                    alt="ArrowForBack"
                    className="rotate-180 w-[17px] h-[17px]"
                    onClick={() => navigate(-1)}
                />
                <p
                    className="text-[16px] sm:text-[18px] font-[400] text-[#3061F4]"
                    onClick={() => navigate(-1)}
                >
                    Back
                </p>
            </div>
            <div className="flex justify-between items-center mb-4 ">
                <h2 className="text-xl font-bold">Internship Details</h2>

                <div className="flex items-center">
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                        onClick={handleEdit}
                    >
                        Edit
                    </button>
                    <button
                        className="bg-red-500 text-white px-4 py-2 rounded"
                        // onClick={handleDelete}
                        onClick={openDeleteModal} // Open delete modal
                    >
                        Delete
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                {/* Left Section */}
                <div className="lg:col-span-2">
                    {internship.thumbnail ? (
                        <img
                            src={internship.thumbnail}
                            alt={internship.title}
                            className="w-full h-60 object-cover rounded-lg mb-4"
                        />
                    ) : (
                        <div className="w-full h-60 bg-gray-200 rounded-lg mb-4 flex items-center justify-center">
                            <span className="text-gray-500">
                                No Image Available
                            </span>
                        </div>
                    )}
                    <h1 className="text-3xl font-bold mb-2 mt-14">
                        {internship.title || 'No Title Provided'}
                    </h1>
                    <p className="text-gray-600 mb-4">
                        <strong>Company Name: </strong>{' '}
                        {internship.company || 'No Company Provided'}
                    </p>
                    <p className="mb-6   text-justify">
                        <strong>Description: </strong>{' '}
                        {internship.description || 'No Description Available'}
                    </p>

                    <div className="border-t pt-4 space-y-2">
                        <h2 className="text-lg font-semibold mb-2 ">
                            Highlights
                        </h2>
                        {/* <p>
                            <strong>Mode:</strong>{' '}
                            {internship.mode || 'Not specified'}
                        </p> */}
                        <p>
                            <strong>Days:</strong>{' '}
                            {internship.days?.join(', ') || 'Not specified'}
                        </p>
                        {/* <p>
                            <strong>Duration:</strong>{' '}
                            {internship.duration
                                ? `${internship.duration} ${internship.duration_unit}`
                                : 'Not specified'}
                        </p> */}
                        {/* <p>
                            <strong>Type:</strong>{' '}
                            {internship.internship_type || 'Not specified'}
                        </p> */}
                        {/* <p>
                            <strong>Stipend:</strong>{' '}
                            {internship.stipend
                                ? `${internship.stipend} INR`
                                : 'Not Provided'}
                        </p> */}
                        <p>
                            <strong>Location:</strong>{' '}
                            {internship.location || 'Not specified'}
                        </p>
                        <p>
                            <strong>Active:</strong>{' '}
                            {internship.is_active ? 'Yes' : 'No'}
                        </p>

                        {/* <p>
                            <strong>Start Date:</strong>
                            {new Date(internship.start_date).toLocaleDateString(
                                'en-US',
                                {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                }
                            )}{' '}
                            {console.log(internship.start_date)}
                        </p> */}
                        <p>
                            <strong>Start Date:</strong>
                            {internship.start_date
                                ? new Date(
                                      internship.start_date
                                  ).toLocaleDateString('en-US', {
                                      year: 'numeric',
                                      month: 'short',
                                      day: 'numeric',
                                  })
                                : 'No date given'}
                        </p>
                    </div>

                    <div className="mt-6 space-y-2">
                        <p>
                            <strong>Application Deadline:</strong>{' '}
                            {new Date(
                                internship.application_deadline
                            ).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                            })}{' '}
                        </p>
                        <p>
                            <strong>Requirements:</strong>{' '}
                            {internship.requirements || 'Not specified'}
                        </p>
                    </div>
                </div>

                {/* Right Section */}
                <div className="bg-white shadow-md p-4 rounded-lg h-56">
                    <h2 className="text-lg font-bold mb-4">
                        Internship Details
                    </h2>
                    <div className="space-y-2">
                        <p>
                            <strong>Mode:</strong>{' '}
                            {internship.mode || 'Not specified'}
                        </p>
                        <p>
                            <strong>Type:</strong>{' '}
                            {internship.internship_type || 'Not specified'}
                        </p>
                        <p>
                            <strong>Certificate:</strong>{' '}
                            {internship.certificate ? 'Yes' : 'No'}
                        </p>
                        <p>
                            <strong>Duration:</strong>{' '}
                            {internship.duration
                                ? `${internship.duration} ${internship.duration_unit}`
                                : 'Not specified'}
                        </p>
                        {/* <p>
                            <strong>Stipend:</strong>{' '}
                            {internship.internship_type}
                            {internship.stipend
                                ? `${internship.stipend.toLocaleString()} INR ${
                                      internship.stipend_frequency
                                  }`
                                : 'Not Provided'}
                        </p> */}
                        <p>
                            <strong>
                                {(() => {
                                    switch (internship.internship_type) {
                                        case 'Stipend':
                                            return 'Stipend Amount: ';
                                        case 'Paid':
                                            return 'Internship Fees: ';
                                        default:
                                            return 'Stipend: ';
                                    }
                                })()}
                            </strong>
                            {internship.internship_type === 'Free'
                                ? 'Not Provided'
                                : internship.stipend
                                ? `${internship.stipend.toLocaleString()} INR ${
                                      internship.stipend_frequency
                                  }`
                                : 'Not Provided'}
                        </p>
                    </div>
                </div>
            </div>
            {/* Delete Confirmation Modal */}
            <ModalBox
                show={showDeleteModal}
                fun={handleDeleteConfirm}
                message="Are you sure you want to delete this internship?"
            />
        </div>
    );
};

export default InternshipDetailTi;

import { Input } from '../Elements/Input/Input';
import { useState } from 'react';
import { MoonLoader } from 'react-spinners';
import uploadResume from '../../assets/Images/uploadResume.svg';
import deleteImage from '../../assets/Images/deleteImage.svg';

export const ResumeFile = ({
    resumefile,
    resumeStatus,
    formik,
    deleteFile,
    from,
}: any) => {
    const isLoading = ['uploading...', 'deleting...'].includes(resumeStatus);
    // console.log(formik.values.resume, "RESUM_E")

    return (
        <div className="relative">
            <Input
                id="resumeUpload"
                hidden
                name="resume"
                label="Upload Resume"
                accept={'application/pdf'}
                type={'file'}
                disabled={isLoading}
                onChange={resumefile}
                onClick={(e: any) => {
                    e.target.value = null;
                }}
                onBlur={formik.handleBlur}
                error={formik.errors.resume?.url}
                isTouched={formik.touched.resume?.url}
                design={'mt-2 '}
                impo="impo"
            />
            <label
                className="flex justify-between border-[0.7px] border-black outline-[#0074FC] p-2 rounded-lg"
                htmlFor="resumeUpload"
            >
                <div className="flex items-center">
                    {!isLoading ? (
                        <a
                            href={formik?.values?.resume?.url}
                            target="_blank"
                            rel="noreferrer"
                            className="mr-2 text-[#0074FC]"
                        >
                            {from && formik?.values?.resume?.url?.length
                                ? `${formik?.values?.resume?.name}`
                                : resumeStatus}
                        </a>
                    ) : (
                        <p>
                            {from && formik?.values?.resume?.url?.length
                                ? `${formik?.values?.resume?.name}`
                                : resumeStatus}
                        </p>
                    )}
                    {/* {isLoading && <MoonLoader size={18} color={'#0074FC'} />} */}
                </div>
                {isLoading ? (
                    <MoonLoader size={18} color={'#0074FC'} />
                ) : formik?.values.resume?.url?.length ? (
                    <img
                        alt="deleteIcon"
                        className="cursor-pointer"
                        src={deleteImage}
                        // onClick={deleteFile}
                        onClick={() => deleteFile(formik?.values?.resume?.name)}
                    />
                ) : (
                    <img
                        src={uploadResume}
                        alt="uploadResume"
                        className="cursor-pointer"
                    />
                )}
            </label>
        </div>
    );
};

{
    /* <img
                        alt='deleteIcon'
                        className="cursor-pointer"
                        src={deleteImage}
                        onClick={deleteFile}
                /> */
}

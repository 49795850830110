import { toast } from 'react-hot-toast';
import { postRecord } from './backend/apiCalls';

// @ts-ignore
import { load } from '@cashfreepayments/cashfree-js';

// import Cashfree from 'cashfree-pg-sdk-javascript';

// export const loadCashfree = () => {
//     return new Promise((resolve: any) => {
//         const script = document.createElement("script");
//         script.src = "https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js";
//         document.body.appendChild(script);
//         script.onload = () => {
//             resolve(true);
//         }
//         script.onerror = () => {
//             resolve(false);
//         }
//     })

// }

// const verifyOrder = async (order_details:any) => {
//     // const verifiedResponse = await postRecord({

//     // })
//     const verifiedResponse = await verifyOrderAPICall(order_details)

// }

// export const createOrder = async (id:number,origin:string,amount:number,coupon_code:string,discount:number,whole_amount:number,charges:number,successAction:any,navigate:any) => {
//     console.info("creating order",coupon_code);
//     const createdOrderResponse = await postRecord(
//         {
//             amount: amount.toString(),
//             origin,
//             id: Number(id),
//             ...coupon_code && { coupon_code },
//             whole_amount: Number(whole_amount),
//             ...discount && {discount},
//             ...charges && {charges},
//             redirect_url: document.location.origin + '/payment',
//         },
//         'payments'
//     );
//     // console.log(createdOrderResponse)
//     if (!createdOrderResponse.status) {
//         return toast.error(createdOrderResponse.message);
//     }
//     const {
//         id: order_id,
//         amount: ordered_amount,
//         payment_link,
//         payment_session_id
//     } = createdOrderResponse.data;
//     // console.log(payment_session_id);

//     // window.location.href = payment_link;

//     // const cashfreeLoaded = await loadCashfree();
//     // console.log(cashfreeLoaded);

//     // if (!cashfreeLoaded) {
//     //   toast.error("Cash Free SDK loading failed")
//     //   return
//     // }

//     // navigate("/payment");
//     const _window = window as any;
//     const cashfree = new _window.Cashfree(payment_session_id);
//     // // cashfree.drop(document.getElementById("paymentForm"), config);
//     cashfree.redirect()
//     // paymentObject.open()
//   }

export const createOrder = async (
    id: number,
    origin: string,
    amount: number,
    coupon_code: string | undefined,
    discount: number | undefined,
    whole_amount: number,
    charges: number | undefined,
    successAction: () => void,
    navigate: (url: string) => void
): Promise<void> => {
    try {
        // console.info('Creating order', coupon_code);

        const createdOrderResponse = await postRecord(
            {
                amount: amount.toString(),
                origin,
                id,
                ...(coupon_code && { coupon_code }),
                whole_amount,
                ...(discount && { discount }),
                ...(charges && { charges }),
                redirect_url: document.location.origin + '/payment',
            },
            'payments'
        );
        console.log('createdOrderResponse', createdOrderResponse);

        if (!createdOrderResponse.status) {
            throw new Error(createdOrderResponse.message);
        }

        const {
            id: order_id,
            amount: ordered_amount,
            payment_link,
            payment_session_id,
        } = createdOrderResponse.data;

        // const _window = window as any;
        // console.log(_window, "_window")
        // const cashfree = new _window.Cashfree(payment_session_id);
        // console.log(cashfree, "cashfree")
        // cashfree.redirect();

        // const cashfree = new _window.Cashfree({
        //     mode: "production" });
        //     //or sandbox

        // let checkoutOptions = {
        //     paymentSessionId: payment_session_id,
        //     redirectTarget: "_self" //optional (_self or _blank) } cashfree.checkout(checkoutOptions)
        // }
        // cashfree.checkout(checkoutOptions)

        const cashfree = await load({ mode: 'production' });
        let checkoutOptions = {
            paymentSessionId: payment_session_id,
            redirectTarget: '_self', //optional (_self or _blank)
        };

        cashfree.checkout(checkoutOptions);

        successAction();
    } catch (error) {
        console.error('Error creating order:', error);
        toast.error('Failed to create order. Please try again.');
    }
};

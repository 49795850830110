import curveLine from "../../assets/Images/curveLine.png";
import { SignupUserDetails } from '../SignupUserDetails/SignupUserDetails';

export const LearningPlatform = () => {

  return (
    <div className='bg-[#FBFCFC] w-7/12 lg:w-11/12'>
        <SignupUserDetails />
        <img src={curveLine} alt="cornerLine" className='absolute right-0 bottom-2 rotate-90' />
    </div>
  )
}

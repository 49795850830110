import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../..';
import { readRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import closeBtn from '../../assets/Images/closeBtn.svg';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import bgImage from '../../assets/Images/bgImage.svg';

export const Advertisement = () => {
    // const navigate = useNavigate();
    const [closeAd, setCloseAd] = useState(false);
    const [addDetails, setAddDetails] = useState<any>([]);
    // const { token } = useAppSelector(
    //     ({ signin }: any) => signin['signinValue']
    // );
    const AdvertisementRef = useRef<any>([]);
    const timeoutRef = useRef<any>(null);

    const showImagePreview = () => {
        const responseData = [...AdvertisementRef.current];
        const removeFirstIndex: any = responseData.shift();
        const fullAdvertisement: any = [...responseData, removeFirstIndex];
        AdvertisementRef.current = [...fullAdvertisement];
        setAddDetails([...fullAdvertisement]);
    };

    useEffect(() => {
        if (!closeAd) {
            clearInterval(timeoutRef.current);
        }
        if (addDetails.length) {
            timeoutRef.current = setInterval(() => {
                !closeAd && showImagePreview();
            }, 1800);
        }
        return () => clearInterval(timeoutRef.current);
    }, [closeAd, addDetails]);

    useEffect(() => {
        let listen: boolean = true;
        if (listen) {
            (async () => {
                let getAds = await readRecord({}, 'advertisements');
                // console.log(getAds.data, "geT");
                if (getAds.status) {
                    AdvertisementRef.current = getAds.data;
                    setAddDetails(getAds.data);
                } else {
                    toast.error(getAds.message);
                }
            })();
        }
        return () => {
            listen = false;
        };
    }, []);

    if (!addDetails.length)
        return (
            <div>
                <img
                    src={bgImage}
                    // alt="background"
                    className="mt-4 object-cover w-full h-[290px] z-0"
                />
            </div>
        );

    return !closeAd ? (
        <div className="relative bg-[#F6F7F8]">
            <img
                src={closeBtn}
                alt="AdvertisementCloseButton"
                className="w-[20px] h-[20px] absolute cursor-pointer right-2 top-[-12px]"
                onClick={() => setCloseAd(true)}
            />
            <div className="w-full flex gap-x-1 overflow-x-auto snap-x snap-mandatory scrollBar scroll-smooth">
                {addDetails.length > 0 &&
                    addDetails.map((ad: any, index: number) => {
                        // console.log(ad, "ADS")
                        return (
                            <div key={index} className="flex-shrink-0 w-full">
                                <a
                                    href={`${
                                        ad['url_type'] === 'inside'
                                            ? `/profile/view/${
                                                  ad['url'].split('/')[1]
                                              }`
                                            : ad['url']
                                    }`}
                                    key={index}
                                    target={`${
                                        ad['url_type'] === 'inside'
                                            ? ''
                                            : '_blank'
                                    }`}
                                    className="cursor-pointer"
                                >
                                    <img
                                        key={index}
                                        src={ad['image']}
                                        alt="AdsForAdvertisement"
                                        className="object-fill h-[290px] w-full cursor-pointer"
                                    />
                                </a>
                            </div>
                        );
                    })}
            </div>
        </div>
    ) : (
        <img
            src={bgImage}
            alt="background"
            className="mt-4 object-cover w-full h-[290px] z-0"
        />
    );
};

// import React, { useState, useEffect } from 'react';
// import eyeForShowInterest from '../../assets/Images/eyeForShowInterest.svg';
// import arrow from '../../assets/Images/RightArrow.svg';
// import phoneNumStatus from '../../assets/Images/phoneNumStatus.svg';
// import locationImg from '../../assets/Images/locationImg.svg';
// import mailIcon from '../../assets/Images/mailIcon.svg';
// import { PulseLoader } from 'react-spinners';
// import {
//     createSearchParams,
//     useNavigate,
//     useSearchParams,
// } from 'react-router-dom';
// import { useAppDispatch, useAppSelector } from '../..';
// import { postRecord } from '../../Services/backend/apiCalls';
// import { toast } from 'react-hot-toast';
// import { setSkillSearch } from '../../Redux/Reducers/skillBasedSearchReducers';
// import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
// import { sBrowser } from '../../Services/commonFunctions';

// export const ShowInterest = () => {
//     const navigate = useNavigate();
//     const dispatch = useAppDispatch();
//     const [searchParams] = useSearchParams();
//     const skill = searchParams.get('skill') || '';

//     const [loading, setLoading] = useState(true);
//     const [sentInterestIndex, setSentInterestIndex] = useState();
//     const [interestedId, setInterestedId] = useState<any>([]);
//     const [sentShownInterest, setSentShownInterest] = useState(false);
//     const [
//         { token },
//         { skillBasedResponse, skills },
//         { appConfig, show_interest_action },
//     ] = useAppSelector(({ signin, skill, appConfig }: any) => [
//         signin['signinValue'],
//         skill['skillBasedSearchData'],
//         appConfig['configData'],
//     ]);
//     // console.log({appConfig});

//     const showInterestData = async () => {
//         // setLoading(true);
//         const body = {
//             interestSearch: skill,
//         };
//         const response = await postRecord(body, 'showinterests');
//         if (response.status) {
//             // setLoading(false);
//             dispatch(setSkillSearch({ skillBasedResponse: response.profiles }));
//             setInterestedId(response.interestedId);
//         } else toast.error(response.message);
//         setLoading(false);
//     };

//     useEffect(() => {
//         showInterestData();
//     }, [skill]);

//     const handleClickShowInterest = async (user_id: any, index: any) => {
//         setSentInterestIndex(index);
//         const body = {
//             interested_id: user_id,
//         };
//         setInterestedId([...interestedId, user_id]);
//         const sendInterestResponse = await postRecord(body, 'send-interest');
//         if (sendInterestResponse.status) {
//             // const idsRemaining = skillBasedResponse.filter(
//             //     (idx: any) => idx['user_id'] !== user_id
//             // );
//             // dispatch(setSkillSearch({ skillBasedResponse: idsRemaining }));
//             setSentShownInterest(true);
//             toast.success(sendInterestResponse.message);
//         } else toast.error(sendInterestResponse.message);
//         setSentShownInterest(false);
//     };

//     if (loading) {
//         return (
//             <div className="flex w-full min-h-[450px] items-center justify-center">
//                 <PulseLoader color="#0074FC" />
//             </div>
//         );
//     }

//     return (
//         <div className="bg-[#F6F7F8] h-auto pb-10">
//             <div
//                 className="flex gap-2 items-center mt-10 w-11/12 mx-auto cursor-pointer"
//                 onClick={() => navigate(-1)}
//             >
//                 <img
//                     src={arrow}
//                     alt="ArrowForBack"
//                     className="rotate-180 w-[17px] h-[17px]"
//                 />
//                 <p className="text-[18px] font-[400] text-[#3061F4]">Back</p>
//             </div>
//             {!skillBasedResponse.length ? (
//                 <div className="min-h-[300px] flex items-center justify-center">
//                     <p className="text-[18px] font-[400] text-[#3061F4]">
//                         No Freelancers with the skills which you searched
//                     </p>
//                 </div>
//             ) : (
//                 skillBasedResponse.map((show: any, index: any) => {
//                     return (
//                         <div
//                             key={index}
//                             className="bg-white flex flex-col items-center space-y-4 mt-16 w-11/12 mx-auto p-2"
//                         >
//                             <div className="w-full p-4 mx-auto flex">
//                                 <div className="border-r-[1px] border-[#D7D7D7] flex flex-col justify-evenly items-center w-3/12 space-y-3">
//                                     {/* <div className="flex items-center justify-center bg-gradient-to-t from-[#2975FD] to-[#01DDB7] rounded-full w-[96px] h-[96px] text-[50px] text-white cursor-pointer">
//                                         {show['name'][0]}
//                                     </div> */}

//                                     {/* update by me */}
//                                     <div className="flex items-center justify-center bg-gradient-to-t from-[#2975FD] to-[#01DDB7] rounded-full w-[96px] h-[96px] text-[50px] text-white cursor-pointer">
//                                         {show.profilePicture?.url ? (
//                                             <img
//                                                 src={show.profilePicture.url}
//                                                 className="w-[96px] h-[96px] rounded-full object-cover"
//                                                 alt={show.name}
//                                             />
//                                         ) : (
//                                             <span>{show.name.charAt(0)}</span>
//                                         )}
//                                     </div>
//                                     <p className="text-[16px] font-[500]">
//                                         {show['industry']}
//                                     </p>
//                                     {show['resume']?.url?.length > 0 && (
//                                         <a
//                                             href={show['resume']?.url}
//                                             target="_blank"
//                                             rel="noreferrer"
//                                             className="border-[1px] border-[#DBDBDB] rounded-[5px] p-2 flex items-center gap-2 cursor-pointer"
//                                         >
//                                             <p className="text-[14px] font-[400] text-[#3061F4]">
//                                                 View Resume
//                                             </p>
//                                             <img
//                                                 src={eyeForShowInterest}
//                                                 alt="viewEye"
//                                                 className="w-[17px] h-[17px]"
//                                             />
//                                         </a>
//                                     )}
//                                 </div>
//                                 <div className="w-full flex flex-col items-end space-y-2">
//                                     {show_interest_action === 'edit' && (
//                                         <div className="flex justify-end">
//                                             <button
//                                                 className={`bg-[#33BD87] rounded-[4px] text-[13px] font-[400] text-white p-2.5 px-4 disabled:opacity-60 `}
//                                                 disabled={interestedId.includes(
//                                                     show.user_id
//                                                 )}
//                                                 onClick={() =>
//                                                     handleClickShowInterest(
//                                                         show.user_id,
//                                                         index
//                                                     )
//                                                 }
//                                             >
//                                                 {interestedId.includes(
//                                                     show.user_id
//                                                 )
//                                                     ? 'Shown Interest'
//                                                     : 'Show Interest'}
//                                             </button>
//                                         </div>
//                                     )}
//                                     <div className="w-11/12 flex flex-col space-y-6 justify-end">
//                                         <p className="text-[25px] font-[500]">
//                                             {show['name']}
//                                         </p>
//                                         <div className="grid grid-cols-2 gap-6 xl:flex xl:gap-10">
//                                             <div className="flex gap-2 items-center border-r-[1px] xl:pr-10">
//                                                 <img
//                                                     src={phoneNumStatus}
//                                                     alt="Phone"
//                                                     className="w-[17px] h-[17px]"
//                                                 />
//                                                 <p className="text-[14px] font-[400]">
//                                                     {show['contact_number']}
//                                                 </p>
//                                             </div>
//                                             <div className="flex items-center gap-2 border-r-[1px] xl:pr-10">
//                                                 <img
//                                                     src={mailIcon}
//                                                     alt="Phone"
//                                                     className="w-[17px] h-[17px]"
//                                                 />
//                                                 <p className="text-[14px] font-[400]">
//                                                     {show['email']}
//                                                 </p>
//                                             </div>
//                                             <div className="flex items-start xl:items-center gap-2">
//                                                 <img
//                                                     src={locationImg}
//                                                     alt="Phone"
//                                                     className="w-[17px] h-[17px]"
//                                                 />
//                                                 <p className="text-[14px] font-[400]">
//                                                     {show['address_line']}
//                                                 </p>
//                                             </div>
//                                         </div>
//                                         <div className="flex gap-2">
//                                             {show['skills'].map(
//                                                 (skill: any, i: number) => (
//                                                     <p
//                                                         key={i}
//                                                         className="text-[10px] font-[500] cursor-pointer text-[#3061F4] ml-1 rounded-[17.5px] p-1.5 px-4 bg-[#F8F8F8]"
//                                                         onClick={() => {
//                                                             navigate({
//                                                                 pathname:
//                                                                     '/show-interest',
//                                                                 search: createSearchParams(
//                                                                     { skill }
//                                                                 ).toString(),
//                                                             });
//                                                         }}
//                                                     >
//                                                         {skill}
//                                                     </p>
//                                                 )
//                                             )}
//                                         </div>
//                                         {show['url'].length > 0 && (
//                                             <div className="flex gap-2">
//                                                 <h1 className="text-[14px] font-[600]">
//                                                     LinkedIn Profile :
//                                                 </h1>
//                                                 <p className="text-[14px] text-[#555555] font-[400]">
//                                                     {show['url']}
//                                                 </p>
//                                             </div>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                             {/* <hr className="border-b-1 border-[#EEEEEE] -mt-0.5 w-full"></hr>
//                         <div className="w-full flex justify-end pb-2">
//                             <img
//                                 src={arrow}
//                                 alt="Arrow"
//                                 className="w-[26px] h-[20.9px] mr-6"
//                             />
//                         </div> */}
//                         </div>
//                     );
//                 })
//             )}
//         </div>
//     );
// };

import React, { useState, useEffect } from 'react';
import eyeForShowInterest from '../../assets/Images/eyeForShowInterest.svg';
import arrow from '../../assets/Images/RightArrow.svg';
import phoneNumStatus from '../../assets/Images/phoneNumStatus.svg';
import locationImg from '../../assets/Images/locationImg.svg';
import mailIcon from '../../assets/Images/mailIcon.svg';
import { PulseLoader } from 'react-spinners';
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../..';
import { postRecord } from '../../Services/backend/apiCalls';
import { toast } from 'react-hot-toast';
import { setSkillSearch } from '../../Redux/Reducers/skillBasedSearchReducers';

export const ShowInterest = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const skill = searchParams.get('skill') || '';

    const [loading, setLoading] = useState(true);
    const [sentInterestIndex, setSentInterestIndex] = useState();
    const [interestedId, setInterestedId] = useState<any>([]);
    const [sentShownInterest, setSentShownInterest] = useState(false);
    const [
        { token },
        { skillBasedResponse, skills },
        { appConfig, show_interest_action },
    ] = useAppSelector(({ signin, skill, appConfig }: any) => [
        signin['signinValue'],
        skill['skillBasedSearchData'],
        appConfig['configData'],
    ]);

    const showInterestData = async () => {
        const body = {
            interestSearch: skill,
        };
        const response = await postRecord(body, 'showinterests');
        if (response.status) {
            dispatch(setSkillSearch({ skillBasedResponse: response.profiles }));
            setInterestedId(response.interestedId);
        } else toast.error(response.message);
        setLoading(false);
    };

    useEffect(() => {
        showInterestData();
    }, [skill]);

    const handleClickShowInterest = async (user_id: any, index: any) => {
        setSentInterestIndex(index);
        const body = {
            interested_id: user_id,
        };
        setInterestedId([...interestedId, user_id]);
        const sendInterestResponse = await postRecord(body, 'send-interest');
        if (sendInterestResponse.status) {
            setSentShownInterest(true);
            toast.success(sendInterestResponse.message);
        } else toast.error(sendInterestResponse.message);
        setSentShownInterest(false);
    };

    if (loading) {
        return (
            <div className="flex w-full min-h-[450px] items-center justify-center">
                <PulseLoader color="#0074FC" />
            </div>
        );
    }

    return (
        <div className="bg-[#F6F7F8] min-h-screen w-full pb-6 md:pb-10">
            <div
                className="flex gap-2 items-center p-4 md:p-0 mt-2 md:mt-10 w-full md:w-11/12 mx-auto cursor-pointer"
                onClick={() => navigate(-1)}
            >
                <img
                    src={arrow}
                    alt="ArrowForBack"
                    className="rotate-180 w-4 md:w-[17px] h-4 md:h-[17px]"
                />
                <p className="text-base md:text-[18px] font-[400] text-[#3061F4]">
                    Back
                </p>
            </div>

            {!skillBasedResponse.length ? (
                <div className="min-h-[300px] flex items-center justify-center p-4">
                    <p className="text-base md:text-[18px] font-[400] text-[#3061F4] text-center">
                        No Freelancers with the skills which you searched
                    </p>
                </div>
            ) : (
                <div className="w-full px-4 md:px-0">
                    {skillBasedResponse.map((show: any, index: any) => (
                        <div
                            key={index}
                            className="bg-white rounded-lg shadow-sm mt-4 md:mt-16 w-full md:w-11/12 mx-auto overflow-hidden"
                        >
                            <div className="flex flex-col md:flex-row w-full p-4">
                                {/* Left Profile Section */}
                                <div className="w-full md:w-3/12 flex flex-col items-center space-y-4 md:border-r md:border-[#D7D7D7] p-4">
                                    <div className="w-20 h-20 md:w-24 md:h-24 rounded-full bg-gradient-to-t from-[#2975FD] to-[#01DDB7] flex items-center justify-center">
                                        {show.profilePicture?.url ? (
                                            <img
                                                src={show.profilePicture.url}
                                                className="w-full h-full rounded-full object-cover"
                                                alt={show.name}
                                            />
                                        ) : (
                                            <span className="text-3xl md:text-5xl text-white">
                                                {show.name[0]}
                                            </span>
                                        )}
                                    </div>

                                    <p className="text-sm md:text-base font-medium text-center">
                                        {show.industry}
                                    </p>

                                    {show.resume?.url && (
                                        <a
                                            href={show.resume.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex items-center gap-2 border border-[#DBDBDB] rounded px-3 py-2"
                                        >
                                            <span className="text-xs md:text-sm text-[#3061F4]">
                                                View Resume
                                            </span>
                                            <img
                                                src={eyeForShowInterest}
                                                alt="view"
                                                className="w-4 h-4 md:w-5 md:h-5"
                                            />
                                        </a>
                                    )}
                                </div>

                                {/* Right Content Section */}
                                <div className="flex-1 p-4">
                                    {show_interest_action === 'edit' && (
                                        <div className="flex justify-end mb-4">
                                            <button
                                                disabled={interestedId.includes(
                                                    show.user_id
                                                )}
                                                onClick={() =>
                                                    handleClickShowInterest(
                                                        show.user_id,
                                                        index
                                                    )
                                                }
                                                className="bg-[#33BD87] text-white px-4 py-2 rounded text-xs md:text-sm disabled:opacity-60"
                                            >
                                                {interestedId.includes(
                                                    show.user_id
                                                )
                                                    ? 'Shown Interest'
                                                    : 'Show Interest'}
                                            </button>
                                        </div>
                                    )}

                                    <div className="space-y-4">
                                        <h2 className="text-xl md:text-2xl font-medium">
                                            {show.name}
                                        </h2>

                                        <div className="flex flex-col space-y-3 md:space-y-0 md:flex-row md:space-x-6">
                                            <div className="flex items-center gap-2">
                                                <img
                                                    src={phoneNumStatus}
                                                    alt=""
                                                    className="w-4 h-4 md:w-5 md:h-5"
                                                />
                                                <span className="text-xs md:text-sm">
                                                    {show.contact_number}
                                                </span>
                                            </div>
                                            <div className="flex items-center gap-2">
                                                <img
                                                    src={mailIcon}
                                                    alt=""
                                                    className="w-4 h-4 md:w-5 md:h-5"
                                                />
                                                <span className="text-xs md:text-sm break-all">
                                                    {show.email}
                                                </span>
                                            </div>
                                            <div className="flex items-start gap-2">
                                                <img
                                                    src={locationImg}
                                                    alt=""
                                                    className="w-4 h-4 md:w-5 md:h-5 mt-1"
                                                />
                                                <span className="text-xs md:text-sm">
                                                    {show.address_line}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="flex flex-wrap gap-2">
                                            {show.skills.map(
                                                (skill: string, i: number) => (
                                                    <span
                                                        key={i}
                                                        onClick={() => {
                                                            navigate({
                                                                pathname:
                                                                    '/show-interest',
                                                                search: createSearchParams(
                                                                    { skill }
                                                                ).toString(),
                                                            });
                                                        }}
                                                        className="text-[10px] md:text-xs text-[#3061F4] bg-[#F8F8F8] px-3 py-1 rounded-full cursor-pointer"
                                                    >
                                                        {skill}
                                                    </span>
                                                )
                                            )}
                                        </div>

                                        {show.url && (
                                            <div className="flex flex-col md:flex-row gap-1 md:gap-2">
                                                <span className="text-xs md:text-sm font-semibold">
                                                    LinkedIn Profile:
                                                </span>
                                                <span className="text-xs md:text-sm text-[#555555] break-all">
                                                    {show.url}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

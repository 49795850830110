import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../..';
// import SampleImageUpload from '../../assets/Images/sampleImageUpload.svg';
// import { CommonRequestCard } from '../CommonRequestCard/CommonRequestCard';
import { JustifyBetweenComponent } from './Checkout';
import { PulseLoader } from 'react-spinners';
import { Back } from '../Elements/back';
import { postRecord, readRecord } from '../../Services/backend/apiCalls';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import toast from 'react-hot-toast';
import { RoleCard } from './Role';
import moment from 'moment';
import { daysConversionAndSort } from '../../Services/commonFunctions';

export const Cancellation = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { order_id, id } = state;
    const [cancelRequestData, setCancelRequestData] = useState([]);
    const [showCancellation, setShowCancellation] = useState(false);
    const [loading, setLoading] = useState(false);

    const cancellationFunctionCall = async () => {
        setLoading(true);
        const getRecord = await readRecord(
            {
                order_id: order_id,
                training_id: id,
            },
            'canceltrainings'
        );
        console.log(getRecord, 'getRecordd');
        if (getRecord.status) {
            setCancelRequestData(getRecord);
        } else toast.error(getRecord.message);
        setLoading(false);
    };

    useEffect(() => {
        cancellationFunctionCall();
    }, []);

    const convertCurrencyWithComma = (amount: any) => {
        return Number(amount).toLocaleString('en-IN');
    };

    return (
        <section className=" bg-[#F6F7F8] ">
            <div className="w-11/12 mx-auto py-6">
                <Back />
                <h1 className="mt-5 font-[500] text-[25px]">Cancellation</h1>
                {loading ? (
                    <div className="flex w-full min-h-[400px] items-center justify-center">
                        <PulseLoader color="#0074FC" />
                    </div>
                ) : (
                    <div className="w-full flex items-start flex-wrap xl:flex-nowrap xl:space-x-4 mt-4">
                        {/* Left card */}
                        <div className="w-full xl:w-8/12 p-6 bg-white">
                            <DetailsCard
                                cancelRequestData={cancelRequestData}
                            />
                            <div className="flex flex-col space-y-5 p-4 py-6 mt-[50px] bg-[#EDF3FF]">
                                <h1 className="text-[#2975FD] text-[16px] font-[600]">
                                    Cancellation & Refund Terms
                                </h1>
                                <div className="space-y-3">
                                    <p className="text-[#686868] text-[12px] font-[400]">
                                        You can cancel the booking upto 4
                                        hour(s) before showtime. Refunds will be
                                        processed according to cancellation
                                        Policy
                                    </p>
                                    <div className="flex items-center gap-2 relative parent">
                                        <input
                                            type="checkbox"
                                            //   id={sectionName + 'applyCouponCheckBox'}
                                            checked={showCancellation}
                                            onChange={() => {
                                                setShowCancellation(
                                                    !showCancellation
                                                );
                                            }}
                                            title="apply coupon"
                                            className="border-[#D4D5D9] w-[18px] h-[18px] cursor-pointer"
                                        />
                                        {/* {CancellationPolicy} */}
                                        <CancellationPolicy
                                            cancelRequestData={
                                                cancelRequestData
                                            }
                                        />
                                        <p className="text-[#686868] text-[12px] font-[400]">
                                            I agree with the
                                            <span className="border-[#000000] border-b-[1px] border-dashed ml-1 text-black text-[12px] font-[500]">
                                                Cancellation Policy.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Right card */}
                        <AmountComponent
                            cancelRequestData={cancelRequestData}
                            convertCurrencyWithComma={convertCurrencyWithComma}
                            isButtonForCancel={showCancellation !== true}
                            showCancellation={showCancellation}
                        />
                    </div>
                )}
            </div>
        </section>
    );
};

const CancellationPolicy = ({ cancelRequestData }: any) => {
    // console.log({cancelRequestData});
    return (
        <>
            {cancelRequestData.cancellationValues && (
                <div className="absolute child hidden z-[2] p-5 bg-white bottom-9 w-[90%] shadow-md space-y-6">
                    <h4 className="font-[600] text-[#2975FD] text-[20px]">
                        Cancellation Policy
                    </h4>
                    {/* <div className="space-y-4">
                        <h1 className="text-[#333333] text-[18px] font-[500]">
                            Cancellation/reschedule by TI/FL
                        </h1>
                        <div className="space-y-2">
                            <p className="text-[#545454] text-[14px] font-[500]">
                                Fully refundable
                            </p>
                        </div>
                    </div> */}
                    <div className="space-y-4">
                        {/* <h1 className="text-[#333333] text-[18px] font-[500]">
                            Cancellation/reschedule by You
                        </h1> */}
                        <div className="space-y-2">
                            <p className="text-[#545454] text-[14px] font-[500]">
                                Before{' '}
                                {
                                    cancelRequestData.cancellationValues
                                        .cancellation_range[2]
                                }{' '}
                                days –{' '}
                                {/* {
                                    cancelRequestData.cancellationValues
                                        .cancellation_value[2]
                                }{' '}
                                % */}
                                Fully refundable
                            </p>
                            <p className="text-[#545454] text-[14px] font-[500]">
                                Within{' '}
                                {
                                    cancelRequestData.cancellationValues
                                        .cancellation_range[1]
                                }{' '}
                                days –{' '}
                                {
                                    cancelRequestData.cancellationValues
                                        .cancellation_value[1]
                                }
                                % of the fees will be deducted
                            </p>
                            <p className="text-[#545454] text-[14px] font-[500]">
                                Within{' '}
                                {
                                    cancelRequestData.cancellationValues
                                        .cancellation_range[0]
                                }{' '}
                                day –{' '}
                                {
                                    cancelRequestData.cancellationValues
                                        .cancellation_value[0]
                                }
                                % of the fees will be deducted
                                {/* No refund */}
                            </p>
                        </div>
                    </div>
                    <div className="drop-shadow-lg absolute left-[130px] -bottom-[70px]">
                        <div className="w-[40px] h-[75px] arrowDown bg-white"></div>
                    </div>
                </div>
            )}
        </>
    );
};

const DetailsCard = ({ cancelRequestData }: any) => {
    // console.log(cancelRequestData, 'cancelRequestData@123');

    const courseDetails = [
        {
            value:
                cancelRequestData.checkoutData &&
                cancelRequestData.checkoutData.duration,
            name: 'Duration',
        },
        {
            value:
                cancelRequestData.checkoutData &&
                daysConversionAndSort(
                    cancelRequestData.checkoutData.mode_of_teaching.session
                ),
            name: 'Day',
        },
        {
            value: moment(
                cancelRequestData.checkoutData &&
                    cancelRequestData.checkoutData.date_of_training
            ).format('DD/MM/YYYY'),
            name: 'Start Date',
        },
        {
            value:
                cancelRequestData.checkoutData &&
                cancelRequestData.checkoutData.batch_timing,
            name: 'Batch Timing',
        },
    ];

    return (
        <>
            {cancelRequestData.checkoutData && (
                <div className="flex space-x-8 items-center">
                    <img
                        src={cancelRequestData.checkoutData.course.image}
                        alt="courseImage"
                        className="w-4/12 aspect-auto object-contain"
                    />
                    <div className="space-y-4 w-8/12">
                        <h3 className="text-[30px] font-[500]">
                            {cancelRequestData.checkoutData.course.title}
                        </h3>
                        <div className="flex items-center space-x-3">
                            <h5 className="text-[20px] text-[#686868]">
                                {cancelRequestData.checkoutData.Trainer.name}
                            </h5>
                            <RoleCard
                                role={
                                    cancelRequestData.checkoutData.Trainer.role
                                }
                            />
                        </div>
                        <div className="flex flex-wrap">
                            {courseDetails.map((detail: any, index: number) => {
                                return (
                                    <div
                                        key={index}
                                        className={`${
                                            index !==
                                                courseDetails.length - 1 &&
                                            'border-r'
                                        } mr-3 pr-4 py-1 mt-1 space-y-2`}
                                    >
                                        <h6 className="text-[14px] text-[400] text-[#303030]">
                                            {detail.name}
                                        </h6>
                                        <p className="text-[#3061F4] text-[12px] font-[500]">
                                            {detail.value}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div></div>
                    </div>
                </div>
            )}
        </>
    );
};

const AmountComponent = ({
    cancelRequestData,
    convertCurrencyWithComma,
    isButtonForCancel,
    showCancellation,
}: any) => {
    const navigate = useNavigate();
    const { token } = useAppSelector(({ signin }: any) => signin.signinValue);

    const payAction = async (refund_id: any) => {
        // console.log('action', refund_id);
        const body = {
            refund_id: refund_id,
        };
        const response = await postRecord(body, 'canceltrainings');
        // console.log(response, "response")
        if (response.status) {
            navigate('/dashboard');
        } else {
            console.log('fdfd');

            toast.error(response.message);
        }
    };

    return (
        <>
            {cancelRequestData.amount && (
                <div className="w-full mt-6 xl:mt-0 xl:w-3/12 p-6 bg-white space-y-6 sticky top-2 h-full">
                    <p className="text-[#5F5F5F] font-[500] text-[16px]">
                        Refund Details
                    </p>
                    <div className="border-b pb-4">
                        <JustifyBetweenComponent
                            leftValue={'Total Amount Paid'}
                            rightValue={convertCurrencyWithComma(
                                cancelRequestData.amount.totalAmount
                            )}
                        />
                    </div>
                    <div className="pt-[40px] space-y-4">
                        <JustifyBetweenComponent
                            leftValue={'Convenience Fees'}
                            rightValue={convertCurrencyWithComma(
                                cancelRequestData.amount.deduction.amount
                            )}
                            operator={'-'}
                            isCoupon={true}
                            couponText={
                                cancelRequestData.amount.deduction.percentage +
                                '%'
                            }
                        />
                        <div className="border-t border-b py-3">
                            <JustifyBetweenComponent
                                leftValue={'Total Refund Amount'}
                                rightValue={convertCurrencyWithComma(
                                    cancelRequestData.amount.refundAmount
                                )}
                            />
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <button
                            className={`bg-[#2975FD] text-white px-6 py-3 rounded-[6px] ${
                                !isButtonForCancel && 'cursor-pointer'
                            }`}
                            disabled={isButtonForCancel}
                            onClick={() =>
                                payAction(cancelRequestData.refund_id)
                            }
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import {
    useLocation,
    useNavigate,
    createSearchParams,
    useSearchParams,
} from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import { useAppDispatch, useAppSelector } from '../..';
import { showBookNowModal } from '../../Redux/Reducers/DRReducers';
import { setSignValue } from '../../Redux/Reducers/signinUserReducers';
import {
    deleteRecord,
    updateRecord,
    readRecord,
} from '../../Services/backend/apiCalls';
import { ButtonCompWithActionController } from '../Elements/Button/Button';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import { Dialog } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import { dateFormat } from './DateFormat';
import moment from 'moment';

function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export const DirectRequestActionComponent = ({
    directRequestStatus,
    id,
    isTrainer,
    batchEndDate,
    batches,
    role,
}: // selectedBatchDate,
any) => {
    const [showDeletePrompt, setShowDeletePrompt] = useState(false);
    const requestName = window.location.pathname.split('/')[1];

    const dispatch = useAppDispatch();
    const isCourseBooked = useSelector((state: any) => {
        return state.compare.compareCoursesData.bookedBatchTimeAllCourse;
    });

    const [courseBatchs, setCourseBatchs] = useState([]);

    const location = useLocation();
    const [searchParams] = useSearchParams();
    const isDirectRequestPage = location.pathname.includes('booknow');
    const dr_id = searchParams.get('dr_id');

    useEffect(() => {
        const viewCourses = async () => {
            const CourseDetail = await readRecord(
                { id, ...(isDirectRequestPage && { isDrNeeded: true, dr_id }) },
                'coursequery'
            );
            setCourseBatchs(CourseDetail?.data[0].batches);
        };
        viewCourses();
    }, []);

    const courseBookedTimings = useAppSelector((state: any) => {
        return state.compare?.compareCoursesData?.bookedBatchTime[0];
    });

    const currentDate = getCurrentDate();
    const batchEndDateFilter = batchEndDate?.filter(
        (date: any) => currentDate <= date
    );

    const paymentId = directRequestStatus.id ?? directRequestStatus.id;
    const { attendedCourses, directRequestedIds } = useAppSelector(
        ({ signin }: any) => signin.signinValue
    );
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const cancelButtonRef = useRef<any>(null);
    const acceptButtonRef = useRef<any>(null);
    const rejectButtonRef = useRef<any>(null);

    const setShowBookingModal = () => {
        dispatch(showBookNowModal(true));
    };

    const Customize = () => {
        navigate(`/course/customize/${id}`);
    };

    const cancelRequest = async () => {
        setLoading(true);
        const cancelrequestResponse = await deleteRecord(
            { id: dr_id },
            'directrequests'
        );
        if (!cancelrequestResponse.status) {
            toast.error(cancelrequestResponse.message);
        } else {
            toast.success(cancelrequestResponse.message);
            dispatch(
                setSignValue({
                    directRequestedIds: directRequestedIds.filter(
                        (courseId: any) => courseId.toString() !== id.toString()
                    ),
                })
            );
            navigate('/request/booknow');
        }
    };

    const acceptOrRejectRequest = async (status: string) => {
        setLoading(true);
        const response = await updateRecord(
            { status, id: directRequestStatus.id },
            'directrequests'
        );
        // console.log({ response });
        if (response.status) {
            navigate(0);
        } else {
            toast.error(response.message);
        }
        setLoading(false);
    };

    const selectedTab =
        'bg-[#3061F4] text-[14px] p-4 text-white font-[500] rounded-[4px] cursor-pointer text-center';
    const unselectedTab =
        'bg-[#F3F7FB] text-[14px] p-4 text-[#3061F4] font-[500] rounded-[4px] cursor-pointer';

    const filterPastDate = batches?.filter((bt: any) => {
        const batchDate = moment(bt?.batchDate).format('YYYY-MM-DD'); // Use the same format as in the array
        const currentDate = moment().format('YYYY-MM-DD');
        return batchDate >= currentDate;
    });

    // console.log(filterPastDate, 'filterPastDate', batches, 'batches');
    const getNonBookedCourseData = filterPastDate.filter((batch: any) => {
        return !courseBookedTimings.some(
            (item: any) =>
                `${dateFormat(batch.batchDate)}(${batch.batchTime})` ===
                `${dateFormat(item.batch_date)}(${item.batch_timing})`
        );
    });

    // const res = batches.reduce((acc: any, cru: any) => {
    //     const isCheck = courseBookedTimings.some(
    //         (couBookTime: any) =>
    //             (couBookTime.batch_timing === cru.batchTime &&
    //                 couBookTime.isCancelled === true) ||
    //             (couBookTime.batch_timing === cru.batchTime &&
    //                 !(
    //                     couBookTime.isPaymentDone === false &&
    //                     couBookTime.isCancelled === false
    //                 ))
    //     );

    //     if (isCheck) {
    //         acc.push(cru);
    //     }
    //     return acc;
    // }, []);

    const popUpOnchangeHandler = (value: any) => {
        setShowDeletePrompt(value);
    };
    const getNonBookedCourse = [...getNonBookedCourseData];
    if (loading) {
        return (
            <div className="flex w-full my-6 items-center justify-center">
                <PulseLoader color="#0074FC" />
            </div>
        );
    }

    if (directRequestStatus?.status === 'pending' && isTrainer) {
        return (
            <div className="flex justify-around w-9/12 mx-auto py-6">
                <ButtonCompWithActionController
                    className="bg-white border-2 border-[#33BD87] w-full text-[14px] mt-3 px-5 py-2 text-[#33BD87] font-[500] rounded-[4px] cursor-pointer text-center"
                    buttonText={'Accept'}
                    action={() => acceptOrRejectRequest('accepted')}
                    Ref={acceptButtonRef}
                />
                <ButtonCompWithActionController
                    className="bg-white border-2 border-[#EA4335] w-full text-[14px] mt-3 px-6 py-2 text-[#EA4335] font-[500] rounded-[4px] cursor-pointer text-center"
                    buttonText={'Reject'}
                    action={() => acceptOrRejectRequest('rejected')}
                    Ref={rejectButtonRef}
                />
            </div>
        );
    }

    if (
        directRequestStatus &&
        isCourseBooked &&
        !directRequestStatus?.isPaymentDone &&
        !isTrainer
    ) {
        return (
            <div className="flex flex-col py-6 px-4">
                {directRequestStatus.status === 'accepted' && (
                    <button
                        className="bg-[#3061F4] text-[14px] mt-3 px-4 py-3 text-white font-[500] rounded-[4px] cursor-pointer text-center"
                        onClick={() =>
                            navigate({
                                pathname: '/checkout',
                                search: createSearchParams({
                                    id: paymentId,
                                    checkoutOrigin: 'dr',
                                }).toString(),
                            })
                        }
                    >
                        Make Payment
                    </button>
                )}
                <ButtonCompWithActionController
                    className="bg-[#fb5335] w-full text-[14px] mt-3 px-4 py-3 text-white font-[500] rounded-[4px] cursor-pointer text-center"
                    buttonText={'Cancel Request'}
                    action={cancelRequest}
                    Ref={cancelButtonRef}
                />
            </div>
        );
    }

    if (directRequestStatus?.isPaymentDone && requestName === 'booknow') {
        return (
            <div className="flex flex-col justify-center items-center py-6">
                <button
                    disabled={true}
                    className="bg-[#FB5935] text-[14px] mt-3 px-6 py-2 text-white font-[500] rounded-[4px] text-center opacity-50"
                >
                    Paid
                </button>
            </div>
        );
    }

    // console.log(getNonBookedCourse, "getNonBookedCourse")

    // selectedBatchDate
    if (!isTrainer) {
        return (
            <div className="flex flex-col gap-y-4 mt-10 p-12">
                <>
                    {batchEndDateFilter?.length > 0 && (
                        <button
                            className={selectedTab}
                            onClick={
                                (role === 'ST' &&
                                    courseBookedTimings?.length < 1 &&
                                    getNonBookedCourse?.length !== 0) ||
                                (role === 'CO' &&
                                    getNonBookedCourse?.length !== 0)
                                    ? setShowBookingModal
                                    : () => setShowDeletePrompt(true)
                            }
                        >
                            BOOK NOW
                        </button>
                    )}

                    <button
                        type="button"
                        className={unselectedTab}
                        onClick={Customize}
                    >
                        {batchEndDateFilter?.length > 0
                            ? 'Customize'
                            : 'Request For a Slot'}
                    </button>
                    {((role === 'ST' && courseBookedTimings.length === 1) ||
                        getNonBookedCourse?.length === 0) && (
                        <CustomPopUp
                            showDeletePrompt={showDeletePrompt}
                            changeHandler={popUpOnchangeHandler}
                            content={
                                (getNonBookedCourse?.length === 0 &&
                                    'All Batch Timings Are Booked') ||
                                (role === 'ST' &&
                                    courseBookedTimings.length === 1 &&
                                    'Student can book only one batch')
                            }
                        />
                    )}
                </>
            </div>
        );
    }
    return <></>;
};

export const CustomPopUp = ({
    showDeletePrompt,
    changeHandler,
    content,
}: any) => {
    return (
        <Transition show={showDeletePrompt} as={React.Fragment}>
            <Dialog
                onClose={() => {}}
                className="fixed inset-0 z-10 overflow-y-auto"
            >
                <div className="flex items-center justify-center min-h-screen">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <div className="bg-white p-4 w-80 max-w-md mx-auto rounded shadow-lg z-50">
                        <Dialog.Title className="text-lg font-semibold">
                            {content}
                        </Dialog.Title>

                        <div className="flex justify-end mt-4">
                            <Button
                                onClick={() => {
                                    changeHandler(false);
                                }}
                                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

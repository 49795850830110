import React, { useEffect } from 'react';
import { Footer } from './Components/Footer/Footer';
import { useLocation } from 'react-router-dom';
import { ConditionsHeader } from './Components/Header/conditionHeader';

export const ConditionsMargin = ({ children }: any) => {
    const { pathname } = useLocation();
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
        });
    }, [pathname]);
    return (
        <div className="h-screen">
            <ConditionsHeader />
            <div className="flex flex-col justify-between h-screen bg-[#F6F7F8]">
                <div className="m-4 border-2 mb-2">
                    <div className="m-4">
                        <h1 className="font-[800]">
                            Trisekt Terms and Conditions
                        </h1>
                        <p className="mt-2 ml-2">
                            Welcome to Trisekt, the leading portal connecting
                            students and training institutes. Before you begin
                            using our platform, please take a moment to review
                            and understand our terms and conditions.
                        </p>
                        <h1 className="font-[600] mt-2">
                            1. Acceptance of Terms
                        </h1>
                        <p className="mt-2 ml-2">
                            By accessing or using Trisekt, you agree to abide by
                            these Terms and Conditions, including any future
                            updates or modifications. If you do not agree with
                            any part of these terms, please do not use our
                            platform.
                        </p>
                        <h1 className="font-[600] mt-2"> 2. User Accounts</h1>
                        <div className="mt-2">
                            <p className="font-[600] ml-2">
                                <span className="font-[600]">2.1 </span>User
                                Registration
                            </p>
                        </div>
                        <p className="mt-2 ml-4">
                            To access certain features of Trisekt, you may need
                            to create an account. You are responsible for
                            keeping your account’s password confidential.
                        </p>

                        <div className="mt-2 ml-2">
                            <p className="font-[600]">
                                <span className="font-[600]">2.2 </span>Accuracy
                                of Information
                            </p>
                        </div>
                        <p className="mt-2 ml-4">
                            You must provide accurate and up-to-date information
                            during the registration process and throughout your
                            use of Trisekt.
                        </p>
                    </div>

                    <div className="ml-4 mt-2">
                        <h1 className="font-[600]">3. User Responsibilities</h1>
                        <div className="mt-2">
                            <p className="font-[600] ml-2">
                                <span className="font-[600]">3.1 </span>
                                Student Responsibilities
                            </p>
                        </div>
                        <p className="mt-2 ml-4">
                            As a student, you agree to use Trisekt for
                            educational purposes only and to respect the
                            policies and guidelines of the training institutes
                            you interact with.
                        </p>

                        <div className="mt-2 ml-2">
                            <p className="font-[600]">
                                <span className="font-[600]">3.2 </span>
                                Institute Responsibilities
                            </p>
                        </div>
                        <p className="mt-2 ml-4">
                            As a training institute, you agree to provide
                            accurate information about your programs and adhere
                            to ethical and legal standards in your interactions
                            with students.
                        </p>
                    </div>

                    <div className="m-4 mt-2">
                        <h1 className="font-[600]">4. Privacy</h1>
                        <div>
                            <p className="font-[600] mt-2 ml-2">
                                <span className="font-[600]">4.1 </span>
                                General
                            </p>
                        </div>
                        <p className="mt-2 ml-4">
                            Trisekt (whose registered office is at Bangalore)
                            (“Trisekt”, “we”, “our” or “us”), acting as data
                            controller, is committed to protecting and
                            respecting your privacy. This notice (the “Privacy
                            Notice”) is designed to tell you about our practices
                            regarding the collection, use and disclosure of
                            information that you may provide via this website or
                            the “Platform“).
                        </p>

                        <p className="mt-2 ml-4">
                            This Privacy Notice (together with our Terms and
                            Conditions sets out the basis on which any personal
                            data we collect from you, or that you provide to us,
                            will be processed by us. Please read the following
                            carefully to understand our views and practices
                            regarding your personal data and how we will treat
                            it.
                        </p>

                        <div className="mt-2 ml-2">
                            <h1 className="font-[600] mt-2">
                                <span className="font-[600]">4.2 </span> What
                                information do we collect from you and for how
                                long?
                            </h1>
                            <p className="mt-2 ml-2">
                                We may collect and process the following data
                                about you:
                            </p>
                            <h1 className="mt-2 font-[600] ml-4">
                                <span className="font-[600]">4.2.1 </span> The
                                information you give us
                            </h1>
                            <p className="mt-2 ml-6">
                                You may give us information, including
                                information that can identify you (“Personal
                                Data“), when you use our Platforms, by filling
                                in forms on the Platforms (such as the sign-up
                                form), when you enter into any of our promotions
                                or surveys, when you correspond with us by
                                phone, e-mail or otherwise, and when you report
                                a problem with our Platforms. Trisekt is
                                vigilant about the security of the members of
                                the platform as well as information relating to
                                the identity and means of payment of each of its
                                members. If we notice a payment incident, a
                                member of our team may contact you to verify
                                your identity.
                            </p>
                            <h1 className="font-[600] mt-2 ml-6">
                                The information you give us may include:
                            </h1>

                            <p className="mt-2 ml-8">
                                1. Mandatory information required to register
                                for the service we provide on our Platforms or
                                to access other services provided by us,
                                including your name, email address, date of
                                birth, gender, mobile phone number, and password
                                are required. All these fields are mandatory.
                                Trisekt will not be able to provide you with the
                                services offered on our Platforms if the
                                required information is not provided,
                                consequently, you will not be able to register
                                for a user account on our Platforms
                            </p>

                            <p className="mt-2 ml-8">2. A postal address;</p>
                            <p className="mt-2 ml-8">
                                3. Details of your qualifications;
                            </p>
                            <p className="mt-2 ml-8">
                                4. A record of any correspondence between you
                                and us;
                            </p>

                            <p className="mt-2 ml-8">
                                5. A record of any requests you have made or
                                related adverts you have placed with or through
                                our Platforms;
                            </p>
                            <p className="mt-2 ml-8">
                                6. Details of accounting or financial
                                transactions including transactions carried out
                                through our Platforms or otherwise. This may
                                include information such as your payment card or
                                bank account details, details of courses that
                                you have offered through our Platforms;
                            </p>
                            <p className="mt-2 ml-8">
                                7. Details of your visits to our Platforms and
                                the resources that you access;
                            </p>
                            <p className="mt-2 ml-8">
                                8. Your replies to any surveys or
                                questionnaires, such as your reviews on the
                                courses you have attended. Such information may
                                be used for analytic & user understanding
                                purposes;
                            </p>
                            <p className="mt-2 ml-8">
                                9. Information we may require from you when you
                                report a problem with our Platforms or our
                                service, such as the subject of your request for
                                support;
                            </p>
                            <p className="mt-2 ml-8">
                                10. Location information when you have given
                                consent to the collection and processing of this
                                data;
                            </p>
                            <p className="mt-2 ml-8">
                                11. Resume and other documents that you have
                                agreed to provide to us.
                            </p>
                            <h1 className="mt-2 ml-4 font-[600]">
                                <span className="font-[600]">4.2.2 </span>
                                Information we collect automatically
                            </h1>
                            <p className="mt-2 ml-6">
                                1. If you sign up via social media
                                authentication methods, Trisekt will access
                                certain Personal Data (e.g. first name, surname,
                                email, etc.) in your social media account
                                pursuant to the applicable terms and conditions
                                of these social media platforms.
                            </p>
                            <p className="mt-2 ml-6">
                                2. We also collect aggregated information
                                regarding your activity on our Platform (such as
                                the amount of courses offered, your response
                                rate, etc.). Such information may be published
                                on your public profile on our Platform.
                            </p>

                            <h1 className="mt-2 ml-4 font-[600]">
                                <span className="font-[600]">4.2.3 </span>
                                Data retention
                            </h1>
                            <p className="mt-2 ml-6">
                                Your data will be retained and secured
                                indefinitely unless you explicitly request the
                                complete deletion of all your data. To do so,
                                please send an email to admin@trisekt.com titled
                                Data Deletion.
                            </p>
                        </div>
                        <div className="mt-2">
                            <h1 className="font-[600]">
                                5. Content and Conduct
                            </h1>

                            <p className="font-[600] mt-2 ml-2">
                                <span className="font-[600]">5.1 </span>
                                User-Generated Content
                            </p>
                            <p className="mt-2 ml-4">
                                Users are solely responsible for the content
                                they post on Trisekt. You agree not to upload,
                                share, or engage in any activity that violates
                                our Content Guidelines.
                            </p>

                            <p className="font-[600] mt-2 ml-2">
                                <span className="font-[600]">5.2 </span>
                                Prohibited Activities
                            </p>
                            <p className="mt-2 ml-4">
                                You may not engage in any activity that violates
                                applicable laws, infringes on intellectual
                                property rights, or disrupts the functioning of
                                Trisekt.
                            </p>
                        </div>

                        <div className="mt-2">
                            <h1 className="font-[600]">
                                6. Dispute Resolution
                            </h1>

                            <p className="font-[600] mt-2 ml-2">
                                <span className="font-[600]">6.1. </span>
                                Dispute Resolution
                            </p>
                            <p className="mt-2 ml-4">
                                Any disputes arising from the use of Trisekt
                                shall be resolved through arbitration in
                                accordance with our Dispute Resolution Policy.
                            </p>
                        </div>

                        <div className="mt-2">
                            <h1 className="font-[600]">
                                7. Limitation of Liability
                            </h1>

                            <p className="font-[600] mt-2 ml-2">
                                <span className="font-[600]">7.1 </span>
                                Disclaimer
                            </p>
                            <p className="mt-2 ml-4">
                                Trisekt is provided "as is," and we make no
                                warranties or representations regarding its
                                accuracy or reliability. We shall not be liable
                                for any damages arising from your use or
                                inability to use Trisekt.
                            </p>
                        </div>

                        <div className="mt-2">
                            <h1 className="font-[600] mt-2">8. Termination</h1>

                            <p className="font-[600] mt-2 ml-2">
                                <span className="font-[600] mt-2">8.1 </span>
                                Termination
                            </p>
                            <p className="mt-2 ml-4">
                                We reserve the right to terminate or suspend
                                your access to Trisekt at our discretion,
                                without notice, if you violate these terms or
                                engage in conduct that may harm other users or
                                the platform.
                            </p>
                        </div>

                        <div className="mt-2">
                            <h1 className="font-[600] mt-2">
                                9. Changes to Terms and Conditions
                            </h1>

                            <p className="font-[600] mt-2 ml-2">
                                <span className="font-[600] mt-2">9.1 </span>
                                Updates
                            </p>
                            <p className="mt-2 ml-4">
                                We may update these terms from time to time. It
                                is your responsibility to review them
                                periodically. Your continued use of Trisekt
                                after changes constitutes acceptance of the
                                revised terms.
                            </p>
                        </div>

                        <div className="mt-2">
                            <h1 className="font-[600]">
                                10. Contact Information
                            </h1>

                            <p className="font-[600] mt-2 ml-2">
                                <span className="font-[600] mt-2">10.1 </span>
                                Contact Us
                            </p>
                            <p className="mt-2 ml-4">
                                If you have any questions or concerns regarding
                                these terms, please contact us at
                                admin@trisekt.com.
                            </p>

                            <p className="mt-2 ml-4">
                                By using Trisekt, you acknowledge that you have
                                read, understood, and agree to these Terms and
                                Conditions. Thank you for choosing Trisekt to
                                further your education and training goals.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

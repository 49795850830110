import { UserInfo } from './Userinfo';
import { ContactInfo } from './Contactinfo';
import { OtherInfo } from './Otherinfo';
import { BankDetails } from './Bankdetails';
import { Achievements } from './Achievements';

export const TIprofile = ({
    formik,
    role,
    isViewable,
    email,
    CityInfo,
    search,
    places,
    dropdownVal,
    selectedInputValue,
}: any) => {
    return (
        <div className="space-y-10">
            <UserInfo
                role={role}
                formik={formik}
                cardTitle={'Institute'}
                isViewable={isViewable}
            />
            <ContactInfo
                isViewable={isViewable}
                role={role}
                formik={formik}
                email={email}
                CityInfo={CityInfo}
                search={search}
                places={places}
                dropdownVal={dropdownVal}
                selectedInputValue={selectedInputValue}
            />
            {!isViewable && (
                <OtherInfo
                    formik={formik}
                    isViewable={isViewable}
                />
            )}
            {!isViewable && (
                <BankDetails formik={formik} isViewable={isViewable} />
            )}
            {!isViewable && <Achievements formik={formik} />}
        </div>
    );
};

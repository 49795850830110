import { PulseLoader } from 'react-spinners';
import { useEffect } from "react";
import { authentication } from '../Firebase/firebase';
import { useNavigate } from 'react-router-dom';

export const AuthChecker = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/login");
  }, []);

  return (
      <div className='flex items-center h-[100vh] w-100 justify-center space-x-2'>
          <p>Checking your session</p>
          <PulseLoader color="#0074FC"/>
    </div>
  )
}

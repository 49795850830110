import { date } from "yup/lib/locale";

export const ProgressBar = ({ startDate, endDate }: any) => {
    let progressTime;
    let startTime = new Date(startDate).getTime();
    let endTime = new Date(endDate).getTime();
    let todayTime=new Date().getTime();
    let total=endTime-startTime;
    let current=Math.abs(todayTime-startTime);
    progressTime = Math.round((current / total) * 100);
    const progressWidth=`${progressTime}%`
    const progressStyle = ` h-full rounded-lg bg-[#2975FD] `
        return (
            <div className="relative text-[#5F5F5F] text-[12px]">
                <div className='h-[5px] relative rounded-lg bg-gray-300'>
                    <div className={`w-100 h-full rounded-l-lg absolute`}></div>
                    <div className={progressStyle} style={{width:progressWidth}}></div>
                </div>
                    <p className="mt-1 text-right">{progressTime}%</p>
            </div>
        );
    }
import { useRef, useState, useEffect } from 'react';
import { Input } from '../../Elements/Input/Input';
import curveLine from '../../../assets/Images/curveLine.png';
import { Providers } from '../Providers/Providers';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { CreateValidationSignSchema } from '../../../Services/Formik/Formik';
import toast from 'react-hot-toast';
import Login_Logo from '../../../assets/Images/Login_Logo.svg';
import {
    browserLocalPersistence,
    inMemoryPersistence,
    setPersistence,
    signInWithEmailAndPassword,
} from 'firebase/auth';
import { authentication } from '../../../Firebase/firebase';
import { readRecord } from '../../../Services/backend/apiCalls';
import { isTrainer } from '../../../Services/commonFunctions';
import { camelCaseConvertForErrorMessage } from '../../../Services/commonFunctions';
import { useDispatch } from 'react-redux';
import { setSignValue } from '../../../Redux/Reducers/signinUserReducers';
import { ALLOWED_USERS } from '../../../config';
import { BeatLoader } from 'react-spinners';
import { Checking } from './Checking';

export const roleBasedPageSwitch = async (
    role: any,
    callbackFunction: any,
    uid: any,
    token: any,
    storeDataDispatch: any,
    redirect_url: string | boolean
) => {
    localStorage.setItem('searchTerm', '');
    if (!role) {
        return callbackFunction('/role', { replace: true });
    }
    const userData = await readRecord({}, 'user');
    if (!userData.status) {
        return callbackFunction('/register/personal', { replace: true });
    }
    storeDataDispatch({
        username: userData.data.name,
        ...(!isTrainer(role) && {
            attendedCourses: [...new Set(userData.data.attended_courses)],
            directRequestedIds: userData.data.directRequestIds,
        }),
    });
    // callbackFunction(redirect_url || '/dashboard', { replace: true });
    // sessionStorage.getItem("lastExpiredRoute") ||
    callbackFunction('/dashboard', { replace: true });
    toast.success('logged successfully');
};

export const storeLoggedUserDetails = async (
    userObject: any,
    navigate: any,
    storeDataDispatch: any,
    redirect_url: string | boolean
) => {
    const user = userObject;
    const tokenResult = await user.getIdTokenResult();
    const { token, claims: userDetails } = tokenResult;
    if (userObject.providerId === null && !user.emailVerified) {
        return toast.error(
            camelCaseConvertForErrorMessage('please verify your email')
        );
    }
    const { user_id, email, role } = userDetails;
    if (!ALLOWED_USERS.includes(role)) {
        // console.log(role, "12")
        return toast.error('Unknown User');
    }
    localStorage.setItem('token', token);
    await storeDataDispatch({
        email,
        uid: user_id,
        role,
        isTokenExpired: false,
    });
    await roleBasedPageSwitch(
        role,
        navigate,
        user_id,
        token,
        storeDataDispatch,
        redirect_url
    );
};

export const authErrors: any = {
    'auth/user-not-found': 'Please sign up',
    'auth/email-already-exists':
        'Email Id already exists, Please Sign in to Continue',
    'auth/wrong-password': 'Invalid password',
    'auth/too-many-requests': 'Please try again after 30 seconds',
    'auth/invalid-login-credentials': 'Invalid Login Credentials',
};

export const SignIn = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const errormessage = searchParams.get('errormessage');
    const signInButtonRef = useRef<any>(null);
    const [loading, setLoading] = useState(false);

    const storeUserData = (data: any) => {
        dispatch(setSignValue(data));
    };

    const forgetpassword = () => {
        navigate('/forgot-password');
    };

    const handleSubmit = async () => {
        signInButtonRef.current.disabled = true;
        if (window.navigator.onLine) {
            setLoading(true);
            console.log(process.env.REACT_APP_API_KEY);
            console.log(process.env.REACT_APP_API_KEY2);

            const { email, password } = formik.values;
            try {
                const signedResult: any = await signInWithEmailAndPassword(
                    authentication,
                    email,
                    password
                );
                // console.log(signedResult, 'signedResult');
                // if (signedResult) {
                setPersistence(authentication, browserLocalPersistence);
                await storeLoggedUserDetails(
                    signedResult.user,
                    navigate,
                    storeUserData,
                    (location.state !== null && location.state.from) || false
                );
                // } else {
                //   toast.error(signedResult?.error?.message)
                // }
            } catch (error: any) {
                // console.log('ELSE_ELSE', authErrors[error.code], error.code, error);
                toast.error(
                    camelCaseConvertForErrorMessage(authErrors[error.code])
                );
            }
            setLoading(false);
        } else {
            toast.error(
                camelCaseConvertForErrorMessage(
                    'Please Check your Internet Connection'
                )
            );
        }
        signInButtonRef.current.disabled = false;
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: () => CreateValidationSignSchema('signin'),
        onSubmit: () => {
            handleSubmit();
        },
    });

    useEffect(() => {
        if (errormessage) {
            toast.error(errormessage);
            navigate('/login');
        }
    }, []);

    return (
        <div className="bg-[#FBFCFC] w-7/12 lg:w-11/12 flex justify-center items-center">
            <img src={curveLine} alt="" className="absolute top-0 right-0" />
            <form
                className="flex flex-col justify-center items-start w-9/12 lg:w-6/12 xl:w-4/12 mx-auto space-y-8"
                onSubmit={formik.handleSubmit}
            >
                <img src={Login_Logo} alt="Login_Logo" className="w-[190px]" />
                <div className="w-full space-y-4">
                    <Input
                        design={'w-full'}
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.email}
                        isTouched={formik.touched.email}
                        impo="impo"
                        focus={'focus'}
                    />
                    <Input
                        design={'w-full'}
                        paddingForEye={'pr-10'}
                        name="password"
                        label="Password"
                        type={'password'}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.password}
                        isTouched={formik.touched.password}
                        impo="impo"
                    />
                </div>
                <div className="w-full flex justify-end">
                    <p
                        className="text-[12px] font-[300] cursor-pointer"
                        onClick={forgetpassword}
                    >
                        Forgot Password?
                    </p>
                </div>
                <button
                    ref={signInButtonRef}
                    className="bg-gradient-to-r from-[#0074FC] to-[#00E0B8] flex items-center justify-center w-full p-3 text-[12px] font-[400] text-white mt-6 rounded-md"
                    type="submit"
                >
                    {loading ? (
                        <BeatLoader size={13} color="#fff" />
                    ) : (
                        'Sign In'
                    )}
                </button>
                <div className="flex items-center w-full pt-2">
                    <div className="flex-grow bg bg-gray-300 h-0.5"></div>
                    <div className="flex-grow-0 mx-5 text-[14px] font-[300]">
                        or
                    </div>
                    <div className="flex-grow bg bg-gray-300 h-0.5"></div>
                </div>
                <div className="flex flex-col w-full items-center">
                    <Providers />
                    <p className="text-[12px] font-[300] ">
                        Don't have an account ?{' '}
                        <span
                            className="text-[#0666ED] text-[13px] font-[500] cursor-pointer"
                            onClick={() => navigate('/register')}
                        >
                            Sign Up for free
                        </span>
                        <p
                            className="text-[#0666ED] sm:text-[12px] text-[10px] font-[400] cursor-pointer sm:text-center text-left "
                            onClick={() => navigate('/email-verification')}
                        >
                            Resend Email verification Link
                        </p>
                    </p>
                </div>
            </form>
        </div>
    );
};

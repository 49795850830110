import React from 'react';
import profileDropDown from "../../assets/Images/profileDropDown.svg";

type ArrowProps = {
    open: boolean,
    onClick:any
}

export const Arrow = ({open,onClick}:ArrowProps) => {
  return (
    <img src={profileDropDown} alt="arrow" className={`${open&&"rotate-180"} cursor-pointer`} onClick={onClick} />
  )
}
import axios from 'axios';
import toast from 'react-hot-toast';
import { NavigateToSignInWithRouteParams } from '../commonFunctions';

// const DigitalOceanUAT = 'https://seal-app-5kkvf.ondigitalocean.app/';

const DigitalOceanProduction =
    'https://production-api-vzdpe.ondigitalocean.app/';

// const backendUrl = 'http://localhost:8080/';

const axiosClient = axios.create({
    baseURL: DigitalOceanProduction,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

axiosClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // console.log({ error });
        let res = error.response;
        // console.log({res});
        if (error.code === 'ERR_NETWORK') {
            window.location.href = '/networkerror';
        }
        if (res.status === 401) {
            return NavigateToSignInWithRouteParams(res);
        }
        if (res) {
            // res.data.message && toast.error(res.data.message);
            return res;
        }
    }
);

export default axiosClient;

import React from 'react';
import { Arrow } from './Arrow';

export const Accordian = ({ name, openCondition, onClick, children }: any) => {
    return (
        <div className="space-y-4">
            <div>
                <div className="flex justify-between">
                    <p className="text-[16px] font-[600]">{name}</p>
                    <Arrow
                        open={openCondition}
                        onClick={() => onClick(console.log())}
                    />
                </div>
                <div className="border-b-[1px] bg-[#EBEBEB]"></div>
            </div>
            {openCondition && children}
        </div>
    );
};

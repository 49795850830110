import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from '../..';
import { useNavigate } from 'react-router-dom';
import { updateRecord } from '../../Services/backend/apiCalls';
import { dateFormat } from '../CMS/Role';
import { PulseLoader } from 'react-spinners';
import markAsRead from '../../assets/Images/markAsRead.svg';
import {
    showNotificationModal,
    showNotificationIsAvailable,
} from '../../Redux/Reducers/popupReducer';
import { setNotify } from '../../Redux/Reducers/notificationReducer';
import moment from 'moment';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const MarkAllNotificationRead = async (
    notifyResponse: any,
    token: any,
    navigate: any,
    dispatch: any
) => {
    const filterForIsRead = notifyResponse.filter(
        (data: any) => data['is_read'] === false
    );
    const mapForIsRead = filterForIsRead.map((read: any) => read['id']);

    const body = {
        notificationIds: mapForIsRead,
    };
    const response = await updateRecord(body, 'notifications');
    console.log(response);

    if (response.status) {
        dispatch(showNotificationIsAvailable(false));
        let assign_Data = [...notifyResponse];
        const mapForRefreshData = assign_Data.map((read: any) => ({
            ...read,
            is_read: true,
        }));
        dispatch(setNotify({ notifyResponse: mapForRefreshData }));
    } else toast.error(response.message);
};

export const ShowNotificationModal = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const notifyRef: any = useRef();

    const [{ token, role, username }, { showNotifyModal }, { notifyResponse }] =
        useAppSelector(({ signin, modal, notification }: any) => [
            signin['signinValue'],
            modal,
            notification['notificationData'],
        ]);
    const filterForIsRead = notifyResponse.filter(
        (data: any) => data['is_read'] === false
    );

    const notificationPageRoute = () => {
        dispatch(showNotificationModal(false));
        navigate('/notification');
    };

    const dataSetInHTMLTag = 'notification';

    return (
        <div
            className="rounded-[10px] space-y-10 scroll p-4 h-[45%] md:w-[50%] lg:w-[40%] xl:w-[35%] 2xl:w-[30%] bg-white shadow-xl absolute md:top-16 lg:top-14 md:right-[32%] lg:right-[25%] xl:right-[19%] 2xl:right-[14%] overflow-auto z-[20]"
            data-modaltype={dataSetInHTMLTag}
            ref={notifyRef}
        >
            <div
                className="flex items-center justify-between"
                data-modaltype={dataSetInHTMLTag}
            >
                <h1
                    className="text-[23px] font-[500] mr-3"
                    data-modaltype={dataSetInHTMLTag}
                >
                    Notifications
                </h1>
                {notifyResponse.length > 0 && filterForIsRead.length > 0 && (
                    <div
                        className="flex items-center gap-2 cursor-pointer"
                        data-modaltype={dataSetInHTMLTag}
                    >
                        <img src={markAsRead} alt="MarkAsRead" />
                        <p
                            className="text-[#2975FD] text-[18px] font-[500]"
                            data-modaltype={dataSetInHTMLTag}
                            onClick={() =>
                                MarkAllNotificationRead(
                                    notifyResponse,
                                    token,
                                    navigate,
                                    dispatch
                                )
                            }
                        >
                            Mark as read
                        </p>
                    </div>
                )}
            </div>
            <div className="h-[60%] space-y-4">
                {loading ? (
                    <div
                        className="flex justify-center items-center h-full"
                        data-modaltype={dataSetInHTMLTag}
                    >
                        <PulseLoader color="#0074FC" />
                    </div>
                ) : notifyResponse.length > 0 ? (
                    notifyResponse
                        .slice(0, 3)
                        .map((note: any, index: number) => {
                            return (
                                <div
                                    key={index}
                                    data-modaltype={dataSetInHTMLTag}
                                    className={`flex gap-1 cursor-pointer ${
                                        note['is_read']
                                            ? 'bg-white'
                                            : 'bg-[#F6F6F6]'
                                    }`}
                                >
                                    <div
                                        className="px-4 py-4 mt-1"
                                        data-modaltype={dataSetInHTMLTag}
                                    >
                                        <p
                                            data-modaltype={dataSetInHTMLTag}
                                            className="w-[10px] h-[10px] rounded-full bg-blue-600 mt-1.5"
                                        ></p>
                                    </div>
                                    <div
                                        className="w-full py-4 border-b-[0.5px] border-[#b3d6e4]"
                                        data-modaltype={dataSetInHTMLTag}
                                    >
                                        <p
                                            className=""
                                            data-modaltype={dataSetInHTMLTag}
                                        >
                                            {note.message}
                                        </p>
                                        <p
                                            className="mt-1 text-[#808283]"
                                            data-modaltype={dataSetInHTMLTag}
                                        >
                                            {dateFormat(note.createdAt)}
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                ) : (
                    <p
                        className="flex justify-center items-center"
                        data-modaltype={dataSetInHTMLTag}
                    >
                        No Notifications
                    </p>
                )}
                {notifyResponse && notifyResponse.length > 3 && (
                    <h1
                        className="w-10/12 mx-auto text-[#2975FD] text-[18px] font-[500] pb-4"
                        data-modaltype={dataSetInHTMLTag}
                    >
                        <span
                            className="cursor-pointer"
                            onClick={notificationPageRoute}
                            data-modaltype={dataSetInHTMLTag}
                        >
                            View all Notifications
                        </span>
                    </h1>
                )}
            </div>
        </div>
    );
};

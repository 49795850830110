import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../..';
import { setCompareCourse } from '../../Redux/Reducers/comparecoursesReducers';
import CloseIconForCC from '../../assets/Images/CloseIconForCC.svg';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const CompareCourseModal = ({
    CourseInfo,
    CourseInfoChange,
    checkedStatus,
    checkedStatusChange,
}: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { token } = useAppSelector(
        ({ signin }: any) => signin['signinValue']
    );

    const compareSelectedCoursesFunc = async () => {
        if (checkedStatus.length > 1) {
            const courseIds = CourseInfo.map((course: any) => course.id);
            const courseTrainerId = CourseInfo.map(
                (course: any) => course.trainer_id
            );
            navigate('/comparing-course', {
                state: {
                    courseIds: courseIds,
                    courseTrainerId: courseTrainerId,
                },
            });
        } else
            toast.error(
                camelCaseConvertForErrorMessage('Select Minimum 2 Courses')
            );
    };

    const courseCloseButton = (status: any) => {
        const removeID = CourseInfo.filter(
            (idx: any) => idx['id'] !== status.id
        );
        CourseInfoChange([...removeID]);
        const removeIdForChecked = checkedStatus.filter(
            (idx: any) => idx !== status.id
        );
        checkedStatusChange([...removeIdForChecked]);
    };

    const clearAll = () => {
        checkedStatusChange([]);
        CourseInfoChange([]);
        dispatch(setCompareCourse({ cardState: false }));
    };

    return (
        <div className="flex flex-col w-full">
            {/* Bottom Overlay for Modal */}
            <div className="h-auto w-full flex items-center justify-center bg-[#000000] bg-opacity-[0.86] fixed bottom-0 left-0 overflow-hidden z-[50]">
                <div className="flex flex-col space-y-4 p-4 w-full max-w-4xl">
                    {/* Header Section */}
                    <div className="flex justify-between items-center gap-4 w-full bg-black p-2 rounded-md">
                        <h1 className="text-[14px] sm:text-[16px] md:text-[18px] font-[500] text-white">
                            Compare ({checkedStatus.length})
                        </h1>
                        <div className="flex items-center gap-4">
                            <p
                                className="text-white text-[14px] sm:text-[16px] md:text-[18px] font-[400] cursor-pointer"
                                onClick={clearAll}
                            >
                                Clear
                            </p>
                            <button
                                className="bg-[#085BEB] p-3 text-white text-[14px] sm:text-[16px] md:text-[18px] font-[400] rounded-md shadow-md"
                                onClick={compareSelectedCoursesFunc}
                            >
                                Compare
                            </button>
                        </div>
                    </div>
                    {/* Course List Section */}
                    <div className="flex flex-wrap gap-4 justify-center overflow-y-auto max-h-[300px]">
                        {CourseInfo.map((status: any, index: number) => {
                            return (
                                <div
                                    className="flex justify-center w-full sm:w-[270px]"
                                    key={index}
                                >
                                    <div className="flex flex-col gap-2 px-4 py-4 bg-white relative w-full sm:w-[270px] rounded-md shadow-md">
                                        <img
                                            src={CloseIconForCC}
                                            alt="CloseIcon"
                                            className="absolute top-2 right-2 cursor-pointer w-[20px] sm:w-[22px] h-[20px] sm:h-[22px]"
                                            onClick={() =>
                                                courseCloseButton(status)
                                            }
                                        />
                                        <div className="mt-6">
                                            <img
                                                src={status['image']}
                                                alt={status['image']}
                                                className="w-full h-[100px] sm:h-[120px] object-contain"
                                            />
                                            <h1 className="mt-2 text-lg sm:text-xl font-[500] pl-3 capitalize">
                                                {status['title']}
                                            </h1>
                                            <p className="text-[#999999] text-[12px] sm:text-[14px] font-[400] pl-3">
                                                {status['name']}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

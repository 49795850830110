// import firebase from "firebase";
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// const firebaseConfig = {
//   apiKey: "AIzaSyB5piB4frT_YGTYKQ2ee5p0J_EoBvw1jPg",
//   authDomain: "trisekt-c1b63.firebaseapp.com",
//   projectId: "trisekt-c1b63",
//   storageBucket: "trisekt-c1b63.appspot.com",
//   messagingSenderId: "322489740015",
//   appId: "1:322489740015:web:9119aad2df01659438091a"
// };

const firebaseConfig = {
    apiKey: 'AIzaSyBDJFONQVnB4Y6ulBUXTEfdKWyxg7a_0Lw',
    authDomain: 'trisekt-c1b63.firebaseapp.com',
    projectId: 'trisekt-c1b63',
    storageBucket: 'trisekt-c1b63.appspot.com',
    messagingSenderId: '322489740015',
    appId: '1:322489740015:web:9119aad2df01659438091a',
};

const app = initializeApp(firebaseConfig);

// getAuth;
// const storage = firebase.storage();

// const db = app.firestore();

// const authentication  = getAuth(app)
export const storage = getStorage(app);

export const authentication: any = getAuth(app);

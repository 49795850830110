import moment from "moment";

export const RoleCard = ({ role }: any) => {
  // console.log(role);
    const roleConfig:any = {
      TI: {
        bg: "bg-[#D3F9EB]",
        color: "text-[#07BEA1]",
        name:"Institute"
      },
      FL: {
        bg: "bg-[#D3F9EB]",
        color: "text-[#07BEA1]",
        name:"Freelancer"
      },
      CO: {
        bg: 'bg-[#E0FFF3]',
        color: 'text-[#33BD87]',
        name:"Corporate"
      },
      ST: {
        bg: "bg-[#E7EFFF]",
        color: "text-[#4C8CFD]",
        name:"Student"
      },
    }
  
  // console.log(roleConfig[role], "role");

  return (
      <p className={`ml-3 text-[10px] rounded-[12px] px-3 p-0.5 font-[400] ${roleConfig[role]["bg"]} ${roleConfig[role]["color"]}`}>{roleConfig[role]["name"]}</p>
    )
  }

export const dateFormat=(dateText:string)=>{
    let text;
    if(moment(dateText).format("DD-MMM-YYYY") !== "Invalid date"){
        text= moment(dateText).format("DD-MMM-YYYY")
    }else{
        text=  moment(new Date()).format("DD-MMM-YYYY")
    }
    return text;
}
import { useState,useRef } from "react";
import toast from "react-hot-toast";
import { useAppSelector, useAppDispatch } from "../..";
import { updateRecord, deleteRecord } from "../../Services/backend/apiCalls";
import { Feedback } from "./Feedback";
import { ReplyButton, DeleteButton } from "./Buttons";
import { setViewCourse } from "../../Redux/Reducers/viewCourseReducers";
import { ButtonCompWithActionController } from "../Elements/Button/Button";

export const ReplayFeedback = ({
    index,
    removeReviewId,addNewPost,
    id,
    userRole,
    clickedReplyId,
    setClickedReplyId,
    status,
    checkUser
}: any) => {
    const dispatch = useAppDispatch();
    const [replyStatus, setReplyStatus] = useState('');
    const replyButtonRef = useRef(null);
    // const [replied, setReplied] = useState(false);
    const { token, username } = useAppSelector(
        ({ signin }: any) => signin['signinValue']
    );
    const { reviews } = useAppSelector(({ view }: any) => view['ReviewData']);

    const SendingReply = async (repliedID: any) => {
        const body = {
            id: Number(repliedID),
            reply: replyStatus,
        };
        const replyForReview = await updateRecord(body,"reviews");
        if (replyForReview.status) {
            setReplyStatus('');
            const val = [...reviews];
            val[index] = { ...val[index], reply: replyStatus };
            dispatch(setViewCourse({ reviews: val }));
            toast.success(replyForReview.message);
        } else {
            toast.error(replyForReview.message);
        }
    };

    const deleteReplyCommented = async () => {
        const body = {
            id: status.id,
            reply: null
        }
        let deleteResponse = await updateRecord(body, 'reviews');
        if (deleteResponse.status) {
            setReplyStatus('');
            const val = [...reviews];
            val[index] = { ...val[index], reply: null };
            dispatch(setViewCourse({ reviews: val }));
            toast.success('Reply Comment Deleted')
        } else {
            toast.error(deleteResponse.message)
        }
    }

    return (
        <div>
            {!userRole && status && status.reply === null ? (
                <div>
                    <div className="flex gap-4">
                        <ReplyButton
                            status={status.id}
                            setClickedReplyId={setClickedReplyId}
                        />
                    </div>
                    {clickedReplyId === status.id ? (
                        <div className="flex w-11/12 xl:w-11/12 justify-between mt-4">
                            <div className="w-[40px] h-[40px] bg-black rounded-full text-white flex items-center justify-center">
                                {username.charAt(0)}
                            </div>
                            <input
                                name="comment"
                                className="border rounded-full w-8/12 xl:w-9/12 px-4"
                                value={replyStatus}
                                onChange={(e: any) =>
                                    setReplyStatus(e.target.value)
                                }
                            />
                            <ButtonCompWithActionController
                                Ref={replyButtonRef}
                                action={() => SendingReply(status.id)}
                                className="bg-[#3061F4] text-white rounded-[20px] p-1 px-5 text-[14px] font-[500]"
                                buttonText="Send"
                            />
                            {/* <button
                                className="bg-[#3061F4] text-white rounded-[20px] p-1 px-5 text-[14px] font-[500]"
                                onClick={() => SendingReply(status.id)}
                            >
                                Send
                            </button> */}
                        </div>
                    ) : null}
                </div>
            ) : (
                <div>
                    <Feedback
                        status={status}
                        userRole={userRole}
                        username={username}
                        checkUser={checkUser}
                    />
                    {checkUser && <p
                        className="text-[#267DF8] text-[14px] font-[500] mt-4 cursor-pointer"
                        onClick={deleteReplyCommented}
                    >
                        Delete
                    </p>}
                </div>
            )}
        </div>
    );
};
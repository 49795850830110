import { useState, useRef, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Input } from '../Elements/Input/Input';
import { Dropdown } from '../Elements/Dropdown/Dropdown';
import { ResumeFile } from './ResumeFile';
import { FreetextDropdown } from '../Elements/FreetextDropdown';
import { Cities } from './SignupUserDetails';
import closeBtn from '../../assets/Images/closeBtn.svg';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

const industryOptions = ['IT Trainings', 'Embedded System'];

export const SignUpDetailsForm = ({
    search,
    formik,
    role,
    CityInfo,
    resumefile,
    dropdownVal,
    toggleDropdown,
    places,
    selectedInputValue,
    resumeStatus,
    deleteFile,
    uploadProfilePicture,
    profilePictureStatus,
    deleteProfilePicture,
}: any) => {
    const [isIndustryDropdownOpen, setIsIndustryDropdownOpen] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleIndustrySelect = (option: any) => {
        const selectedOptions = formik.values.industry
            ? formik.values.industry.split(',')
            : [];
        if (selectedOptions.includes(option)) {
            formik.setFieldValue(
                'industry',
                selectedOptions.filter((item: any) => item !== option).join(',')
            );
        } else {
            formik.setFieldValue(
                'industry',
                [...selectedOptions, option].join(',')
            );
        }
    };

    const handleClickOutside = (event: any) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setIsIndustryDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="w-full space-y-2 xl:space-y-6 z-10 p-4">
            {role === 'TI' ? (
                <>
                    <Input
                        name="name"
                        label="Institute Name"
                        labeldesign={'text-[14px] xl:text-[18px] font-[400]'}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.name}
                        isTouched={formik.touched.name}
                    />
                    <Input
                        name="url"
                        label="Institute URL"
                        placeholder="Starts with protocol https:// or http://"
                        labeldesign={'text-[14px] xl:text-[18px] font-[400]'}
                        value={formik.values.url}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.url}
                        isTouched={formik.touched.url}
                    />
                    <div className="relative" ref={dropdownRef}>
                        <label className="text-[14px] xl:text-[18px] font-[400]">
                            Industry
                        </label>
                        <div
                            className="border p-2 cursor-pointer flex justify-between items-center"
                            onClick={() =>
                                setIsIndustryDropdownOpen(
                                    !isIndustryDropdownOpen
                                )
                            }
                        >
                            <span>
                                {formik.values.industry
                                    ? formik.values.industry
                                          .split(',')
                                          .join(', ')
                                    : 'Select options'}
                            </span>
                            <span className="ml-2">
                                {isIndustryDropdownOpen ? '▲' : '▼'}
                            </span>
                        </div>
                        {isIndustryDropdownOpen && (
                            <div className="absolute bg-white border mt-1 max-h-60 overflow-y-auto z-10 w-full rounded-md shadow-lg">
                                {industryOptions.map((option) => (
                                    <div
                                        key={option}
                                        onClick={() =>
                                            handleIndustrySelect(option)
                                        }
                                        className="p-2 cursor-pointer hover:bg-gray-100 flex items-center"
                                    >
                                        <input
                                            type="checkbox"
                                            checked={formik.values.industry
                                                .split(',')
                                                .includes(option)}
                                            onChange={() =>
                                                handleIndustrySelect(option)
                                            }
                                            className="mr-2"
                                        />
                                        <span>{option}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                        {formik.touched.industry && formik.errors.industry && (
                            <div className="text-red-600 text-sm">
                                {formik.errors.industry}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <>
                    {['ST', 'FL'].includes(role) && (
                        <div className="flex flex-wrap justify-between">
                            <Input
                                name="first_name"
                                label="First Name"
                                value={formik.values.first_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.first_name}
                                isTouched={formik.touched.first_name}
                                design={'mt-2 w-full md:w-5/12'}
                            />
                            <Input
                                name="last_name"
                                label="Last Name"
                                value={formik.values.last_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.last_name}
                                isTouched={formik.touched.last_name}
                                design={'mt-2 w-full md:w-5/12'}
                            />
                        </div>
                    )}
                    {role === 'CO' && (
                        <Input
                            name="organization_name"
                            label="Organization Name"
                            value={formik.values.organization_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.errors.organization_name}
                            isTouched={formik.touched.organization_name}
                            design={'mt-0'}
                        />
                    )}
                </>
            )}
            <Input
                name="contact_number"
                label="Contact Number"
                labeldesign={'text-[14px] xl:text-[18px] font-[400]'}
                value={formik.values.contact_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.contact_number}
                isTouched={formik.touched.contact_number}
                margin={'margin'}
            />
            {role === 'CO' && (
                <Input
                    name="company_url"
                    label="Company URL"
                    value={formik.values.company_url}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.company_url}
                    isTouched={formik.touched.company_url}
                />
            )}
            <div
                className={`${
                    role === 'FL' && 'flex flex-wrap justify-between'
                }`}
            >
                <Dropdown
                    role={role}
                    label="City"
                    labeldesign={'text-[14px] xl:text-[18px] font-[400]'}
                    Cities={Cities}
                    name="city"
                    val={formik.values.city}
                    onChange={(e: any) => {
                        formik.handleChange(e);
                        CityInfo(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.errors.city}
                    isTouched={formik.touched.city}
                />
                {role === 'FL' && (
                    <Input
                        name="industry"
                        label="Industry"
                        value={formik.values.industry}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.industry}
                        isTouched={formik.touched.industry}
                        design={'mt-2 w-full md:w-5/12'}
                    />
                )}
            </div>
            {role === 'FL' && (
                <>
                    <Input
                        name="linkedin_url"
                        label="Linkedln Profile"
                        value={formik.values.linkedin_url}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.linkedin_url}
                        isTouched={formik.touched.linkedin_url}
                        design={'mt-2'}
                        impo="impo"
                    />
                    <ResumeFile
                        resumefile={resumefile}
                        resumeStatus={resumeStatus}
                        formik={formik}
                        deleteFile={deleteFile}
                    />
                </>
            )}
            {role === 'FL' && (
                <Input
                    name="skills"
                    label="Skills"
                    value={formik.values.skills}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.skills}
                    isTouched={formik.touched.skills}
                    placeholder={'eg. skill1, skill2 ...'}
                    margin={'margin'}
                />
            )}
            <div className="relative">
                <Input
                    name="address_line"
                    label="Address"
                    labeldesign={'text-[14px] xl:text-[18px] font-[400]'}
                    value={formik.values.address_line}
                    onChange={(e: any) => {
                        formik.handleChange(e);
                        search(e.target.value);
                    }}
                    onClick={toggleDropdown}
                    onBlur={formik.handleBlur}
                    error={formik.errors.address_line}
                    isTouched={formik.touched.address_line}
                    size={'size'}
                />
                {dropdownVal && (
                    <FreetextDropdown
                        places={places}
                        onClick={selectedInputValue}
                    />
                )}
            </div>

            <div>
                <ProfilePicture
                    uploadProfilePicture={uploadProfilePicture}
                    profilePictureStatus={profilePictureStatus}
                    formik={formik}
                    deleteProfilePicture={deleteProfilePicture}
                />{' '}
            </div>
        </div>
    );
};

export const ProfilePicture = ({
    uploadProfilePicture,
    profilePictureStatus,
    deleteProfilePicture,
    formik,
}: any) => {
    return (
        <div className="mt-2">
            <label className="text-[14px] xl:text-[18px] font-[400]">
                Profile Picture
            </label>
            <div className="flex items-center mt-2">
                <input
                    type="file"
                    accept="image/*"
                    onChange={uploadProfilePicture}
                    className="hidden"
                    id="profilePicture"
                />
                <label
                    htmlFor="profilePicture"
                    className="cursor-pointer bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-2 text-white rounded-[8px] text-[12px] font-[300]"
                >
                    Upload Profile Picture
                </label>
                {profilePictureStatus && (
                    <div className="flex items-center ml-4">
                        <span className="text-[14px]">
                            {profilePictureStatus}
                        </span>
                        <img
                            src={closeBtn}
                            alt="Delete"
                            className="ml-2 cursor-pointer"
                            onClick={() =>
                                deleteProfilePicture(
                                    formik.values.profilePicture.name
                                )
                            }
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

import { useState, useEffect } from 'react';
import { Tiptap } from '../Tiptap/Tiptap';
import { Details } from '../Tiptap/Details';
import parse from 'html-react-parser';
import { useAppSelector, useAppDispatch } from '../..';
import { count } from 'console';
import { saveProfile } from '../../Redux/Reducers/comparecoursesReducers';

function extractInnerTextFromHTML(htmlString: any) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.textContent || tempElement.innerText;
}

export const Achievements = ({ formik }: any) => {
    const { isSaveAchivement } = useAppSelector(
        (state: any) => state.compare.compareCoursesData
    );
    const dispatch = useAppDispatch();

    const [description, setDescription] = useState('');

    let [defaultValue, setDefaultValue] = useState('');

    let [value, setValue] = useState('');

    let [isShow, setIsShow] = useState(false);

    const [count, setCount] = useState(0);

    setTimeout(() => {
        setIsShow(true);
    }, 2000);
    useEffect(() => {
        if (formik.values.achievements && count === 0 && isSaveAchivement) {
            setCount(count + 1);
            setDefaultValue(formik.values.achievements);
            dispatch(saveProfile(false));
        }
    }, [isSaveAchivement]);

    useEffect(() => {
        if (formik.values.achievements) {
            setValue(formik.values.achievements);
            dispatch(saveProfile(true));
        }
        if (formik.values.achievements && count === 0 && isSaveAchivement) {
            setCount(count + 1);
            setDefaultValue(formik.values.achievements);
            dispatch(saveProfile(false));
        }
    }, [formik.values.achievements]);

    return (
        <div>
            <h1 className="text-[#2580F5] text-[25px] font-[500] px-6">
                Highlight your Achievements
            </h1>
            <div className="bg-white p-12 rounded-[30px] mt-4 space-y-4">
                <div className="flex flex-col gap-2 sm:w-auto w-56">
                    <label className="text-lg">Achievements</label>
                    <div className="flex flex-col gap-4  bg-[#f6f7f8]">
                        {isShow ? (
                            <Tiptap
                                setDescription={setDescription}
                                formik={formik}
                                value={value}
                            />
                        ) : null}
                        {/* <Details
                            description={description}
                            formik={formik}
                            defaultValue={defaultValue}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

// import {useState, useEffect, useRef} from 'react';
// import { useNavigate,useLocation, createSearchParams } from 'react-router-dom';
// import { useFormik } from "formik";
// import { CO, ST, TI, FL } from '../../Services/Formik/Formik';
// import { Button } from '../Elements/Button/Button';
// import toast from 'react-hot-toast';
// import {  postRecord, readRecord,deleteRecord} from '../../Services/backend/apiCalls';
// import { PulseLoader } from "react-spinners";
// import { useAppSelector, useAppDispatch } from '../..';
// import {storage} from "../../Firebase/firebase";
// import {ref,uploadBytesResumable,getDownloadURL} from "firebase/storage";
// import { useDebounce } from "../../hooks/DebounceHook";
// import { InputJSON } from './InputJSON';
// import { SignUpDetailsForm } from './SignupForm';
// import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
// import { deleteObject } from 'firebase/storage';
// import { Back } from '../Elements/back';
// import { ResumeFile } from './ResumeFile';

// export const Cities = ["bangalore", "chennai", "kolkata", "mumbai", "delhi", "pune"];

// export const SignupUserDetails = ({ input}:any) => {
//     const location = useLocation();
//     const [places, setPlaces] = useState([]);
//     const [dropdownVal, setDropdownVal] = useState(false);
//     let [loading, setLoading] = useState(false);
//     const [resumeStatus, setResumeStatus] = useState("");
//     const [citySelected, setCitySelected] = useState("bangalore");
//     const navigate = useNavigate();
//     const {uid,role} = useAppSelector(({signin}:any)=>signin["signinValue"]);
//     const [searchTerm, setSearchTerm] = useState("");
//     const debouncedSearchTerm = useDebounce(searchTerm, 500);
//     const dropdownRef: any = useRef(null);

//     const roleForSchema:any = {
//         "CO" : CO,
//         "ST" : ST,
//         "TI" : TI,
//         "FL" : FL
//     }

//     const CityInfo = (e:any) => {
//         setCitySelected(e.target.value);
//         formik.setFieldValue("address_line", '')
//         setDropdownVal(false)
//     }

//     useEffect(() => {
//         const handleClickOutside = (event:any) => {
//         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//             setDropdownVal(false);
//         }
//         };
//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//         document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, []);

//     useEffect(() => {
//         if (debouncedSearchTerm) {
//           (async () => {
//             setDropdownVal(searchTerm.length >= 3 ? true : false)
//               formik.setFieldValue("city", citySelected);
//             //   console.log(citySelected, "citySelected", debouncedSearchTerm, "debouncedSearchTerm")
//             const SuggesionInput = await readRecord({input:debouncedSearchTerm,city:citySelected},"placesuggestion");
//             // console.log(SuggesionInput, "SuggesionInput")
//             if(SuggesionInput.status) {
//                 setPlaces(SuggesionInput.places);
//             }else{
//                 toast.error(SuggesionInput.message)
//             }
//           })()
//         }
//     },[debouncedSearchTerm])

//     const selectedInputValue = (val:any, id:any) => {
//         // console.log(val,id,"valid")
//         setDropdownVal(false)
//         if(role === "TI"){
//             formik.setFieldValue("branch_id", id)
//         }
//         const getValue = val.split(',');
//         const country = getValue[getValue.length-1];
//         formik.setFieldValue("country", country)
//         const state = getValue[getValue.length-2];
//         formik.setFieldValue("state", state)
//         formik.setFieldValue("address_line", val)
//     }

//     const handleDirectSubmit = async () => {
//         setLoading(true);
//         const { skills, contact_number, ...rest } = formik.values;
//         const body = {
//             ...rest,
//             contact_number: contact_number,
//             ...(skills && { skills: skills.split(',') }),
//         };
//         const response = await postRecord(body, "profiles");
//         if(response.status){
//             navigate("/register/status");
//         } else toast.error(response.message)
//         setLoading(false);
//     }

//     const formik = useFormik({
//         initialValues: InputJSON[role],
//         validationSchema: roleForSchema[role],
//         onSubmit: () => {
//             handleDirectSubmit()
//         }
//       });

//       const uploadResumeFile=async(e:any)=>{
//           const file = e.target.files[0];
//         const fileName=`${file?.name}_${uid}` + "_resume";
//           if (!e.target.files.length) {
//               return
//           }
//               setResumeStatus('uploading...');
//           const storageRef = ref(storage, `/resumes/${fileName}`);
//           const uploadTask = uploadBytesResumable(storageRef, file)
//             uploadTask.on("state_changed",(snapshot:any) => {},(err) => console.log(err),() => {
//                     // download url
//                     getDownloadURL(uploadTask.snapshot.ref).then((url) => {
//                         // console.log(url);
//                         // formik.setFieldValue("resume_url", url);
//                         formik.setFieldValue('resume.url', url);
//                         formik.setFieldValue('resume.name', file?.name);
//                         formik.setFieldValue('resume.key', fileName);
//                         setResumeStatus(file.name);
//                         // toast.success("uploaded successfully");
//                     });
//                     }
//         );
//     }

//     const deleteFile = async () => {
//               setResumeStatus('deleting...');

//             const deleteStorageRef = ref(storage, `/resumes/${uid+"_resume"}`);
//             deleteObject(deleteStorageRef).then(async () => {
//                 formik.setFieldValue("resume", {
//                     url: "",
//                     name: "",
//                     key: ""
//                 });
//                 setResumeStatus("");
//             }).catch((err) => {
//                 console.log("error occurred", err);
//             })
//     }

//     const toggleDropdown = () => {
//         setDropdownVal(!dropdownVal);
//     };

//   return (
//     <>
//     {loading ? <div className='h-[100vh] w-7/12 lg:w-11/12 flex items-center justify-center mx-auto'>
//     <PulseLoader color="#0074FC" />
//     </div> :
//               <form className='w-11/12 h-full scroll-smooth flex justify-center' onSubmit={formik.handleSubmit}>
//         <div className='flex flex-col justify-center mx-auto space-y-6 xl:space-y-10 w-8/12 lg:w-6/12 xl:w-6/12' ref={dropdownRef}>
//             <SignUpDetailsForm resumefile={uploadResumeFile} deleteFile={deleteFile} places={places} resumeStatus={resumeStatus} formik={formik} role={role} CityInfo={CityInfo} dropdownVal={dropdownVal} toggleDropdown={toggleDropdown} selectedInputValue={selectedInputValue} search={setSearchTerm} />
//             <div className='flex items-center justify-between'>
//                 <Button className='z-0 w-5/12 bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-3 text-white rounded-[8px] px-10 text-[12px] font-[300]' onClick={() => navigate({
//                     pathname: "/role",
//                     search:createSearchParams({role}).toString()
//                 })} type="button" btnName="Back" />
//                 <Button className='z-0 w-5/12 bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-3 text-white rounded-[8px] px-10 text-[12px] font-[300]' type="submit" btnName="Complete" />
//             </div>
//         </div>
//     </form>}
//     </>
//   )
// }

// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
// import { useFormik } from 'formik';
// import { CO, ST, TI, FL } from '../../Services/Formik/Formik';
// import { Button } from '../Elements/Button/Button';
// import toast from 'react-hot-toast';
// import {
//     postRecord,
//     readRecord,
//     deleteRecord,
// } from '../../Services/backend/apiCalls';
// import { PulseLoader } from 'react-spinners';
// import { useAppSelector, useAppDispatch } from '../..';
// import { storage } from '../../Firebase/firebase';
// import {
//     ref,
//     uploadBytesResumable,
//     getDownloadURL,
//     deleteObject,
// } from 'firebase/storage';
// import { useDebounce } from '../../hooks/DebounceHook';
// import { InputJSON } from './InputJSON';
// import { SignUpDetailsForm } from './SignupForm';
// import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
// import { Back } from '../Elements/back';
// import { ResumeFile } from './ResumeFile';

// import { v4 as uuidv4 } from 'uuid'; // Import uuid

// export const Cities = [
//     'bangalore',
//     'chennai',
//     'kolkata',
//     'mumbai',
//     'delhi',
//     'pune',
// ];

// export const SignupUserDetails = ({ input }: any) => {
//     const location = useLocation();
//     const [places, setPlaces] = useState([]);
//     const [dropdownVal, setDropdownVal] = useState(false);
//     let [loading, setLoading] = useState(false);
//     const [resumeStatus, setResumeStatus] = useState('');
//     const [profilePicStatus, setProfilePicStatus] = useState('');
//     const [citySelected, setCitySelected] = useState('bangalore');
//     const navigate = useNavigate();
//     const { uid, role } = useAppSelector(
//         ({ signin }: any) => signin['signinValue']
//     );
//     const [searchTerm, setSearchTerm] = useState('');
//     const debouncedSearchTerm = useDebounce(searchTerm, 500);
//     const dropdownRef: any = useRef(null);

//     const roleForSchema: any = {
//         CO: CO,
//         ST: ST,
//         TI: TI,
//         FL: FL,
//     };

//     const CityInfo = (e: any) => {
//         setCitySelected(e.target.value);
//         formik.setFieldValue('address_line', '');
//         setDropdownVal(false);
//     };

//     useEffect(() => {
//         const handleClickOutside = (event: any) => {
//             if (
//                 dropdownRef.current &&
//                 !dropdownRef.current.contains(event.target)
//             ) {
//                 setDropdownVal(false);
//             }
//         };
//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, []);

//     useEffect(() => {
//         if (debouncedSearchTerm) {
//             (async () => {
//                 setDropdownVal(searchTerm.length >= 3 ? true : false);
//                 formik.setFieldValue('city', citySelected);
//                 //   console.log(citySelected, "citySelected", debouncedSearchTerm, "debouncedSearchTerm")
//                 const SuggesionInput = await readRecord(
//                     { input: debouncedSearchTerm, city: citySelected },
//                     'placesuggestion'
//                 );
//                 // console.log(SuggesionInput, "SuggesionInput")
//                 if (SuggesionInput.status) {
//                     setPlaces(SuggesionInput.places);
//                 } else {
//                     toast.error(SuggesionInput.message);
//                 }
//             })();
//         }
//     }, [debouncedSearchTerm]);

//     const selectedInputValue = (val: any, id: any) => {
//         // console.log(val,id,"valid")
//         setDropdownVal(false);
//         if (role === 'TI') {
//             formik.setFieldValue('branch_id', id);
//         }
//         const getValue = val.split(',');
//         const country = getValue[getValue.length - 1];
//         formik.setFieldValue('country', country);
//         const state = getValue[getValue.length - 2];
//         formik.setFieldValue('state', state);
//         formik.setFieldValue('address_line', val);
//     };

//     const handleDirectSubmit = async () => {
//         setLoading(true);
//         const { skills, branch_id, contact_number, ...rest } = formik.values;
//         let body = {
//             ...rest,
//             // branch_id: branch_id + uuidv4(), // Generate unique branch_id update by me
//             contact_number: contact_number,
//             ...(skills && { skills: skills.split(',') }),
//         };
//         if (role === 'TI') {
//             body = {
//                 ...body,
//                 branch_id: formik.values.branch_id + uuidv4(), // Generate unique branch_id
//             };
//         }
//         console.log(body, 'body');

//         const response = await postRecord(body, 'profiles');
//         console.log(response, 'responseeeee');

//         if (response.status) {
//             navigate('/register/status');
//         } else toast.error(response.message);
//         setLoading(false);
//     };

//     const formik = useFormik({
//         initialValues: InputJSON[role],
//         validationSchema: roleForSchema[role],
//         onSubmit: () => {
//             handleDirectSubmit();
//         },
//     });

//     const uploadResumeFile = async (e: any) => {
//         const file = e.target.files[0];
//         const fileName = `${file?.name}_${uid}` + '_resume';
//         if (!e.target.files.length) {
//             return;
//         }
//         setResumeStatus('uploading...');
//         const storageRef = ref(storage, `/resumes/${fileName}`);
//         const uploadTask = uploadBytesResumable(storageRef, file);
//         uploadTask.on(
//             'state_changed',
//             (snapshot: any) => {},
//             (err) => console.log(err),
//             () => {
//                 // download url
//                 getDownloadURL(uploadTask.snapshot.ref).then((url) => {
//                     // console.log(url);
//                     // formik.setFieldValue("resume_url", url);
//                     formik.setFieldValue('resume.url', url);
//                     formik.setFieldValue('resume.name', file?.name);
//                     formik.setFieldValue('resume.key', fileName);
//                     setResumeStatus(file.name);
//                     // toast.success("uploaded successfully");
//                 });
//             }
//         );
//     };

//     const deleteFile = async (fileName: string) => {
//         setResumeStatus('deleting...');

//         // const deleteStorageRef = ref(storage, `/resumes/${  fileName+ uid+"_resume"}`);
//         // also add file name here

//         const deleteStorageRef = ref(
//             storage,
//             `/resumes/${fileName + '_' + uid + '_resume'}`
//         );
//         //above line also add file name here

//         console.log(deleteStorageRef, 'deleteStorageRef');

//         deleteObject(deleteStorageRef)
//             .then(async () => {
//                 formik.setFieldValue('resume', {
//                     url: '',
//                     name: '',
//                     key: '',
//                 });
//                 setResumeStatus('');
//             })
//             .catch((err) => {
//                 console.log('error occurred', err);
//             });
//     };

//     const uploadProfilePicture = async (e: any) => {
//         const file = e.target.files[0];
//         const fileName = `${file?.name}_${uid}` + '_profile';
//         if (!e.target.files.length) {
//             return;
//         }
//         setProfilePicStatus('uploading...');
//         const storageRef = ref(storage, `/profiles/${fileName}`);
//         const uploadTask = uploadBytesResumable(storageRef, file);
//         uploadTask.on(
//             'state_changed',
//             (snapshot: any) => {},
//             (err) => console.log(err),
//             () => {
//                 getDownloadURL(uploadTask.snapshot.ref).then((url) => {
//                     formik.setFieldValue('profilePicture.url', url);
//                     formik.setFieldValue('profilePicture.name', file?.name);
//                     formik.setFieldValue('profilePicture.key', fileName);
//                     setProfilePicStatus(file.name);
//                 });
//             }
//         );
//     };

//     const deleteProfilePicture = async (fileName: string) => {
//         setProfilePicStatus('deleting...');

//         const deleteStorageRef = ref(
//             storage,
//             `/profiles/${fileName + '_' + uid + '_profile'}`
//         );

//         deleteObject(deleteStorageRef)
//             .then(async () => {
//                 formik.setFieldValue('profilePicture', {
//                     url: '',
//                     name: '',
//                     key: '',
//                 });
//                 setProfilePicStatus('');
//             })
//             .catch((err) => {
//                 console.log('error occurred', err);
//             });
//     };

//     const toggleDropdown = () => {
//         setDropdownVal(!dropdownVal);
//     };

//     return (
//         <>
//             {loading ? (
//                 <div className="h-[100vh] w-7/12 lg:w-11/12 flex items-center justify-center mx-auto">
//                     <PulseLoader color="#0074FC" />
//                 </div>
//             ) : (
//                 <form
//                     className="w-11/12 h-full scroll-smooth flex justify-center"
//                     onSubmit={formik.handleSubmit}
//                 >
//                     <div
//                         className="flex flex-col justify-center mx-auto space-y-6 xl:space-y-10 w-8/12 lg:w-6/12 xl:w-6/12"
//                         ref={dropdownRef}
//                     >
//                         <SignUpDetailsForm
//                             resumefile={uploadResumeFile}
//                             deleteFile={deleteFile}
//                             places={places}
//                             resumeStatus={resumeStatus}
//                             profilePictureStatus={profilePicStatus}
//                             uploadProfilePicture={uploadProfilePicture}
//                             deleteProfilePicture={deleteProfilePicture}
//                             formik={formik}
//                             role={role}
//                             CityInfo={CityInfo}
//                             dropdownVal={dropdownVal}
//                             toggleDropdown={toggleDropdown}
//                             selectedInputValue={selectedInputValue}
//                             search={setSearchTerm}
//                         />
//                         <div className="flex items-center justify-between">
//                             <Button
//                                 className="z-0 w-5/12 bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-3 text-white rounded-[8px] px-10 text-[12px] font-[300]"
//                                 onClick={() =>
//                                     navigate({
//                                         pathname: '/role',
//                                         search: createSearchParams({
//                                             role,
//                                         }).toString(),
//                                     })
//                                 }
//                                 type="button"
//                                 btnName="Back"
//                             />
//                             <Button
//                                 className="z-0 w-5/12 bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-3 text-white rounded-[8px] px-10 text-[12px] font-[300]"
//                                 type="submit"
//                                 btnName="Complete"
//                             />
//                         </div>
//                     </div>
//                 </form>
//             )}
//         </>
//     );
// };

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { CO, ST, TI, FL } from '../../Services/Formik/Formik';
import { Button } from '../Elements/Button/Button';
import toast from 'react-hot-toast';
import {
    postRecord,
    readRecord,
    deleteRecord,
} from '../../Services/backend/apiCalls';
import { PulseLoader } from 'react-spinners';
import { useAppSelector, useAppDispatch } from '../..';
import { storage } from '../../Firebase/firebase';
import {
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from 'firebase/storage';
import { useDebounce } from '../../hooks/DebounceHook';
import { InputJSON } from './InputJSON';
import { SignUpDetailsForm } from './SignupForm';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import { Back } from '../Elements/back';
import { ResumeFile } from './ResumeFile';

import { v4 as uuidv4 } from 'uuid'; // Import uuid

export const Cities = [
    'bangalore',
    'chennai',
    'kolkata',
    'mumbai',
    'delhi',
    'pune',
];

export const SignupUserDetails = ({ input }: any) => {
    const location = useLocation();
    const [places, setPlaces] = useState([]);
    const [dropdownVal, setDropdownVal] = useState(false);
    let [loading, setLoading] = useState(false);
    const [resumeStatus, setResumeStatus] = useState('');
    const [profilePicStatus, setProfilePicStatus] = useState('');
    const [citySelected, setCitySelected] = useState('bangalore');
    const navigate = useNavigate();
    const { uid, role } = useAppSelector(
        ({ signin }: any) => signin['signinValue']
    );
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const dropdownRef: any = useRef(null);

    const roleForSchema: any = {
        CO: CO,
        ST: ST,
        TI: TI,
        FL: FL,
    };

    const CityInfo = (e: any) => {
        setCitySelected(e.target.value);
        formik.setFieldValue('address_line', '');
        setDropdownVal(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setDropdownVal(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (debouncedSearchTerm) {
            (async () => {
                setDropdownVal(searchTerm.length >= 3 ? true : false);
                formik.setFieldValue('city', citySelected);
                const SuggesionInput = await readRecord(
                    { input: debouncedSearchTerm, city: citySelected },
                    'placesuggestion'
                );
                if (SuggesionInput.status) {
                    setPlaces(SuggesionInput.places);
                } else {
                    toast.error(SuggesionInput.message);
                }
            })();
        }
    }, [debouncedSearchTerm]);

    const selectedInputValue = (val: any, id: any) => {
        setDropdownVal(false);
        if (role === 'TI') {
            formik.setFieldValue('branch_id', id);
        }
        const getValue = val.split(',');
        const country = getValue[getValue.length - 1];
        formik.setFieldValue('country', country);
        const state = getValue[getValue.length - 2];
        formik.setFieldValue('state', state);
        formik.setFieldValue('address_line', val);
    };

    const handleDirectSubmit = async () => {
        setLoading(true);
        const { skills, branch_id, contact_number, ...rest } = formik.values;
        let body = {
            ...rest,
            contact_number: contact_number,
            ...(skills && { skills: skills.split(',') }),
        };
        if (role === 'TI') {
            body = {
                ...body,
                branch_id: formik.values.branch_id + uuidv4(), // Generate unique branch_id
            };
        }

        const response = await postRecord(body, 'profiles');

        console.log(response, 'response');

        if (response.status) {
            navigate('/register/status');
        } else toast.error(response.message);
        setLoading(false);
    };

    const formik = useFormik({
        initialValues: InputJSON[role],
        validationSchema: roleForSchema[role],
        onSubmit: () => {
            handleDirectSubmit();
        },
    });

    const uploadResumeFile = async (e: any) => {
        const file = e.target.files[0];
        const fileName = `${file?.name}_${uid}` + '_resume';
        if (!e.target.files.length) {
            return;
        }
        setResumeStatus('uploading...');
        const storageRef = ref(storage, `/resumes/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            'state_changed',
            (snapshot: any) => {},
            (err) => console.log(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    formik.setFieldValue('resume.url', url);
                    formik.setFieldValue('resume.name', file?.name);
                    formik.setFieldValue('resume.key', fileName);
                    setResumeStatus(file.name);
                });
            }
        );
    };

    const deleteFile = async (fileName: string) => {
        setResumeStatus('deleting...');

        const deleteStorageRef = ref(
            storage,
            `/resumes/${fileName + '_' + uid + '_resume'}`
        );

        deleteObject(deleteStorageRef)
            .then(async () => {
                formik.setFieldValue('resume', {
                    url: '',
                    name: '',
                    key: '',
                });
                setResumeStatus('');
            })
            .catch((err) => {
                console.log('error occurred', err);
            });
    };

    const uploadProfilePicture = async (e: any) => {
        const file = e.target.files[0];
        const fileName = `${file?.name}_${uid}` + '_profile';
        if (!e.target.files.length) {
            return;
        }
        setProfilePicStatus('uploading...');
        const storageRef = ref(storage, `/profiles/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            'state_changed',
            (snapshot: any) => {},
            (err) => console.log(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    formik.setFieldValue('profilePicture.url', url);
                    formik.setFieldValue('profilePicture.name', file?.name);
                    formik.setFieldValue('profilePicture.key', fileName);
                    setProfilePicStatus(file.name);
                });
            }
        );
    };

    const deleteProfilePicture = async (fileName: string) => {
        setProfilePicStatus('deleting...');

        const deleteStorageRef = ref(
            storage,
            `/profiles/${fileName + '_' + uid + '_profile'}`
        );

        deleteObject(deleteStorageRef)
            .then(async () => {
                formik.setFieldValue('profilePicture', {
                    url: '',
                    name: '',
                    key: '',
                });
                setProfilePicStatus('');
            })
            .catch((err) => {
                console.log('error occurred', err);
            });
    };

    const toggleDropdown = () => {
        setDropdownVal(!dropdownVal);
    };

    return (
        <>
            {loading ? (
                <div className="h-[100vh] w-11/12 flex items-center justify-center mx-auto">
                    <PulseLoader color="#0074FC" />
                </div>
            ) : (
                <form
                    className="w-full h-full scroll-smooth flex justify-center"
                    onSubmit={formik.handleSubmit}
                >
                    <div
                        className="flex flex-col justify-center mx-auto space-y-4 md:space-y-6 lg:space-y-10 w-full md:w-10/12 lg:w-8/12 xl:w-6/12"
                        ref={dropdownRef}
                    >
                        <SignUpDetailsForm
                            resumefile={uploadResumeFile}
                            deleteFile={deleteFile}
                            places={places}
                            resumeStatus={resumeStatus}
                            profilePictureStatus={profilePicStatus}
                            uploadProfilePicture={uploadProfilePicture}
                            deleteProfilePicture={deleteProfilePicture}
                            formik={formik}
                            role={role}
                            CityInfo={CityInfo}
                            dropdownVal={dropdownVal}
                            toggleDropdown={toggleDropdown}
                            selectedInputValue={selectedInputValue}
                            search={setSearchTerm}
                        />
                        <div className="flex flex-col md:flex-row items-center justify-between space-y-2 md:space-y-0 md:space-x-2">
                            <Button
                                className="z-40 w-full md:w-5/12 bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-2 md:p-3 text-white rounded-md md:rounded-lg text-xs md:text-sm font-light md:font-medium"
                                onClick={() =>
                                    navigate({
                                        pathname: '/role',
                                        search: createSearchParams({
                                            role,
                                        }).toString(),
                                    })
                                }
                                type="button"
                                btnName="Back"
                            />
                            <Button
                                className="  z-40 w-full md:w-5/12 bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-2 md:p-3 text-white rounded-md md:rounded-lg text-xs md:text-sm font-light md:font-medium"
                                type="submit"
                                btnName="Complete"
                            />
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateRecord } from '../../Services/backend/apiCalls';
import { Card } from './Card';
import ScrollY from '../../assets/Images/ScrollY.svg';
import { useAppSelector } from '../..';
import { DashBoardLoader } from './NoDataChecker';
import './card.css';
import { toast } from 'react-hot-toast';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';

export const DirectRequest = ({ directRequests, dashLoading }: any) => {
    const scrollRef: any = useRef();
    const navigate = useNavigate();
    const [drQuery, setDRQuery] = useState('pending');
    const [loading, setLoading] = useState(dashLoading);
    const { signin }: any = useAppSelector((state) => state);
    const role = signin.signinValue.role;
    const userType = ['TI', 'FL'].includes(role) ? 'Trainee' : 'Trainer';
    const isTrainee = !['TI', 'FL'].includes(role);
    const [directRequestData, setDirectRequestData] =
        useState<any>(directRequests);
    const { appConfig } = useAppSelector(
        ({ appConfig }: any) => appConfig['configData']
    );
    //   const { token } = useAppSelector(state => state.signin.signinValue);
    // console.log({directRequests});

    useEffect(() => {
        if (directRequests !== directRequestData) {
            setDirectRequestData(directRequests);
        }

        setLoading(dashLoading);
    }, [directRequests, dashLoading]);

    const updateDirectRequest = async (id: number, status: string) => {
        let body = { id, status };
        let drResponse = await updateRecord(body, 'directrequests');

        if (drResponse.status) {
            const filteredArray = directRequestData.filter(
                (data: any) => data.id !== id
            );
            setDirectRequestData(filteredArray);
            toast.success(
                `Book Now ${camelCaseConvertForErrorMessage(
                    status
                )} Successfully`
            );
        } else {
            toast.error(drResponse.message);
        }
    };

    const directMap = (
        <div
            className={`mx-auto flex relative space-x-4 overflow-x-auto snap-x snap-mandatory scrollBar scroll-smooth`}
            ref={scrollRef}
        >
            {directRequestData.map((DR: any, index: number) => {
                console.log('thi is date', DR.date_of_training);

                // console.log(DR, 'DR');
                return (
                    <Card
                        id={DR.id}
                        status={DR.status}
                        userRole={role}
                        isTrainee={isTrainee}
                        key={index}
                        cardColor={'bg-[#F2F4F5B2]'}
                        cardType="DR"
                        title={DR.course.title}
                        courseDescription={DR.course.description}
                        course_id={DR.course_id}
                        user_id={DR[`${userType}`].user_id}
                        userName={DR[`${userType}`].name}
                        role={DR[`${userType}`].role}
                        date_of_training={DR.date_of_training}
                        date={DR.createdAt}
                        trainees_count={DR.trainees_count}
                        fee={DR.fee}
                        phoneNumber={DR[`${userType}`].contact_number}
                        width={'w-[292px]'}
                        isPaymentDone={DR['isPaymentDone']}
                        updateDirectRequest={updateDirectRequest}
                        appConfig={
                            appConfig['direct_request_action'] === 'edit' &&
                            appConfig['cms_action'] === 'edit'
                        }
                    />
                );
            })}
        </div>
    );

    return (
        <div className="bg-white py-6">
            <div className="w-[95%] mx-auto flex flex-col justify-between">
                <div>
                    <h1 className="text-[35px] font-[500] leading-[53.5px] text-center">
                        {userType === 'Trainee'
                            ? 'New Course Requests For You'
                            : 'Course Requests From You'}
                    </h1>
                    <div className="w-[50px] h-[3px] mt-1 rounded-full m-auto bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>
                </div>
                {/* <div className="flex justify-end">
                    <p
                        className="py-3 pr-8 cursor-pointer text-[14px] font-[500] text-[#5F5F5F] hover:text-[#267DF8]"
                        onClick={() =>
                            navigate('/request/booknow', {
                                state: 'typeForDashboard',
                            })
                        }
                    >
                        See All
                    </p>
                </div> */}
                {directRequestData.length > 4 && (
                    <div className="flex justify-end">
                        <p
                            className="py-3 pr-8 cursor-pointer text-[14px] font-[500] text-[#5F5F5F] hover:text-[#267DF8]"
                            onClick={() =>
                                navigate('/request/booknow', {
                                    state: 'typeForDashboard',
                                })
                            }
                        >
                            See All
                        </p>
                    </div>
                )}
                <div className="flex items-center justify-between space-x-5 h-[400px]">
                    {!loading && (
                        <img
                            src={ScrollY}
                            alt="ScrollY"
                            className="cursor-pointer"
                            onClick={() =>
                                (scrollRef.current.scrollLeft -= 1000)
                            }
                        />
                    )}
                    <DashBoardLoader
                        children={directMap}
                        data={directRequestData}
                        loading={loading}
                        height={'360px'}
                    />
                    {!loading && (
                        <img
                            src={ScrollY}
                            alt="ScrollY"
                            className="rotate-180 cursor-pointer"
                            onClick={() =>
                                (scrollRef.current.scrollLeft += 1000)
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import arrow from "../../assets/Images/RightArrow.svg"

export const Back = () => {
    const navigate = useNavigate();
  return (
      <div className="flex gap-2 items-center mt-10 w-full mb-8 mx-auto cursor-pointer">
          <img
              src={arrow}
              alt="ArrowForBack"
              className="rotate-180 w-[17px] h-[17px]"
              onClick={() => navigate(-1)}
          />
          <p
              className="text-[18px] font-[400] text-[#3061F4]"
              onClick={() => navigate(-1)}
          >
              Back
          </p>
      </div>
  );
}

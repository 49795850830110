import { useState } from 'react';
import { readRecord } from '../../Services/backend/apiCalls';
import { COprofile } from './COprofile';
import { FLprofile } from './FLprofile';
import { STprofile } from './STprofile';
import { TIprofile } from './TIprofile';

export const ProfileData = ({
    resumeStatus,
    resumefile,
    deleteFile,
    citySelected,
    setCitySelected,
    id,
    singleUserRole,
    formik,
    role,
    isViewable,
    email,
    suggesion,
    places,
    dropdownVal,
    selectedInputValue,
    search,
    CityInfo,
}: any) => {
    // const [places, setPlaces] = useState([]);
    // const [dropdownVal, setDropdownVal] = useState(false);
    const setRole = isViewable ? singleUserRole : role;

    // const CityInfo = (e: any) => {
    //     setCitySelected(e.target.value);
    // };

    // const getInputSuggesion = async(e: any) => {
    //   // setDropdownVal(e.target.value.length >= 3 ? true : false)
    //   const SuggesionInput = await readRecord({input:e.target.value,city:citySelected}, "placesuggestion");
    //   // setPlaces(SuggesionInput.places);
    // }

    return (
        <div className="py-14">
            {setRole === 'TI' && (
                <TIprofile
                    formik={formik}
                    role={setRole}
                    email={email}
                    isViewable={isViewable}
                    CityInfo={CityInfo}
                    search={search}
                    places={places}
                    dropdownVal={dropdownVal}
                    selectedInputValue={selectedInputValue}
                />
            )}
            {setRole === 'FL' && (
                <FLprofile
                    id={id}
                    formik={formik}
                    role={setRole}
                    email={email}
                    isViewable={isViewable}
                    CityInfo={CityInfo}
                    search={search}
                    places={places}
                    dropdownVal={dropdownVal}
                    selectedInputValue={selectedInputValue}
                    resumefile={resumefile}
                    deleteFile={deleteFile}
                    resumeStatus={resumeStatus}
                />
            )}
            {setRole === 'CO' && (
                <COprofile
                    formik={formik}
                    role={setRole}
                    email={email}
                    isViewable={isViewable}
                    CityInfo={CityInfo}
                    search={search}
                    places={places}
                    dropdownVal={dropdownVal}
                    selectedInputValue={selectedInputValue}
                />
            )}
            {setRole === 'ST' && (
                <STprofile
                    formik={formik}
                    role={setRole}
                    email={email}
                    isViewable={isViewable}
                    CityInfo={CityInfo}
                    search={search}
                    places={places}
                    dropdownVal={dropdownVal}
                    selectedInputValue={selectedInputValue}
                />
            )}
        </div>
    );
};

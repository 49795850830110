import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    showBookModal:false
}

const DRSlice = createSlice({
    name: "DR",
    initialState,
    reducers: {
        showBookNowModal: (state: any, action: PayloadAction<any>) => {
            state.showBookModal = action.payload;
        }
    }
});

export const { showBookNowModal } = DRSlice.actions;
export default DRSlice.reducer;
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { postRecord } from '../../../Services/backend/apiCalls';
import { camelCaseConvertForErrorMessage } from '../../../Services/commonFunctions';
import { Footer } from '../../Footer/Footer';

export const Respond = ({ emailVerified, checkedMessage }: any) => {
    const { state } = useLocation();
    console.log(state);

    const [processing, setProcessing] = useState(false);
    const navigate = useNavigate();

    const resendEmail = async () => {
        setProcessing(true);
        const { status, message } = await postRecord(
            {
                email: state.email,
                redirect_url: state.redirect_url,
            },
            'resendmail'
        );
        if (status) {
            return toast.success(
                camelCaseConvertForErrorMessage(
                    `Email Resent Successfully to ${state.email}`
                )
            );
        } else {
            toast.error(message);
        }
        setProcessing(false);
    };

    // Conditionally set the heading text based on the 'from' state
    const headingText =
        state?.from === 'email-verification'
            ? 'Email Verification'
            : 'Thanks for SignUp';

    return (
        <div className="bg-[#FBFCFC] w-7/12 lg:w-11/12">
            <div className="flex flex-col justify-center text-center items-center w-11/12 mx-auto space-y-4 h-[100vh]">
                <h1 className="text-3xl">{headingText}</h1>
                <p className="text-[#007BF7] text-[14px]">
                    Please click the verification link sent to your Email ID
                </p>
                <p className="text-[14px]">
                    Didn't receive mail? Click on
                    <span
                        className={`text-[#007BF7] text-[15px] ml-2 font-[500] ${
                            !processing && 'cursor-pointer'
                        }`}
                        onClick={resendEmail}
                    >
                        resend link
                    </span>
                </p>
                <p className="text-[14px]">
                    Already have an account ?{' '}
                    <span
                        className="text-[#0666ED] text-[13px] font-[500] cursor-pointer"
                        onClick={() => navigate('/')}
                    >
                        Sign In
                    </span>
                </p>
            </div>
            {/* <Footer /> */}
        </div>
    );
};

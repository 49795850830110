import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FilterSection = ({ filters, handleFilterChange }) => {
    const [expandedSection, setExpandedSection] = useState('');

    const filterSections = [
        {
            id: 'mode',
            title: 'Mode',
            options: [
                { label: 'Online', value: 'Online' },
                { label: 'Offline', value: 'Offline' },
                { label: 'Both', value: 'Both' }, // Added Both option
            ],
        },
        {
            id: 'days',
            title: 'Days',
            options: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ],
        },
        {
            id: 'certificate',
            title: 'Certification',
            options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
            ],
        },
    ];

    const toggleSection = (sectionId) => {
        setExpandedSection(expandedSection === sectionId ? '' : sectionId);
    };

    return (
        <div className="bg-white p-4 rounded-lg w-80 mt-6">
            <div className="space-y-4">
                {filterSections.map((section) => (
                    <div
                        key={section.id}
                        className="border-b border-gray-100 pb-2"
                    >
                        <button
                            onClick={() => toggleSection(section.id)}
                            className="w-full flex justify-between items-center py-2"
                        >
                            <span className="text-base font-medium">
                                {section.title} (
                                {filters[section.id]?.length || 0})
                            </span>
                            {expandedSection === section.id ? (
                                <FaChevronUp className="w-5 h-5" />
                            ) : (
                                <FaChevronDown className="w-5 h-5" />
                            )}
                        </button>

                        {expandedSection === section.id && (
                            <div className="mt-2 space-y-2">
                                {section.options.map((option) => {
                                    const value =
                                        typeof option === 'string'
                                            ? option
                                            : option.value;
                                    const label =
                                        typeof option === 'string'
                                            ? option
                                            : option.label;

                                    return (
                                        <div
                                            key={value}
                                            className="flex items-center"
                                        >
                                            <input
                                                type="checkbox"
                                                id={value}
                                                checked={filters[
                                                    section.id
                                                ]?.includes(value)}
                                                onChange={() =>
                                                    handleFilterChange(
                                                        section.id,
                                                        value
                                                    )
                                                }
                                                className="w-4 h-4 border-gray-300 rounded mr-3"
                                            />
                                            <label
                                                htmlFor={value}
                                                className="text-gray-700"
                                            >
                                                {label}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FilterSection;

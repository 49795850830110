import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ScrollY from '../../assets/Images/ScrollY.svg'; // Adjust path as needed
import { DashBoardLoader } from '../Dashboard/NoDataChecker';
import { readRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';

const InternshipTiDashboard = () => {
    const [internships, setInternships] = useState([]);
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(true); // Add loading state
    const navigate = useNavigate();
    const scrollRef = useRef(null);

    // Fetch user_id (uid) directly from local storage
    useEffect(() => {
        const storedUserId = localStorage.getItem('uid');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);

    useEffect(() => {
        const fetchInternships = async () => {
            if (!userId) return;

            setLoading(true); // Set loading to true before fetching data
            try {
                // Pass userId as a query parameter
                const data = await readRecord(
                    { userId }, // Query parameters with userId
                    'ti-internships' // Endpoint
                );

                if (data.success) {
                    setInternships(data.data);
                    console.log(data.data);
                } else {
                    console.error('Data fetching unsuccessful:', data.message);
                    toast.error('Failed to load internships data');
                }
            } catch (error) {
                console.error('Error fetching internship data:', error);
                toast.error('Error fetching internship data');
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchInternships();
    }, [userId]);

    const handleCardClick = (internshipId) => {
        navigate(`/internshipTi/${internshipId}`);
    };

    return (
        <div className="p-6 bg-[#F2F4F5B2] relative w-full">
            <div className="">
                <h1 className="text-[35px] font-[500] text-center">
                    Your Internships
                </h1>
                <div className="w-[50px] mt-1 h-[3px] rounded-full m-auto bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>

                <div className="flex items-center justify-between space-x-5 h-[440px] mt-6">
                    <img
                        src={ScrollY}
                        alt="ScrollY"
                        className="cursor-pointer"
                        onClick={() =>
                            scrollRef.current &&
                            (scrollRef.current.scrollLeft -= 1000)
                        }
                    />

                    <div
                        className="flex relative space-x-4 overflow-x-auto scrollBar scroll-smooth snap-x snap-mandatory "
                        ref={scrollRef}
                        style={{ width: 'calc(292px * 5 + 12px * 3)' }}
                    >
                        {loading ? (
                            <div className="flex justify-center items-center w-full h-full">
                                <DashBoardLoader
                                    loading={loading}
                                    height="360px"
                                />
                            </div>
                        ) : internships.length === 0 ? (
                            <p className="text-center ml-[45%]">
                                No internships available
                            </p>
                        ) : (
                            internships.map((internship) => (
                                <div
                                    key={internship.id}
                                    className="bg-white p-4 rounded-lg sm:w-[292px] w-[230px] snap-start flex-shrink-0 flex flex-col justify-between cursor-pointer "
                                    onClick={() =>
                                        handleCardClick(internship.id)
                                    }
                                >
                                    <div className="">
                                        <img
                                            src={
                                                internship.thumbnail ||
                                                'https://via.placeholder.com/150'
                                            }
                                            alt={internship.title}
                                            className="w-full h-40 object-contain rounded-lg mb-4"
                                        />
                                        <h2 className="text-xl font-semibold mb-2 truncate">
                                            {internship.title}
                                        </h2>
                                        <p className="text-gray-600 mb-4 overflow-hidden text-ellipsis whitespace-nowrap truncate">
                                            {internship.description}
                                        </p>
                                        <p className="text-sm text-gray-500 mb-2 truncate">
                                            Company:{' '}
                                            {typeof internship.company ===
                                            'string'
                                                ? internship.company
                                                : internship.company?.name ||
                                                  'N/A'}
                                        </p>
                                        <p className="text-sm text-gray-500">
                                            <strong>
                                                Internship Type:{' '}
                                                {internship.stipend
                                                    ? 'Paid'
                                                    : 'Free'}
                                            </strong>
                                        </p>
                                    </div>
                                    <div className="flex items-end"></div>
                                </div>
                            ))
                        )}
                    </div>
                    <img
                        src={ScrollY}
                        alt="ScrollY"
                        className="rotate-180 cursor-pointer"
                        onClick={() =>
                            scrollRef.current &&
                            (scrollRef.current.scrollLeft += 1000)
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default InternshipTiDashboard;

import React, { useEffect, useState } from 'react';
import { DirectRequest } from './DirectRequest';
import { Stats } from './Stats';
import { Bidding } from './Bidding';
import { CourseThroughPortal } from './CourseThroughPortal';
import { Suggestions } from './Suggestions';
import { readRecord } from '../../Services/backend/apiCalls';
import { useAppDispatch, useAppSelector } from '../../';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { camelCaseConvertForErrorMessage } from '../../Services/commonFunctions';
import { setAppConfig } from '../../Redux/Reducers/appConfigReducers';
import { isTrainer } from '../../Services/commonFunctions';

import { useLocation } from 'react-router-dom';
import InternshipTiDashboard from '../Internship/InternshipTiDashboard';
import { InternshipSuggestions } from '../Internship/InternshipSuggestions';

export const Dashboard = () => {
    const location = useLocation(); // Get access to location object

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [{ token, role }, { appConfig }] = useAppSelector(
        ({ signin, appConfig }: any) => [
            signin['signinValue'],
            appConfig['configData'],
        ]
    );

    const [dashBoardLoading, setDashboardLoading] = useState(true);
    const bidSuggest =
        isTrainer(role) &&
        appConfig &&
        appConfig['bidding_suggestions'] !== 'off' &&
        appConfig['bidding_action'] !== 'off';

    const drSuggest =
        !isTrainer(role) &&
        appConfig &&
        appConfig['direct_request_suggestions'] !== 'off' &&
        appConfig['direct_request_action'] !== 'off';

    const [dashboardData, setDashboardData] = useState<any>({
        directRequests: [],
        biddings: [],
        trainings: [],
        statistics: { onGoing: 0, completed: 0 },
    });

    const fetchDashBoardData = async () => {
        setDashboardLoading(true);
        const {
            appConfig,
            status,
            directRequests,
            biddings,
            trainings,
            statistics,
            error,
            message,
        }: any = await readRecord({}, 'dashboard', navigate);
        console.log(directRequests, 'directRequests');
        if (status) {
            dispatch(setAppConfig({ appConfig: appConfig }));
            setDashboardData({
                directRequests,
                biddings,
                trainings,
                statistics,
                appConfig,
            });
        } else toast.error(message);
        setDashboardLoading(false);
    };

    useEffect(() => {
        let listen: boolean = true;
        if (listen) {
            fetchDashBoardData();
        }
        return () => {
            listen = false;
        };
    }, []);

    // update by me
    useEffect(() => {
        const handleHashNavigation = () => {
            if (location.hash) {
                const id = location.hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    setTimeout(
                        () => element.scrollIntoView({ behavior: 'smooth' }),
                        100
                    ); // Adjust delay as necessary
                }
            }
        };

        handleHashNavigation();
    }, [location, dashBoardLoading]); // Include dashBoardLoading if it affects rendering of elements

    return (
        <div className="w-full h-auto mx-auto">
            {/* <Stats statistics={dashboardData["statistics"]} dashLoading={dashBoardLoading} /> */}
            {appConfig && appConfig['cms_action'] !== 'off' && (
                <>
                    {' '}
                    {appConfig &&
                        appConfig['direct_request_action'] !== 'off' && (
                            <DirectRequest
                                directRequests={dashboardData['directRequests']}
                                dashLoading={dashBoardLoading}
                                // appConfig={dashboardData['appConfig']}
                            />
                        )}
                    {appConfig && appConfig['bidding_action'] !== 'off' && (
                        <div id="bidding">
                            <Bidding
                                biddings={dashboardData['biddings']}
                                dashLoading={dashBoardLoading}
                            />
                        </div>
                    )}
                    <CourseThroughPortal
                        trainings={dashboardData['trainings']}
                        dashLoading={dashBoardLoading}
                    />
                    {(bidSuggest || drSuggest) && (
                        <div id="suggestions">
                            <Suggestions />
                        </div>
                    )}
                    {role === 'ST' && appConfig['show_internship'] && (
                        <InternshipSuggestions />
                    )}
                    {role === 'TI' && appConfig['show_internship'] && (
                        <InternshipTiDashboard />
                    )}
                </>
            )}
        </div>
    );
};

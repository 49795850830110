import { useAppDispatch } from "../..";
import { updateRecord } from "../../Services/backend/apiCalls";
import toast from "react-hot-toast"

export const ReplyButton=({status,setClickedReplyId}:any)=>{
    return <h1 className='text-[#267DF8] text-[14px] font-[500] mt-4 cursor-pointer' onClick={()=>setClickedReplyId(status)}>Reply</h1>
}

export const EditButton = ({ status, setClickedReplyId, token }: any) => {
    
    const EditRequest=async(statusId:any)=>{
        const body={
            id: statusId,
            rating: 4,
            description: ''
        }
        const editResponse = await updateRecord(body, "reviews");
        if(editResponse.status){
            toast.success(editResponse.message)
        }else{
            toast.error(editResponse.message)
        }
    }
    return <h1 className='text-[#267DF8] text-[14px] font-[500] mt-4 cursor-pointer' onClick={()=>EditRequest(status.id)}>Edit</h1>
}

export const DeleteButton = ({ removeReviewId, statusID, token, callDeleteFunc, setSelectUserId, selectUserId }: any) => {
    const dispatch = useAppDispatch();

    return (
        <h1
            className="text-[#267DF8] text-[14px] font-[500] mt-4 cursor-pointer"
            // onClick={deleteReviewDetails}
            onClick={() => callDeleteFunc(statusID)}
        >
            Delete
        </h1>
    );
};
import React from 'react';
import completedStatus from "../../assets/Images/completedStatus.png";
import { Status } from '../LoginFunctionality/Status/Status';
import curveLine from "../../assets/Images/curveLine.png";
import { useNavigate } from 'react-router-dom';
import completedLogo from "../../assets/Images/completedLogo.svg";

export const CompletedStatus = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/dashboard');
  }

  return (
          <div className="bg-[#FBFCFC] w-7/12 lg:w-11/12">
          <div className='flex flex-col justify-center items-center w-9/12 lg:w-6/12 xl:w-4/12 mx-auto space-y-8 h-[100vh]'>
            <img src={completedLogo} alt="Registered" className='object-fit w-full h-[180px]' />
            <div className='text-center text-[18px] font-[500]'>
              <h1>Sign-Up</h1>
              <h1>Process Completed</h1>
            </div>
            <button onClick={handleClick} className="bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-2 text-white px-6 rounded-md">Start</button>
          </div>
          <img src={curveLine} alt="cornerLine" className='absolute right-0 bottom-2 rotate-90' />
      </div>
  )
}

import { convertedTimeToNumber } from '../AddCourse/ConverterTimeToNumber';

export const findParameterData = (parameter: any, data: any) => {
    if (parameter === 'mode') {
        return data.mode_of_teaching.mode;
    } else if (parameter === 'session') {
        return data.mode_of_teaching.session;
    } else {
        return data[parameter];
    }
};

export const isDataContainsAllElementsOfArray = (data: any, array: any) => {
    let removeDuplicatesInArray = [...new Set(array)];
    for (let item of removeDuplicatesInArray) {
        if (data.includes(item)) {
            return true;
        }
    }
    return false;

    // let successArray = removeDuplicatesInArray.filter(item => {
    //     return data.includes(item);
    // });
    // return removeDuplicatesInArray.length === successArray.length;
};

export const createObjectWithFilterKey = (object: any) => {
    const newObject: any = {};
    for (let key in object) {
        newObject[key] = false;
    }
    return newObject;
};

export const isAllKeysAreTrue = (object: any) => {
    let count = 0;
    for (let key in object) {
        if (object[key]) {
            count += 1;
        }
    }
    return count === Object.keys(object).length;
};

// export const batchTimingFilter = (batches: any, filter: any) => {
//     let splittedFilterTime = filter.split('-');
//     // console.log({ splittedFilterTime });
//     // console.log({splittedFilterTime},convertedTimeToNumber(splittedFilterTime[0]));
//     // console.log();

//     const batchTimings: any = [
//         ...new Set(batches.map((batch: any) => batch.batchTime)),
//     ];
//     // console.log(batchTimings);
//     for (let time of batchTimings) {
//         // console.log(time.split('-')[0]);
//         if (
//             convertedTimeToNumber(splittedFilterTime[0]) <=
//                 convertedTimeToNumber(time.split('-')[0]) &&
//             convertedTimeToNumber(splittedFilterTime[1]) >=
//                 convertedTimeToNumber(time.split('-')[1])
//         ) {
//             return true;
//         }
//     }
//     return false;
// };

// second version
// export const batchTimingFilter = (batches:any, filter:any) => {
//     let splittedFilterTime = filter.split("-");
//     // console.log({ splittedFilterTime });
// // console.log({splittedFilterTime},convertedTimeToNumber(splittedFilterTime[0]));
//     // console.log();

//     const batchTimings: any = [...new Set(batches.map((batch: any) => batch.batchTime))];
//     // console.log(batchTimings);
//     for (let time of batchTimings) {
//     // console.log(time.split('-')[0]);
//     if ((convertedTimeToNumber(splittedFilterTime[0]) <= convertedTimeToNumber(time.split("-")[0])) && (convertedTimeToNumber(splittedFilterTime[1]) >= convertedTimeToNumber(time.split("-")[1]))) {
//         return true;
//     }
// }
// return false;
// }

// update by me

export const batchTimingFilter = (batches: any, filter: any) => {
    let [filterStartTime, filterEndTime] = filter
        .split('-')
        .map((time: any) => convertedTimeToNumber(time));

    const batchTimings: any = [
        ...new Set(batches.map((batch: any) => batch.batchTime)),
    ];

    for (let time of batchTimings) {
        let [batchStartTime, batchEndTime] = time
            .split('-')
            .map((time: any) => convertedTimeToNumber(time));

        if (
            (filterStartTime >= batchStartTime &&
                filterStartTime <= batchEndTime) || // Start time of filter is within batch timing
            (filterEndTime >= batchStartTime &&
                filterEndTime <= batchEndTime) || // End time of filter is within batch timing
            (filterStartTime <= batchStartTime && filterEndTime >= batchEndTime) // Entire batch timing is within filter range
        ) {
            return true;
        }
    }

    return false;
};

export const durationFilter = (duration: any, filter: any) => {
    // let result = true;
    switch (filter) {
        case 'lessOneMonth':
            return duration < 30;
        case 'oneToThree':
            return duration >= 30 && duration <= 90;

        default:
            return duration > 90;
    }
};

export const filterCourse = (data: any, filterObject: any) => {
    const filterData = data.filter((item: any, index: any) => {
        // console.log({item});
        const newFilterObject = {
            ...createObjectWithFilterKey({ ...filterObject }),
        };
        for (let key in filterObject) {
            if (key === 'fee') {
                newFilterObject[key] =
                    filterObject.fee.lowerLimit <= item['fee'] &&
                    filterObject.fee.higherLimit >= item['fee'];
            } else if (key === 'session') {
                newFilterObject[key] = isDataContainsAllElementsOfArray(
                    findParameterData('session', item),
                    filterObject.session
                );
            } else if (key === 'batchTiming') {
                newFilterObject[key] = batchTimingFilter(
                    item['batches'],
                    filterObject[key]
                );
            } else if (key === 'duration') {
                newFilterObject[key] = durationFilter(
                    item['converted_to_days'],
                    filterObject[key]
                );
            } else if (
                filterObject[key] !== undefined &&
                filterObject[key] === findParameterData(key, item)
            ) {
                newFilterObject[key] = true;
            }
        }
        return isAllKeysAreTrue(newFilterObject);
    });
    return filterData;
};

// let { mode, ...rest } = filterObject;
// setFilterObject(rest)

// let newObject = { ...filterObject };
// delete newObject["mode"];
// setFilterObject(newObject);

// let result = filterCourse(data, filterObject);
// console.log(result)

import { useEffect, useState } from "react";
import unfilledstar from "../../assets/Images/unfilledstar.svg";
import filledstar from "../../assets/Images/filledstar.svg";

export const StarRating = ({ isClickable, rate, noPointer }: any) => {
    const [rating, setRating] = useState(rate);
    const [hover, setHover] = useState(0);

    useEffect(() => {
        if (rating !== rate) {
            setRating(rate);
        }
    }, [rate]);

    return (
        <div className={`flex items-center space-x-1`}>
            {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                    <img
                        alt="star"
                        // onClick={() => isClickable && ratingForReview(index,setRating)}
                        // onMouseEnter={() => ratingForReview(hover, setHover)}
                        onMouseEnter={() => isClickable && setHover(index)}
                        onClick={() => isClickable && setRating(index)}
                        // onMouseLeave={() => isClickable && setHover(rating)}
                        src={
                            index <= (hover || rating)
                                ? filledstar
                                : unfilledstar
                        }
                        className={`w-[18px] h-[18px] ${
                            noPointer ? '' : 'cursor-default'
                        }`}
                        key={index}
                    />
                );
            })}
        </div>
    );
};
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    configData: {
        appConfig: [],
    },
};

const notificationSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {
        setAppConfig: (state: any, action: PayloadAction<any>) => {
            state.configData = {
                ...state.configData,
                ...action.payload,
            };
        },
    },
});

export const { setAppConfig } = notificationSlice.actions;
export default notificationSlice.reducer;

import moment from "moment";

export const dateFormat = (date: any) => {
    return moment(date).format('DD-MMM-YYYY')
}


export function formatDateToDDMonthYYYY(isoDate:any) {
    const dateParts = isoDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
  
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const formattedDate = `${day}-${monthNames[month - 1]}-${year}`;
    return formattedDate;
  }
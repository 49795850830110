// const daysChoice = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];

// export const DayField=({title,onChange,value,error,isTouched,onBlur,id}:any)=>{
//     return (
//         <div className="mb-14 relative">
//             <h1 className="text-[15px] font-[500]">
//                 {title}
//                 <span className="ml-0.5 text-red-600">*</span>
//             </h1>
//             <div className="flex bg-white rounded-[8px] h-[55px] items-center px-4 mt-4 border-[0.7px] border-black">
//                 <div className="flex justify-between w-10/12 xl:w-8/12">
//                     {daysChoice.map((day, i) => {
//                         return (
//                             <div
//                                 key={i}
//                                 className={`w-[94px] h-[35px] border-[0.7px] border-black rounded-[17.5px] text-[12px] font-[400] flex justify-center items-center cursor-pointer capitalize ${
//                                     value.includes(day)
//                                         ? 'bg-[#085BEB] text-white'
//                                         : 'bg-[#F8F8F8] text-black'
//                                 }`}
//                                 onClick={() => onChange(day)}
//                                 tabIndex={0}
//                                 id={id}
//                                 onBlur={onBlur}
//                             >
//                                 {day}
//                             </div>
//                         );
//                     })}
//                 </div>
//             </div>
//             {error && isTouched && (
//                 <p className="text-red-500 text-[12px] absolute right-4 bottom-[-30px]">
//                     {error}
//                 </p>
//             )}
//         </div>
//     );
// }

const daysChoice = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
];

export const DayField = ({
    title,
    onChange,
    value,
    error,
    isTouched,
    onBlur,
    id,
}: any) => {
    return (
        <div className="mb-14 relative">
            <h1 className="text-[15px] font-[500]">
                {title}
                <span className="ml-0.5 text-red-600">*</span>
            </h1>
            <div className="flex flex-wrap bg-white rounded-[8px] py-4 items-center px-4 mt-4 border-[0.7px] border-black">
                <div className="flex flex-wrap justify-between w-full gap-2">
                    {daysChoice.map((day, i) => {
                        return (
                            <div
                                key={i}
                                className={`flex-1 min-w-[70px] h-[35px] border-[0.7px] border-black rounded-[17.5px] text-[12px] font-[400] flex justify-center items-center cursor-pointer capitalize ${
                                    value.includes(day)
                                        ? 'bg-[#085BEB] text-white'
                                        : 'bg-[#F8F8F8] text-black'
                                }`}
                                onClick={() => onChange(day)}
                                tabIndex={0}
                                id={id}
                                onBlur={onBlur}
                            >
                                {day}
                            </div>
                        );
                    })}
                </div>
            </div>
            {error && isTouched && (
                <p className="text-red-500 text-[12px] absolute right-4 bottom-[-30px]">
                    {error}
                </p>
            )}
        </div>
    );
};

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { readRecord } from '../../Services/backend/apiCalls';
import toast from 'react-hot-toast';
import { PulseLoader } from 'react-spinners';
import { FaChevronDown, FaChevronUp, FaExternalLinkAlt } from 'react-icons/fa';
import TrisektLogo from '../../assets/Images/trisektLoginLogo.svg';
import arrow from '../../assets/Images/RightArrow.svg';
import { useLocation } from 'react-router-dom';
import { Section } from './types'; // Adjust the path as necessary

const Accordion: React.FC = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(
        null
    );
    const [sections, setSections] = useState<Section[]>([]);
    const accordionRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const { role } = location.state || {};

    useEffect(() => {
        if (role !== 'ST') {
            navigate('/dashboard');
            return;
        }
    }, [role, navigate]);

    useEffect(() => {
        const checkAuthentication = async () => {
            setLoading(true);
            const storedToken = localStorage.getItem('token');

            if (storedToken) {
                try {
                    const response = await readRecord({}, 'validate-token');
                    if (response.status) {
                        setIsAuthenticated(true);
                    } else {
                        toast.error(
                            'Authentication failed. Please Sign in again.'
                        );
                        setIsAuthenticated(false);
                        navigate('/login');
                    }
                } catch (error) {
                    toast.error('Authentication failed. Please Sign in again.');
                    setIsAuthenticated(false);
                    navigate('/login');
                }
            } else {
                toast.error('Please Sign in');
                setIsAuthenticated(false);
                navigate('/login');
            }

            setLoading(false);
        };

        checkAuthentication();
    }, [navigate]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                accordionRef.current &&
                !accordionRef.current.contains(event.target as Node)
            ) {
                setOpenSectionIndex(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchSections = async () => {
            try {
                const response = await readRecord({}, 'editOpportunityData');
                if (response.status) {
                    const transformedData = response.data.map(
                        (item: { data: Section }) => item.data
                    ); // Transform the API response to match the required structure
                    setSections(transformedData);
                } else {
                    toast.error('Failed to fetch sections data.');
                }
            } catch (error) {
                toast.error('Failed to fetch sections data.');
            }
        };

        fetchSections();
    }, []);

    const toggleSection = (index: number) => {
        setOpenSectionIndex(openSectionIndex === index ? null : index);
    };

    const handleItemClick = (label: string) => {
        const firstWord = label.split(' ')[0]; // Extract the first word
        const searchQuery = encodeURIComponent(firstWord);
        console.log(`Navigating to /dashboard?search=${searchQuery}`);
        navigate(`/dashboard?search=${searchQuery}`);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <PulseLoader color="#0074FC" />
            </div>
        );
    }

    return (
        <div className="bg-[#EFEFEF] h-screen">
            <div className="bg-[#EFEFEF] font-poppins">
                <div
                    ref={accordionRef}
                    className="flex flex-col w-4/5 max-w-6xl p-8 rounded-lg mx-auto my-auto space-y-4"
                >
                    <div className="flex justify-between items-center mb-8">
                        <div
                            className="flex items-center cursor-pointer"
                            onClick={() => navigate('/dashboard')}
                        >
                            <img
                                src={TrisektLogo}
                                alt="Logo"
                                className="h-10 mr-4"
                            />
                            <h1 className="text-2xl font-bold text-[#327BFD] mt-2">
                                Your Guide to Excellence
                            </h1>
                        </div>
                    </div>
                    <h1 className="cursor-pointer" onClick={() => navigate(-1)}>
                        <div className="flex gap-2">
                            <img src={arrow} alt="" className="rotate-180" />
                            <p>Back</p>
                        </div>
                    </h1>
                    {sections.length === 0 ? (
                        <div className="flex justify-center items-center h-full">
                            <p className="text-2xl font-bold text-gray-500">
                                No trending courses found
                            </p>
                        </div>
                    ) : (
                        sections.map((section, sectionIndex) => (
                            <div
                                key={sectionIndex}
                                className="rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-102 hover:shadow-lg"
                            >
                                <button
                                    className={`flex justify-between items-center w-full p-4 text-left text-white bg-gradient-to-r from-blue-500 to-teal-500 focus:outline-none shadow-sm`}
                                    onClick={() => toggleSection(sectionIndex)}
                                >
                                    {section.title}
                                    <span
                                        className={`text-xl transform transition-transform duration-300 ${
                                            openSectionIndex === sectionIndex
                                                ? 'rotate-[-90]'
                                                : 'rotate-0'
                                        }`}
                                    >
                                        {openSectionIndex === sectionIndex ? (
                                            <FaChevronDown />
                                        ) : (
                                            <FaChevronUp />
                                        )}
                                    </span>
                                </button>
                                <div
                                    className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                                        openSectionIndex === sectionIndex
                                            ? 'max-h-96'
                                            : 'max-h-0'
                                    }`}
                                >
                                    {section.content.map(
                                        (item: any, itemIndex: any) => (
                                            <a
                                                key={itemIndex}
                                                href={item.url}
                                                className="p-4 font-medium text-black hover:bg-gray-100 flex items-center group"
                                                onClick={() =>
                                                    handleItemClick(
                                                        item.actionKeyword
                                                    )
                                                }
                                            >
                                                <span className="flex-grow group-hover:underline">
                                                    {item.label}
                                                </span>
                                                <FaExternalLinkAlt className="ml-2 opacity-0 group-hover:opacity-100" />
                                            </a>
                                        )
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

export default Accordion;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../..';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { postRecord } from '../../Services/backend/apiCalls';
import { BookNow } from '../../Services/Formik/Formik';
import { showBookNowModal } from '../../Redux/Reducers/DRReducers';
import { getTomorrowDate } from '../../Services/commonFunctions';
import { setSignValue } from '../../Redux/Reducers/signinUserReducers';
import { ButtonCompWithActionController } from '../Elements/Button/Button';
import { dateFormat } from '../ViewCourse/DateFormat';
import moment from 'moment';

interface BookNowProps {
    courseId: number;
    batchData: any;
}

export const BooknowPopup = ({ courseId, batchData }: BookNowProps) => {
    const batches = batchData?.filter((bt: any) => {
        const batchDate = moment(bt?.batchDate).format('YYYY-MM-DD');
        const currentDate = moment().format('YYYY-MM-DD');
        return batchDate >= currentDate;
    });

    const { token, attendedCourses, directRequestedIds }: any = useAppSelector(
        (state) => state.signin.signinValue
    );
    const navigate = useNavigate();
    const sendButtonRef = useRef<any>(null);
    const dispatch = useAppDispatch();

    const courseBookedTimings = useAppSelector((state: any) => {
        return state.compare?.compareCoursesData?.bookedBatchTime?.[0];
    });

    const getNonBookedCourseData = batches?.filter((batch: any) => {
        return !courseBookedTimings.some(
            (item: any) =>
                `${dateFormat(batch.batchDate)}(${batch.batchTime})` ===
                `${dateFormat(item.batch_date)}(${item.batch_timing})`
        );
    });

    const getNonBookedCourse = [...getNonBookedCourseData];

    const initialValues = {
        batch_timing: '',
        date_of_training: '',
    };

    const sendDirectRequest = async () => {
        const { batch_timing, date_of_training } = values;

        const body = {
            course_id: Number(courseId),
            batch_timing: batch_timing,
            date_of_training: date_of_training,
        };

        const response = await postRecord(body, 'directrequests');
        if (response.status) {
            toast.success('Direct request sent successfully');
            navigate({
                pathname: '/checkout',
                search: createSearchParams({
                    id: response.data.id,
                    checkoutOrigin: 'dr',
                }).toString(),
            });
        } else {
            toast.error(response.message);
        }
        dispatch(showBookNowModal(false));
        dispatch(
            setSignValue({
                directRequestedIds: [...directRequestedIds, courseId],
            })
        );
    };

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
    } = useFormik({
        initialValues,
        validationSchema: BookNow,
        onSubmit: sendDirectRequest,
    });

    const batchDates = useMemo(() => {
        const bt = [
            ...new Set(
                getNonBookedCourse &&
                    getNonBookedCourse?.map((batch: any) => batch.batchDate)
            ),
        ];
        setFieldValue('date_of_training', bt[0]);
        return bt;
    }, []);

    const batchTimings = useMemo(() => {
        if (!values.date_of_training?.length) {
            return (
                getNonBookedCourse &&
                getNonBookedCourse
                    ?.filter((batch: any) => batch.batchDate === batchDates[0])
                    .map((batch: any) => batch.batchTime)
            );
        }
        return (
            getNonBookedCourse &&
            getNonBookedCourse
                ?.filter(
                    (batch: any) => batch.batchDate === values.date_of_training
                )
                .map((batch: any) => batch.batchTime)
        );
    }, [values.date_of_training]);

    useEffect(() => {
        if (batchTimings && batchTimings.length) {
            setFieldValue('batch_timing', batchTimings[0]);
        }
    }, [JSON.stringify(batchTimings)]);

    const closeModal = () => {
        dispatch(showBookNowModal(false));
    };

    const checkDate = (e: any) => {
        setFieldValue(
            'date_of_training',
            moment(e.target.value).format('YYYY-MM-DD')
        );
    };

    return (
        <div className="h-[100%] w-full flex items-center justify-center bg-black bg-opacity-[0.64] fixed overflow-auto top-0 py-10 z-[3]">
            <form className="w-full max-w-[90%] sm:max-w-[75%] md:max-w-[50%] lg:max-w-[30%] bg-[#F6F7F8] rounded-md p-4 sm:p-6 space-y-5">
                <div className="flex justify-between">
                    <button
                        type="button"
                        className="bg-red-400 text-white rounded-sm px-4 py-2"
                        onClick={closeModal}
                    >
                        Close
                    </button>
                </div>
                <div className="flex flex-col">
                    <label htmlFor="dateOfTraining" className="font-bold">
                        Date Of Training
                    </label>
                    <select
                        title="batchDates"
                        name="date_of_training"
                        id="batchDate"
                        className="mt-2 py-3 bg-white outline-none w-full rounded-md px-2"
                        defaultValue={values.date_of_training}
                        onBlur={handleBlur}
                        onChange={checkDate}
                    >
                        {batchDates.map((batch: any, index: number) => {
                            return (
                                <option key={index}>
                                    {moment(batch).format('DD-MMM-YYYY')}
                                </option>
                            );
                        })}
                    </select>
                    {errors.date_of_training && (
                        <p className="text-red-600 text-[13px] mt-1">
                            {errors.date_of_training}
                        </p>
                    )}
                </div>
                <div className="flex flex-col">
                    <label htmlFor="batchTiming" className="font-bold">
                        Batch Timings
                    </label>
                    <select
                        title="batchTimings"
                        name="batch_timing"
                        id="batchTiming"
                        className="mt-2 py-3 bg-white outline-none w-full rounded-md px-2"
                        defaultValue={values.batch_timing}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    >
                        {batchTimings &&
                            batchTimings.map(
                                (batchTime: any, index: number) => {
                                    return (
                                        <option key={index}>{batchTime}</option>
                                    );
                                }
                            )}
                    </select>
                    {errors.batch_timing && (
                        <p className="text-red-600 text-[13px] mt-1">
                            {errors.batch_timing}
                        </p>
                    )}
                </div>

                <ButtonCompWithActionController
                    type="button"
                    disabled={Boolean(Object.keys(errors).length)}
                    className="text-white w-full rounded-md bg-blue-600 py-2"
                    buttonText={'Book'}
                    action={handleSubmit}
                    Ref={sendButtonRef}
                />
            </form>
        </div>
    );
};

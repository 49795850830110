import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import fb from '../../../assets/Images/fb.svg';
import google from '../../../assets/Images/google.svg';
import {
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    linkWithPopup,
} from 'firebase/auth';
import { authentication } from '../../../Firebase/firebase';
import { useAppDispatch } from '../../..';
import { setSignValue } from '../../../Redux/Reducers/signinUserReducers';
import { toast } from 'react-hot-toast';
import { camelCaseConvertForErrorMessage } from '../../../Services/commonFunctions';
import { storeLoggedUserDetails } from '../Signin/Signin';

export const Providers = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const PROVIDERS: any = {
        google: GoogleAuthProvider,
        fb: FacebookAuthProvider,
    };

    const storeUserData = (data: any) => {
        dispatch(setSignValue(data));
    };

    const loginFunction = async (type: any) => {
        let provider = new PROVIDERS[type]();
        let googleProvider = new GoogleAuthProvider();

        try {
            let result = await signInWithPopup(authentication, provider);
            // console.log({ result });

            await storeLoggedUserDetails(
                result.user,
                navigate,
                storeUserData,
                (location.state !== null && location.state.from) || false
            );
        } catch (error: any) {
            // console.log({error});
            const errorMessage = error.message;
            const code = error.code;
            if (type === 'fb') {
                // const credential = FacebookAuthProvider.credentialFromError(error);
                // let errorText: string = error.code.split("/")[1].replace(/-/g," ");
                // console.log(errorText);
                if (code === 'auth/account-exists-with-different-credential') {
                    toast.error(
                        camelCaseConvertForErrorMessage(
                            code.split('auth/')[1].split('-').join(' ')
                        )
                    );
                    // try {
                    //     // console.log(authentication.currentUser);
                    //     const linkedResult = await linkWithPopup(authentication.currentUser, provider);

                    //     storeLoggedUserDetails(
                    //         linkedResult.user,
                    //         navigate,
                    //         storeUserData,
                    //         location.state.from || false
                    //     );
                    // } catch (error) {
                    //     console.log({ error });
                    //     toast.error(
                    //         camelCaseConvertForErrorMessage(
                    //             "Unknown error occured, try other methods"
                    //         )
                    //     );
                    // }

                    // }
                }
            }
        }
    };
    return (
        <div className="flex items-center gap-x-6 justify-center py-3">
            <img
                alt="googleIcon"
                src={google}
                onClick={() => loginFunction('google')}
                className="cursor-pointer bg-[#EFEFEF] rounded-lg stroke-[#EFEFEF]"
            />
            <img
                alt="fbIcon"
                src={fb}
                onClick={() => loginFunction('fb')}
                className="cursor-pointer bg-[#EFEFEF] rounded-lg stroke-[#EFEFEF]"
            />
        </div>
    );
};

export const RadioInput = ({
    type,
    label,
    className,
    Checked,
    disabled,
    ...rest
}: any) => {
    return (
        <div
            className={`${className} flex bg-white items-center mb-12 sm:w-1/2  w-28 border-[0.7px] border-black`}
        >
            <input
                type={type}
                className="ml-4 cursor-pointer "
                disabled={disabled ? disabled : null}
                {...rest}
            />
            <label className="ml-2 text-[15px] font-[500]">{label}</label>
        </div>
    );
};

// import React, { useState } from 'react';
// import { Input } from '../../Elements/Input/Input';
// import { Providers } from '../Providers/Providers';
// import { postRecord } from '../../../Services/backend/apiCalls';
// import curveLine from '../../../assets/Images/curveLine.png';
// import { useNavigate } from 'react-router-dom';
// import { useFormik } from 'formik';
// import { CreateValidationSignSchema } from '../../../Services/Formik/Formik';
// import toast from 'react-hot-toast';
// import { PulseLoader } from 'react-spinners';
// import { useAppDispatch } from '../../..';
// import { setSignValue } from '../../../Redux/Reducers/signinUserReducers';
// import Login_Logo from '../../../assets/Images/Login_Logo.svg';
// import { URLConfig } from '../../../config';
// import { BeatLoader } from 'react-spinners';

// export const SignUp = () => {
//     const dispatch = useAppDispatch();
//     const navigate = useNavigate();
//     let [loading, setLoading] = useState(false);

//     const signUp = async () => {
//         setLoading(true);
//         dispatch(setSignValue({ ...setSignValue, email: formik.values.email }));
//         const redirect_url = URLConfig[document.location.hostname];
//         console.log(redirect_url, 'redirect_url');
//         const body = { ...formik.values, redirect_url };
//         console.log(body, 'body');
//         const response = await postRecord(body, 'signup');
//         if (response.status) {
//             toast.success(response.message);
//             navigate('/respond', {
//                 state: { email: formik.values.email, redirect_url },
//             });
//             formik.resetForm();
//         } else {
//             setLoading(false);
//             toast.error(response.message);
//         }
//         console.log('toasthjghj');
//     };

//     const formik = useFormik({
//         initialValues: {
//             email: '',
//             password: '',
//         },
//         validationSchema: () => CreateValidationSignSchema('signup'),
//         onSubmit: () => {
//             signUp();
//         },
//     });

//     return (
//         <>
//             {/* {loading?
//       <div className="w-7/12 lg:w-11/12 flex items-center justify-center mx-auto">
//         <PulseLoader color="#0074FC" />
//       </div>: */}
//             <div className="bg-[#FBFCFC] w-7/12 lg:w-11/12 flex justify-center items-center">
//                 <form
//                     className="w-9/12 lg:w-6/12 xl:w-4/12 mx-auto space-y-8 flex flex-col justify-center"
//                     onSubmit={formik.handleSubmit}
//                 >
//                     <img
//                         src={Login_Logo}
//                         alt="Login_Logo"
//                         className="w-[190px]"
//                     />
//                     <div className="w-full space-y-4">
//                         <Input
//                             design={'w-full'}
//                             name="email"
//                             label="Email"
//                             value={formik.values.email}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             error={formik.errors.email}
//                             isTouched={formik.touched.email}
//                             impo="impo"
//                             focus={'focus'}
//                         />
//                         <Input
//                             design={'w-full'}
//                             paddingForEye={'pr-10'}
//                             name="password"
//                             label="Password"
//                             type={'password'}
//                             value={formik.values.password}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             error={formik.errors.password}
//                             isTouched={formik.touched.password}
//                             impo="impo"
//                         />
//                     </div>
//                     <button
//                         className="bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-3 text-[12px] flex items-center justify-center font-[400] text-white rounded-md"
//                         type="submit"
//                     >
//                         {loading ? (
//                             <BeatLoader size={13} color="#fff" />
//                         ) : (
//                             'Next'
//                         )}
//                     </button>
//                     <div className="flex items-center w-11/12 mx-auto pt-2">
//                         <div className="flex-grow bg bg-gray-300 h-0.5"></div>
//                         <div className="flex-grow-0 mx-5 text-[14px] font-[300]">
//                             or
//                         </div>
//                         <div className="flex-grow bg bg-gray-300 h-0.5"></div>
//                     </div>
//                     <div className="flex flex-col w-full items-center">
//                         <Providers />
//                         <p className="text-[12px] font-[300]">
//                             Already have an account ?{' '}
//                             <span
//                                 className="text-[#0666ED] text-[13px] font-[500] cursor-pointer"
//                                 onClick={() => navigate('/')}
//                             >
//                                 Sign In
//                             </span>
//                         </p>
//                     </div>
//                 </form>
//                 <img
//                     src={curveLine}
//                     alt=""
//                     className="absolute bottom-2 right-0 rotate-90"
//                 />
//             </div>
//         </>
//     );
// };

import React, { useState } from 'react';
import { Input } from '../../Elements/Input/Input';
import { Providers } from '../Providers/Providers';
import { postRecord } from '../../../Services/backend/apiCalls';
import curveLine from '../../../assets/Images/curveLine.png';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { CreateValidationSignSchema } from '../../../Services/Formik/Formik';
import toast from 'react-hot-toast';
import { BeatLoader } from 'react-spinners';
// import { useAppDispatch } from '../../../Redux/store';
import { useAppDispatch } from '../../..';

import { setSignValue } from '../../../Redux/Reducers/signinUserReducers';
import Login_Logo from '../../../assets/Images/Login_Logo.svg';
import { URLConfig } from '../../../config';

export const SignUp: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isFocused, setIsFocused] = useState(false); // State to manage focus on password field

    const signUp = async () => {
        setLoading(true);
        dispatch(setSignValue({ email: formik.values.email }));
        const redirect_url = URLConfig[document.location.hostname];
        console.log(redirect_url, 'redirect_url');

        const body = { ...formik.values, redirect_url };
        console.log(body, 'rbody');
        const response = await postRecord(body, 'signup');
        console.log(response, 'response');

        if (response.status) {
            toast.success(response.message);
            navigate('/respond', {
                state: { email: formik.values.email, redirect_url },
            });
            formik.resetForm();
        } else {
            setLoading(false);
            toast.error(response.message);
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        // update
        validationSchema: CreateValidationSignSchema('signup'),
        onSubmit: signUp,
    });

    return (
        <div className="bg-[#FBFCFC] w-7/12 lg:w-11/12 flex justify-center items-center relative">
            <form
                className="w-9/12 lg:w-6/12 xl:w-4/12 mx-auto space-y-8 flex flex-col justify-center"
                onSubmit={formik.handleSubmit}
            >
                <img src={Login_Logo} alt="Login_Logo" className="w-[190px]" />
                <div className="w-full space-y-4">
                    <Input
                        design={'w-full'}
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.errors.email}
                        isTouched={formik.touched.email}
                        impo="impo"
                        focus={'focus'}
                    />
                    <div className="relative">
                        <Input
                            design={'w-full'}
                            paddingForEye={'pr-10'}
                            name="password"
                            label="Password"
                            type={'password'}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={(e: any) => {
                                formik.handleBlur(e);
                                setIsFocused(false);
                            }}
                            onFocus={() => setIsFocused(true)}
                            error={formik.errors.password}
                            isTouched={formik.touched.password}
                            impo="impo"
                        />
                        {isFocused && (
                            <PasswordValidation
                                password={formik.values.password}
                            />
                        )}
                    </div>
                </div>
                <button
                    className="bg-gradient-to-r from-[#0074FC] to-[#00E0B8] p-3 text-[12px] flex items-center justify-center font-[400] text-white rounded-md"
                    type="submit"
                >
                    {loading ? <BeatLoader size={13} color="#fff" /> : 'Next'}
                </button>
                <div className="flex items-center w-11/12 mx-auto pt-2">
                    <div className="flex-grow bg bg-gray-300 h-0.5"></div>
                    <div className="flex-grow-0 mx-5 text-[14px] font-[300]">
                        or
                    </div>
                    <div className="flex-grow bg bg-gray-300 h-0.5"></div>
                </div>
                <div className="flex flex-col w-full items-center">
                    <Providers />
                    <p className="text-[12px] font-[300]">
                        Already have an account?{' '}
                        <span
                            className="text-[#0666ED] text-[13px] font-[500] cursor-pointer"
                            onClick={() => navigate('/')}
                        >
                            Sign In
                        </span>
                    </p>
                </div>
            </form>
            <img
                src={curveLine}
                alt=""
                className="absolute bottom-2 right-0 rotate-90"
            />
        </div>
    );
};

const PasswordValidation: React.FC<{ password: string }> = ({ password }) => {
    const requirements = [
        { text: 'Minimum of 8 characters', regex: /.{8,}/ },
        { text: 'Contains an uppercase letter', regex: /[A-Z]/ },
        { text: 'Contains a number (0-9)', regex: /\d/ },
        {
            text: 'Contains special characters (!@#$%^&*)',
            regex: /[!@#$%^&*(),.?":{}|<>]/,
        },
    ];

    const checkRequirement = (regex: RegExp) => regex.test(password);

    return (
        <div className="absolute top-12 left-0 mt-10  w-full bg-white border border-gray-300 rounded-lg shadow-lg p-4 z-10">
            <h4 className="text-sm font-bold mb-2">Password Requirements</h4>
            <ul className="list-disc list-inside   -ml-2 ">
                {requirements.map((req, index) => (
                    <li
                        key={index}
                        className={
                            checkRequirement(req.regex)
                                ? 'text-green-600'
                                : 'text-red-600'
                        }
                    >
                        {req.text}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SignUp;

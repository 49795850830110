import React from 'react';
import { RoleCard } from '../CMS/Role';

export const CommonRequestCard = ({
    courseImg,
    title,
    name,
    role,
    courseDetails,
}: any) => {
    return (
        <div className="flex flex-col md:flex-row space-x-0 md:space-x-8 items-center ">
            <img
                // src={courseImg}
                // update by me
                src={
                    'https://foundr.com/wp-content/uploads/2023/04/How-to-create-an-online-course.jpg.webp'
                }
                alt="courseImage"
                className="w-100px h-[220px] object-contain md:w-1/4"
            />
            <div className="space-y-4 w-8/12">
                <h3 className="text-[30px] font-[500]">{title}</h3>
                <div className="flex items-center space-x-3">
                    <h5 className="text-[20px] text-[#686868]">{name}</h5>
                    <RoleCard role={role} />
                </div>
                <div className="flex flex-wrap">
                    {courseDetails.map((detail: any, index: number) => {
                        return (
                            <div
                                key={index}
                                className={`${
                                    index !== courseDetails.length - 1 &&
                                    'border-r'
                                } mr-3 pr-4 py-1 mt-1 space-y-2`}
                            >
                                <h6 className="text-[14px] text-[400] text-[#303030]">
                                    {detail.name}
                                </h6>
                                <p className="text-[#3061F4] text-[12px] font-[500]">
                                    {detail.value}
                                </p>
                            </div>
                        );
                    })}
                </div>
                <div></div>
            </div>
        </div>
    );
};

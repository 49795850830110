// import React, {
//     useState,
//     ChangeEvent,
//     KeyboardEvent,
//     FormEvent,
//     useEffect,
// } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import {
//     postRecord,
//     readRecord,
//     updateRecord,
// } from '../../Services/backend/apiCalls';
// import { toast, Toaster } from 'react-hot-toast';
// import { storage } from '../../Firebase/firebase';
// import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
// import arrow from '../../assets/Images/RightArrow.svg';

// const AddInternship = () => {
//     const [tags, setTags] = useState<string[]>([]);
//     const [tagInput, setTagInput] = useState<string>('');
//     const [internshipType, setInternshipType] = useState<string>('Free');
//     const [stipend, setStipend] = useState<string>('');
//     const [selectedDays, setSelectedDays] = useState<string[]>([]);
//     const [uploading, setUploading] = useState<boolean>(false);
//     const [previewUrl, setPreviewURL] = useState<string>('');
//     const [imageURL, setImageURL] = useState<string | null>(null);
//     const [userId, setUserId] = useState<string | null>(null);
//     const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
//     const [stipendFrequency, setStipendFrequency] =
//         useState<string>('per month');

//     const [initialFormState, setInitialFormState] = useState<any>(null);
//     const [hasChanges, setHasChanges] = useState<boolean>(false);
//     const { id } = useParams();
//     const [formData, setFormData] = useState({
//         title: '',
//         description: '',
//         duration: '',
//         durationUnit: 'days',
//         mode: '',
//         startDate: '',
//         startTime: '',
//         endTime: '',
//         isActive: 'Yes',
//         certificate: 'Yes',
//         location: '',
//         deadline: '',
//         requirements: '',
//         thumbnail: null as File | null,
//         company: '',
//     });

//     const navigate = useNavigate();

//     useEffect(() => {
//         if (id) {
//             (async () => {
//                 const data = await readRecord({}, `ti-internships/${id}`);
//                 if (data.success) {
//                     const internship = data.data;
//                     const newFormData = {
//                         title: internship.title,
//                         description: internship.description,
//                         duration: internship.duration.toString(),
//                         durationUnit: internship.duration_unit,
//                         mode: internship.mode,
//                         startDate: internship.start_date,
//                         startTime: internship.start_time,
//                         endTime: internship.end_time,
//                         isActive: internship.is_active ? 'Yes' : 'No',
//                         certificate: internship.certificate ? 'Yes' : 'No',
//                         location: internship.location,
//                         deadline: internship.application_deadline,
//                         requirements: internship.requirements,
//                         company: internship.company,
//                         thumbnail: null,
//                     };

//                     setFormData(newFormData);
//                     setTags(internship.tags);
//                     setSelectedDays(internship.days);
//                     setImageURL(internship.thumbnail);
//                     setPreviewURL(internship.thumbnail);
//                     setInternshipType(internship.internship_type || 'Free');
//                     setStipend(
//                         internship.stipend ? internship.stipend.toString() : ''
//                     );
//                     setStipendFrequency(
//                         internship.stipend_frequency || 'per month'
//                     );

//                     // Add this new code to store initial state
//                     setInitialFormState({
//                         ...newFormData,
//                         tags: internship.tags,
//                         selectedDays: internship.days,
//                         internshipType: internship.internship_type || 'Free',
//                         stipend: internship.stipend
//                             ? internship.stipend.toString()
//                             : '',
//                         imageURL: internship.thumbnail,
//                         stipendFrequency:
//                             internship.stipend_frequency || 'per month', // Add this line
//                     });

//                     setIsSaveDisabled(true);
//                     setHasChanges(false);
//                 }
//             })();
//         }
//     }, [id]);

//     useEffect(() => {
//         if (initialFormState) {
//             const hasFormChanges =
//                 JSON.stringify({
//                     ...formData,
//                     tags,
//                     selectedDays,
//                     internshipType,
//                     stipend,
//                     imageURL,
//                     stipendFrequency,
//                 }) !== JSON.stringify(initialFormState);

//             setHasChanges(hasFormChanges);
//         }
//     }, [
//         formData,
//         tags,
//         selectedDays,
//         internshipType,
//         stipend,
//         imageURL,
//         initialFormState,
//     ]);

//     useEffect(() => {
//         const storedUserId = localStorage.getItem('uid');
//         if (storedUserId) {
//             setUserId(storedUserId);
//         }
//     }, []);

//     useEffect(() => {
//         // Check if it's in create mode by confirming absence of `id`
//         if (!id) {
//             setIsSaveDisabled(false); // Always enable the button in create mode
//             return; // Exit early to prevent further checks in create mode
//         }

//         // For edit mode (when `id` is present), perform validation and change checks
//         const isFormIncomplete =
//             !formData.title ||
//             !formData.company ||
//             !formData.description ||
//             !formData.deadline;
//         const isDateInvalid =
//             formData.startDate && formData.deadline > formData.startDate;

//         if (isDateInvalid) {
//             toast.error(
//                 'Application deadline must be before or on the start date.'
//             );
//         }

//         // Set `isSaveDisabled` based on validation and change checks in edit mode
//         setIsSaveDisabled(
//             isFormIncomplete || isDateInvalid || uploading || !hasChanges
//         );
//     }, [formData, uploading, hasChanges, id]);

//     const handleAddTag = (e: KeyboardEvent<HTMLInputElement>) => {
//         if (e.key === 'Enter' && tagInput.trim()) {
//             setTags([...tags, tagInput.trim()]);
//             setTagInput('');
//             setIsSaveDisabled(false);
//         }
//     };

//     const handleRemoveTag = (index: number) => {
//         setTags(tags.filter((_, i) => i !== index));
//         setIsSaveDisabled(false);
//     };

//     const handleChange = (
//         e: ChangeEvent<
//             HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
//         >
//     ) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//         setIsSaveDisabled(false);
//     };

//     const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
//         if (e.target.files) {
//             setFormData({ ...formData, thumbnail: e.target.files[0] });
//             uploadImage(e.target.files[0]);
//             setIsSaveDisabled(false);
//         }
//     };

//     const uploadImage = (file: File) => {
//         const fileName = `${formData.title}-${new Date().getTime()}_thumbnail`;
//         const storageRef = ref(storage, `/thumbnails/${fileName}`);
//         setUploading(true);

//         const uploadTask = uploadBytesResumable(storageRef, file);
//         uploadTask.on(
//             'state_changed',
//             () => {},
//             (err) => {
//                 setUploading(false);
//                 console.error('Error uploading file:', err);
//                 toast.error('Failed to upload image.');
//             },
//             () => {
//                 getDownloadURL(uploadTask.snapshot.ref).then((url) => {
//                     setPreviewURL(url);
//                     setImageURL(url);
//                     setUploading(false);
//                     toast.success('Image uploaded successfully!');
//                 });
//             }
//         );
//     };

//     const toggleDaySelection = (day: string) => {
//         if (selectedDays.includes(day)) {
//             setSelectedDays(selectedDays.filter((d) => d !== day));
//         } else {
//             setSelectedDays([...selectedDays, day]);
//         }
//         setIsSaveDisabled(false);
//     };

//     const handleSubmit = async (e: FormEvent) => {
//         e.preventDefault();

//         // Date validation
//         if (formData.startDate && formData.deadline > formData.startDate) {
//             toast.error('Application deadline cannot be after the start date.');
//             formData.deadline = '';
//             return;
//         }

//         // Proceed with form submission if dates are valid
//         setIsSaveDisabled(true);

//         const internshipDetails = {
//             title: formData.title,
//             description: formData.description,
//             tags: tags,
//             days: selectedDays,
//             duration: parseInt(formData.duration, 10),
//             duration_unit: formData.durationUnit,
//             mode: formData.mode,
//             start_date: formData.startDate,
//             start_time: formData.startTime,
//             end_time: formData.endTime,
//             internship_type: internshipType,
//             stipend: internshipType === 'Paid' ? parseInt(stipend, 10) : null,
//             stipend_frequency:
//                 internshipType === 'Paid' ? stipendFrequency : null,
//             is_active: formData.isActive === 'Yes',
//             certificate: formData.certificate === 'Yes',
//             location: formData.location || null,
//             application_deadline: formData.deadline,
//             requirements: formData.requirements || null,
//             thumbnail: imageURL,
//             company: formData.company,
//             user_id: userId,
//         };

//         console.log(internshipDetails);

//         try {
//             const response = id
//                 ? await updateRecord(internshipDetails, `internships/${id}`)
//                 : await postRecord(internshipDetails, 'internships');
//             toast.success(
//                 id
//                     ? 'Internship updated successfully!'
//                     : 'Internship created successfully!'
//             );
//             navigate(-1);
//         } catch (error) {
//             console.error('Error saving internship:', error);
//             toast.error('Failed to save internship. Please try again.');
//         }
//     };

//     const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

//     return (
//         <div className="container mx-auto sm:p-24 p-4 bg-gray-50">
//             <h1 className="text-2xl font-bold ">
//                 {id ? 'Edit Internship' : 'Add New Internship'}
//             </h1>
//             <div className="flex gap-2 items-center  mt-5 w-full mx-auto cursor-pointer mb-4">
//                 <img
//                     src={arrow}
//                     alt="ArrowForBack"
//                     className="rotate-180 w-[17px] h-[17px]"
//                     onClick={() => navigate(-1)}
//                 />
//                 <p
//                     className="text-[16px] sm:text-[18px] font-[400] text-[#3061F4]"
//                     onClick={() => navigate(-1)}
//                 >
//                     Back
//                 </p>
//             </div>
//             <form onSubmit={handleSubmit}>
//                 <div className="flex items-center justify-end   ">
//                     <button
//                         type="submit"
//                         // className="px-6 py-2 bg-blue-500 text-white rounded mt-6"
//                         className={`px-6 py-2 rounded mt-6 text-white ${
//                             isSaveDisabled || uploading
//                                 ? 'bg-gray-500 cursor-not-allowed'
//                                 : 'bg-blue-500 text-white'
//                         }`}
//                         disabled={uploading || isSaveDisabled}
//                     >
//                         {id ? 'Save' : 'Create'}
//                     </button>
//                 </div>

//                 <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Internship Title{' '}
//                             <span className="text-red-600">*</span>
//                         </label>
//                         <input
//                             type="text"
//                             name="title"
//                             value={formData.title}
//                             onChange={handleChange}
//                             placeholder="Enter internship title"
//                             className="w-full px-4 py-2 border border-gray-300 rounded"
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Company Name <span className="text-red-600">*</span>
//                         </label>
//                         <input
//                             type="text"
//                             name="company"
//                             value={formData.company}
//                             onChange={handleChange}
//                             placeholder="Enter company name"
//                             className="w-full px-4 py-2 border border-gray-300 rounded"
//                             required
//                         />
//                     </div>
//                     <div className="form-group md:col-span-2">
//                         <label className="block mb-2 font-semibold">
//                             Description <span className="text-red-600">*</span>
//                         </label>
//                         <textarea
//                             name="description"
//                             value={formData.description}
//                             onChange={handleChange}
//                             placeholder="Enter internship description"
//                             className="w-full px-4 py-2 border border-gray-300 rounded "
//                             rows={6}
//                             required
//                         ></textarea>
//                     </div>
//                     <div className="form-group md:col-span-2">
//                         <label className="block mb-2 font-semibold">
//                             Relevant Tags{' '}
//                             <span className="text-red-600">*</span>
//                         </label>
//                         <input
//                             type="text"
//                             placeholder="Press Enter to add tags"
//                             value={tagInput}
//                             onChange={(e) => setTagInput(e.target.value)}
//                             onKeyDown={handleAddTag}
//                             className="w-full px-4 py-2 border border-gray-300 rounded"
//                         />
//                         <div className="mt-2 flex flex-wrap gap-2">
//                             {tags.map((tag, index) => (
//                                 <span
//                                     key={index}
//                                     className="inline-flex items-center bg-gray-200 px-3 py-1 rounded-full"
//                                 >
//                                     {`#${tag}`}
//                                     <button
//                                         type="button"
//                                         onClick={() => handleRemoveTag(index)}
//                                         className="ml-2 text-red-500 hover:text-red-700"
//                                     >
//                                         x
//                                     </button>
//                                 </span>
//                             ))}
//                         </div>
//                     </div>
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Days <span className="text-red-600">*</span>
//                         </label>
//                         <div className="grid sm:grid-cols-4 grid-cols-2 gap-2">
//                             {[
//                                 'Sunday',
//                                 'Monday',
//                                 'Tuesday',
//                                 'Wednesday',
//                                 'Thursday',
//                                 'Friday',
//                                 'Saturday',
//                             ].map((day) => (
//                                 <button
//                                     key={day}
//                                     type="button"
//                                     onClick={() => toggleDaySelection(day)}
//                                     className={`px-4 py-2 border rounded ${
//                                         selectedDays.includes(day)
//                                             ? 'bg-blue-500 text-white'
//                                             : 'bg-white text-gray-700'
//                                     }`}
//                                 >
//                                     {day}
//                                 </button>
//                             ))}
//                         </div>
//                     </div>
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Duration <span className="text-red-600">*</span>
//                         </label>
//                         <div className="flex gap-2">
//                             <input
//                                 type="number"
//                                 name="duration"
//                                 value={formData.duration}
//                                 onChange={handleChange}
//                                 placeholder="e.g., 10"
//                                 className="w-full px-4 py-2 border rounded"
//                                 required
//                                 min="1" // Prevents negative values
//                             />
//                             <select
//                                 name="durationUnit"
//                                 value={formData.durationUnit}
//                                 onChange={handleChange}
//                                 className="px-4 py-2 border rounded"
//                             >
//                                 <option value="days">Days</option>
//                                 <option value="weeks">Weeks</option>
//                                 <option value="months">Months</option>
//                             </select>
//                         </div>
//                     </div>
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Mode <span className="text-red-600">*</span>
//                         </label>
//                         <div className="flex gap-4">
//                             <label>
//                                 <input
//                                     type="radio"
//                                     name="mode"
//                                     value="Offline"
//                                     checked={formData.mode === 'Offline'}
//                                     onChange={handleChange}
//                                     className="mr-2"
//                                 />{' '}
//                                 Offline
//                             </label>
//                             <label>
//                                 <input
//                                     type="radio"
//                                     name="mode"
//                                     value="Online"
//                                     checked={formData.mode === 'Online'}
//                                     onChange={handleChange}
//                                     className="mr-2"
//                                 />{' '}
//                                 Online
//                             </label>
//                             <label>
//                                 <input
//                                     type="radio"
//                                     name="mode"
//                                     value="Online, Offline"
//                                     // checked={formData.mode === 'Both'}
//                                     checked={
//                                         formData.mode === 'Online, Offline'
//                                     }
//                                     onChange={handleChange}
//                                     className="mr-2"
//                                 />{' '}
//                                 Both
//                             </label>
//                         </div>
//                     </div>
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Internship Start Date{' '}
//                             <span className="text-red-600">*</span>
//                         </label>
//                         <input
//                             type="date"
//                             name="startDate"
//                             value={formData.startDate}
//                             onChange={handleChange}
//                             className="w-full px-4 py-2 border border-gray-300 rounded"
//                             required
//                             // min={today} // Disables past dates
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Start Time <span className="text-red-600">*</span>
//                         </label>
//                         <input
//                             type="time"
//                             name="startTime"
//                             value={formData.startTime}
//                             onChange={handleChange}
//                             className="w-full px-4 py-2 border border-gray-300 rounded"
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             End Time <span className="text-red-600">*</span>
//                         </label>
//                         <input
//                             type="time"
//                             name="endTime"
//                             value={formData.endTime}
//                             onChange={handleChange}
//                             className="w-full px-4 py-2 border border-gray-300 rounded"
//                             required
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Internship Type*
//                         </label>
//                         <div className="flex gap-4">
//                             <label>
//                                 <input
//                                     type="radio"
//                                     name="internshipType"
//                                     value="Free"
//                                     checked={internshipType === 'Free'}
//                                     // onChange={(e) =>
//                                     //     setInternshipType(e.target.value)

//                                     // }
//                                     onChange={(e) => {
//                                         setInternshipType(e.target.value);
//                                         setIsSaveDisabled(false); // Enable save button
//                                     }}
//                                     className="mr-2"
//                                 />{' '}
//                                 Free
//                             </label>
//                             <label>
//                                 <input
//                                     type="radio"
//                                     name="internshipType"
//                                     value="Paid"
//                                     checked={internshipType === 'Paid'}
//                                     onChange={(e) => {
//                                         setInternshipType(e.target.value);
//                                         setIsSaveDisabled(false); // Enable save button
//                                     }}
//                                     className="mr-2"
//                                 />{' '}
//                                 Paid
//                             </label>
//                         </div>
//                     </div>
//                     {/* {internshipType === 'Paid' && (
//                         <div className="form-group">
//                             <label className="block mb-2 font-semibold">
//                                 Stipend Amount*
//                             </label>
//                             <input
//                                 type="number"
//                                 placeholder="Enter stipend amount"
//                                 value={stipend}
//                                 onChange={(e) => setStipend(e.target.value)}
//                                 className="w-full px-4 py-2 border border-gray-300 rounded"
//                                 required
//                             />
//                         </div>
//                     )} */}
//                     {internshipType === 'Paid' && (
//                         <div className="form-group">
//                             <label className="block mb-2 font-semibold">
//                                 Stipend Amount*
//                             </label>
//                             <div className="flex gap-2 items-center">
//                                 <input
//                                     type="number"
//                                     placeholder="Enter stipend amount"
//                                     value={stipend}
//                                     onChange={(e) => setStipend(e.target.value)}
//                                     className="w-full px-4 py-2 border border-gray-300 rounded"
//                                     required
//                                 />
//                                 <select
//                                     value={stipendFrequency}
//                                     onChange={(e) => {
//                                         setStipendFrequency(e.target.value);
//                                         setIsSaveDisabled(false); // This line isn't strictly necessary now but won't hurt
//                                     }}
//                                     className="px-4 py-2 border border-gray-300 rounded"
//                                 >
//                                     <option value="per month">Per Month</option>
//                                     <option value="per week">Per Week</option>
//                                     <option value="per day">Per Day</option>
//                                 </select>
//                             </div>
//                         </div>
//                     )}
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Is it Active?*
//                         </label>
//                         <div className="flex gap-4">
//                             <label>
//                                 <input
//                                     type="radio"
//                                     name="isActive"
//                                     value="Yes"
//                                     checked={formData.isActive === 'Yes'}
//                                     onChange={handleChange}
//                                     className="mr-2"
//                                 />{' '}
//                                 Yes
//                             </label>
//                             <label>
//                                 <input
//                                     type="radio"
//                                     name="isActive"
//                                     value="No"
//                                     checked={formData.isActive === 'No'}
//                                     onChange={handleChange}
//                                     className="mr-2"
//                                 />{' '}
//                                 No
//                             </label>
//                         </div>
//                     </div>
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Certificate of Completion*
//                         </label>
//                         <div className="flex gap-4">
//                             <label>
//                                 <input
//                                     type="radio"
//                                     name="certificate"
//                                     value="Yes"
//                                     checked={formData.certificate === 'Yes'}
//                                     onChange={handleChange}
//                                     className="mr-2"
//                                 />{' '}
//                                 Yes
//                             </label>
//                             <label>
//                                 <input
//                                     type="radio"
//                                     name="certificate"
//                                     value="No"
//                                     checked={formData.certificate === 'No'}
//                                     onChange={handleChange}
//                                     className="mr-2"
//                                 />{' '}
//                                 No
//                             </label>
//                         </div>
//                     </div>
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Location
//                         </label>
//                         <input
//                             type="text"
//                             name="location"
//                             value={formData.location}
//                             onChange={handleChange}
//                             placeholder="Enter location"
//                             className="w-full px-4 py-2 border border-gray-300 rounded"
//                         />
//                     </div>
//                     <div className="form-group">
//                         <label className="block mb-2 font-semibold">
//                             Application Deadline{' '}
//                             <span className="text-red-600">*</span>
//                         </label>
//                         <input
//                             type="date"
//                             name="deadline"
//                             value={formData.deadline}
//                             // min={today} // Disables past dates
//                             onChange={handleChange}
//                             className="w-full px-4 py-2 border border-gray-300 rounded"
//                             required
//                         />
//                     </div>
//                     <div className="form-group md:col-span-2">
//                         <label className="block mb-2 font-semibold">
//                             Requirements
//                         </label>
//                         <textarea
//                             name="requirements"
//                             value={formData.requirements}
//                             onChange={handleChange}
//                             placeholder="Enter requirements"
//                             className="w-full px-4 py-2 border border-gray-300 rounded"
//                         ></textarea>
//                     </div>
//                     <div className="form-group md:col-span-2">
//                         <label className="block mb-2 font-semibold">
//                             Thumbnail <span className="text-red-600">*</span>
//                         </label>
//                         <input
//                             type="file"
//                             name="thumbnail"
//                             onChange={handleFileChange}
//                             className="w-full px-4 py-2 border border-gray-300 rounded"
//                             accept="image/*"
//                         />
//                         {uploading && <p>Uploading image...</p>}
//                         {previewUrl && (
//                             <div className="mt-4">
//                                 <img
//                                     src={previewUrl}
//                                     alt="Preview"
//                                     className="w-24 h-24 object-cover rounded-lg"
//                                 />
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </form>
//             <Toaster />
//         </div>
//     );
// };

// export default AddInternship;

import React, {
    useState,
    ChangeEvent,
    KeyboardEvent,
    FormEvent,
    useEffect,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    postRecord,
    readRecord,
    updateRecord,
} from '../../Services/backend/apiCalls';
import { toast, Toaster } from 'react-hot-toast';
import { storage } from '../../Firebase/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import arrow from '../../assets/Images/RightArrow.svg';

const AddInternship = () => {
    const [tags, setTags] = useState<string[]>([]);
    const [tagInput, setTagInput] = useState<string>('');
    const [internshipType, setInternshipType] = useState<string>('Free');
    const [stipend, setStipend] = useState<string>('');
    const [selectedDays, setSelectedDays] = useState<string[]>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [previewUrl, setPreviewURL] = useState<string>('');
    const [imageURL, setImageURL] = useState<string | null>(null);
    const [userId, setUserId] = useState<string | null>(null);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
    const [stipendFrequency, setStipendFrequency] =
        useState<string>('per month');
    const [initialFormState, setInitialFormState] = useState<any>(null);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const { id } = useParams();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        duration: '',
        durationUnit: 'days',
        mode: '',
        startDate: '',
        startTime: '',
        endTime: '',
        isActive: 'Yes',
        certificate: 'Yes',
        location: '',
        deadline: '',
        requirements: '',
        thumbnail: null as File | null,
        company: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            (async () => {
                const data = await readRecord({}, `ti-internships/${id}`);
                console.log(data);

                if (data.success) {
                    const internship = data.data;
                    const newFormData = {
                        title: internship.title,
                        description: internship.description,
                        duration: internship.duration.toString(),
                        durationUnit: internship.duration_unit,
                        mode: internship.mode,
                        startDate: internship.start_date,
                        startTime: internship.start_time,
                        endTime: internship.end_time,
                        isActive: internship.is_active ? 'Yes' : 'No',
                        certificate: internship.certificate ? 'Yes' : 'No',
                        location: internship.location,
                        deadline: internship.application_deadline,
                        requirements: internship.requirements,
                        company: internship.company,
                        thumbnail: null,
                    };

                    setFormData(newFormData);
                    setTags(internship.tags);
                    setSelectedDays(internship.days);
                    setImageURL(internship.thumbnail);
                    setPreviewURL(internship.thumbnail);
                    setInternshipType(internship.internship_type || 'Free');
                    setStipend(
                        internship.stipend ? internship.stipend.toString() : ''
                    );
                    setStipendFrequency(
                        internship.stipend_frequency || 'per month'
                    );

                    setInitialFormState({
                        ...newFormData,
                        tags: internship.tags,
                        selectedDays: internship.days,
                        internshipType: internship.internship_type || 'Free',
                        stipend: internship.stipend
                            ? internship.stipend.toString()
                            : '',
                        imageURL: internship.thumbnail,
                        stipendFrequency:
                            internship.stipend_frequency || 'per month',
                    });

                    setIsSaveDisabled(true);
                    setHasChanges(false);
                }
            })();
        }
    }, [id]);

    useEffect(() => {
        if (initialFormState) {
            const hasFormChanges =
                JSON.stringify({
                    ...formData,
                    tags,
                    selectedDays,
                    internshipType,
                    stipend,
                    imageURL,
                    stipendFrequency,
                }) !== JSON.stringify(initialFormState);

            setHasChanges(hasFormChanges);
        }
    }, [
        formData,
        tags,
        selectedDays,
        internshipType,
        stipend,
        imageURL,
        initialFormState,
        stipendFrequency,
    ]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('uid');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);

    useEffect(() => {
        if (!id) {
            setIsSaveDisabled(false);
            return;
        }

        const isFormIncomplete =
            !formData.title ||
            !formData.company ||
            !formData.description ||
            !formData.deadline;
        // const isDateInvalid =
        //     formData.startDate && formData.deadline > formData.startDate;

        // Only validate dates if start date is provided
        const isDateInvalid = formData.startDate
            ? formData.deadline > formData.startDate
            : false;

        if (isDateInvalid) {
            toast.error(
                'Application deadline must be before or on the start date.'
            );
        }

        setIsSaveDisabled(
            isFormIncomplete || isDateInvalid || uploading || !hasChanges
        );
    }, [formData, uploading, hasChanges, id]);

    const handleAddTag = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && tagInput.trim()) {
            addNewTag();
        }
    };

    const addNewTag = () => {
        if (tagInput.trim()) {
            setTags([...tags, tagInput.trim()]);
            setTagInput('');
            setIsSaveDisabled(false);
        }
    };

    const handleRemoveTag = (index: number) => {
        setTags(tags.filter((_, i) => i !== index));
        setIsSaveDisabled(false);
    };

    const handleChange = (
        e: ChangeEvent<
            HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
    ) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setIsSaveDisabled(false);
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFormData({ ...formData, thumbnail: e.target.files[0] });
            uploadImage(e.target.files[0]);
            setIsSaveDisabled(false);
        }
    };

    const uploadImage = (file: File) => {
        const fileName = `${formData.title}-${new Date().getTime()}_thumbnail`;
        const storageRef = ref(storage, `/thumbnails/${fileName}`);
        setUploading(true);

        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            'state_changed',
            () => {},
            (err) => {
                setUploading(false);
                console.error('Error uploading file:', err);
                toast.error('Failed to upload image.');
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setPreviewURL(url);
                    setImageURL(url);
                    setUploading(false);
                    toast.success('Image uploaded successfully!');
                });
            }
        );
    };

    const toggleDaySelection = (day: string) => {
        if (selectedDays.includes(day)) {
            setSelectedDays(selectedDays.filter((d) => d !== day));
        } else {
            setSelectedDays([...selectedDays, day]);
        }
        setIsSaveDisabled(false);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        // if (formData.startDate && formData.deadline > formData.startDate) {
        //     toast.error('Application deadline cannot be after the start date.');
        //     formData.deadline = '';
        //     return;
        // }

        // Only validate if BOTH start date AND deadline are provided
        if (formData.startDate && formData.deadline) {
            const startDate = new Date(formData.startDate);
            const deadline = new Date(formData.deadline);

            if (deadline > startDate) {
                toast.error(
                    'Application deadline cannot be after the start date.'
                );
                formData.deadline = '';
                return;
            }
        }

        setIsSaveDisabled(true);

        const internshipDetails = {
            title: formData.title,
            description: formData.description,
            tags: tags,
            days: selectedDays,
            duration: parseInt(formData.duration, 10),
            duration_unit: formData.durationUnit,
            mode: formData.mode,
            start_date: formData.startDate || null,
            start_time: formData.startTime,
            end_time: formData.endTime,
            // internship_type: internshipType,
            // stipend: internshipType === 'Paid' ? parseInt(stipend, 10) : null,
            // stipend_frequency:
            //     internshipType === 'Paid' ? stipendFrequency : null,
            internship_type: internshipType, // will save 'Free', 'Paid', or 'Stipend'
            stipend:
                internshipType === 'Paid' || internshipType === 'Stipend'
                    ? parseInt(stipend, 10)
                    : null,
            stipend_frequency:
                internshipType === 'Paid' || internshipType === 'Stipend'
                    ? stipendFrequency
                    : null,

            is_active: formData.isActive === 'Yes',
            certificate: formData.certificate === 'Yes',
            location: formData.location || null,
            application_deadline: formData.deadline,
            requirements: formData.requirements || null,
            thumbnail: imageURL,
            company: formData.company,
            user_id: userId,
        };

        try {
            const response = id
                ? await updateRecord(internshipDetails, `internships/${id}`)
                : await postRecord(internshipDetails, 'internships');
            toast.success(
                id
                    ? 'Internship updated successfully!'
                    : 'Internship created successfully!'
            );
            navigate(-1);
        } catch (error) {
            console.error('Error saving internship:', error);
            toast.error('Failed to save internship. Please try again.');
        }
    };

    return (
        <div className="container mx-auto sm:p-24 p-4 bg-gray-50">
            <h1 className="text-2xl font-bold ">
                {id ? 'Edit Internship' : 'Add New Internship'}
            </h1>
            <div className="flex gap-2 items-center mt-5 w-full mx-auto cursor-pointer mb-4">
                <img
                    src={arrow}
                    alt="ArrowForBack"
                    className="rotate-180 w-[17px] h-[17px]"
                    onClick={() => navigate(-1)}
                />
                <p
                    className="text-[16px] sm:text-[18px] font-[400] text-[#3061F4]"
                    onClick={() => navigate(-1)}
                >
                    Back
                </p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="flex items-center justify-end">
                    <button
                        type="submit"
                        className={`px-6 py-2 rounded mt-6 text-white ${
                            isSaveDisabled || uploading
                                ? 'bg-gray-500 cursor-not-allowed'
                                : 'bg-blue-500 text-white'
                        }`}
                        disabled={uploading || isSaveDisabled}
                    >
                        {id ? 'Save' : 'Create'}
                    </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Internship Title{' '}
                            <span className="text-red-600">*</span>
                        </label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            placeholder="Enter internship title"
                            className="w-full px-4 py-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Company Name <span className="text-red-600">*</span>
                        </label>
                        <input
                            type="text"
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                            placeholder="Enter company name"
                            className="w-full px-4 py-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="form-group md:col-span-2">
                        <label className="block mb-2 font-semibold">
                            Description <span className="text-red-600">*</span>
                        </label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            placeholder="Enter internship description"
                            className="w-full px-4 py-2 border border-gray-300 rounded"
                            rows={6}
                            required
                        ></textarea>
                    </div>
                    <div className="form-group md:col-span-2">
                        <label className="block mb-2 font-semibold">
                            Relevant Tags{' '}
                            <span className="text-red-600">*</span>
                        </label>
                        <div className="flex gap-2">
                            <input
                                type="text"
                                placeholder="Enter tags"
                                value={tagInput}
                                onChange={(e) => setTagInput(e.target.value)}
                                onKeyDown={handleAddTag}
                                className="w-full px-4 py-2 border border-gray-300 rounded"
                            />
                            <button
                                type="button"
                                onClick={addNewTag}
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 sm:hidden block"
                            >
                                Add
                            </button>
                        </div>
                        <div className="mt-2 flex flex-wrap gap-2">
                            {tags.map((tag, index) => (
                                <span
                                    key={index}
                                    className="inline-flex items-center bg-gray-200 px-3 py-1 rounded-full"
                                >
                                    {`#${tag}`}
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveTag(index)}
                                        className="ml-2 text-red-500 hover:text-red-700"
                                    >
                                        x
                                    </button>
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Days <span className="text-red-600">*</span>
                        </label>
                        <div className="grid sm:grid-cols-4 grid-cols-2 gap-2">
                            {[
                                'Sunday',
                                'Monday',
                                'Tuesday',
                                'Wednesday',
                                'Thursday',
                                'Friday',
                                'Saturday',
                            ].map((day) => (
                                <button
                                    key={day}
                                    type="button"
                                    onClick={() => toggleDaySelection(day)}
                                    className={`px-4 py-2 border rounded ${
                                        selectedDays.includes(day)
                                            ? 'bg-blue-500 text-white'
                                            : 'bg-white text-gray-700'
                                    }`}
                                >
                                    {day}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Duration <span className="text-red-600">*</span>
                        </label>
                        <div className="flex gap-2">
                            <input
                                type="number"
                                name="duration"
                                value={formData.duration}
                                onChange={handleChange}
                                placeholder="e.g., 10"
                                className="w-full px-4 py-2 border rounded"
                                required
                                min="1"
                            />
                            <select
                                name="durationUnit"
                                value={formData.durationUnit}
                                onChange={handleChange}
                                className="px-4 py-2 border rounded"
                            >
                                <option value="days">Days</option>
                                <option value="weeks">Weeks</option>
                                <option value="months">Months</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Mode <span className="text-red-600">*</span>
                        </label>
                        <div className="flex gap-4">
                            <label>
                                <input
                                    type="radio"
                                    name="mode"
                                    value="Offline"
                                    checked={formData.mode === 'Offline'}
                                    onChange={handleChange}
                                    className="mr-2"
                                />{' '}
                                Offline
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="mode"
                                    value="Online"
                                    checked={formData.mode === 'Online'}
                                    onChange={handleChange}
                                    className="mr-2"
                                />{' '}
                                Online
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="mode"
                                    value="Online, Offline"
                                    checked={
                                        formData.mode === 'Online, Offline'
                                    }
                                    onChange={handleChange}
                                    className="mr-2"
                                />{' '}
                                Both
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Internship Start Date{' '}
                            <span className="text-red-600">*</span>
                        </label>
                        <input
                            type="date"
                            name="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Start Time <span className="text-red-600">*</span>
                        </label>
                        <input
                            type="time"
                            name="startTime"
                            value={formData.startTime}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            End Time <span className="text-red-600">*</span>
                        </label>
                        <input
                            type="time"
                            name="endTime"
                            value={formData.endTime}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    {/* <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Internship Type*
                        </label>
                        <div className="flex gap-4">
                            <label>
                                <input
                                    type="radio"
                                    name="internshipType"
                                    value="Free"
                                    checked={internshipType === 'Free'}
                                    onChange={(e) => {
                                        setInternshipType(e.target.value);
                                        setIsSaveDisabled(false);
                                    }}
                                    className="mr-2"
                                />{' '}
                                Free
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="internshipType"
                                    value="Paid"
                                    checked={internshipType === 'Paid'}
                                    onChange={(e) => {
                                        setInternshipType(e.target.value);
                                        setIsSaveDisabled(false);
                                    }}
                                    className="mr-2"
                                />{' '}
                                Paid
                            </label>
                        </div>
                    </div>
                    {internshipType === 'Paid' && (
                        <div className="form-group">
                            <label className="block mb-2 font-semibold">
                                Stipend Amount*
                            </label>
                            <div className="flex gap-2 items-center">
                                <input
                                    type="number"
                                    placeholder="Enter stipend amount"
                                    value={stipend}
                                    onChange={(e) => setStipend(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded"
                                    required
                                />
                                <select
                                    value={stipendFrequency}
                                    onChange={(e) => {
                                        setStipendFrequency(e.target.value);
                                        setIsSaveDisabled(false);
                                    }}
                                    className="px-4 py-2 border border-gray-300 rounded"
                                >
                                    <option value="per month">Per Month</option>
                                    <option value="per week">Per Week</option>
                                    <option value="per day">Per Day</option>
                                </select>
                            </div>
                        </div>
                    )} */}

                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Internship Type*
                        </label>
                        <div className="flex gap-4">
                            <label>
                                <input
                                    type="radio"
                                    name="internshipType"
                                    value="Free"
                                    checked={internshipType === 'Free'}
                                    onChange={(e) => {
                                        setInternshipType(e.target.value);
                                        setIsSaveDisabled(false);
                                    }}
                                    className="mr-2"
                                />{' '}
                                Free
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="internshipType"
                                    value="Paid"
                                    checked={internshipType === 'Paid'}
                                    onChange={(e) => {
                                        setInternshipType(e.target.value);
                                        setIsSaveDisabled(false);
                                    }}
                                    className="mr-2"
                                />{' '}
                                Paid
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="internshipType"
                                    value="Stipend"
                                    checked={internshipType === 'Stipend'}
                                    onChange={(e) => {
                                        setInternshipType(e.target.value);
                                        setIsSaveDisabled(false);
                                    }}
                                    className="mr-2"
                                />{' '}
                                Stipend{' '}
                                <small className="    text-xs">
                                    {' '}
                                    <i>(provided by you)</i>{' '}
                                </small>
                            </label>
                        </div>
                    </div>

                    {(internshipType === 'Paid' ||
                        internshipType === 'Stipend') && (
                        <div className="form-group">
                            <label className="block mb-2 font-semibold">
                                Amount*
                            </label>
                            <div className="flex gap-2 items-center">
                                <input
                                    type="number"
                                    placeholder="Enter amount"
                                    value={stipend}
                                    onChange={(e) => setStipend(e.target.value)}
                                    className="w-full px-4 py-2 border border-gray-300 rounded"
                                    required
                                />
                                <select
                                    value={stipendFrequency}
                                    onChange={(e) => {
                                        setStipendFrequency(e.target.value);
                                        setIsSaveDisabled(false);
                                    }}
                                    className="px-4 py-2 border border-gray-300 rounded"
                                >
                                    <option value="per month">Per Month</option>
                                    <option value="per week">Per Week</option>
                                    <option value="per day">Per Day</option>
                                </select>
                            </div>
                        </div>
                    )}

                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Is it Active?*
                        </label>
                        <div className="flex gap-4">
                            <label>
                                <input
                                    type="radio"
                                    name="isActive"
                                    value="Yes"
                                    checked={formData.isActive === 'Yes'}
                                    onChange={handleChange}
                                    className="mr-2"
                                />{' '}
                                Yes
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="isActive"
                                    value="No"
                                    checked={formData.isActive === 'No'}
                                    onChange={handleChange}
                                    className="mr-2"
                                />{' '}
                                No
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Certificate of Completion*
                        </label>
                        <div className="flex gap-4">
                            <label>
                                <input
                                    type="radio"
                                    name="certificate"
                                    value="Yes"
                                    checked={formData.certificate === 'Yes'}
                                    onChange={handleChange}
                                    className="mr-2"
                                />{' '}
                                Yes
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="certificate"
                                    value="No"
                                    checked={formData.certificate === 'No'}
                                    onChange={handleChange}
                                    className="mr-2"
                                />{' '}
                                No
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Location
                        </label>
                        <input
                            type="text"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            placeholder="Enter location"
                            className="w-full px-4 py-2 border border-gray-300 rounded"
                        />
                    </div>
                    <div className="form-group">
                        <label className="block mb-2 font-semibold">
                            Application Deadline{' '}
                            <span className="text-red-600">*</span>
                        </label>
                        <input
                            type="date"
                            name="deadline"
                            value={formData.deadline}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded"
                            required
                        />
                    </div>
                    <div className="form-group md:col-span-2">
                        <label className="block mb-2 font-semibold">
                            Requirements
                        </label>
                        <textarea
                            name="requirements"
                            value={formData.requirements}
                            onChange={handleChange}
                            placeholder="Enter requirements"
                            className="w-full px-4 py-2 border border-gray-300 rounded"
                        ></textarea>
                    </div>
                    <div className="form-group md:col-span-2">
                        <label className="block mb-2 font-semibold">
                            Thumbnail <span className="text-red-600">*</span>
                        </label>
                        <input
                            type="file"
                            name="thumbnail"
                            onChange={handleFileChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded"
                            accept="image/*"
                        />
                        {uploading && <p>Uploading image...</p>}
                        {previewUrl && (
                            <div className="mt-4">
                                <img
                                    src={previewUrl}
                                    alt="Preview"
                                    className="w-24 h-24 object-cover rounded-lg"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </form>
            <Toaster />
        </div>
    );
};

export default AddInternship;

import axiosClient from './axiosClient';
import { NavigateToSignInWithRouteParams } from '../commonFunctions';
import { toast } from 'react-hot-toast';
import axios from 'axios';

export async function forgotpassword(emailsendRequest: any) {
    return axiosClient
        .post('/forgotpassword', emailsendRequest)
        .then((res) => Promise.resolve(res.data));
}

export const readRecord = async (
    params: any = {},
    endpoint: string,
    navigate: any = false
) => {
    const accessToken = localStorage.getItem('token') || '';
    const headers = { token: accessToken };
    let response = await axiosClient
        .get(`/${endpoint}`, { headers, params })
        .then((res) => Promise.resolve(res));
    // if (response.status === 401) {
    //     return NavigateToSignInWithRouteParams(response);
    // console.log('24', response.data);

    // }
    return response.data;
};

export const postRecord = async (
    body: any,
    endpoint: string,
    navigate: any = false
) => {
    // console.log('endpoint', endpoint);
    // console.log('endpoint', body);

    // const headers = { token };
    const accessToken = localStorage.getItem('token') || '';
    const headers = { token: accessToken };
    const response = await axiosClient
        .post(`/${endpoint}`, body, { headers })
        .then((res) => Promise.resolve(res));
    // if (response.status === 401) {
    //     return NavigateToSignInWithRouteParams(response);
    // }
    // console.log('42', response.data);

    return response.data;
};

export const updateRecord = async (
    body: any,
    endpoint: string,
    navigate: any = false
) => {
    const accessToken = localStorage.getItem('token') || '';
    const headers = { token: accessToken };
    const response = await axiosClient
        .put(`/${endpoint}`, body, { headers })
        .then((res) => Promise.resolve(res));
    // if (response.status === 401) {
    //     return NavigateToSignInWithRouteParams(response);
    // }
    return response.data;
};

export const deleteRecord = async (
    params: any,
    endpoint: string,
    navigate: any = false
) => {
    const accessToken = localStorage.getItem('token') || '';
    const headers = { token: accessToken };
    const response = await axiosClient
        .delete(`/${endpoint}`, { headers, params })
        .then((res) => Promise.resolve(res));
    if (response.status === 401) {
        return NavigateToSignInWithRouteParams(response);
    }
    return response.data;
};

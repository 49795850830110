import React from 'react';
import * as Yup from 'yup';

var strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
);
const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validateEmail = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const URL =
    /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

// const gstValidate =
//     /d{2}[a-zA-Z]{5}d{4}[a-zA-Z]{1}[a-zA-Zd]{1}[zZ]{1}[a-zA-Zd]{1}$/;

export const CreateValidationSchema = Yup.object().shape({});

export const ChangePasswordValidationSchema = Yup.object().shape({
    new_password: Yup.string()
        .required('Enter the new password')
        .min(6, 'Should have Atleast Six Charcters')
        .max(12, 'Enter maximum 12 characters')
        .matches(/^(?=.*[a-z])/, 'Must Contain One Lowercase Character')
        .matches(/^(?=.*[A-Z])/, 'Must Contain One Uppercase Character')
        .matches(/^(?=.*[0-9])/, 'Must Contain One Number Character')
        .matches(
            /^(?=.*[!@#\$%\^&\*])/,
            '  Must Contain  One Special Case Character'
        ),
    // confirm_password: Yup.string()
    //     .required('Enter the confirm password')
    //     .oneOf([Yup.ref('new_password')], 'Your passwords does not match.'),
});

export const CreateValidationUpdatePassSchema = Yup.object().shape({
    new_password: Yup.string()
        .required('Enter the new password')
        // .matches(strongRegex, 'Use Strong Password e4iywc*b5s6GS!u')
        .matches(/^(?=.*[a-z])/, ' Must Contain One Lowercase Character')
        .matches(/^(?=.*[A-Z])/, '  Must Contain One Uppercase Character')
        .matches(/^(?=.*[0-9])/, '  Must Contain One Number Character')
        .matches(
            /^(?=.*[!@#\$%\^&\*])/,
            '  Must Contain  One Special Case Character'
        )
        .min(6, 'Should have Atleast Six Charcters')
        .max(12, 'Enter maximum 12 characters'),
});

export const CreateValidationEmailSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email is invalid')
        .required('Required')
        .matches(validateEmail, 'Only alphabets are allowed for this field ')
        .max(50),
    redirect_url: Yup.string().required('Required'),
});

export const CreateValidationSignSchema = (text: string) => {
    return Yup.object().shape({
        email: Yup.string()
            .email('Email is invalid')
            .required('Required')
            .matches(
                validateEmail,
                'Only alphabets are allowed for this field '
            )
            .max(50),
        ...(text === 'signin'
            ? {
                  password: Yup.string().required('Enter the password'),
              }
            : {
                  password: Yup.string()
                      .required('Enter the password')
                      .matches(
                          /^(?=.*[a-z])/,
                          ' Must Contain One Lowercase Character'
                      )
                      .matches(
                          /^(?=.*[A-Z])/,
                          '  Must Contain One Uppercase Character'
                      )
                      .matches(
                          /^(?=.*[0-9])/,
                          '  Must Contain One Number Character'
                      )
                      .matches(
                          /^(?=.*[!@#\$%\^&\*])/,
                          '  Must Contain  One Special Case Character'
                      )
                      .min(8, 'Should have Atleast Eight Charcters')
                      .max(12, 'Enter maximum 12 characters'),
              }),
    });
};

export const profileForST = Yup.object().shape({
    name: Yup.string().required('Please enter the name'),
    contact_number: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .length(10, 'Please enter a valid mobile number')
        .required('Required'),
    city: Yup.string().required('Select anyone city'),
    address_line: Yup.string().required('Required'),
    state: Yup.string(),
});

export const profileForCO = Yup.object().shape({
    name: Yup.string(),
    company_url: Yup.string().matches(URL, 'Enter a valid url'),
    contact_number: Yup.string()
        .matches(phoneRegExp, 'Phone Number is not valid')
        .length(10, 'Please Enter Valid Number')
        .required('Required'),
    city: Yup.string().required('Select anyone city'),
    address_line: Yup.string().required('Required'),
    state: Yup.string(),
    country: Yup.string().required('Select anyone country'),
});

export const profileForFL = Yup.object().shape({
    name: Yup.string().required('Please enter the name'),
    contact_number: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .length(10, 'Please Enter Valid Number')
        .required('Required'),
    city: Yup.string().required('Select anyone city'),
    address_line: Yup.string().required('Required'),
    state: Yup.string(),
    country: Yup.string().required('Select anyone country'),
    linkedin_url: Yup.string(),
    industry: Yup.string().required('Required'),
    // resume_url: Yup.string().required('Please upload your resume'),
    skills: Yup.string()
        // .of(Yup.string())
        .required('Please enter your skills'),
    achievements: Yup.string(),
});

export const profileForTI = Yup.object().shape({
    name: Yup.string().required('Enter your institute name'),
    contact_number: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .length(10, 'Please enter a valid mobile number')
        .required('Required'),
    // achievements: Yup.string().required(""),
    industry: Yup.string().required(' name'),
    url: Yup.string().matches(URL, 'Enter a valid url').required('Enter URL'),
    country: Yup.string(),
    state: Yup.string(),
    city: Yup.string().required('Select anyone city'),
    address_line: Yup.string().required('Required'),
    achievements: Yup.string(),
    gst_number: Yup.string(),
});

export const CO = Yup.object().shape({
    organization_name: Yup.string().required('Enter your organization name'),
    contact_number: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .length(10, 'Please enter a valid mobile number')
        .required('Required'),
    city: Yup.string().required('Select anyone city'),
    address_line: Yup.string().required('Required'),
    company_url: Yup.string()
        .required('Required')
        .matches(URL, 'Enter a valid url'),
});

export const ST = Yup.object().shape({
    first_name: Yup.string()
        .max(20, 'Too Long!')
        .required('Enter the FirstName'),
    last_name: Yup.string().max(20, 'Too Long!').required('Enter the LastName'),
    contact_number: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .length(10, 'Please enter a valid mobile number')
        .required('Required'),
    city: Yup.string().required('Select anyone city'),
    address_line: Yup.string().required('Required'),
});

export const FL = Yup.object().shape({
    first_name: Yup.string()
        .max(20, 'Too Long!')
        .required('Enter the FirstName'),
    last_name: Yup.string().max(20, 'Too Long!').required('Enter the LastName'),
    contact_number: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .length(10, 'Please Enter Valid Number')
        .required('Required'),
    city: Yup.string().required('Select anyone city'),
    address_line: Yup.string().required('Required'),
    linkedin_url: Yup.string(),
    industry: Yup.string().required('required'),
    // resume_url: Yup.string().required('Please upload your resume here'),
    skills: Yup.string().required('Please enter your skills'),
});

export const TI = Yup.object().shape({
    name: Yup.string().required('Please Enter your institute name'),
    contact_number: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .length(10, 'Please enter a valid mobile number')
        .required('Required'),
    achievements: Yup.string().required(''),
    industry: Yup.string().required('required'),
    url: Yup.string().matches(URL, 'Enter a valid url').required('Required'),
    city: Yup.string().required('Select anyone city'),
    address_line: Yup.string().required('Required'),
});

export const addCourses = (showStateOfPercent: any) => {
    return Yup.object().shape({
        title: Yup.string().required('Please Enter the title'),
        description: Yup.string().required('Enter the description'),
        duration_count: Yup.number()
            .required('Enter course duration')
            .positive('Enter a valid percentage')
            .min(1),
        duration_type: Yup.string(),
        fee: Yup.number()
            .required('Enter the Fee')
            .positive('Enter Valid Amount')
            .min(1, 'Enter Valid Amount')
            .integer('invalid fee'),
        ...(showStateOfPercent && {
            discount_percentage: Yup.number()
                //   .required('Enter the discount percentage')
                .max(100, 'invalid percentage')
                //   .positive('Enter a valid percentage')
                .integer('invalid percentage'),
        }),
        // : { discount_percentage: Yup.number() }), // deployment
        // discount_percentage: Yup.number().when('discount_available', {
        //     is: (discount_available: any) => discount_available,
        //     then: Yup.number()
        //         .required('Enter the discount percentage')
        //         .max(100, 'invalid percentage')
        //         .positive('Enter a valid percentage 11')
        //         .integer('invalid percentage'),
        //     otherwise: Yup.number().notRequired(),
        // }),
        highlights: Yup.string(),
        image: Yup.string().required('Please Upload Image'),
        session: Yup.array()
            .min(1, 'Choose atleast one day')
            .of(Yup.string())
            .required('Session is required'),
        batches: Yup.array()
            .min(1, 'Atleast one batch is required')
            .required('Batch is required'),
        // tags: Yup.array().min(1, "Atleast one tag is required").required("Atleast one tag is required"),
        tags: Yup.array()
            .min(1, 'Enter AtLeast One Tag')
            .required('Select Tags')
            .of(Yup.string()),
        mode: Yup.string(),
        isactive: Yup.boolean(),
        certification_guidance: Yup.boolean(),
        discount_available: Yup.boolean(),
        // .oneOf(
        // [true],
        // 'Percentage Available'
        // ),
    });
};

export const customizeCourses = Yup.object().shape({
    title: Yup.string().required('Please Enter the title'),
    description: Yup.string().required('Enter the description'),
    duration_count: Yup.number()
        .required('Enter course duration')
        .positive('Enter a valid percentage')
        .min(1),
    duration_type: Yup.string(),
    fee: Yup.number()
        .required('Enter the Fee')
        .positive('Enter Valid Amount')
        .min(1, 'Enter Valid Amount')
        .integer('invalid fee'),
    highlights: Yup.string(),
    session: Yup.array()
        .min(1, 'Choose atleast one day')
        .of(Yup.string())
        .required('Session is required'),
    // batch: Yup.object().required('Batch is required'),
});

export const BookNow = Yup.object().shape({
    date_of_training: Yup.string()
        .min(5, 'Select Date of Joining')
        .required('Select Date of Joining'),
    batch_timing: Yup.string()
        .min(5, 'Select batch Training')
        .required('Select batch Training'),
});

export const NewBidSchema = Yup.object().shape({
    title: Yup.string().required('Course Title is required'),
    address: Yup.string().required('Address is required'),
    start_date: Yup.string().required('Start date is required'),
    trainees_count: Yup.number()
        .positive('Enter a valid count')
        .required('Head count is required'),
    preferred_days: Yup.array()
        .min(1, 'Choose atleast one day')
        .of(Yup.string())
        .required('Days is required'),
    days: Yup.number()
        .required('Days is required')
        .min(1, 'Enter valid days count'),
    mode: Yup.string().required('Mode is required'),
    // tags:Yup.array()
    tags: Yup.array()
        .min(1, 'Enter AtLeast One Tag')
        .required('Select Tags')
        .of(Yup.string()),
});

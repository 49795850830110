import React, { useState, useEffect, useRef } from 'react';
import { Card } from './Card';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../..';
import { postRecord } from '../../Services/backend/apiCalls';
import { DashBoardLoader } from './NoDataChecker';
import ScrollY from '../../assets/Images/ScrollY.svg';
import circleBlue from '../../assets/Images/circleBlue.svg';
import { setSuggestCourse } from '../../Redux/Reducers/suggestionCardReducer';

export const Suggestions = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [suggestion, setSuggestion] = useState([]);
    const scrollRef: any = useRef();
    const [alreadyfetcheditems, setAlreadyFetchedItems] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const { appConfig } = useAppSelector(
        ({ appConfig }: any) => appConfig['configData']
    );

    const { signin }: any = useAppSelector((state) => state);
    const role = signin.signinValue.role;
    const userType = ['TI', 'FL'].includes(role) ? 'trainee' : 'trainer';
    const isTrainee = !['TI', 'FL'].includes(role);

    const fetchSuggestionData = async () => {
        const { status, data } = await postRecord(
            alreadyfetcheditems,
            isTrainee ? 'directrequestsuggestions' : 'biddingsuggestions'
        );
        if (status) {
            dispatch(setSuggestCourse({ suggestion: data }));
            // console.log(data.map((dt: any) => dt.id));
            const fetchedIds = data.map((dt: any) => dt.id);
            const removePastCouseData = data
                .filter((course: any) => {
                    let currentDate = new Date().toISOString();
                    if (isTrainee) {
                        if (currentDate <= course?.batches[0]?.batchDate) {
                            return course;
                        }
                    } else {
                        if (currentDate <= course?.start_date) {
                            return course;
                        }
                    }
                })
                .sort((a: any, b: any) => {
                    const dateA = new Date(a.createdAt).getTime();
                    const dateB = new Date(b.createdAt).getTime();

                    return dateB - dateA;
                });
            setAlreadyFetchedItems(fetchedIds);
            setSuggestion(removePastCouseData);
            // setSuggestion(data);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchSuggestionData();
    }, []);

    const suggestionMap = (
        <div
            className="flex relative space-x-4 overflow-x-auto scrollBar scroll-smooth snap-x snap-mandatory"
            ref={scrollRef}
        >
            {suggestion.map((suggest: any, index: number) => {
                return (
                    <Card
                        id={suggest.id}
                        isTrainee={isTrainee}
                        userRole={role}
                        key={index}
                        cardColor={'bg-white'}
                        cardType="suggest"
                        title={suggest.title}
                        // courseDescription={
                        //     isTrainee
                        //         ? suggest.course_description
                        //         : suggest.description
                        // }
                        courseDescription={' '}
                        user_id={
                            isTrainee
                                ? suggest.profile.user_id
                                : suggest.Trainee.user_id
                        }
                        userName={
                            isTrainee
                                ? suggest.profile.name
                                : suggest.Trainee.name
                        }
                        role={
                            isTrainee
                                ? suggest.profile.role
                                : suggest.Trainee.role
                        }
                        date={
                            isTrainee ? suggest.createdAt : suggest.start_date
                        }
                        width={'w-[292px]'}
                        image={suggest.image}
                        trainees_count={suggest.trainees_count}
                        appConfigDr={
                            appConfig['direct_request_suggestions'] ===
                                'edit' &&
                            appConfig['direct_request_action'] === 'edit' &&
                            appConfig['cms_action'] === 'edit'
                        }
                        appConfigBd={
                            appConfig['bidding_suggestions'] === 'edit' &&
                            appConfig['bidding_action'] === 'edit' &&
                            appConfig['cms_action'] === 'edit'
                        }
                    />
                );
            })}
        </div>
    );

    return (
        <div className="py-6 bg-[#F2F4F5B2] relative">
            <img
                src={circleBlue}
                alt="circleBlue"
                className="absolute rotate-180 top-0 right-0 -z-20 sm:z-0"
            />
            <div className="w-[95%] mx-auto">
                <div>
                    <h1 className="sm:text-[35px] text-[25px] font-[500] text-center z-10">
                        {userType === 'trainee'
                            ? 'Ongoing Bids Just For You'
                            : 'Courses Recommended For You'}
                    </h1>
                    <div className="w-[50px] mt-1 h-[3px] rounded-full m-auto bg-gradient-to-r from-[#04D5BD] to-[#267DF8]"></div>
                </div>
                {/* <div className="flex w-full px-8 py-4 mt-6 items-center justify-end mx-auto">
                    <h3 className="text-[25px] font-[500]">
                        {userType === 'trainee'
                            ? 'Ongoing Bids Just For You'
                            : 'Direct Request'}
                    </h3>
                    <p
                        className="cursor-pointer text-[14px] font-[500] text-[#5F5F5F] hover:text-[#267DF8]"
                        onClick={() =>
                            navigate('/request/suggestion', {
                                state: 'typeForDashboard',
                            })
                        }
                    >
                        See All
                    </p>
                </div> */}
                {/* update by me */}

                {suggestion.length > 4 && (
                    <div className="flex justify-end">
                        <p
                            className="py-3 pr-8 cursor-pointer text-[14px] font-[500] text-[#5F5F5F] hover:text-[#267DF8] mb-4"
                            onClick={() =>
                                navigate('/request/suggestion', {
                                    state: 'typeForDashboard',
                                })
                            }
                        >
                            See All
                        </p>
                    </div>
                )}
                <div
                    className={`flex items-center justify-around space-x-5 ${
                        isTrainee ? 'h-[440px]' : 'h-[260px]'
                    }`}
                >
                    {!loading && (
                        <img
                            src={ScrollY}
                            alt="ScrollY"
                            className="cursor-pointer"
                            onClick={() =>
                                (scrollRef.current.scrollLeft -= 1000)
                            }
                        />
                    )}
                    <DashBoardLoader
                        children={suggestionMap}
                        data={suggestion}
                        loading={loading}
                    />
                    {!loading && (
                        <img
                            src={ScrollY}
                            alt="ScrollY"
                            className="rotate-180 cursor-pointer"
                            onClick={() =>
                                (scrollRef.current.scrollLeft += 1000)
                            }
                        />
                    )}
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

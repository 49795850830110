import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { suggestionTypes, suggestionState } from '../Types/suggestionCardType';

const initialState: suggestionState = {
    SuggestionData: {
        suggestion: [],
    },
};

const suggestCourseSlice = createSlice({
    name: 'suggest',
    initialState,
    reducers: {
        setSuggestCourse: (state: suggestionState, action: PayloadAction<any>) => {
            state.SuggestionData = {
                ...state.SuggestionData,
                ...action.payload,
            };
        },
    },
});

export const { setSuggestCourse } = suggestCourseSlice.actions;
export default suggestCourseSlice.reducer;

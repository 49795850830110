import { configureStore } from '@reduxjs/toolkit';
import zipcodeReducer from './Reducers/zipcodeReducers';
import signinReducer from './Reducers/signinUserReducers';
import directrequestReducer from './Reducers/directRequestReducers';
import DRReducer from './Reducers/DRReducers';
import viewCourseDetails from './Reducers/viewCourseReducers';
import ModalSlice from './Reducers/popupReducer';
import comparecoursesReducers from './Reducers/comparecoursesReducers';
import skillBasedSearchReducers from './Reducers/skillBasedSearchReducers';
import notificationReducer from './Reducers/notificationReducer';
import suggestionCardReducer from './Reducers/suggestionCardReducer';
import appConfigReducers from './Reducers/appConfigReducers';
import internshipSearchReducer from './Reducers/internshipSearchReducer';

export const store = configureStore({
    reducer: {
        zipcode: zipcodeReducer,
        signin: signinReducer,
        directRequest: directrequestReducer,
        modal: ModalSlice,
        DR: DRReducer,
        view: viewCourseDetails,
        compare: comparecoursesReducers,
        skill: skillBasedSearchReducers,
        notification: notificationReducer,
        suggest: suggestionCardReducer,
        appConfig: appConfigReducers,
        internshipSearch: internshipSearchReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }),
});

export type State = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;

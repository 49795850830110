// import { DirectRequestActionComponent } from './DirectRequestActionComponent';
// import {
//     daysConversionAndSort,
//     isTrainer,
// } from '../../Services/commonFunctions';
// import mode from '../../assets/Images/mode.svg';
// import session from '../../assets/Images/session.svg';
// import DiscountAvailable from '../../assets/Images/DiscountAvailable.svg';
// import certification from '../../assets/Images/certification.svg';
// import { useAppSelector } from '../..';

// export const SelectedDetails = ({
//     directRequestStatus,
//     id,
//     individualCourse,
//     role,
//     isDirectRequestPage,
//     selectedBatchDate,
// }: any) => {
//     const { appConfig } = useAppSelector(
//         ({ appConfig }: any) => appConfig['configData']
//     );
//     // const batchEndDate = individualCourse?.batches[0].batchDate;
//     const batchEndDate = individualCourse?.batches?.map(
//         (date: any) => date?.batchDate
//     );

//     const DetailsForCourse: any = [
//         {
//             image: mode,
//             text: 'Mode',
//             value: individualCourse
//                 ? individualCourse.mode_of_teaching
//                     ? individualCourse.mode_of_teaching.mode
//                     : ''
//                 : '',
//         },
//         ...(individualCourse.discount_available
//             ? [
//                   {
//                       image: DiscountAvailable,
//                       text: 'Discount',
//                       value: individualCourse
//                           ? individualCourse.discount_percentage
//                           : '',
//                   },
//               ]
//             : []),
//         {
//             image: certification,
//             text: 'Certification',
//             value: individualCourse
//                 ? individualCourse.certification_guidance === false
//                     ? 'No'
//                     : 'Yes'
//                 : '',
//         },
//         // {
//         //     image: session,
//         //     text: 'Session',
//         //     value: individualCourse
//         //         ? individualCourse.mode_of_teaching
//         //             ? individualCourse.mode_of_teaching.session
//         //             : ''
//         //         : '',
//         // },
//     ];

//     // console.log(
//     //     batchEndDate,
//     //     'batchEndDate',
//     //     individualCourse,
//     //     'individualCourse',
//     //     directRequestStatus.status !== 'rejected' &&
//     //         appConfig['direct_request_action'] === 'edit' &&
//     //         appConfig['cms_action'] === 'edit',
//     //     'BOOLEAN',
//     //     isTrainer(role),
//     //     role,
//     //     'role'
//     // );

//     return (
//         <div className="w-4/12 xl:w-4/12">
//             <h1 className="   pl-2  font-medium">Original Course Details</h1>

//             <div className="p-1 xl:p-2 bg-white">
//                 <div className="flex flex-col pt-2 items-start w-full space-y-4">
//                     {DetailsForCourse.map((course: any, i: any) => {
//                         return (
//                             <div
//                                 key={i}
//                                 className="flex w-full items-center px-2 border-b-[1px] border-[#F3F3F3] border-dashed"
//                             >
//                                 <div className="flex items-center gap-2 w-[47%]">
//                                     <img
//                                         src={course.image}
//                                         alt="courses"
//                                         className="w-[18px] h-[18px]"
//                                     />
//                                     <h1 className="text-[15px] text-start font-[400]">
//                                         {course.text}
//                                     </h1>
//                                 </div>
//                                 <h1>
//                                     <span className="ml-1">:</span>
//                                     <span className="w-[53%] text-[13px] font-[600] ml-3 capitalize">
//                                         {/* {course.value} */}
//                                         {course.value === 'both' ? (
//                                             <span>Online,Offline</span>
//                                         ) : (
//                                             course.value
//                                         )}
//                                         {course.text === 'Discount' && '%'}
//                                     </span>
//                                 </h1>
//                             </div>
//                         );
//                     })}
//                 </div>
//                 {directRequestStatus.status !== 'rejected' &&
//                     appConfig['direct_request_action'] === 'edit' &&
//                     appConfig['cms_action'] === 'edit' && (
//                         <DirectRequestActionComponent
//                             batchEndDate={batchEndDate}
//                             directRequestStatus={directRequestStatus}
//                             id={id}
//                             isTrainer={isTrainer(role)}
//                             isDirectRequestPage={isDirectRequestPage}
//                             batches={individualCourse.batches}
//                             role={role}
//                             selectedBatchDate={selectedBatchDate}
//                         />
//                     )}
//             </div>
//         </div>
//     );
// };

import { DirectRequestActionComponent } from './DirectRequestActionComponent';
import {
    daysConversionAndSort,
    isTrainer,
} from '../../Services/commonFunctions';
import mode from '../../assets/Images/mode.svg';
import DiscountAvailable from '../../assets/Images/DiscountAvailable.svg';
import certification from '../../assets/Images/certification.svg';
import { useAppSelector } from '../..';

export const SelectedDetails = ({
    directRequestStatus,
    id,
    individualCourse,
    role,
    isDirectRequestPage,
    selectedBatchDate,
}: any) => {
    const { appConfig } = useAppSelector(
        ({ appConfig }: any) => appConfig['configData']
    );
    const batchEndDate = individualCourse?.batches?.map(
        (date: any) => date?.batchDate
    );

    const DetailsForCourse: any = [
        {
            image: mode,
            text: 'Mode',
            value: individualCourse
                ? individualCourse.mode_of_teaching
                    ? individualCourse.mode_of_teaching.mode
                    : ''
                : '',
        },
        ...(individualCourse.discount_available
            ? [
                  {
                      image: DiscountAvailable,
                      text: 'Discount',
                      value: individualCourse
                          ? individualCourse.discount_percentage
                          : '',
                  },
              ]
            : []),
        {
            image: certification,
            text: 'Certification',
            value: individualCourse
                ? individualCourse.certification_guidance === false
                    ? 'No'
                    : 'Yes'
                : '',
        },
    ];

    return (
        <div className="w-full md:w-4/12 xl:w-4/12">
            <h1 className="pl-2 font-medium text-lg md:text-xl">
                Original Course Details
            </h1>
            <div className="p-2 bg-white rounded shadow-sm">
                <div className="flex flex-col items-start w-full space-y-4 pt-2">
                    {DetailsForCourse.map((course: any, i: any) => (
                        <div
                            key={i}
                            className="flex w-full items-center px-2 border-b border-dashed border-gray-300"
                        >
                            <div className="flex items-center gap-2 w-[47%]">
                                <img
                                    src={course.image}
                                    alt={course.text}
                                    className="w-[18px] h-[18px]"
                                />
                                <h1 className="text-[15px] font-medium">
                                    {course.text}
                                </h1>
                            </div>
                            <h1 className="flex-1 text-[13px] font-semibold ml-3 capitalize">
                                {course.value === 'both' ? (
                                    <span>Online, Offline</span>
                                ) : (
                                    course.value
                                )}
                                {course.text === 'Discount' && '%'}
                            </h1>
                        </div>
                    ))}
                </div>
                {directRequestStatus.status !== 'rejected' &&
                    appConfig['direct_request_action'] === 'edit' &&
                    appConfig['cms_action'] === 'edit' && (
                        <DirectRequestActionComponent
                            batchEndDate={batchEndDate}
                            directRequestStatus={directRequestStatus}
                            id={id}
                            isTrainer={isTrainer(role)}
                            isDirectRequestPage={isDirectRequestPage}
                            batches={individualCourse.batches}
                            role={role}
                            selectedBatchDate={selectedBatchDate}
                        />
                    )}
            </div>
        </div>
    );
};
